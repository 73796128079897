import React, { Component } from 'react'
import add from '../../Images/add.svg';
import styles from "../user.module.css";
import { Box, Modal, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'

const Modalstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 643,
    maxWidth: 643,
    minHeight: 477,
    bgcolor: 'background.paper',
    border: 'transparent',
    boxShadow: 24,
    p: 4,
    borderRadius: '15px',
};
export default class UpdateUserModal extends Component {
    render() {
        const { EdituserOpen, handleEditUserModalClose, UpdateEditUser, handleRole, EditUserRole, EditUserFirstName, handleEditFirstName, EditUserLastName, handleEditLastName, EditUserEmail } = this.props
        return (
            <Modal open={EdituserOpen} aria-labelledby="modal-modal-title">
                <Box sx={Modalstyle}>
                    <div>
                        <span className={styles.EditUserHeading}>Edit User Details</span>
                        <img onClick={handleEditUserModalClose} className={styles.editmodalCrossImg} src={add} />
                    </div>
                    <div className={`${styles.editUsername} ${styles.TextWrapperEditUser}`}>
                        <div className={styles.editFirstNameWrapper}>
                            <label className={styles.EdituserLabel}>First Name</label>
                            <TextField label="First Name" value={EditUserFirstName} onChange={handleEditFirstName} className={styles.Edituserinput} variant="outlined" required fullWidth />
                        </div>
                        <div className={styles.editLastNameWrapper}>
                            <label className={styles.EdituserLabel}>Last Name</label>
                            <TextField value={EditUserLastName} label="Last Name" onChange={handleEditLastName} className={styles.Edituserinput} variant="outlined" required fullWidth />
                        </div>
                    </div>
                    <div className={styles.TextWrapperEditUser}>
                        <label className={styles.EdituserLabel}>Email <span className={styles.EditUserLabelSpan}>- if incorrect, delete user and re-send invitation to correct email</span></label>
                        <TextField value={EditUserEmail} label="User’s Email" className={styles.Edituserinput} variant="outlined" required disabled />
                    </div>
                    <div className={styles.TextWrapperEditUser}>
                        <label className={styles.EdituserLabel}>Role</label>
                        <FormControl fullWidth>
                            <InputLabel >Role</InputLabel>
                            <Select
                                label="Role"
                                onChange={handleRole}
                                value={EditUserRole}
                            >
                                <MenuItem value="Recruiter">Recruiter</MenuItem>
                                <MenuItem value="Admin">Admin</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className={styles.EditUSerBtns}>
                        <div onClick={handleEditUserModalClose} className={`${styles.EditUserCancelBtn}`}>Cancel</div>
                        <div onClick={UpdateEditUser} className={`${styles.EditUserUpdateBtn}`}>Update</div>
                    </div>

                </Box>
            </Modal>
        )
    }
}

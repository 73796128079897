import styles from "./Navbar.module.css";
import "./navUniversal.css";
import { Avatar, TextField } from "@mui/material";
import profile from "./../Images/Profile.jpg";
import React from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import { Card, Col, Container, Row, Button, Table } from "react-bootstrap";
import { Redirect } from "react-router";
import { sendTokenToChromeExtension } from "../Login/sendTokenToExt";
import add from "../Images/add.svg";
import UploadIcon from "../Images/document-upload.svg";

var images = "";
const options = [{ value: "logout", label: "Log out" }];
export class NavbarTop extends React.Component {
  constructor() {
    super();
    this.openHandler = this.openHandler.bind(this);
    this.popupCloseHandler = this.popupCloseHandler.bind(this);
    // this.onSubmit = this.onSubmit.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.closeTranscribeAlert = this.closeTranscribeAlert.bind(this);
    this.openTranscribeAlert = this.openTranscribeAlert.bind(this);
    this.closeTranscribeFaultAlert = this.closeTranscribeFaultAlert.bind(this);
    this.openTranscribeFaultAlert = this.openTranscribeFaultAlert.bind(this);

    this.closeSummarizeAlert = this.closeSummarizeAlert.bind(this);
    this.openSummarizeAlert = this.openSummarizeAlert.bind(this);
    this.closeSummarizeFaultAlert = this.closeSummarizeFaultAlert.bind(this);
    this.openSummarizeFaultAlert = this.openSummarizeFaultAlert.bind(this);

    this.closeExtractAlert = this.closeExtractAlert.bind(this);
    this.openExtrctAlert = this.openExtrctAlert.bind(this);
    this.closeExtractFaultAlert = this.closeExtractFaultAlert.bind(this);
    this.openExtractFaultAlert = this.openExtractFaultAlert.bind(this);
    this.onChangeQuestionLabel = this.onChangeQuestionLabel.bind(this);
    this.UploadCallBtn = this.UploadCallBtn.bind(this);

    this.state = {
      files: [],
      Name: "",
      name: "",
      Type: "",
      FileName: "",
      title: "",
      callName: "",
      image: null,
      fileUrl: [],
      User: "",
      visibility: false,
      recruiter: [],
      duration: "",
      person: "",
      show: false,
      alerting: false,
      transcribeAlert: false,
      transcribeFaultAlert: false,
      summarizeAlert: false,
      summarizeFaultAlert: false,
      extractAlert: false,
      extractFaultAlert: false,
      imageName: "",
      totalPage: 0,
      current: 1,
      minIndex: 0,
      maxIndex: 0,
      companyID: "",
      userLogged: "",
      hashFile: "",
      candidate_name: "",
      candidate_email: "",
      candidate_phone: "",
      search: "",
      searchDate: "",
      value: "",
      rolesDisplay: "",
      role: "",
      redirect: false,
      UserFirName: null,
      UserLasName: null,
      UserImage: null,
      UploadFileName: null,
    };
  }
  openHandler() {
    this.setState({ show: true });
  }
  popupCloseHandler() {
    this.setState({ show: false });
    // window.location.reload();
  }
  openAlert() {
    this.setState({ alerting: true });
    //console.log("button worjing")
  }

  componentDidMount() {
    const cookies = new Cookies();
    var config = cookies.get("token");

    if (!cookies.get("token")) {
      config = localStorage.getItem("token");
      console.log("token: ", config);
    }
    axios
      .get(process.env.REACT_APP_LOGIN_URL + "/login/users", {
        headers: {
          Authorization: "Token " + config,
        },
      })
      .then((response) => {
        // console.log(response.data[0].username)
        this.setState({ userLogged: response.data[0].username });
      })
      .catch((error) => console.log(error));

    axios
      .get(process.env.REACT_APP_LOGIN_URL + "/workorder/role", {
        headers: {
          Authorization: "Token " + config,
        },
      })
      .then((response) => {
        //this.setState({ userLogged: response.data[0].username })
        this.setState({ rolesDisplay: response.data.message });
        console.log(response.data);
      })
      .catch((error) => console.log(error));
    var Pages = window.location.pathname.slice(1);
    if (Pages == "home") {
      this.setState({ PageSelected: "Dashboard" });
    } else if (Pages == "calls") {
      this.setState({ PageSelected: "Calls" });
    } else if (Pages == "support") {
      this.setState({ PageSelected: "Support" });
    } else if (Pages == "questionbank") {
      this.setState({ PageSelected: "Questions" });
    } else {
      this.setState({ PageSelected: "" });
    }
    axios
      .get(process.env.REACT_APP_BACKEND_URL + "/login/get", {
        headers: {
          Authorization: "Token " + config,
        },
      })
      .then((response) => {
        // console.log(response.data.message.email)
        this.setState({
          UserFirName: response.data.message.firstName,
          UserLasName: response.data.message.lastName,
          UserImage: response.data.message.user_image_url,
        });
        //this.setState({ rolesDisplay:  })
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const cookies = new Cookies();
    var config = cookies.get("token");

    if (!cookies.get("token")) {
      config = localStorage.getItem("token");
      //console.log("token: ", config)
    }

    if (this.state.candidate_name == null || this.state.candidate_name == "" || this.state.candidate_name == "null"){
      alert("Enter First and Last Name")
    }

    else{
      //console.log("token: ", config)
    //console.log(this.state);
    //let grouping = window.location.pathname
    //console.log(grouping)
    let form_data = new FormData();
    var files = document.getElementById("image");
    console.log(files);
    var fileinput = files.files[0].name;
    var filers = fileinput.substring(0, fileinput.indexOf("."));
    images = filers;
    var roletype = this.state.role;
    if (!this.state.role) {
      roletype = 1;
    }

    form_data.append("audiofile", this.state.image);
    form_data.append("title", filers);
    form_data.append("role", roletype);

    form_data.append("candidate_name", this.state.candidate_name);
    form_data.append("candidate_email", this.state.candidate_email);
    form_data.append("candidate_phone", this.state.candidate_phone);
    form_data.append("callName", this.state.callName);
    form_data.append("user", this.state.userLogged);
    form_data.append("company", this.state.companyID);
    //images = String(this.state.image)
    //form_data.append('group', grouping);
    let url = process.env.REACT_APP_LOGIN_URL + "/file/posts";

    axios
      .post(url, form_data, {
        headers: {
          Authorization: "Token " + config,
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        //console.log(res.data);
        this.setState({ hashFile: res.data });
        var newUri = this.state.image;
        var newStuff = newUri;
        var timestamp = Math.floor(new Date().getTime() / 1000);

        const cookies = new Cookies();
        var config = cookies.get("token");

        if (!cookies.get("token")) {
          config = localStorage.getItem("token");
          //console.log("token: ", config)
        }
        const d = new Date();
        let text = d.toString();

        axios({
          method: "POST",
          url: process.env.REACT_APP_LOGIN_URL + "/task/job",
          headers: {
            Authorization: "Token " + config,
          },
          data: {
            jobid: images + text,
            uri: res.data.message,
            roleid: [this.state.role],
            quesID: [],
            questionkindcode: [],
            candidate_name: this.state.candidate_name,
            candidate_email: this.state.candidate_email,
            candidate_phone: this.state.candidate_phone,
          },
        })
          .then((response) => {
            this.openAlert();
            this.openTranscribeAlert();
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          })
          .catch((error) => {
            this.openTranscribeFaultAlert();
          });
      })
      .catch((err) => {
        console.log(err);
        alert("Something went wrong, please try again");
      });

    }
    
  };
  handleChangeSelect = (value) => {
    this.setState({ value }, () =>
      console.log(`Option selected:`, this.state.value)
    );
    // console.log(value)
    if (value.label == "Log out") {
      alert("Logged out");
      const cookies = new Cookies();
      cookies.remove("token"); //clear storage so no user is stored in the session
      cookies.remove("loggedin"); //clear storage so no user is stored in the session
      cookies.remove("loginfrommail"); //clear storage so no user is stored in the session
      localStorage.clear();
      //redirect user to the home page, which is protected, so will redirect to login page
      window.location.href = "/";
    }
  };

  onChangeName(e) {
    this.setState({ Name: e.target.value });
  }

  // handle the change of values
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  // handle the file
  handleImageChange = (e) => {
    console.log(e);
    this.setState({
      image: e.target.files[0],
    });
    this.setState({ UploadFileName: e.target.files[0].name });
    console.log(e.target.files[0].name);
  };
  handleChangeCandidateName = (e) => {
    const hasSpace = e.target.value.includes(' ');
    if (hasSpace){
      this.setState({
        candidate_name: e.target.value,
      });
    }
    else{
      this.setState({candidate_name: null});
    }
    
  };
  handleChangeCandidateEmail = (e) => {
    this.setState({
      candidate_email: e.target.value,
    });
  };

  handleChangeCandidatePhone = (e) => {
    this.setState({
      candidate_phone: e.target.value,
    });
  };
  onChangeQuestionLabel(e) {
    this.setState({ role: e.target.value });
  }

  wrapperFunction() {
    this.transcribeFile();
    this.summarizeFile();
  }
  // popupCloseHandler() {
  //     this.setState({ show: false })
  //     window.location.reload();
  // }
  // openAlert() {
  //     this.setState({ alerting: true })
  //     //console.log("button worjing")
  // }
  closeTranscribeAlert() {
    this.setState({ transcribeAlert: false });
  }
  closeTranscribeFaultAlert() {
    this.setState({ transcribeFaultAlert: false });
  }
  openTranscribeAlert() {
    this.setState({ transcribeAlert: true });
    //console.log("button worjing")
  }
  openTranscribeFaultAlert() {
    this.setState({ transcribeFaultAlert: true });
  }
  closeSummarizeAlert() {
    this.setState({ summarizeAlert: false });
  }
  closeSummarizeFaultAlert() {
    this.setState({ summarizeFaultAlert: false });
  }
  openSummarizeAlert() {
    this.setState({ summarizeAlert: true });
    // console.log("button worjing")
  }
  openSummarizeFaultAlert() {
    this.setState({ summarizeFaultAlert: true });
  }

  closeExtractAlert() {
    this.setState({ extractAlert: false });
  }
  closeExtractFaultAlert() {
    this.setState({ extractFaultAlert: false });
  }
  openExtrctAlert() {
    this.setState({ extractAlert: true });
    // console.log("button worjing")
  }
  openExtractFaultAlert() {
    this.setState({ extractFaultAlert: true });
  }
  openHandler() {
    this.setState({ show: true });
  }
  testFunction() {
    this.handleChange();
    this.handleImageChange();
  }
  UploadCallBtn() {
    document.querySelector("#image").click();
  }

  handleCreateNewItem = (e) => {
    const id = e.target.value;
    if (id == "settings") {
      this.setState({ redirect: true });
      setTimeout(() => {
        this.setState({ redirect: false });
      }, 100);
    }
    if (id == "logout") {
      //alert('Logged out')
      const cookies = new Cookies();
      cookies.remove('token', {
                path: '/',
                domain: 'squire-olas.com', // Replace with your desired domain
              }); //clear storage so no user is stored in the session
      cookies.remove("loggedin"); //clear storage so no user is stored in the session
      cookies.remove("loginfrommail"); //clear storage so no user is stored in the session
      cookies.remove("redirect"); //clear storage so no user is stored in the session
      cookies.remove("Isadmin"); //clear storage so no user is stored in the session
      //redirect user to the home page, which is protected, so will redirect to login page
      localStorage.clear();
      sendTokenToChromeExtension({
        message: "logoutApp",
      });
      window.location.href = "/";
    }
  };
  render() {
    const { userLogged, show, alerting, rolesDisplay, PageSelected } =
      this.state;
    const customStyles = {
      control: (base) => ({
        ...base,
        height: 70,
        minHeight: 35,
        width: 200,
        minWidth: 100,
      }),
    };
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to="/settings" />;
    }

    return (
      <div id="TopNavBarWrapper" className={styles.NavbarWrapperTop}>
        <div>
          <h1 className={styles.PageHeader}>{this.props.PageData}</h1>
        </div>
        <div className={styles.NavRightDataWrapper}>
          {/* <button className={styles.NavBarGroupBtn}>Create Group</button> */}
          <button className={styles.NavbarUploadBtn} onClick={this.openHandler}>
            Upload Call
          </button>
          <div className={styles.verticalLine}></div>
          <Avatar
            className={styles.UserImageNavbar}
            src={this.state.UserImage}
          />
          <p className={styles.UserNameNavbar}>
            {this.state.UserFirName} {this.state.UserLasName}
          </p>
          <FormControl sx={{ m: 1, minWidth: 5 }}>
            <InputLabel shrink={false}>{userLogged}</InputLabel>
            <Select
              // labelId="demo-simple-select-standard-label"
              label="Type"
              id="lang"
              onChange={this.handleCreateNewItem}
            >
              <MenuItem id="settings" value="settings">
                Settings
              </MenuItem>
              <MenuItem id="logout" value="logout">
                Logout
              </MenuItem>
            </Select>
          </FormControl>
        </div>

        <Modal
          className={styles.modalPopup}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={this.popupCloseHandler}
        >
          <div>
            <div className={styles.ModalHeader}>
              <h3 className={styles.createlabel}>Upload Call</h3>
              <div className={styles.closeicon}>
                <img onClick={this.popupCloseHandler} src={add} />
              </div>
            </div>
            <div className={styles.ModalWrapper}>
              <form onSubmit={this.handleSubmit}>
                <Alert className="alertsSize" show={alerting} variant="success">
                  <Alert.Heading>File Successfully Uploaded</Alert.Heading>
                  <p>The File is uploaded and actions are taking place!</p>
                </Alert>
                <div>
                  <div className={styles.modalWrapperInput}>
                    <h2 className={styles.InputLable}>Candidate Name</h2>
                    <TextField
                      id="candidateName"
                      //label="Name goes here ...."
                      //InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      value={this.state.candidate_name}
                      onChange={this.handleChangeCandidateName}
                      required
                      className={styles.modalPopupInput}
                      fullWidth
                  
                    />
                  </div>

                  <div className={styles.modalWrapperInput}>
                    <h2 className={styles.InputLable}>Candidate Email</h2>
                    <TextField
                      className={styles.modalPopupInput}
                      //label="Email goes here ...."
                      //InputLabelProps={{ shrink: true }}
                      id="candidateEmail"
                      variant="outlined"
                      value={this.state.candidate_email}
                      onChange={this.handleChangeCandidateEmail}
                      required
                      fullWidth
                    />
                  </div>

                  <div className={styles.modalWrapperInput}>
                    <h2 className={styles.InputLable}>
                      Candidate Phone Number
                    </h2>
                    <TextField
                      className={styles.modalPopupInput}
                      //label="Phone Number goes here ...."
                     // InputLabelProps={{ shrink: true }}
                      id="candidatePhone"
                      variant="outlined"
                      value={this.state.candidate_phone}
                      onChange={this.handleChangeCandidatePhone}
                      required
                      fullWidth
                    />
                  </div>

                  <div className={styles.modalWrapperInput}>
                    <h2 className={styles.InputLable}>Role</h2>
                    <FormControl sx={{ minWidth: 120 }} fullWidth>
                      <InputLabel id="demo-simple-select-helper-label">
                        Role
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        label="Role"
                        onChange={this.onChangeQuestionLabel}
                        required
                      >
                        {Array.isArray(rolesDisplay)
                          ? rolesDisplay.map((element) => {
                              return (
                                <MenuItem key={element.id} value={element.id}>
                                  {element.title}
                                </MenuItem>
                              );
                            })
                          : null}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className={styles.UploadFileWrapper}>
                  <img src={UploadIcon} />
                  <div
                    onClick={this.UploadCallBtn}
                    className={styles.UploadCallText}
                  >
                    Click to upload File
                  </div>
                  <div className={styles.maximumSize}>
                    Maximum file size 50 MB
                  </div>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    id="image"
                    accept=".mp3, .amr, .flac, .ogg, .webm, .wav"
                    className="label"
                    onChange={this.handleImageChange}
                    required
                  />
                  <div className={styles.UploadCallText}>
                    {this.state.UploadFileName && this.state.UploadFileName}{" "}
                  </div>
                </div>
                <input
                  className={styles.submitFile}
                  value="Upload Call"
                  type="submit"
                  // onClick={this.handleSubmit}
                />
              </form>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Box, Grid, Tab, } from "@mui/material";
import { NavbarLeft } from "../Components/Nav-Left/NavLeft";
import { NavbarTop } from "../Components/Navbar";
import UniversalCss from "../Universal.module.css";
import styles from "./user.module.css";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import InviteUserForm from "./InviteUserForm";
import UserDetails from "./UserDetails";
import Billing from "./Billing";
import ErrorModal from "../Components/Error_Modle/ErrorModal";
import SuccessModal from "../Components/Success_Modle/SuccessModal";
import axios from 'axios';
import Cookies from 'universal-cookie';
import ManageUserCharge from "./ManageUserCharge";





export default class UserReporting extends Component {
  constructor() {
    super();
    this.state = {
        activeUsers: '',
        activeAdmins: '',
        activeRecruiters: '',
        recordedCalls: '',
        recordedCalls: '',
        transcribedCalls: '',
        summarizedCalls: '',
        extractCalls: '',
        recruiterNotesCalls: '',

      }
    
  }
  componentDidMount() {
    this.getData();
  }

  async getData() {
    const cookies = new Cookies();
    var config = cookies.get("token")

    if (!cookies.get("token")) {
      config = localStorage.getItem("token")
    }

    var headerconfigAdmin = {
        method: 'get',
        url: process.env.REACT_APP_BACKEND_URL + '/company/reporting_users',
        headers: {
          'Authorization': 'Token ' + config,
        },
      };
  
      try {
        const response = await axios(headerconfigAdmin);
        const usersCount = response.data.users;
        const recruiterCount = response.data.recruiters;
        const admincount = response.data.admins;
       
        this.setState({
          activeAdmins: admincount,
          activeUsers: usersCount,
          activeRecruiters: recruiterCount,
        });
      } catch (error) {
        console.error(error);
      }

    // console.log(config);
    var headerconfig = {
      method: 'get',
      url: process.env.REACT_APP_BACKEND_URL + '/view/get_company_reporting_calls',
      headers: {
        'Authorization': 'Token ' + config,
      },
    };

    try {
      const response = await axios(headerconfig);
      const data = response.data.message;
      console.log(data);
      this.setState({
        recordedCalls: data
      });
    } catch (error) {
      console.error(error);
    }

    var headerconfig1 = {
        method: 'get',
        url: process.env.REACT_APP_BACKEND_URL + '/view/get_company_transcript_calls',
        headers: {
          'Authorization': 'Token ' + config,
        },
      };
  
      try {
        const response = await axios(headerconfig1);
        const data = response.data.message;
        console.log(data);
        this.setState({
          transcribedCalls: data
        });
      } catch (error) {
        console.error(error);
      }
   

      var headerconfig2 = {
        method: 'get',
        url: process.env.REACT_APP_BACKEND_URL + '/view/get_company_extract',
        headers: {
          'Authorization': 'Token ' + config,
        },
      };
  
      try {
        const response = await axios(headerconfig2);
        const data = response.data.message;
        console.log(data);
        this.setState({
          extractCalls: data
        });
      } catch (error) {
        console.error(error);
      }

      var headerconfig3 = {
        method: 'get',
        url: process.env.REACT_APP_BACKEND_URL + '/view/get_company_summarys',
        headers: {
          'Authorization': 'Token ' + config,
        },
      };
  
      try {
        const response = await axios(headerconfig3);
        const data = response.data.message;
        console.log(data);
        this.setState({
          summarizedCalls: data
        });
      } catch (error) {
        console.error(error);
      }

      var headerconfig4 = {
        method: 'get',
        url: process.env.REACT_APP_BACKEND_URL + '/view/get_company_recruiter_notes',
        headers: {
          'Authorization': 'Token ' + config,
        },
      };
  
      try {
        const response = await axios(headerconfig4);
        const data = response.data.message;
        console.log(data);
        this.setState({
          recruiterNotesCalls: data
        });
      } catch (error) {
        console.error(error);
      }
  }



  render() {
    return (
      <>
        
        <Box sx={{ flexGrow: 1 }}>
          <Grid container className={UniversalCss.GridContainerWrapperHegih}>
            <Grid item md={1.7} xs={2} className={UniversalCss.LeftNavbarShadow}>
              <NavbarLeft />
            </Grid>
            <Grid item md={10.3} xs={10} className={UniversalCss.navbarAndRouteWrapper} >
              <NavbarTop PageData={"Reporting"} />

              <h2>User stats</h2>
             <li>Users: {this.state.activeUsers}</li>
             <li>Admins: {this.state.activeAdmins}</li>
             <li>Recruiters: {this.state.activeRecruiters}</li>
            
             <h2>Reporting stats</h2>
             <li>Recorded Calls: {this.state.recordedCalls}</li>
             <li>Transcribed Calls: {this.state.transcribedCalls}</li>
             <li>Summarized Calls: {this.state.summarizedCalls}</li>
             <li>Extracted Calls: {this.state.extractCalls}</li>
             <li>Recruiter Notes Added:  {this.state.recruiterNotesCalls}</li>
            </Grid>
          </Grid>
        </Box >
      </>
    );
  }
}

import React, { Component } from 'react'
import add from '../../Images/add.svg';
import errorIcon from '../../Images/icons/Errorindicator.svg';
import styles from "../Initigration.module.css";
import { Box, Modal } from '@mui/material';


const Deletestyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 643,
    maxWidth: 643,
    minHeight: 477,
    bgcolor: 'background.paper',
    border: 'transparent',
    boxShadow: 24,
    p: 6,
    borderRadius: '15px',
    textAlign: 'center',
};
export default class ConFormationDisconnect extends Component {
    render() {
        const { confirmDisconnectConnect, handleClose, DisconnectApp, DisconnectAppName } = this.props
        return (
            <Modal open={confirmDisconnectConnect} aria-labelledby="modal-modal-title">
                <Box sx={Deletestyle}>
                    <img onClick={handleClose} style={{ position: 'relative', cursor: "pointer", left: '280px', top: '-35px' }} src={add} />
                    <div className={styles.ErrorMessageImageWrapper}>
                        <img src={errorIcon} style={{ width: "100%" }} />
                    </div>
                    <div>
                        <div className={styles.ModalHeadingError}>Are you sure you want to disconnect <br /> from {DisconnectAppName}?</div>
                    </div>
                    <div style={{ marginTop: "50px" }} className={styles.ConfirmDisconnectBtns}>
                        <div onClick={handleClose} className={`${styles.ConfirmDisconnectBtnsNo}`}>No, Thanks</div>
                        <div onClick={DisconnectApp} className={`${styles.ConfirmDisconnectBtnsYes}`}>Yes, Disconnect</div>
                    </div>
                </Box>
            </Modal>
        )
    }
}

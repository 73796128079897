import React, { Component } from "react";
import styles from './Roles.module.css'
import vuesaxlineartrash from "./../Images/vuesaxlineartrash.svg";

import axios from 'axios';
import Cookies from 'universal-cookie';
import { Button, Modal } from "react-bootstrap";


class PopupModalClass extends React.Component {
    constructor(props) {
        super(props)
        this.hideModal = this.hideModal.bind(this)
        this.getModal = this.getModal.bind(this)


        this.state = {
            show: false,
            id: this.props.passid,
            showMenu: true,
        }

    }
    getModal() {
        this.setState({ show: true });
    };

    hideModal() {
        this.setState({ show: false });
    };
    deleteQuestion(e) {
        const cookies = new Cookies();
        var config = cookies.get("token")

        if (!cookies.get("token")) {
            config = localStorage.getItem("token")
            //console.log("token: ", config)
        }
        //var teststuff = window.location.pathname.split('/'[0])
        //console.log(teststuff[2], " is the path")
        var urlToUse = window.location.pathname.split('/'[0])
        axios({
            method: "POST",
            url: process.env.REACT_APP_LOGIN_URL + '/workorder/updaterolequestionmap',
            headers: {
                'Authorization': 'Token ' + config
            },
            data: {
                priority: 0,
                archive: 1,
                questionbankid: this.props.passid,
                roleid: urlToUse[2]


            }
        }).then(
            response => {
                window.location.reload()
                //authentication is set to true, allowing for access to protected pages

            }
        )
            .catch((err) => {
                console.log(err)

            })
    }
    getModalMenu() {
        this.setState({ showMenu: true });
    };

    hideModalMenu() {
        window.location.reload()
        //this.setState({ showMenu: false });
    };

    render() {
        const { show, showMenu } = this.state



        return (

            <React.Fragment>
                {this.props.show && (
                    <div className={styles.modalStyle}>



                        <Modal
                            size="md"
                            className={styles.modalPopup}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered show={showMenu} onHide={this.hideModalMenu}>
                            <Modal.Header closeButton>
                                {this.props.pass_name}
                            </Modal.Header>
                            <Modal.Body>
                                <div className="rectangle-812">
                                    <div className="rectangle-813">
                                        <div className="flex-container-18">
                                            <img className={styles.vuesaxlineartrash} src={vuesaxlineartrash} />
                                            <button className={styles.primarybutton4} onClick={() => this.deleteQuestion(this.props.passid)} >Delete</button>
                                        </div>
                                    </div>


                                </div></Modal.Body>
                            <Modal.Footer>
                                <Button className={styles.closebutton} variant="secondary" onClick={this.hideModalMenu}>
                                    Close
                                </Button>

                            </Modal.Footer>
                        </Modal>



                    </div>

                    /*
<div className={styles.rectangle812} >
                            
                            <div className={styles.flexcontainer19} >
                                <img className={styles.vuesaxlineartrash} src={vuesaxlineartrash} />
                                <button className={styles.primarybutton4} onClick={() => this.deleteQuestion(this.props.passid)} >Delete</button>
                            </div>

                           
                            <button className={styles.primarybutton4} onClick={this.props.onHide}>Close</button>
                        </div>
                    */
                )}
            </React.Fragment>
        );
    }
}

export default PopupModalClass;

import React, { Component } from 'react'
import "./Waitloader.css"

export default class WaitLoader extends Component {
    render() {
        return (
            <>
                <div className='loaderWrapperDivWait'>
                    <div class="loading-outer">
                        <div class="loading-inner"></div>
                    </div>
                    <div className='LoaderWaitText'>Building Your Notetaker Instance, This Will Take A Minute or Two</div>
                </div>
            </>
        )
    }
}

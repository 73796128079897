import React from "react";
import axios from "axios";
import {
  Card,
  Col,
  Container,
  Row,
  Pagination,
  Button,
  Table,
  Alert,
} from "react-bootstrap";
import styles from "./Single_file.module.css";
import { parse, format } from 'date-fns';

import knight from "./../Images/squireInapp.png";

import Cookies from "universal-cookie";
import group48096677 from "./../Images/group48096677.svg";
import vuesaxlinearcall from "./../Images/vuesaxlinearcall.svg";

import vuesaxlinearmessage from "./../Images/vuesaxlinearmessage.svg";

import vuesaxlinearelement from "./../Images/vuesaxlinearelement.svg";
import group48096653 from "./../Images/group48096653.svg";
import group480966531 from "./../Images/group480966531.svg";
import group480966532 from "./../Images/group480966532.svg";
import group48096654 from "./../Images/group48096654.svg";

import { Link } from "react-router-dom";
import maskGroup from "./../Images/maskGroup.svg";
import Modal from "react-bootstrap/Modal";
import divider from "./../Images/divider.svg";

import vuesaxlineartimer1 from "./../Images/vuesaxlineartimer1.svg";
import Sound from "./../Images/Untitled-removebg-preview.png";
import Arrow from "./../Images/arrow-removebg-preview.png";
import Select from "react-select";
import { Avatar, InputLabel, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import profile from "./../Images/Profile.jpg";
import { NavbarTop } from "../Components/Navbar";
import { NavbarLeft } from "../Components/Nav-Left/NavLeft";
import LinearProgress from "@mui/material/LinearProgress";
import UniversalCss from "../Universal.module.css";
import wordsToNumbers from 'words-to-numbers';

import JsonData from './Nationalities.json'
import Loader from "../Login/Loader/Loader";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

//var recordings = [[["not summarized "],[3]] [["Summary is either in Progress, "],[5]] [[" Or you need to click summarize!"]]]
var speakers = [];
var images = "";
let bullhornIds = []
const options = [
  { value: "Foundational Data", label: "Foundational Data" },
  { value: "Unique Insights", label: "Unique Insights" },
  { value: "Recruiter Notes", label: "Recruiter Notes" },
  { value: "Business Dev", label: "Business Dev" },
  { value: "Job Specific", label: "Job Specific" },
];
export class Single extends React.Component {
  constructor() {
    super();
    //binding the data so it can be called
    this.downloadTextFile = this.downloadTextFile.bind(this);
    this.openHandler = this.openHandler.bind(this);
    this.closeTranscribeAlert = this.closeTranscribeAlert.bind(this);
    this.openTranscribeAlert = this.openTranscribeAlert.bind(this);
    this.closeTranscribeFaultAlert = this.closeTranscribeFaultAlert.bind(this);
    this.openTranscribeFaultAlert = this.openTranscribeFaultAlert.bind(this);

    this.closeEditAlert = this.closeEditAlert.bind(this);
    this.openEditAlert = this.openEditAlert.bind(this);
    this.closeEditFaultAlert = this.closeEditFaultAlert.bind(this);
    this.openEditFaultAlert = this.openEditFaultAlert.bind(this);

    this.popupCloseHandler = this.popupCloseHandler.bind(this);

    this.updateExtract = this.updateExtract.bind(this);
    this.changeTalismanId = this.changeTalismanId.bind(this)
    this.handleChangeCandidateName = this.handleChangeCandidateName.bind(this)
    this.handleChangeCandidatePhone = this.handleChangeCandidatePhone.bind(this)
    this.handleChangeCandidateEmail = this.handleChangeCandidateEmail.bind(this)
    this.likeFeedback = this.likeFeedback.bind(this)
    this.dislikeFeedback = this.dislikeFeedback.bind(this)
    this.likeFeedbackSumm = this.likeFeedbackSumm.bind(this)
    this.dislikeFeedbackSumm = this.dislikeFeedbackSumm.bind(this)
    this.likeFeedbackExt = this.likeFeedbackExt.bind(this)
    this.dislikeFeedbackExt = this.dislikeFeedbackExt.bind(this)
    this.updateExtract2 = this.updateExtract2.bind(this);

    this.audioRef = React.createRef();

    //setting to empty strings
    this.state = {
      files: [],
      showing: false,
      status: "",
      video: "",
      TranscriptedText: [],
      SummarizedText: [],
      channels: [],
      pathnames: "",
      callInfo: "",
      callInfoNumb: "",
      callInfoCand: "",
      foundData: false,
      extractShow: false,
      summaryShow: true,
      uniqueInsights: false,
      recruitNotes: false,
      businessDev: false,
      jobSpecificData: false,

      fullName: "",
      origfullName: "",
      newfullName: "",
      emailAddress: "",
      origEmailAddress: "",
      newEmailAddress: "",
      phoneNumber: "",
      origPhoneNumber: "",
      newPhoneNumber: "",
      currentLocation: "",
      newCurrentLocation: "",
      origCurrentLocation: "",
      desiredLocation: "",
      newDesiredLocation: "",
      origDesiredLocation: "",
      salExpectations: "",
      newSalExpectations: "",
      orgiSalExpectations: "",
      relevantExperience: "",
      origRelevantExperience: "",
      newRelevantExperience: "",
      skills: "",
      origSkills: "",
      newSkills: "",
      languagesTech: "",
      origLanguagesTech: "",
      newLanguagesTech: "",
      langProficiency: "",
      newLangProficiency: "",
      origLangProficiency: "",
      previousIndustries: "",
      newPreviousIndustries: "",
      origPreviousIndustries: "",
      interestOutside: "",
      newInterestOutside: "",
      origInterestOutside: "",
      hobbies: "",
      newHobbies: "",
      origHobbies: "",
      school: "",
      newSchool: "",
      origSchool: "",
      otherCompanies: "",
      newOtherCompanies: "",
      origOtherCompanies: "",
      hiringManagers: "",
      newHiringManagers: "",
      origHiringManagers: "",

      finalArray: [],
      sendExtractUpdate: [],
      labelsForExtract: [],
      duration: "",
      searchField: "",
      timers: [],
      setFinalArray: [],
      BhRestToken: "",
      restUrl: "",
      candidate_name: "",
      candidate_email: "",
      candidate_phone: "",
      show: false,
      alerting: false,
      userLogged: "",
      value: "",
      origSummary: [],
      editing: false,
      callInfoEmail: "",
      questions: [],
      BullHornID: [],
      transcribeAlert: false,
      transcribeFaultAlert: false,
      editAlert: false,
      editFaultAlert: false,
      CopyAlert: false,
      itrisID: "",
      Transcript: {},
      Loader: false,
      AtsConnected: '',
      TalismanID: '',
      cc_consultant: '',
      call_ids: '',
      company: '',
      file_key: '',
      hash: '',
      name: '',
      user: '',
      audioDuration: 0,
      FreeForm: '',
      transcriptStatus: '',
      summStatus: 'Loading',
      isClicked: false,
      isClicked1: false,
      showHoverBox: false,
      isClickedSumm: false,
      isClickedSumm1: false,
      showHoverBoxSumm: false,
      isClickedExt: false,
      isClickedExt1: false,
      showHoverBoxExt: false,
      isVisibleStuff: true,
      isVisibleStuffSumm: true,
      isVisibleStuffExt: true,
      bulhornAlertTranscript: false,
      bullhornAlertTranscriptFault: false,
      bullhornAlertSumm: false,
      bullhornAlertSummFault: false,
      bullhornExtractAlert: false,
      bullhornExtractAlertFault: false,
      loading: false,
      loadingSumm: false,

    };
  }

  closeTranscribeAlert() {
    this.setState({ transcribeAlert: false });
  }
  openTranscribeAlert() {
    this.setState({ transcribeAlert: true });
    //console.log("button worjing")
  }
  openTranscribeFaultAlert() {
    this.setState({ transcribeFaultAlert: true });
  }
  closeTranscribeFaultAlert() {
    this.setState({ transcribeFaultAlert: false });
  }

  closeEditAlert() {
    this.setState({ editAlert: false });
  }
  openEditAlert() {
    this.setState({ editAlert: true });
    //console.log("button worjing")
  }
  openEditFaultAlert() {
    this.setState({ editFaultAlert: true });
  }
  closeEditFaultAlert() {
    this.setState({ editFaultAlert: false });
  }

  openCopyAlert() {
    this.setState({ CopyAlert: !this.state.CopyAlert });
    //console.log("button worjing")
  }

  likeFeedback() {
    this.setState((prevState) => ({
      isClicked: !prevState.isClicked,
      isClicked1: false, // Ensure button 2 is unclicked
      showHoverBox: true,
      isVisibleStuff: false
    }));


    let newUrl = window.location.pathname;
    const cookies = new Cookies();
    var config = cookies.get("token");

    if (!cookies.get("token")) {
      console.log("here wer are anfsfsdgd");
      config = localStorage.getItem("token");
      //console.log("token: ", config)
    }

    let feedback = 1

    axios({
      method: "PUT",
      //url: process.env.REACT_APP_LOGIN_URL + '/questionbank/questiondelete',
      url: process.env.REACT_APP_BACKEND_URL + `/view/dislike_rate${newUrl}`,
      headers: {
        Authorization: "Token " + config,
      },

      data: {
        feedback: feedback
      }

    }).then(
      response => {
        console.log(response)
        //window.location.reload();



      }).catch(error => console.log(error))
    /*setTimeout(() => {
      this.setState({ showHoverBox: false });
    }, 5000); // 10 seconds (in milliseconds)*/
  }

  dislikeFeedback() {
    let newUrl = window.location.pathname;
    this.setState((prevState) => ({
      isClicked: false, // Ensure button 1 is unclicked
      isClicked1: !prevState.isClicked1,
      showHoverBox: true,
      isVisibleStuff: false
    }));
    const cookies = new Cookies();
    var config = cookies.get("token");

    if (!cookies.get("token")) {
      console.log("here wer are anfsfsdgd");
      config = localStorage.getItem("token");
      //console.log("token: ", config)
    }

    let feedback = -1

    axios({
      method: "PUT",
      //url: process.env.REACT_APP_LOGIN_URL + '/questionbank/questiondelete',
      url: process.env.REACT_APP_BACKEND_URL + `/view/dislike_rate${newUrl}`,
      headers: {
        Authorization: "Token " + config,
      },

      data: {
        feedback: feedback
      }

    }).then(
      response => {
        console.log(response)
        //window.location.reload();



      }).catch(error => console.log(error))
    setTimeout(() => {
      this.setState({ showHoverBox: false });
    }, 5000); // 10 seconds (in milliseconds)
  }


  likeFeedbackSumm() {
    this.setState((prevState) => ({
      isClickedSumm: !prevState.isClickedSumm,
      isClickedSumm1: false, // Ensure button 2 is unclicked
      showHoverBoxSumm: true,
      isVisibleStuffSumm: false
    }));


    let newUrl = window.location.pathname;
    const cookies = new Cookies();
    var config = cookies.get("token");

    if (!cookies.get("token")) {
      console.log("here wer are anfsfsdgd");
      config = localStorage.getItem("token");
      //console.log("token: ", config)
    }

    let feedback = 1

    axios({
      method: "PUT",
      //url: process.env.REACT_APP_LOGIN_URL + '/questionbank/questiondelete',
      url: process.env.REACT_APP_BACKEND_URL + `/view/dislike_rate_summ${newUrl}`,
      headers: {
        Authorization: "Token " + config,
      },

      data: {
        feedback: feedback
      }

    }).then(
      response => {
        console.log(response)
        //window.location.reload();



      }).catch(error => console.log(error))

  }

  dislikeFeedbackSumm() {
    let newUrl = window.location.pathname;
    this.setState((prevState) => ({
      isClickedSumm: false, // Ensure button 1 is unclicked
      isClickedSumm1: !prevState.isClickedSumm1,
      showHoverBoxSumm: true,
      isVisibleStuffSumm: false
    }));
    const cookies = new Cookies();
    var config = cookies.get("token");

    if (!cookies.get("token")) {
      console.log("here wer are anfsfsdgd");
      config = localStorage.getItem("token");
      //console.log("token: ", config)
    }

    let feedback = -1

    axios({
      method: "PUT",
      //url: process.env.REACT_APP_LOGIN_URL + '/questionbank/questiondelete',
      url: process.env.REACT_APP_BACKEND_URL + `/view/dislike_rate_summ${newUrl}`,
      headers: {
        Authorization: "Token " + config,
      },

      data: {
        feedback: feedback
      }

    }).then(
      response => {
        console.log(response)
        //window.location.reload();



      }).catch(error => console.log(error))

  }


  likeFeedbackExt() {
    this.setState((prevState) => ({
      isClickedExt: !prevState.isClickedExt,
      isClickedExt1: false, // Ensure button 2 is unclicked
      showHoverBoxExt: true,
      isVisibleStuffExt: false
    }));


    let newUrl = window.location.pathname;
    const cookies = new Cookies();
    var config = cookies.get("token");

    if (!cookies.get("token")) {
      console.log("here wer are anfsfsdgd");
      config = localStorage.getItem("token");
      //console.log("token: ", config)
    }

    let feedback = 1

    axios({
      method: "PUT",
      //url: process.env.REACT_APP_LOGIN_URL + '/questionbank/questiondelete',
      url: process.env.REACT_APP_BACKEND_URL + `/view/dislike_rate_ext${newUrl}`,
      headers: {
        Authorization: "Token " + config,
      },

      data: {
        feedback: feedback
      }

    }).then(
      response => {
        console.log(response)
        //window.location.reload();



      }).catch(error => console.log(error))

  }

  dislikeFeedbackExt() {
    let newUrl = window.location.pathname;
    this.setState((prevState) => ({
      isClickedExt: false, // Ensure button 1 is unclicked
      isClickedExt1: !prevState.isClickedExt1,
      showHoverBoxExt: true,
      isVisibleStuffExt: false
    }));
    const cookies = new Cookies();
    var config = cookies.get("token");

    if (!cookies.get("token")) {
      console.log("here wer are anfsfsdgd");
      config = localStorage.getItem("token");
      //console.log("token: ", config)
    }

    let feedback = -1

    axios({
      method: "PUT",
      //url: process.env.REACT_APP_LOGIN_URL + '/questionbank/questiondelete',
      url: process.env.REACT_APP_BACKEND_URL + `/view/dislike_rate_ext${newUrl}`,
      headers: {
        Authorization: "Token " + config,
      },

      data: {
        feedback: feedback
      }

    }).then(
      response => {
        console.log(response)
        //window.location.reload();



      }).catch(error => console.log(error))

  }
  // get the data on load of the page
  componentDidMount() {
    const cookies = new Cookies();
    var config = cookies.get("token");

    if (!cookies.get("token")) {
      console.log("here wer are anfsfsdgd");
      config = localStorage.getItem("token");
      //console.log("token: ", config)
    }
    this.intigrationappInfo()
    this.audioRef.current.addEventListener('loadedmetadata', this.handleLoadedMetadata);

    // slice the url
    let newUrl = window.location.pathname;
    axios
      .get(process.env.REACT_APP_LOGIN_URL + "/login/users", {
        headers: {
          Authorization: "Token " + config,
        },
      })
      .then((response) => {
        // console.log(response.data[0].username)
        this.setState({ userLogged: response.data[0].username });
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.status === 401) {
          // Clear cookies or perform any other action
          // Replace 'clearCookies()' with the actual function to clear cookies
          const cookies = new Cookies();
          cookies.remove('token', {
            path: '/',
            domain: 'squire-olas.com', // Replace with your desired domain
          }); //clear storage so no user is stored in the session
          cookies.remove("loggedin"); //clear storage so no user is stored in the session
          cookies.remove("loginfrommail"); //clear storage so no user is stored in the session
          cookies.remove("redirect"); //clear storage so no user is stored in the session
          cookies.remove("Isadmin"); //clear storage so no user is stored in the session
          window.location.href = '/'
        }
      });



    axios
      .get(process.env.REACT_APP_LOGIN_URL + `/task/summ_status${newUrl}`, {
        headers: {
          Authorization: "Token " + config,
        },

      })
      .then((response) => {

        setTimeout(() => {
          this.setState({ summStatus: response.data.message }); // Replace with your actual data fetching logic
        }, 2000);
      })
      .catch((error) => console.log(error));
    axios
      .get(process.env.REACT_APP_LOGIN_URL + `/task/transcription_status${newUrl}`, {
        headers: {
          Authorization: "Token " + config,
        },

      })
      .then((response) => {
        console.log(response.data.message, "message")
        this.setState({ transcriptStatus: response.data.message });
      })
      .catch((error) => console.log(error));

    //console.log("token: ", config)
    axios
      .get(process.env.REACT_APP_LOGIN_URL + "/login/identify", {
        headers: {
          Authorization: "Token " + config,
        },
      })
      .then((response) => {
        //console.log(response.data[0].companyid)
        this.setState({ companyID: response.data[0].companyid });
      })
      .catch((error) => console.log(error));

    axios
      .get(process.env.REACT_APP_FILEUPLOAD_URL + `/file/view${newUrl}`, {
        headers: {
          Authorization: "Token " + config,
        },
      })
      .then((response) => {
        axios
          .get(
            process.env.REACT_APP_FILEUPLOAD_URL + `/file/view_info${newUrl}`,
            {
              headers: {
                Authorization: "Token " + config,
              },
            }
          )
          .then((response) => {
            console.log("tagset", response.data.message)
            this.setState({ callInfo: response.data.message.user });
            this.setState({ callInfoCand: response.data.message.cand_name });
            this.setState({ callInfoNumb: response.data.message.cand_phone });
            this.setState({ callInfoEmail: response.data.message.cand_email });
            this.setState({ call_ids: response.data.message.call_ids });
            this.setState({ company: response.data.message.company });
            this.setState({ file_key: response.data.message.file_key });
            this.setState({ hash: response.data.message.hash });
            this.setState({ name: response.data.message.name });
            this.setState({ user: response.data.message.user });


            let email = response.data.message.cand_email;

            

                this.setState({ BhRestToken: sessionStorage.getItem('BhRestToken') })
                this.setState({ restUrl: sessionStorage.getItem('restUrl') })

                axios({
                  method: "GET",
                  url: process.env.REACT_APP_LOGIN_URL + '/integration_ats/candidate',
                  headers: {
                    Authorization: "Token " + config,
                  },
                  params: {
                    email: email,
                    BhRestToken: sessionStorage.getItem('BhRestToken'),
                    restUrl: sessionStorage.getItem('restUrl')

                  },
                })

                  .then((response) => {
                    //let messages = JSON.parse(response.data);
                    const ids = response.data.data.map(item => item.id); // Creates an array of ids from the response data

                    // Log all IDs to the console
                    console.log("IDs:", ids);

                    // You can now use 'ids' array for further processing
                    // For example, to log each ID:
                    ids.forEach(id => console.log(id));
                    bullhornIds = ids
                    this.setState({ BullHornID: ids })


                  })
                  .catch((error) => console.log(error));


             


            console.log("***********************************************************")


            let str = response.data.message.cand_name.split(" ");
            let str1 = response.data.message.cand_email;
            console.log(str);
            if (this.state.AtsConnected == "itris") {

              console.log("find emaul0", response.data.message.cand_email)


              axios
                .get(process.env.REACT_APP_LOGIN_URL + "/integration_ats/get_id", {
                  headers: {
                    Authorization: "Token " + config,
                  },
                  params: {
                    firstName: str[0],
                    lastName: str[1],
                    emailAddress: response.data.message.cand_phone,
                  },
                })
                .then((response) => {
                  console.log("here23", response.data.message)
                  if (response.data.message == "Candidate Not Found") {
                    if (this.fetchPromise) {
                      // already mounted previously

                      return;
                    }
                    this.fetchPromise = fetch(
                      process.env.REACT_APP_LOGIN_URL + "/integration_ats/create_itris",
                      {
                        method: "POST",
                        headers: {
                          Authorization: "Token " + config,
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          firstName: str[0],
                          lastName: str[1],
                          //emailAddress: response.data.message.cand_email
                        }),
                      }
                    )
                      .then((res) => res.json()) // then(res => res.text())  for converting to text


                      .catch((err) => {
                        console.log(err);
                      });
                    console.log("fetched");

                  }
                  this.setState({ itrisID: response.data.message.id });
                  localStorage.setItem("ITRISToken", response.data.token);
                })
                .catch((err) => {
                  console.log(err);
                  if (
                    err.message == "Candidate Not Found" ||
                    "'NoneType' object is not subscriptable"
                  ) {
                    if (this.fetchPromise) {
                      // already mounted previously

                      return;
                    }
                    this.fetchPromise = fetch(
                      process.env.REACT_APP_LOGIN_URL + "/integration_ats/create_itris",
                      {
                        method: "POST",
                        headers: {
                          Authorization: "Token " + config,
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          firstName: str[0],
                          lastName: str[1],
                          //emailAddress: response.data.message.cand_email
                        }),
                      }
                    )
                      .then((res) => res.json()) // then(res => res.text())  for converting to text
                      .then((data) => {
                        console.log(data.message, "fetched");
                        this.setState({ itrisID: data.message })
                        axios({
                          method: "POST",
                          url: process.env.REACT_APP_LOGIN_URL + "/integration_ats/create_itris_email",
                          headers: {
                            Authorization: "Token " + config,
                          },
                          data: {
                            id: data.message,
                            communicationMethodTypeId: 1,
                            value: response.data.message.cand_email,
                          },
                        })
                          .then((response) => {
                            console.log(response, "candidate details");
                            //window.location.href = '/newCall'
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      })

                      .catch((err) => {
                        console.log(err);
                      });
                    console.log("fetched");
                  }

                  /*axios({
                                  method: "POST",
                                  url: 'http://127.0.0.1:8000/integration_ats/create_itris',
                                  headers: {
                                      'Authorization': 'Token ' + config
                                  },
                                  data: {
                                      firstName: str[0],
                                  lastName:str[1],
                                  //emailAddress: response.data.message.cand_email
                                  }
                              }).then(
                                  response => {
                                      console.log(response);
                                      //window.location.href = '/newCall'
                                  }
                              )
                                  .catch((err) => {
                      
                                      console.log(err);
                                  });*/
                  console.log("how many", err);
                });
            }
            else if (this.state.AtsConnected == "Talisman") {
              console.log("talisman connected", this.state.TalismanID, this.state.cc_consultant)
              axios
                .get(
                  process.env.REACT_APP_FILEUPLOAD_URL + `/integration_ats/get_talisman_cc_consultant`,
                  {
                    headers: {
                      Authorization: "Token " + config,
                    },
                  }
                )
                .then((response) => {
                  //console.log(response.data)
                  this.setState({ cc_consultant: response.data.message });
                })
                .catch((error) => console.log(error));


              axios({
                method: "POST",
                url: process.env.REACT_APP_FILEUPLOAD_URL + "/integration_ats/get_talisman_token ",
                headers: {
                  Authorization: "Token " + config,
                }
              })
                .then((response) => {
                  console.log(response.data)
                  //this.setState({ duration: response.data.message[0] });
                  /*axios({
                    method: "GET",
                    url: process.env.REACT_APP_FILEUPLOAD_URL + "/integration_ats/get_talisman_candidate",
                    headers: {
                      Authorization: "Token " + config,
                    },
                    params: {
                      id: 10000040,
                      // Add more parameters if needed
                    },
                  })
                    .then((response) => {
                      console.log(response.data, "here we go");
                      // this.setState({ duration: response.data.message[0] });
                    })
                    .catch((error) => console.log(error));*/
                })
                .catch((error) => console.log(error));


            }
            else if (this.state.AtsConnected == "Bullhorn") {

            }
          })

          .catch((error) => console.log(error));


        axios
          .get(
            process.env.REACT_APP_FILEUPLOAD_URL + `/file/callName${newUrl}`,
            {
              headers: {
                Authorization: "Token " + config,
              },
            }
          )
          .then((response) => {
            //console.log(response.data)
            this.setState({ pathnames: response.data });
          })
          .catch((error) => console.log(error));

        axios
          .get(
            process.env.REACT_APP_FILEUPLOAD_URL + `/file/duration${newUrl}`,
            {
              headers: {
                Authorization: "Token " + config,
              },
            }
          )
          .then((response) => {
            //console.log(response.data)
            this.setState({ duration: response.data.message[0] });
          })
          .catch((error) => console.log(error));
        this.setState({ files: response.data.message });
        axios
          .get(
            process.env.REACT_APP_TASKS_VIEW_URL + `/view/transcribe${newUrl}`,
            {
              headers: {
                Authorization: "Token " + config,
              },
            }
          )
          .then((response) => {
            var transcript = JSON.parse(
              response.data.message[0].transcriptionconv
            );
            transcript = JSON.parse(transcript);

            this.setState({ Transcript: transcript });
          })
          .catch((error) => console.log(error));

        axios
          .get(process.env.REACT_APP_LOGIN_URL + `/view/extract${newUrl}`, {
            headers: {
              Authorization: "Token " + config,
            },
          })
          .then((response) => {
            // /console.log(response)
            var array1 = [];
            for (let jj = 0; jj < response.data.message.length; jj++) {
              if (
                response.data.message[jj].questionid ==
                response.data.questions[jj][0].id
              ) {
                if (response.data.message[jj].answertext) {
                  let splitting = response.data.message[jj].answertext;

                  var array = splitting.split("selected");
                  var currentString = array[0];
                  //\\u20ac
                  //("\\u20ac", "€");

                  if (currentString.includes("\\u00a3") || currentString.includes("\\u20ac")) {
                    var FormattedSet = currentString.replaceAll("\\u00a3", "£").replaceAll("\\u20ac", "€");

                    var newString = FormattedSet.substring(
                      0,
                      currentString.length - 10
                    );
                    var finalString = newString.slice(2);
                  }
                  else {
                    var newString = currentString.substring(
                      0,
                      currentString.length - 5
                    );
                    var finalString = newString.slice(2);
                  }


                  //console.log("uid", response.data.questions[jj][0].id)

                  array1.push({
                    answer: finalString,
                    question: response.data.questions[jj][0].questiontext,
                    questionID: response.data.questions[jj][0].id,
                  });
                } else {
                  let splitting = response.data.message[jj].answertextuser;
                  var array = splitting.split("selected");
                  var currentString = array[0];
                  if (currentString.includes("\\u00a3")) {
                    var FormattedSet = currentString.replaceAll("\\u00a3", "£");

                    var newString = FormattedSet.substring(
                      0,
                      currentString.length - 10
                    );
                    var finalString = newString.slice(2);
                  } else {
                    var newString = currentString.substring(
                      0,
                      currentString.length - 5
                    );
                    var finalString = newString.slice(2);
                  }
                  console.log("fini", finalString)
                  if (finalString.includes("\\n\\n")) {
                    const parts = finalString.split("\\n\\n"); // Split the string at double line breaks

                    const extractedInfo = [];
                    for (let i = 1; i < parts.length; i++) {
                      extractedInfo.push(parts[i]); // Extract each part after the line break and add it to the array
                    }


                    const separator = " ";
                    const concatenatedString = extractedInfo.join(separator);

                    console.log(concatenatedString, "extr");

                    finalString = concatenatedString

                  }

                  console.log("final", finalString)
                  array1.push({
                    answer: finalString,
                    question: response.data.questions[jj][0].questiontext,
                    questionID: response.data.questions[jj][0].id,

                  });


                }
              }
              /*for (let hh = 0; hh<response.data.questions.length; hh++){
                                console.log("no", response.data.questions[hh], )
                               
                            }*/
            }
            console.log(array1)
            this.setState({ labelsForExtract: array1 });
          })
          .catch((error) => console.log(error));

        //get the summary from the backend
        axios
          .get(
            process.env.REACT_APP_TASKS_VIEW_URL + `/view/summarize${newUrl}`,
            {
              headers: {
                Authorization: "Token " + config,
              },
            }
          )
          .then((response) => {
            //console.log("ehrer", response.data.message[0].summarizedtextuser)
            //vasr sortedSumm =
            var summary = "";
            console.log(response.data.message)
            if (response.data.message[0].summarizedtextuser) {
              summary = JSON.parse(response.data.message[0].summarizedtextuser);
              //if (summary)
            } else {
              summary = JSON.parse(response.data.message[0].summarizedtext);
              console.log(summary);
              for (let i = 0; i < summary.length; i++) {
                //console.log(summary[i])
                if (summary[i][0] == "Information not provided.") {
                  delete summary[i];
                  //summary[i].splice(i, 1)
                }
              }

              // /if (summary)
            }

            this.setState({ origSummary: summary });


            if (response.data.message[0].summarizedtext == "Not transcribed") {
              this.setState({
                SummarizedText: [],
              });
            } else {
              this.setState({ SummarizedText: summary });
            }
          })
          .catch((error) => console.log(error));
        this.downloadTextFile();
      })
      .catch((error) => {
        //window.location.href = '/'
      });



    axios
      .get(
        process.env.REACT_APP_TASKS_VIEW_URL + `/integration_ats/get_free_form_notes`,
        {
          headers: {
            Authorization: "Token " + config,
          },
          params: {
            url: newUrl, // Pass newUrl as a parameter
          },
        }
      )
      .then((response) => {
        // console.log(response.data[0].username)
        const newDataFromSecondEndpoint = response.data.message[0].notes;
        //console.log(response.data.message[0].notes, "sumi")
        this.setState({ FreeForm: newDataFromSecondEndpoint })
      })
      .catch((error) => {
        console.log(error);
      });


  }
  componentWillUnmount() {
    // Remove the event listener when the component unmounts to avoid memory leaks
    this.audioRef.current.removeEventListener('loadedmetadata', this.handleLoadedMetadata);
  }
  handleLoadedMetadata = () => {
    // Access the duration of the audio element and update the state
    const audioDuration = this.audioRef.current.duration;

    // Calculate minutes and seconds
    const minutes = Math.floor(audioDuration / 60);
    const seconds = Math.floor(audioDuration % 60); // Use Math.floor to round down

    // Update the state
    this.setState({ audioDuration: { minutes, seconds } });
  };
  updateTagset() {
    const cookies = new Cookies();
    var config = cookies.get("token")
    let newUrl = window.location.pathname

    if (!cookies.get("token")) {
      config = localStorage.getItem("token")
    }
    console.log("updated tags", this.state.callInfoCand, this.state.callInfoEmail, this.state.callInfoNumb)

    axios({
      method: "POST",
      url: process.env.REACT_APP_LOGIN_URL + `/file/update_info${newUrl}`,
      headers: {
        Authorization: "Token " + config,
      },
      data: {
        cand_name: this.state.callInfoCand,
        cand_phone: this.state.callInfoNumb,
        cand_email: this.state.callInfoEmail,
        call_ids: this.state.call_ids,
        company: this.state.company,
        file_key: this.state.file_key,
        hash: this.state.hash,
        name: this.state.name,
        user: this.state.user

      },
    })
      .then((response) => {
        console.log(response.data, "candidate details");
        window.location.reload()
      })
      .catch((err) => {
        console.log(err);
      });

  }
  intigrationappInfo() {
    const cookies = new Cookies();
    var config = cookies.get("token")

    if (!cookies.get("token")) {
      config = localStorage.getItem("token")
    }

    var data = {
      method: 'get',
      url: process.env.REACT_APP_BACKEND_URL + '/integration_ats/get_status',
      headers: {
        'Authorization': 'Token ' + config
      }
    };

    axios(data)
      .then((response) => {
        console.log(response.data.message);
        if (response.data.message === "Itris") {
          this.setState({ AtsConnected: "itris" })
        }
        if (response.data.message === "Talisman") {
          this.setState({ AtsConnected: "Talisman" })
        }
        if (response.data.message === "Bullhorn") {
          this.setState({ AtsConnected: "Bullhorn" })
        }
      })
      .catch((error) => {
        console.log(error);
      });

  }
  // download the file
  // create a dynamic url in the backend which the user can access the file online, itwill become unavailable after 1 hour
  downloadTextFile() {
    const cookies = new Cookies();
    var token = cookies.get("token");

    if (!cookies.get("token")) {
      token = localStorage.getItem("token");
      //console.log("token: ", config)
    }
    //console.log("token: ", token)
    let newUrl = window.location.pathname;
    //console.log(newUrl)

    axios
      .get(process.env.REACT_APP_FILEUPLOAD_URL + `/file/download${newUrl}`, {
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response) => {
        //console.log(response.data.message);
        //var newResponse = response.data.substr(0, 10) + '.eu-west-1' + response.data.substr(10);
        //indow.location.href = "/" //loads in a different page
        this.setState({ video: response.data.message });
        console.log("audio", response.data.message.duration)

        //window.location.href = response.data;
      })
      .catch((error) => { });
  }
  // delete the file from s3
  delete() {
    let newUrl = window.location.pathname.slice(7);
    //console.log(newUrl)
    axios({
      method: "POST",
      url: process.env.REACT_APP_FILEUPLOAD_URL + "/api/delete",
      data: {
        file_name: newUrl,
      },
    })
      .then((response) => {
        //console.log(response);
        window.location.href = "/newCall";
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // set the state of the name
  onChangeName(e) {
    this.setState({ Name: e.target.value });
  }
  changeTalismanId(e) {
    this.setState({ TalismanID: e.target.value });
    /*const cookies = new Cookies();
     var config = cookies.get("token");
 
     if (!cookies.get("token")) {
       console.log("here wer are anfsfsdgd");
       config = localStorage.getItem("token");
       //console.log("token: ", config)
     }
     axios({
       method: "GET",
       url: process.env.REACT_APP_FILEUPLOAD_URL + "/integration_ats/get_talisman_candidate",
       headers: {
         Authorization: "Token " + config,
       },
       params: {
         id:  e.target.value,
         // Add more parameters if needed
       },
     })
       .then((response) => {
         console.log(response.data, "here we go");
         // this.setState({ duration: response.data.message[0] });
       })
       .catch((error) => console.log(error));*/
  }
  // post to create new group
  onSubmit(e) {
    e.preventDefault();
    //post request to post the data
    axios({
      method: "POST",
      url: process.env.REACT_APP_TESTING_URL + "/api/create",
      data: {
        file_name: this.state.Name,
      },
    });
  }
  // handle the change of values
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  // handle the file
  handleImageChange = (e) => {
    this.setState({
      image: e.target.files[0],
    });
  };
  handleChangeCandidateName = (event) => {
    this.setState({ callInfoCand: event.target.textContent });
  };
  handleChangeCandidateEmail = (event) => {
    this.setState({ callInfoEmail: event.target.textContent });
  };
  handleChangeCandidatePhone = (event) => {
    this.setState({ callInfoNumb: event.target.textContent });
  };

  popupCloseHandler() {
    this.setState({ show: false });
    window.location.reload();
  }
  openAlert() {
    this.setState({ alerting: !this.state.alerting });
    //console.log("button worjing")
  }
  openHandler() {
    this.setState({ show: true });
    //console.log("button worjing")
  }

  listPage() {
    window.location.href = "/";
  }

  handleFullNameChange = (e) => {
    this.setState({ fullName: e.target.value });
    this.setState({ newfullName: e.target.value });
  };
  handleEmailChange = (e) => {
    this.setState({ emailAddress: e.target.value });
    this.setState({ newEmailAddress: e.target.value });
  };
  handlePhoneChange = (e) => {
    this.setState({ phoneNumber: e.target.value });
    this.setState({ newPhoneNumber: e.target.value });
  };
  handleCurrentLocationChange = (e) => {
    this.setState({ currentLocation: e.target.value });
    this.setState({ newCurrentLocation: e.target.value });
  };
  handleDesiredLocationChange = (e) => {
    this.setState({ desiredLocation: e.target.value });
    this.setState({ newDesiredLocation: e.target.value });
  };
  handleSalExpectationsChange = (e) => {
    this.setState({ salExpectations: e.target.value });
    this.setState({ newSalExpectations: e.target.value });
  };
  handleRelevantExperienceChange = (e) => {
    this.setState({ relevantExperience: e.target.value });
    this.setState({ newRelevantExperience: e.target.value });
  };

  handleSkillsChange = (e) => {
    this.setState({ skills: e.target.value });
    this.setState({ newSkills: e.target.value });
  };

  handleLanguagesTechChange = (e) => {
    this.setState({ languagesTech: e.target.value });
    this.setState({ newLanguagesTech: e.target.value });
  };

  handleLangProficiencyChange = (e) => {
    this.setState({ langProficiency: e.target.value });
    this.setState({ newLangProficiency: e.target.value });
  };

  handlePreviousIndustriesChange = (e) => {
    this.setState({ previousIndustries: e.target.value });
    this.setState({ newPreviousIndustries: e.target.value });
  };

  handleInterestChange = (e) => {
    this.setState({ interestOutside: e.target.value });
    this.setState({ newInterestOutside: e.target.value });
  };
  handleHobbiesChange = (e) => {
    this.setState({ hobbies: e.target.value });
    this.setState({ newHobbies: e.target.value });
  };
  handleSchoolChange = (e) => {
    this.setState({ school: e.target.value });
    this.setState({ newSchool: e.target.value });
  };
  handleOtherCompaniesChange = (e) => {
    this.setState({ otherCompanies: e.target.value });
    this.setState({ newOtherCompanies: e.target.value });
  };
  handleHiringManagersChange = (e) => {
    this.setState({ hiringManagers: e.target.value });
    this.setState({ newHiringManagers: e.target.value });
  };

  foundationalData() {
    document.getElementById("foundationalData").style.display = "block";
  }
  editText() {
    document.getElementById("textTranscribe").contentEditable = true;
  }
  saveText() {
    document.getElementById("textTranscribe").contentEditable = false;
  }
  showExtract() {
    this.setState({ extractShow: true });
    this.setState({ summaryShow: false });
  }
  showSummary() {
    this.setState({ summaryShow: true });
    this.setState({ extractShow: false });
    this.setState({ foundData: false });
    this.setState({ uniqueInsights: false });
    this.setState({ recruitNotes: false });
    this.setState({ businessDev: false });
    this.setState({ jobSpecificData: false });
  }
  foundationDataSet() {
    console.log("testing");
    this.setState({ foundData: !this.state.foundData });
    this.setState({ uniqueInsights: false });
    this.setState({ recruitNotes: false });
    this.setState({ businessDev: false });
    this.setState({ jobSpecificData: false });
  }
  uniqueInsightsSet() {
    this.setState({ uniqueInsights: !this.state.uniqueInsights });
    this.setState({ foundData: false });
    this.setState({ recruitNotes: false });
    this.setState({ businessDev: false });
    this.setState({ jobSpecificData: false });
  }
  recruitNotesSet() {
    this.setState({ foundData: false });
    this.setState({ uniqueInsights: false });
    this.setState({ recruitNotes: !this.state.recruitNotes });
    this.setState({ businessDev: false });
    this.setState({ jobSpecificData: false });
  }
  businessDevSet() {
    this.setState({ foundData: false });
    this.setState({ uniqueInsights: false });
    this.setState({ recruitNotes: false });
    this.setState({ businessDev: !this.state.businessDev });
    this.setState({ jobSpecificData: false });
  }
  jobSpecificDataSet() {
    this.setState({ foundData: false });
    this.setState({ uniqueInsights: false });
    this.setState({ recruitNotes: false });
    this.setState({ businessDev: false });
    this.setState({ jobSpecificData: !this.state.jobSpecificData });
  }

  onSearchChange = (event) => {
    const searchField = event.target.value.toLocaleLowerCase();

    this.setState(() => {
      return { searchField };
    });
  };
  SettingsPage() {
    const id = document.getElementById("lang").value;
    if (id == "settings") {
      window.location.href = "/settings";
    }
    if (id == "logout") {
      alert("Logged out");
      const cookies = new Cookies();
      cookies.remove("token"); //clear storage so no user is stored in the session
      cookies.remove("loggedin"); //clear storage so no user is stored in the session
      localStorage.clear();
      //redirect user to the home page, which is protected, so will redirect to login page
      window.location.href = "/";
    }
  }
  componentDidUpdate(prevProps, prevState) {
    // Check if the SummarizedText state has changed
    if (this.state.SummarizedText !== prevState.SummarizedText) {
      // If SummarizedText is empty or some specific condition is met, render one set of content
      if (this.state.SummarizedText.length === 0) {
        this.setState({ shouldRenderContent: true });
      } else {
        this.setState({ shouldRenderContent: false });
      }
    }
  }

  handleChangeSelect = (value) => {
    this.setState({ value }, () =>
      console.log(`Option selected:`, this.state.value)
    );
    console.log(value);
    if (value.label == "Foundational Data") {
      console.log("Foundational Data");
      this.foundationDataSet();
      //window.open('/register', '_blank').focus();
    }
    if (value.label == "Unique Insights") {
      console.log("Unique Insights");
      this.uniqueInsightsSet();
      //window.open('/register', '_blank').focus();
    }
    if (value.label == "Recruiter Notes") {
      console.log("Recruiter Notes");
      this.recruitNotesSet();
      //window.open('/register', '_blank').focus();
    }
    if (value.label == "Business Dev") {
      console.log("Business Dev");
      this.businessDevSet();
      //window.open('/register', '_blank').focus();
    }
    if (value.label == "Job Specific") {
      console.log("Job Specific");
      this.jobSpecificDataSet();
      //window.open('/register', '_blank').focus();
    }
  };

  changeSummary() {
    this.openAlert();
    this.setState({ editing: !this.state.editing });
  }

  summaryChange = (e) => {

    const cookies = new Cookies();
    let config = cookies.get("token") || localStorage.getItem("token");

    let newUrl = window.location.pathname;
    let liked = 0;

    console.log("ats", this.state.AtsConnected)

    var fakeImages = document.getElementsByName("summ");
    if (fakeImages.length > 0) {
      // Elements with the name attribute "summ" exist, you can work with them here
      console.log("Found summ elements:", fakeImages);
      let arraySet = [];
      for (let i = 0; i < fakeImages.length; i++) {
        arraySet.push(fakeImages[i].innerHTML);
      }
      console.log(arraySet)

      const getSummary = (message) => {
        if (message[0] && message[0].summarizedtext) {
          return JSON.parse(message[0].summarizedtext);
        } else if (message[0] && message[0].summarizedtextuser) {
          return JSON.parse(message[0].summarizedtextuser);
        }
        return "";
      };

      axios
        .get(process.env.REACT_APP_TASKS_VIEW_URL + `/view/summarize${newUrl}`, {
          headers: {
            Authorization: "Token " + config,
          },
        })
        .then((response) => {
          let summary = getSummary(response.data.message);
          let newLiked = response.data.liked[0].liked
          console.log(newLiked, "newLiked")
          let total = 0;

          for (let j = 0; j < summary.length; j++) {
            //console.log("sum here",arraySet[j], "h")
            let noWhitespaceStr = arraySet[j].replace(/\s/g, '');
            //console.log("sum here",summary[j][0][0], "h")
            let noWhitespaceStr2 = summary[j][0][0].replace(/\s/g, '');
            if (noWhitespaceStr == noWhitespaceStr2) {
              total += 1
            }
            summary[j][0] = [arraySet[j]];
          }
          if (total == summary.length) {
            liked = 0
          } else {
            liked = 1
          }
          if (newLiked == 1) {
            liked = 1;
          }
          console.log(liked, newLiked, "sum here")
          if (document.getElementsByName("summ-1")) {
            var fake = document.getElementsByName("summ-1")
            console.log("newas", fake[0].innerHTML)
            axios({
              method: "post",
              //url: "http://localhost:8000/integration_ats/comment_no_summ",
              url: process.env.REACT_APP_BACKEND_URL + "/integration_ats/add_free_form_notes",
              headers: {
                Authorization: "Token " + config,
              },

              data: {
                url: newUrl,
                notes: fake[0].innerHTML,
              },
            })
              .then((response) => {
                // Handle the success response here
                //window.location.reload()
                console.log("Database success:", response.data);
              })
              .catch((error) => {
                // Handle any errors here
                console.error("itris error:", error);
              });
          }

          return axios({
            method: "put",
            url: process.env.REACT_APP_LOGIN_URL + "/view/summ_update" + newUrl,
            headers: {
              Authorization: "Token " + config,
            },
            data: {
              text: JSON.stringify(summary),
              liked: liked
            },
          });
        })
        .then((response) => {
          return axios.get(
            process.env.REACT_APP_TASKS_VIEW_URL + `/view/summarize${newUrl}`,
            {
              headers: {
                Authorization: "Token " + config,
              },
            }
          );
        })
        .then((response) => {
          let summary = getSummary(response.data.message);

          this.setState({ origSummary: summary });

          if (
            response.data.message[0].summarizedtext == "Not transcribed"
          ) {
            this.setState({
              SummarizedText: [
                [["not summarized "]][
                (["Summary is either in Progress, "], [5])
                ][[" Or you need to click summarize!"]],
              ],
            });
          } else {
            this.setState({ SummarizedText: summary });
          }

          let summaryNote = "";
          for (let ii = 0; ii < summary.length; ii++) {
            summaryNote += summary[ii][0] + "\n";
          }
          let ids = this.state.BullHornID

          ids.forEach(id => console.log("Bullhornids", id));

          this.setState({loadingBullhorn: true})

          ids.forEach(id => {
            axios({
              method: "PUT",
              //url: "http://localhost:8000/integration_ats/comment_no_summ",
              url: process.env.REACT_APP_BACKEND_URL + "/integration_ats/createnote",
              headers: {
                Authorization: "Token " + config,
              },

              data: {
                comments: summaryNote,
                candidate_id: id,
                restUrl: this.state.restUrl,
                BhRestToken: this.state.BhRestToken
              },
            })
              .then((response) => {
                // Handle the success response here
                //window.location.reload()
                console.log("Database success:", response.data);
                this.setState({loadingBullhorn: false})
                this.setState({ bullhornAlertSumm: true })

              })
              .catch((error) => {
                // Handle any errors here
                console.error("itris error:", error);
                this.setState({loadingBullhorn: false})
                this.setState({ bullhornAlertSummFault: true })
              });
          });



          if (this.state.AtsConnected == "itris") {


            return axios({
              method: "post",
              url: process.env.REACT_APP_BACKEND_URL + "/integration_ats/comment",
              headers: {
                Authorization: "Token " + config,
              },
              params: {
                id: this.state.itrisID,
              },
              data: {
                categoryId: 36,
                comment: summary,
              },
            });
          } else if (this.state.AtsConnected == "Talisman") {
            console.log("talisman connected", this.state.TalismanID, this.state.cc_consultant, summary)


            return axios({
              method: "post",
              url: process.env.REACT_APP_BACKEND_URL + "/integration_ats/create_candidate_note",
              //url: "http://localhost:8000/integration_ats/create_candidate_note",
              headers: {
                Authorization: "Token " + config,
              },

              data: {
                id: this.state.TalismanID,
                cc_consultant: this.state.cc_consultant,
                content: summary,
              },
            });

          }
              

  
        })

        .then((response) => {
          console.log(response.data.message[0])
          if (response.data.message[0].match(/^\{"id":/)) {

            console.log(response.data.message[0], " is the id")
            this.openTranscribeAlert();
          }
          else {
            this.openTranscribeFaultAlert();
          }
        })
        .catch((err) => {
          console.log(err);

        });
    } else {
      // Elements with the name attribute "summ" do not exist, get another element or perform an alternative action
      fakeImages = document.getElementById("summ-edit"); // Get an element by its ID or use another method
      let newUrl = window.location.pathname;
      if (fakeImages) {
        console.log("Got another element:", fakeImages.innerHTML);
        let summary = fakeImages.innerHTML
        let ids = this.state.BullHornID



        ids.forEach(id => {
          axios({
            method: "PUT",
            //url: "http://localhost:8000/integration_ats/comment_no_summ",
            url: process.env.REACT_APP_BACKEND_URL + "/integration_ats/createnote",
            headers: {
              Authorization: "Token " + config,
            },

            data: {
              comments: summary,
              candidate_id: id,
              restUrl: this.state.restUrl,
              BhRestToken: this.state.BhRestToken
            },
          })
            .then((response) => {
              // Handle the success response here
              //window.location.reload()
              console.log("Database success:", response.data);
              this.setState({ bullhornAlertSumm: true })
            })
            .catch((error) => {
              // Handle any errors here
              console.error("itris error:", error);
              this.setState({ bullhornAlertSummFault: true })
            });
        });


      
      }



      else {
        console.log("No summ elements and no other element found.");
      }
    }

    /*setTimeout(() => {
      window.location.reload()
    }, 2000);*/
  }


  handleValues = (e) => {
    console.log(e.target.value);
  };


  async updateExtract2() {
    try {
      const cookies = new Cookies();
      let config = cookies.get("token") || localStorage.getItem("token");
      let newUrl = window.location.pathname;

      // Fetch images
      const fakeImages = document.getElementsByName("extrct");
      let arraySet = [];
      for (let i = 0; i < fakeImages.length; i++) {
        arraySet.push(fakeImages[i].innerHTML + '"');
      }

      // Define helper function to create extract
      const createExtract = (message, i, bullhornField) => {
        const keystuff = message.answertext || message.answertextuser;
        let test = keystuff.replace(/{"([^"]+)"/, arraySet[i]);
        test = test.replace(/^/, '{"');
      
        return {
          answertext: test,
          questionid: message.questionid,
          bullhornField: bullhornField  // Include the bullhornField in the result
        };
      };

      // Get extract
      const response = await axios.get(
        process.env.REACT_APP_LOGIN_URL +`/view/extract${newUrl}`,
        { headers: { Authorization: "Token " + config } }
      );

      // Create extractUp array
      let extractUp = response.data.message.map((item, index) => {
        // Ensure there is a question and it has at least one item
        const question = response.data.questions[index];
        const bullhornField = question && question.length > 0 ? question[0].bullhornfield : null;
      
        console.log('hhh', bullhornField); // Log the bullhornField
        return createExtract(item, index, bullhornField); // Pass it to createExtract
      });
      let finalString = null

      console.log(extractUp, this.state.BullHornID)

      let promiseArray = [];
      for (let i = 0; i < extractUp.length; i++) {
          if (extractUp[i].answertext) {
              // Split the text
              let currentString = extractUp[i].answertext.split("selected")[0];
              currentString = currentString.replace("\\u00a3", "£");
              currentString = currentString.replace("\\u20ac", "€");
              finalString = currentString;  // Adjusted this line
          }
          if (extractUp[i].bullhornField) {
              console.log(extractUp[i]);
              let finalValue = finalString.slice(2, -5);
              this.state.BullHornID.forEach(id => {
                  const request = axios({
                      method: "PUT",
                      url: process.env.REACT_APP_LOGIN_URL + '/integration_ats/updateCandidate',
                      headers: {
                          Authorization: "Token " + config,
                      },
                      data: {
                          BhRestToken: this.state.BhRestToken,
                          restUrl: this.state.restUrl,
                          [extractUp[i].bullhornField]: finalValue,
                          id: id
                      }
                  });
                  promiseArray.push(request);
              });
          }
      }

      // Wait for all requests to complete
      try {
        this.setState({ loading: true }); // Start loading before the operations
    
        // First, wait for all promises to resolve or catch errors immediately
        const responses = await Promise.all(promiseArray);
        console.log("All updates successful:", responses);
        this.setState({ bullhornExtractAlert: true });
    
        // Then, perform the Axios request
        const updateResponse = await axios({
          method: "put",
          url: `${process.env.REACT_APP_LOGIN_URL}/view/extract_update${newUrl}`,
          headers: { Authorization: "Token " + config },
          data: { text: extractUp },
        });
    
        // Handle the response or errors from the Axios request
        console.log("Update response received:", updateResponse);
        // Uncomment the next line if you want to reload the page or redirect
        // window.location.reload();
    
      } catch (error) {
        console.error("An error occurred during updates:", error);
        this.setState({ bullhornExtractAlertFault: true });
      } finally {
        this.setState({ loading: false }); // Stop loading after all operations
      }
      

     
    } catch (err) {
      console.log(err);
    }
    //window.location.reload();

  }


  async updateExtract() {
    try {

      // Fetch token
      const cookies = new Cookies();
      let config = cookies.get("token") || localStorage.getItem("token");
      let newUrl = window.location.pathname;

      // Fetch images
      const fakeImages = document.getElementsByName("extrct");
      let arraySet = [];
      for (let i = 0; i < fakeImages.length; i++) {
        arraySet.push(fakeImages[i].innerHTML + '"');
      }

      // Define helper function to create extract
      const createExtract = (message, i) => {
        const keystuff = message.answertext || message.answertextuser;
        let test = keystuff.replace(/{"([^"]+)"/, arraySet[i]);
        test = test.replace(/^/, '{"');



        return {
          answertext: test,
          questionid: message.questionid,
        };
      };

      // Get extract
      const response = await axios.get(
        process.env.REACT_APP_LOGIN_URL + `/view/extract${newUrl}`,
        { headers: { Authorization: "Token " + config } }
      );

      // Create extractUp array
      let extractUp = response.data.message.map(createExtract);

      console.log(extractUp)

      // Update extract

      let noticePeriod = null;
      let noticeInterval = null;
      let nextAvailableDate = null;
      let availableType = null;
      let maleOrFemale = null;
      let birthDate = null;
      let permanentWork = false
      let contractWork = false
      let temporaryWork = false;
      let desiredSalary = null;
      let currencyId = 1;
      let partTime = null
      let fulltime = null
      let desiredRate = null
      let rateInterval = null
      let jobTitle = null
      let statusId = null
      let privateSector = false
      let notForProfitSector = false
      let publicSector = false
      let nationalityId = null
      let question291Exists = false
      let noticedata = []
      let failspace = false;

      console.log(extractUp)
      let finalString = null


      const MONTH_NAMES = [
        'January', 'February', 'March', 'April', 'May', 'June', 'July',
        'August', 'September', 'October', 'November', 'December'
      ];

      this.setState({ Loader: true })

      let combinedData = []

      for (let i = 0; i < extractUp.length; i++) {
        console.log("extract", extractUp[i])
        if (extractUp[i].answertext) {
          // Split the text
          let currentString = extractUp[i].answertext.split("selected")[0];
          console.log(currentString, "euro")
          // Replace the unicode pound sign with the regular one
          currentString = currentString.replace("\\u00a3", "£");
          currentString = currentString.replace("\\u20ac", "€");


          // Determine the length of the substring based on the presence of the pound sign


          console.log(currentString, "current")
          // Create the final string by slicing off the first 2 characters and the last 'subLength' characters
          finalString = currentString

          console.log(finalString, "newcurr")

        }
        //console.log("uid", extractUp[i].questionid)



        if (extractUp[i].questionid === 234) {
          if (!finalString.includes("Information not provided")) {
            let data = []
            let subLength = finalString.includes("£") || finalString.includes("€") ? 10 : 5;


            // Create the final string by slicing off the first 2 characters and the last 'subLength' characters
            finalString = finalString.slice(2, -subLength);
            const checknation = finalString.toLowerCase();
            console.log("data ", JsonData.message);

            for (let j = 0; j < JsonData.message.length; j++) {
              const nationalityName = JsonData.message[j].name.toLowerCase();
              const regex = new RegExp(`\\b${nationalityName}\\b`, 'i');

              if (regex.test(checknation)) {
                console.log("here1", checknation, JsonData.message[j].name);
                nationalityId = JsonData.message[j].id;
                data.push({
                  value: nationalityId,
                  path: "/nationalityId",
                  op: "add"
                });
                break; // Break the loop if a match is found
              }
            }
            axios({
              method: "PUT",
              //url: process.env.REACT_APP_LOGIN_URL + '/questionbank/questiondelete',
              url: process.env.REACT_APP_BACKEND_URL + '/integration_ats/update_fields',
              headers: {
                Authorization: "Token " + config,
              },
              params: {
                //auth: "Basic " + localStorage.getItem("ITRISToken"),
                id: this.state.itrisID,
              },
              data: data

            }).then(
              response => {
                console.log(response)
                //window.location.reload();



              }).catch(error => console.log(error))
          }

        }


        if (extractUp[i].questionid == 235) {
          if (!finalString.includes("Information not provided")) {
            let data = []

            const genderMap = {
              'male': 543,
              'female': 544,
            };

            for (const [gender, value] of Object.entries(genderMap)) {
              const regex = new RegExp(`\\b${gender}\\b`, 'i');
              if (regex.test(finalString)) {
                maleOrFemale = value;
                data.push({
                  "value": maleOrFemale,
                  "path": "/genderId",
                  "op": "add"
                });
                break;
              }
            }
            axios({
              method: "PUT",
              //url: process.env.REACT_APP_LOGIN_URL + '/questionbank/questiondelete',
              url: process.env.REACT_APP_BACKEND_URL + '/integration_ats/update_fields',
              headers: {
                Authorization: "Token " + config,
              },
              params: {
                //auth: "Basic " + localStorage.getItem("ITRISToken"),
                id: this.state.itrisID,
              },
              data: data

            }).then(
              response => {
                console.log(response)
                //window.location.reload();



              }).catch(error => console.log(error))
          }

        }


        if (extractUp[i].questionid == 236) {
          if (!finalString.includes("Information not provided")) {
            let data = []
            let subLength = finalString.includes("£") || finalString.includes("€") ? 10 : 5;


            // Create the final string by slicing off the first 2 characters and the last 'subLength' characters
            finalString = finalString.slice(2, -subLength);
            birthDate = finalString
            data.push({
              "value": birthDate,
              "path": "/birthDate",
              "op": "add"
            });


            axios({
              method: "PUT",
              //url: process.env.REACT_APP_LOGIN_URL + '/questionbank/questiondelete',
              url: process.env.REACT_APP_BACKEND_URL + '/integration_ats/update_fields',
              headers: {
                Authorization: "Token " + config,
              },
              params: {
                //auth: "Basic " + localStorage.getItem("ITRISToken"),
                id: this.state.itrisID,
              },
              data: data

            }).then(
              response => {
                console.log(response)
                //window.location.reload();



              }).catch(error => console.log(error))
          }

        }

        if (extractUp[i].questionid == 237) {
          if (!finalString.includes("Information not provided")) {
            let data = []
            //const inputString = "This is a permanent position.";
            const containsPermanent = finalString.toLowerCase().includes("permanent");
            const containsTemp = finalString.toLowerCase().includes("temporary");
            const containsContract = finalString.toLowerCase().includes("contract");
            if (containsPermanent) {
              permanentWork = true
              data.push({
                "value": permanentWork,

                "path": "/workPreference/permanentWork",

                "op": "add"
              });
            }
            if (containsTemp) {
              temporaryWork = true
              data.push({
                "value": temporaryWork,

                "path": "/workPreference/temporaryWork",

                "op": "add"
              });
            }
            if (containsContract) {
              contractWork = true
              data.push({
                "value": contractWork,

                "path": "/workPreference/contractWork",

                "op": "add"
              });
            }
            axios({
              method: "PUT",
              //url: process.env.REACT_APP_LOGIN_URL + '/questionbank/questiondelete',
              url: process.env.REACT_APP_BACKEND_URL + '/integration_ats/update_fields',
              headers: {
                Authorization: "Token " + config,
              },
              params: {
                //auth: "Basic " + localStorage.getItem("ITRISToken"),
                id: this.state.itrisID,
              },
              data: data

            }).then(
              response => {
                console.log(response)
                //window.location.reload();



              }).catch(error => console.log(error))
          }

        }

        if (extractUp[i].questionid === 238) {
          if (!finalString.includes("Information not provided")) {
            let data = []
            const regex = /[-+]?\b\d+(?:,\d{3})*(?:\.\d+)?\b/g;
            const matches = finalString.match(regex);
            if (matches) {
              // You can loop through the matches to access each extracted number
              for (const match of matches) {
                // Remove commas and currency symbols (e.g., pound signs) if needed
                const numberWithoutSymbols = match.replace(/[^\d]/g, '');

                // Convert the cleaned number to a numeric value (float or integer)
                const numericValue = parseFloat(numberWithoutSymbols);

                console.log("nyns", numericValue);
                desiredSalary = numericValue
              }
            } else {
              console.log("No numbers found in the string.");
            }

            combinedData.push({
              value: desiredSalary,
              path: "/workPreference/desiredSalary",
              op: "add"
            });

            /*axios({
              method: "PUT",
              //url: process.env.REACT_APP_LOGIN_URL + '/questionbank/questiondelete',
              url: process.env.REACT_APP_BACKEND_URL + '/integration_ats/update_fields',
              headers: {
                Authorization: "Token " + config,
              },
              params: {
                //auth: "Basic " + localStorage.getItem("ITRISToken"),
                id: this.state.itrisID,
              },
              data: data

            }).then(
              response => {
                console.log(response)
                //window.location.reload();



              }).catch(error => console.log(error))*/
          }

        }
        if (extractUp[i].questionid == 246) {
          if (!finalString.includes("Information not provided")) {
            let data = []
            const currencyToId = {
              'pound': 1,
              'canadian': 4,
              'swiss': 5,
              'euro': 3,
              'dollar': 2,
              'yen': 7,
              'krona': 6,
              'rupiah': 8,
              'danish krone': 9,
              'norwegian krone': 10,
              'yuan': 11,
              'dong': 12,
              'riyal': 13,
              'poland': 14
            };

            // Convert finalString to lower case once, since we'll be checking it multiple times
            const lowerFinalString = finalString.toLowerCase();

            // Iterate over each currency in the mapping
            for (const currency in currencyToId) {
              // If the currency is in finalString, update currencyId and break the loop
              if (lowerFinalString.includes(currency)) {
                currencyId = currencyToId[currency];
                combinedData.push({
                  "value": currencyId,

                  "path": "/workPreference/currencyId",

                  "op": "add"
                });
                break;
              }
            }
            /*axios({
              method: "PUT",
              //url: process.env.REACT_APP_LOGIN_URL + '/questionbank/questiondelete',
              url: process.env.REACT_APP_BACKEND_URL + '/integration_ats/update_fields',
              headers: {
                Authorization: "Token " + config,
              },
              params: {
                //auth: "Basic " + localStorage.getItem("ITRISToken"),
                id: this.state.itrisID,
              },
              data: data

            }).then(
              response => {
                console.log(response)
                //window.location.reload();



              }).catch(error => console.log(error))*/
          }

        }

        if (extractUp[i].questionid == 244) {
          if (!finalString.includes("Information not provided")) {
            let data = []
            const isPublicSector = finalString.toLowerCase().includes("public");
            const isPrivateSector = finalString.toLowerCase().includes("private");
            const isNfPSector = finalString.toLowerCase().includes("profit");

            if (isPublicSector) {
              publicSector = true
              data.push({
                "value": publicSector,

                "path": "/workPreference/publicSector",

                "op": "add"
              });
            }
            if (isPrivateSector) {
              privateSector = true
              data.push({
                "value": privateSector,

                "path": "/workPreference/privateSector",

                "op": "add"

              });

            }
            if (isNfPSector) {
              notForProfitSector = true
              data.push({
                "value": notForProfitSector,

                "path": "/workPreference/notForProfitSector",

                "op": "add"
              });
            }

            if (!isPublicSector && !isPrivateSector && !isNfPSector) {
              publicSector = false
              privateSector = false
              notForProfitSector = false
              data.push({
                "value": publicSector,

                "path": "/workPreference/publicSector",

                "op": "add"
              });
              data.push({
                "value": privateSector,

                "path": "/workPreference/privateSector",

                "op": "add"

              });
              data.push({
                "value": notForProfitSector,

                "path": "/workPreference/notForProfitSector",

                "op": "add"
              });


            }
            axios({
              method: "PUT",
              //url: process.env.REACT_APP_LOGIN_URL + '/questionbank/questiondelete',
              url: process.env.REACT_APP_BACKEND_URL + '/integration_ats/update_fields',
              headers: {
                Authorization: "Token " + config,
              },
              params: {
                //auth: "Basic " + localStorage.getItem("ITRISToken"),
                id: this.state.itrisID,
              },
              data: data

            }).then(
              response => {
                console.log(response)
                //window.location.reload();



              }).catch(error => console.log(error))
          }


        }

        if (extractUp[i].questionid == 241) {
          if (!finalString.includes("Information not provided")) {
            let data = []
            const isInterested = finalString.toLowerCase().includes("yes");
            if (isInterested) {
              partTime = true

            } else {
              partTime = false
            }
            data.push({
              "value": partTime,

              "path": "/workPreference/partTime",

              "op": "add"
            });
            axios({
              method: "PUT",
              //url: process.env.REACT_APP_LOGIN_URL + '/questionbank/questiondelete',
              url: process.env.REACT_APP_BACKEND_URL + '/integration_ats/update_fields',
              headers: {
                Authorization: "Token " + config,
              },
              params: {
                //auth: "Basic " + localStorage.getItem("ITRISToken"),
                id: this.state.itrisID,
              },
              data: data

            }).then(
              response => {
                console.log(response)
                //window.location.reload();



              }).catch(error => console.log(error))
          }

        }

        if (extractUp[i].questionid == 242) {
          if (!finalString.includes("Information not provided")) {
            let data = []
            const isInterested = finalString.toLowerCase().includes("yes");
            if (isInterested) {
              fulltime = true
            } else {
              fulltime = false
            }
            data.push({
              "value": fulltime,

              "path": "/workPreference/fullTime",

              "op": "add"
            });
            axios({
              method: "PUT",
              //url: process.env.REACT_APP_LOGIN_URL + '/questionbank/questiondelete',
              url: process.env.REACT_APP_BACKEND_URL + '/integration_ats/update_fields',
              headers: {
                Authorization: "Token " + config,
              },
              params: {
                //auth: "Basic " + localStorage.getItem("ITRISToken"),
                id: this.state.itrisID,
              },
              data: data

            }).then(
              response => {
                console.log(response)
                //window.location.reload();



              }).catch(error => console.log(error))
          }

        }

        if (extractUp[i].questionid == 243) {
          if (!finalString.includes("Information not provided")) {
            let data = []
            let subLength = finalString.includes("£") || finalString.includes("€") ? 10 : 5;


            // Create the final string by slicing off the first 2 characters and the last 'subLength' characters
            finalString = finalString.slice(2, -subLength);
            jobTitle = finalString
            data.push({
              "value": jobTitle,

              "path": "/workPreference/jobTitle",

              "op": "add"
            });
            axios({
              method: "PUT",
              //url: process.env.REACT_APP_LOGIN_URL + '/questionbank/questiondelete',
              url: process.env.REACT_APP_BACKEND_URL + '/integration_ats/update_fields',
              headers: {
                Authorization: "Token " + config,
              },
              params: {
                //auth: "Basic " + localStorage.getItem("ITRISToken"),
                id: this.state.itrisID,
              },
              data: data

            }).then(
              response => {
                console.log(response)
                //window.location.reload();



              }).catch(error => console.log(error))
          }

        }

        if (extractUp[i].questionid == 247) {
          if (!finalString.includes("Information not provided")) {
            let data = []
            //jobTitle = finalString
            const isAvailable = finalString.toLowerCase().includes("available");
            const isLooking = finalString.toLowerCase().includes("looking");
            const isDeceased = finalString.toLowerCase().includes("deceased");
            const unavailable = finalString.toLowerCase().includes("unavailable");
            const isNotInUse = finalString.toLowerCase().includes("not use");

            if (isAvailable) {
              statusId = 74
            }
            if (isLooking) {
              statusId = 71
            }


            if (isDeceased) {
              statusId = 72

            }
            if (unavailable) {
              statusId = 75
            }
            if (isNotInUse) {
              statusId = 73


            }
            data.push({
              "value": statusId,

              "path": "/statusId",

              "op": "add"

            });
            axios({
              method: "PUT",
              //url: process.env.REACT_APP_LOGIN_URL + '/questionbank/questiondelete',
              url: process.env.REACT_APP_BACKEND_URL + '/integration_ats/update_fields',
              headers: {
                Authorization: "Token " + config,
              },
              params: {
                //auth: "Basic " + localStorage.getItem("ITRISToken"),
                id: this.state.itrisID,
              },
              data: data

            }).then(
              response => {
                console.log(response)
                //window.location.reload();



              }).catch(error => console.log(error))
          }

        }

        if (extractUp[i].questionid == 239) {
          if (!finalString.includes("Information not provided")) {
            let data = []
            const matches = finalString.match(/\d+/g);
            desiredRate = finalString
            if (matches) {
              desiredRate = parseInt(matches[0], 10);
              console.log(desiredRate) // Return the first integer found in the string
            }
            data.push({

              "value": 1,

              "path": "/workPreference/desiredRateInterval",

              "op": "add"

            },
              {
                "value": currencyId,

                "path": "/workPreference/currencyId",

                "op": "add"
              },
              {

                "value": desiredRate,

                "path": "/workPreference/desiredRate",

                "op": "add"

              });
            axios({
              method: "PUT",
              //url: process.env.REACT_APP_LOGIN_URL + '/questionbank/questiondelete',
              url: process.env.REACT_APP_BACKEND_URL + '/integration_ats/update_fields',
              headers: {
                Authorization: "Token " + config,
              },
              params: {
                //auth: "Basic " + localStorage.getItem("ITRISToken"),
                id: this.state.itrisID,
              },
              data: data

            }).then(
              response => {
                console.log(response)
                //window.location.reload();



              }).catch(error => console.log(error))
          }

        }
        if (extractUp[i].questionid == 240) {
          if (!finalString.includes("Information not provided")) {
            let data = []
            const hourly = finalString.toLowerCase().includes("hour");
            if (hourly) {
              rateInterval = 1
            } else {
              rateInterval = 2
            }
            data.push({

              "value": rateInterval,

              "path": "/workPreference/desiredRateInterval",

              "op": "add"

            });
            axios({
              method: "PUT",
              //url: process.env.REACT_APP_LOGIN_URL + '/questionbank/questiondelete',
              url: process.env.REACT_APP_BACKEND_URL + '/integration_ats/update_fields',
              headers: {
                Authorization: "Token " + config,
              },
              params: {
                //auth: "Basic " + localStorage.getItem("ITRISToken"),
                id: this.state.itrisID,
              },
              data: data

            }).then(
              response => {
                console.log(response)
                //window.location.reload();



              }).catch(error => console.log(error))
          }

        }


        /*if (question291Exists && extractUp[i].questionid === 231 || extractUp[i].questionid === 231) {
          if (!finalString.includes("Information not provided")) {
            let data = [];
            let subLength = finalString.includes("£") || finalString.includes("€") ? 10 : 5;


            // Create the final string by slicing off the first 2 characters and the last 'subLength' characters
            finalString = finalString.slice(2, -subLength);
            // Check if the finalString is already in the yyyy-mm-dd format

            nextAvailableDate = finalString;
            console.log(nextAvailableDate, "exists here")
            
            noticedata.push({
              "value": nextAvailableDate,
              "path": "/nextAvailableDate",
              "op": "add"
            });


            question291Exists = extractUp.some(item => item.questionid === 232);

            availableType = question291Exists ? 3 : 2;
            if (failspace == true){
              availableType = 2
            }
            noticedata.push({
              "value": availableType,
              "path": "/availabilityType",
              "op": "add"
            });
           
          




          } else{
            failspace = true;
          }
        }*/

        if (extractUp[i].questionid === 232) {
          if (!finalString.includes("Information not provided")) {
            let data = []

            console.log("final here", finalString)
            let subLength = finalString.includes("£") || finalString.includes("€") ? 10 : 5;


            // Create the final string by slicing off the first 2 characters and the last 'subLength' characters
            finalString = finalString.slice(2, -subLength);
            const numericValue = wordsToNumbers(finalString);
            console.log("finalString", numericValue)
            question291Exists = extractUp.some(item => item.questionid === 231);

            availableType = question291Exists ? 3 : 1;
            if (failspace == true) {
              availableType = 2
            }

            noticePeriod = numericValue
            const timeUnits = [
              { unit: 'day', code: 1 },
              { unit: 'week', code: 2 },
              { unit: 'month', code: 3 },
            ];

            for (const { unit, code } of timeUnits) {
              if (new RegExp(unit, 'i').test(numericValue)) {
                const splitArray = numericValue.split(' ');
                console.log(splitArray)
                noticePeriod = splitArray[0];  // Keep as string
                noticeInterval = code;  // Integer replacement for unit
                //return { noticePeriod, noticeInterval };
              }
            }
            noticedata.push(
              {
                "value": 1,

                "path": "/availabilityType",

                "op": "add"
              },

              {
                "value": parseInt(noticePeriod),
                "path": "/noticePeriod",
                "op": "add"
              },

              {

                "value": noticeInterval,
                "path": "/noticeInterval",
                "op": "add"

              });


          }

        }


      }

      axios({
        method: "PUT",
        //url: process.env.REACT_APP_LOGIN_URL + '/questionbank/questiondelete',
        url: process.env.REACT_APP_BACKEND_URL + '/integration_ats/update_fields',
        headers: {
          Authorization: "Token " + config,
        },
        params: {
          //auth: "Basic " + localStorage.getItem("ITRISToken"),
          id: this.state.itrisID,
        },
        data: noticedata

      }).then(
        response => {
          console.log(response)
          //window.location.reload();



        }).catch(error => console.log(error))
      if (combinedData.length > 0) {
        axios({
          method: "PUT",
          //url: process.env.REACT_APP_LOGIN_URL + '/questionbank/questiondelete',
          url: process.env.REACT_APP_BACKEND_URL + '/integration_ats/update_fields',
          headers: {
            Authorization: "Token " + config,
          },
          params: {
            //auth: "Basic " + localStorage.getItem("ITRISToken"),
            id: this.state.itrisID,
          },
          data: combinedData,
        })
          .then(response => {
            console.log(response);
            //window.location.reload();
          })
          .catch(error => console.log(error));
      }
      const updateResponse = axios({
        method: "put",
        url: process.env.REACT_APP_LOGIN_URL + "/view/extract_update" + newUrl,
        headers: { Authorization: "Token " + config },
        data: { text: extractUp },
      });
      updateResponse
        .then((response) => {
          //window.location.reload()
        })
        .catch((error) => {
          console.log(error); // Handle errors, if any
        });
      //
    } catch (err) {
      console.log(err);
      this.openEditFaultAlert();
    }
    window.location.reload();
  }


  downloadFile = () => {
    const { Transcript } = this.state;
    const cookies = new Cookies();
    let config = cookies.get("token") || localStorage.getItem("token");

    const textOutput = Object.values(Transcript.comment).join('\n');

    const mystring = textOutput.replaceAll('\n', '  ');
    //const element = document.createElement("a");

    const file = new Blob([JSON.stringify(mystring)], { type: 'text/plain' });


    let ids = this.state.BullHornID
    ids.forEach(id => {
      const formData = new FormData();
      formData.append('file', file, 'Summary.txt');
      formData.append('candidate_id', id)
      formData.append('restUrl', this.state.restUrl)
      formData.append('BhRestToken', this.state.BhRestToken)
      axios.post(process.env.REACT_APP_BACKEND_URL + '/integration_ats/add_transcript_to_candidate', formData, {
        headers: {
          "Authorization": "Token " + config,
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(response => {
          console.log('File uploaded successfully:', response.data);
          this.setState({ bulhornAlertTranscript: true })

          // create something that gives the user a success message. 
        })
        .catch(error => {
          console.error('There was an error uploading the file:', error);

          this.setState({ bullhornAlertTranscriptFault: true })
        });
    });


  }

  render() {
    //format the files as per the groups page
    console.log('render: summStatus is', this.state.summStatus);
    const {
      files,
      transcribeAlert,
      transcribeFaultAlert,
      editAlert,
      editFaultAlert,
    } = this.state;
    //console.log(files)
    files.forEach((el, i, arr) => {
      arr[i] = el.slice(0).split("/").pop();
    });

    const { showing, value, BullHornID, CopyAlert, Transcript, bulhornAlertTranscript, bullhornAlertTranscriptFault, bullhornAlertSumm, bullhornAlertSummFault } = this.state;
    const { status, show, alerting, userLogged } = this.state;
    const {
      video,
      callInfo,
      callInfoCand,
      callInfoNumb,
      summStatus,
      transcriptStatus,
      searchField,
      summaryShow,
      questions,
      audioDuration,
      showHoverBox
    } = this.state;
    const { onSearchChange } = this;
    // const { summStatus, TranscriptedText } = this.state;
    const {
      TranscriptedText,
      SummarizedText,
      duration,
      extractShow,
      labelsForExtract,
      callInfoEmail,
      FreeForm,
      isClickedSumm, isClickedSumm1, showHoverBoxSumm,
      isClickedExt, isClickedExt1, showHoverBoxExt
    } = this.state;



    var minutes = Math.floor(duration / 60);
    var seconds = duration - minutes * 60;
    minutes = minutes.toString();
    seconds = seconds.toString();
    if (minutes == "NaN" && seconds == "NaN") {
      minutes = "Processing";
      seconds = "";
    } else {
      minutes = minutes + " m";
      seconds = " " + seconds + " s";
    }

    const changeHandler = (value) => {
      //this.setState({value: value})
      console.log(value);
      if (value.label == "Foundational Data") {
        console.log("Foundational Data");
        this.foundationDataSet();
        //window.open('/register', '_blank').focus();
      }
      if (value.label == "Unique Insights") {
        console.log("Unique Insights");
        this.uniqueInsightsSet();
        //window.open('/register', '_blank').focus();
      }
      if (value.label == "Recruiter Notes") {
        console.log("Recruiter Notes");
        this.recruitNotesSet();
        //window.open('/register', '_blank').focus();
      }
      if (value.label == "Business Dev") {
        console.log("Business Dev");
        this.businessDevSet();
        //window.open('/register', '_blank').focus();
      }
      if (value.label == "Job Specific") {
        console.log("Job Specific");
        this.jobSpecificDataSet();
        //window.open('/register', '_blank').focus();
      }
    };
    const columnClasses = {
      speaker: styles.recruiter,
      comment: styles.transcriptionCall,
      start_time: styles.speakerTranscript,
    };


    const tableHeaders = ["speaker", "comment", "start_time"];

    const renderTableCell = (key, value, index) => {
      if (key === "speaker") {
        return (
          <div>
            <img className={styles.rectangle828} src={Sound} />
            <span>{value}</span>
          </div>
        );
      } else if (key === "start_time") {
        return (
          <div>
            <img
              className={styles.vuesaxlineartimer1}
              src={vuesaxlineartimer1}
            />
            <span>{value}</span>
          </div>
        );
      }
      return value;
    };

    const notes = Object.keys(Transcript.start_time || {})
      .filter((index) => {
        const rowValues = tableHeaders.map((key) => Transcript[key][index]);
        const rowText = rowValues.join(' ').toLowerCase();
        return rowText.includes(searchField.toLowerCase());
      })
      .map((index) => (
        <tr key={index}>
          {tableHeaders.map((key) => (
            <td key={key} className={columnClasses[key]}>
              {renderTableCell(key, Transcript[key][index], index)}
            </td>
          ))}
        </tr>
      ));


    console.log(typeof (SummarizedText), SummarizedText)
    if (Array.isArray(SummarizedText) && SummarizedText.length === 0) {
      console.log("empyt array", SummarizedText)
    }
    const { isClicked, isClicked1, isVisibleStuff, isVisibleStuffSumm, isVisibleStuffExt, loading, loadingBullhorn, bullhornExtractAlert, bullhornExtractAlertFault } = this.state;



    return (

      <Box sx={{ flexGrow: 1 }}>
        <Grid container className={UniversalCss.GridContainerWrapperHegih}>
          <Grid item md={1.7} xs={2} className={UniversalCss.LeftNavbarShadow}>
            <NavbarLeft />
          </Grid>
          <Grid
            item
            md={10.3}
            xs={10}
            className={UniversalCss.navbarAndRouteWrapper}
          >
            <NavbarTop PageData={"Calls"} />
            {this.state.Loader === true ? <Loader /> : null}
            <div className={styles.flexcontainer12}>
              <div className={styles.grid}>
                <div className={styles.HeadingItemWrapper}>
                  <span className={styles.s1}>
                    <img className={styles.group48096653} src={group48096653} />
                    <div className={styles.InfoHeader}>Recruiter Name:</div>
                    {callInfo}
                  </span>
                </div>
                <div className={styles.HeadingItemWrapper}>
                  <span className={styles.s2}>
                    <img
                      className={styles.group480966531}
                      src={group480966531}
                    />
                    <div className={styles.InfoHeader}>Call Duration: </div>
                    {audioDuration.minutes} minutes {audioDuration.seconds} seconds

                  </span>
                </div>
                <div className={styles.HeadingItemWrapper}>
                  <span className={styles.s3}>
                    <img
                      className={styles.group480966532}
                      src={group480966532}
                    />
                    <div className={styles.InfoHeader}>Number: </div>
                    {callInfoNumb}
                  </span>
                </div>
                <div className={styles.HeadingItemWrapper}>
                  <span className={styles.s4}>
                    <img className={styles.group48096654} src={group48096654} />{" "}
                    <div className={styles.InfoHeader}>Candidate: </div>
                    {callInfoCand}{" "}
                  </span>
                </div>
                <div className={styles.HeadingItemWrapper}>
                  <span>
                    <audio
                      className={styles.s5}
                      ref={this.audioRef}
                      src={video}
                      type="video/*"
                      controls
                    ></audio>

                  </span>
                </div>
              </div>
            </div>

            <div className={styles.flexcontainer17}>
              <div className={styles.flexcontainer18}>
                <div className={styles.rectangle802}>
                  <div className={styles.flexcontainer19}>
                    <span className={styles.calltranscription}>
                      Call Transcription {BullHornID}
                    </span>
                    <button
                      onClick={() => {


                        this.downloadFile()

                      }}
                      className={styles.TranscriptPush}
                    >
                      <span className={styles.primarybuttonATS}>
                        Add Transcript to Bullhorn
                      </span>



                    </button>
                    <Alert
                      className="alertsSize"
                      style={{
                        marginRight: '2%',
                        marginLeft: '2%'
                      }}
                      show={bulhornAlertTranscript}
                      variant="success"
                    >
                      <Alert.Heading>Added to Bullhorn</Alert.Heading>
                    </Alert>
                   
                    <Alert
                      className="alertsSize"
                      style={{
                        marginRight: '2%',
                        marginLeft: '2%'
                      }}
                      show={bullhornAlertTranscriptFault}
                      variant="danger"
                    >
                      <Alert.Heading>Something went wrong</Alert.Heading>
                    </Alert>
                    <input
                      className={styles.rectangle637}
                      placeholder="Search here..."
                      type="text"
                      onChange={onSearchChange}
                    /> {/*showHoverBox && (
                      <div className={styles.HoverBox2}>
                        Thanks for the feedback!
                      </div>
                    )}
                    {isVisibleStuff && (
                      <div className={styles.iconCcontainer1}>
                        <ThumbUpIcon onClick={this.likeFeedback} className={isClicked ? styles.highlightedButton : styles.normalButton}></ThumbUpIcon>
                        <ThumbDownIcon onClick={this.dislikeFeedback} className={isClicked1 ? styles.highlightedButton1 : styles.normalButton1}></ThumbDownIcon>

                      </div>
                    )*/}
                  </div>

                  <div className={styles.flexcontainer20}></div>

                  <table>
                    <thead>
                      <tr></tr>
                    </thead>
                    <tbody className={styles.tableBody}>
                      {notes.length ? notes : <LinearProgress />}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className={styles.flexcontainer34}>
                <div className={styles.rectangle803}>

                  <div className={styles.rectangle809}>

                    <button
                      className={styles.extraction}
                      onClick={() => this.showExtract()}
                    >
                      Extraction
                    </button>
                    {/*showHoverBoxExt && (
                      <div className={styles.HoverBox1}>
                        Thanks for the feedback!
                      </div>
                    )}
                    {isVisibleStuffExt && (
                      <div className={styles.iconCcontainer}>

                        <ThumbUpIcon onClick={this.likeFeedbackExt} className={isClickedExt ? styles.highlightedButton : styles.normalButton}></ThumbUpIcon>
                        <ThumbDownIcon onClick={this.dislikeFeedbackExt} className={isClickedExt1 ? styles.highlightedButton1 : styles.normalButton1}></ThumbDownIcon>

                      </div>
                    )*/}

                    <button
                      className={styles.summary}
                      onClick={() => this.showSummary()}
                    >
                      Summary
                    </button>
                    {/*showHoverBoxSumm && (
                      <div className={styles.HoverBox}>
                        Thanks for the feedback!
                      </div>
                    )}
                    {isVisibleStuffSumm && (
                      <div className={styles.iconCcontainer}>
                        <ThumbUpIcon onClick={this.likeFeedbackSumm} className={isClickedSumm ? styles.highlightedButton : styles.normalButton}></ThumbUpIcon>
                        <ThumbDownIcon onClick={this.dislikeFeedbackSumm} className={isClickedSumm1 ? styles.highlightedButton1 : styles.normalButton1}></ThumbDownIcon>


                      </div>
                    )*/}
                    {
                      //} <span className={styles.tasks1}>Tasks</span>
                    }
                  </div>

                  <span className={styles.loremipsumdolorsi}>
                    {extractShow ? (
                      <div>
                        <button
                          onClick={() => {
                            this.changeSummary();
                          }}
                          className={styles.frame4809656611}
                        >
                          <span className={styles.primarybuttonATS}>edit</span>
                        </button>
                        <Alert
                          className="alertsSize"
                          style={{
                            marginRight: '2%',
                            marginLeft: '2%'
                          }}
                          show={alerting}
                          variant="success"
                        >
                          <Alert.Heading>Info editable!</Alert.Heading>
                        </Alert>

                        <br></br>

                        <label className={styles.Labelsextrct}>Full Name</label>
                        <div
                          key={this.state.callInfoCand} // Add a unique key
                          className={this.state.editing == true ? styles.LabelsCom : styles.LablesNotCom}
                          style={{ textAlign: "left" }}
                          contentEditable={this.state.editing}
                          //onBlur={handleContentChange}
                          onBlur={this.handleChangeCandidateName}
                          suppressContentEditableWarning={true}
                        >{callInfoCand}
                        </div>
                        <br></br>

                        <label className={styles.Labelsextrct}>Email Address</label>
                        <div
                          key={this.state.callInfoEmail} // Add a unique key
                          className={this.state.editing == true ? styles.LabelsCom : styles.LablesNotCom}
                          style={{ textAlign: "left" }}
                          contentEditable={this.state.editing}
                          //onBlur={handleContentChange}
                          onBlur={this.handleChangeCandidateEmail}
                          suppressContentEditableWarning={true}
                        >{callInfoEmail}
                        </div>
                        <br></br>

                        <label className={styles.Labelsextrct}>Phone Number</label>
                        <div
                          key={this.state.callInfoNumb} // Add a unique key
                          className={this.state.editing == true ? styles.LabelsCom : styles.LablesNotCom}
                          style={{ textAlign: "left" }}
                          contentEditable={this.state.editing}
                          //onBlur={handleContentChange}
                          onBlur={this.handleChangeCandidatePhone}
                          suppressContentEditableWarning={true}
                        >{callInfoNumb}
                        </div>
                        <br></br>
                        <button
                          onClick={() => {
                            this.updateTagset();
                          }}
                          className={styles.frame4809656611}
                        >
                          <span className={styles.primarybuttonATS}>save name, email and phone</span>
                        </button>
                        <br></br>
                      </div>
                    ) : null}
                    {extractShow
                      ? labelsForExtract.map((labelsForExtract) => (
                        <div>
                        
                          <label className={styles.Labelsextrct}>
                            {labelsForExtract.question}
                          </label>

                          <Card.Text
                            contentEditable={this.state.editing}
                            style={{ textAlign: "left" }}
                            className={this.state.editing == true ? styles.LabelsCom : styles.LablesNotCom}
                            id="extrct"
                            name="extrct"
                            value={labelsForExtract.answer}
                          >
                            {labelsForExtract.answer}{" "}
                          </Card.Text>
                          
                          <br></br>
                         
                        </div>
                      ))
                      : null}

                    {extractShow ? (
                      <div className="Extraction">
                        <br></br>
                        {loading && <div style={{
                        marginRight: '2%',
                        marginLeft: '2%',
                        marginBottom: '2%'
                      }}>Adding to Bullhorn please wait...</div>}  
                        <Alert
                          className={styles.modalPopup}
                          show={editAlert}
                          variant="success"
                        >
                          

                          <Alert.Heading>Success!</Alert.Heading>
                          <p>Saved</p>
                        </Alert>
                        <Alert
                          className={styles.modalPopup}
                          show={editFaultAlert}
                          variant="danger"
                        >
                          <Alert.Heading>Error!</Alert.Heading>
                          <p>Something Went Wrong!</p>
                        </Alert>
                        <button
                          onClick={this.updateExtract2}
                          className={styles.frame48096312}
                        >
                          {
                            //<img className={styles.Bullhorn} src={bullhorn}></img>
                          }
                          Save Changes To Fields
                        </button>
                        <Alert
                      className="alertsSize"
                      style={{
                        marginRight: '2%',
                        marginLeft: '2%'
                      }}
                      show={bullhornExtractAlert}
                      variant="success"
                    >
                      <Alert.Heading>Added to Bullhorn</Alert.Heading>
                    </Alert>
                    <Alert
                      className="alertsSize"
                      style={{
                        marginRight: '2%',
                        marginLeft: '2%'
                      }}
                      show={bullhornExtractAlertFault}
                      variant="success"
                    >
                      <Alert.Heading>Something went wrong</Alert.Heading>
                    </Alert>
                      </div>
                    ) : null}
                  </span>
                  <Alert
                    className="alertsSize"
                    show={CopyAlert}
                    variant="success"
                  >
                    <Alert.Heading>Summary Copied to Clipboard!</Alert.Heading>
                  </Alert>




                  {summaryShow ? (

                    <div className={styles.flexcontainer191}>

                      <button
                        onClick={() => {
                          navigator.clipboard.writeText(
                            SummarizedText.map(
                              (SummarizedText) => SummarizedText[0]
                            )
                          );
                          this.openCopyAlert();
                        }}
                        className={styles.frame48096566}
                      >
                        <span className={styles.primarybuttonATS}>Copy</span>
                      </button>
                      <button
                        onClick={() => {
                          this.changeSummary();
                        }}
                        className={styles.frame48096566}
                      >
                        <span className={styles.primarybuttonATS}>edit</span>
                      </button>
                      <button
                        onClick={() => {

                          this.summaryChange();

                        }}
                        className={styles.frame48096566}
                      >
                        <span className={styles.primarybuttonATS}>
                          Save and Push Summary to Bullhorn
                        </span>
                      </button>
                    </div>
                  ) : null}

                  {summaryShow ? (
                    <span className={styles.loremipsumdolorsi}>
                      <Alert
                        className={styles.modalPopup}
                        show={bullhornAlertSumm}
                        variant="success"
                      >
                        <Alert.Heading>Success!</Alert.Heading>
                        <p>Saved and pushed to ats!</p>
                      </Alert>
                      <Alert
                        className={styles.modalPopup}
                        show={bullhornAlertSummFault}
                        variant="danger"
                      >
                        <Alert.Heading>Error!</Alert.Heading>
                        <p>Something Went Wrong!</p>
                      </Alert>
                      {this.state.AtsConnected === "Talisman" ? (
                        <div>
                          <label className={styles.Labelsextrct1}>Talisman ID</label>
                          <input required onChange={this.changeTalismanId}></input>
                        </div>
                      ) : null}
                      <br></br>
                      <Alert
                        className="alertsSize"
                        show={alerting}
                        variant="success"
                      >
                        <Alert.Heading>Summary is now editable!</Alert.Heading>
                      </Alert>

                      {loadingBullhorn && <div style={{
                        marginRight: '2%',
                        marginLeft: '2%',
                        marginBottom: '5%'
                      }}>Adding to Bullhorn please wait...</div>}  


                      {summStatus === 'Loading' ? (
                        <div>Loading...</div>
                      ) : summStatus === 'Squire has failed to generate a summary based on the info in this conversation.' ? (
                        <div>
                          {summStatus}
                          {/* Render your specific message here */}
                          <div>
                            <br></br>
                            <InputLabel >Recruiter Notes</InputLabel>
                            <Card.Text
                              contentEditable={true}
                              className={styles.RecNotes}
                              style={{ textAlign: "left" }}
                              id="summ-edit"
                              name="summ-edit"
                              value={FreeForm}
                            >
                              {FreeForm}
                            </Card.Text>
                          </div>
                        </div>

                      ) : summStatus === 'Squire is generating a summary...' ? (
                        <div>NoteTaker is generating a summary...</div>
                      ) : summStatus === 'Summarization is queued to take place' ? (
                        <div>Summarization is queued to take place</div>
                      ) : summStatus === 'Transcribing yet to complete' ? (
                        <div>Transcribing yet to complete</div>
                      ) : summStatus === 'Completed' && SummarizedText.length >= 1 ? (
                        <div>
                          {SummarizedText.map((SummarizedText) => (
                            <Card.Text
                              contentEditable={this.state.editing}
                              style={{ textAlign: "left" }}
                              className={this.state.editing == true ? styles.ContentEditbale : styles.ContentNotEditable}
                              id="summ"
                              name="summ"
                              value={SummarizedText}
                            >
                              {SummarizedText[0]}{" "}
                            </Card.Text>
                          ))}
                          <div>
                            <br></br>
                            <InputLabel style={{ color: 'black' }}>Recruiter Notes</InputLabel>
                            <Card.Text
                              contentEditable={true}
                              className={styles.RecNotes}
                              style={{ textAlign: "left" }}
                              id="summ-1"
                              name="summ-1"
                              value={FreeForm}
                            >
                              {FreeForm}
                            </Card.Text>
                          </div>
                        </div>
                      ) : (
                        <div>
                          {summStatus === 'Completed' && SummarizedText.length === 0 ? (
                            <div>
                              NoteTaker has failed to generate a summary based on the info in this conversation.
                              <div>
                                <br></br>
                                <InputLabel >Recruiter Notes</InputLabel>
                                <Card.Text
                                  contentEditable={true}
                                  className={styles.RecNotes}
                                  style={{ textAlign: "left" }}
                                  id="summ-edit"
                                  name="summ-edit"
                                  value={FreeForm}
                                >
                                  {FreeForm}
                                </Card.Text>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      )}


                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  }
}
import React, { Component } from 'react'
import { FormControl, InputLabel, MenuItem, Select, TextField, } from "@mui/material";
import styles from "./user.module.css";

export default class InviteUserForm extends Component {
    render(props) {
        return (
            <div className={styles.mt10}>
                <div className={styles.userFormDiv}>
                    <div className={styles.FormItemWrapper}>
                        <TextField label="Enter First Name" onChange={e => this.props.FirstNamefn(e, this.props.id)} variant="outlined" required />
                    </div>
                    <div className={styles.FormItemWrapper}>
                        <TextField label="Enter Last Name" onChange={e => this.props.LastNamefn(e, this.props.id)} variant="outlined" required />
                    </div>
                    <div className={styles.FormItemWrapper}>
                        <TextField label="Email" onChange={e => this.props.Emailfn(e, this.props.id)} variant="outlined" type={"email"} required />
                    </div>
                    <div className={styles.FormItemWrapperRole}>
                        {/* <FormControl>
                            <InputLabel className={styles.FormLabel} id="demo-simple-select-label">Role</InputLabel>
                            <Select
                                className={styles.SelectRecuter}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                // value={age}
                                onChange={e => this.props.Recruiterfn(e, this.props.id)}
                                label="Role" required
                            >
                                <MenuItem value="Recruiter">Recruiter</MenuItem>
                                <MenuItem value="Admin">Admin</MenuItem>
                            </Select>
                        </FormControl> */}

                        <FormControl fullWidth>
                            <InputLabel >Role</InputLabel>
                            <Select
                                onChange={e => this.props.Recruiterfn(e, this.props.id)}
                                label="Role"
                            // onChange={e => props.Recruiterfn(e, props.id)}
                            >
                                <MenuItem value="Recruiter">Recruiter</MenuItem>
                                <MenuItem value="Admin">Admin</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className={styles.FormItemWrapperPlusBtn}>
                        <div className={styles.AddNewUserRowWrapper}>
                            <button onClick={this.props.deleteHandeler} className={`${styles.mt0} ${styles.RemoveNewUserBtn}`}>
                                <span className={styles.crossBtn}>+</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

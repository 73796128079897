/* global chrome */
import React from "react";
import Auth from "../Auth";
import axios from "axios";
import styles from "./Login.module.css";
import backgroundimg from "./../Images/backgrounds/login_background.svg";
import Cookies from "universal-cookie";
import googleicon from "./../Images/logo/Google.svg";
import microsofticon from "./../Images/logo/microsoft.svg";
import linkdinicon from "./../Images/logo/linkedin-alt.svg";
import olaslogoimg from "./../Images/KyloeNotetaker.png";
import Alert from "react-bootstrap/Alert";
import { sendTokenToChromeExtension } from "./sendTokenToExt";
import { TextField } from "@mui/material";
import Authenticationleft from "./Authenticationleft";
import { LoginSocialGoogle, LoginSocialLinkedin } from "reactjs-social-login";
import { Link, Redirect } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import Loader from "./Loader/Loader";
import ErrorModal from "../Components/Error_Modle/ErrorModal";
import VerifyEmail from "./VerifyEmail";

//Create class
export class Login extends React.Component {
  //post the login credentials to the backend
  //retrieve the response from the backend - token
  //if success - set auth to true, so rest of site can be accessed
  //else stay false - routes remain protected

  constructor() {
    super();
    //binding the data so it can be called
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangePass = this.onChangePass.bind(this);

    this.LoaderActive = this.LoaderActive.bind(this);
    this.handleClose = this.handleClose.bind(this);
    //setting to empty strings
    this.state = {
      Name: "",
      Pass: "",
      errorMessage: null,
      rememberMe: false,
      AuthProvider: null,
      LoginAuthdata: null,
      TokenPresent: null,
      Loader: false,
      ismodalopen: false,
      isemailverified: false,
      redirect: null,
      Isadmin: null,
      err: null,
    };
  }

  componentDidMount() {
    const cookies = new Cookies();
    var config = cookies.get("token");
    this.setState({ TokenPresent: cookies.get("token") });
    this.setState({ redirect: cookies.get("redirect") });
    this.setState({ redirect: cookies.get("redirect") });
    this.setState({ Isadmin: cookies.get("Isadmin") });
    if (!cookies.get("token")) {
      config = localStorage.getItem("token");
      this.setState({ TokenPresent: config });
    }
  }


  // set the state of the username
  onChangeName(e) {
    this.setState({ Name: e.target.value });
  }

  //setting the state of the password
  onChangePass(e) {
    this.setState({ Pass: e.target.value });
  }

  

  // Funnction to handle Mannual login
  onSubmit(e) {
    e.preventDefault();
    this.setState({ Loader: true });
    //post request to post the data

    const { Name } = this.state;
    if (Name.includes("+")) {
      this.setState({ Loader: false });
      console.log("+++++++++");
      this.setState({ ismodalopen: true });
      this.setState({ err: "Invalid email format" });
    } else {
      axios({
        method: "POST",
        url: process.env.REACT_APP_BACKEND_URL + "/login/login",
        data: {
          username: this.state.Name,
          password: this.state.Pass,
        },
      })
        //get the token from the backend and set the authentication to true
        //store the token in session storage
        .then((response) => {
          console.log(response);
          console.log(response.data.message.is_verified);

          if (
            (response.data.message.is_verified == true ||
              response.data.message.is_verified == null) &&
            (response.data.message.token != null ||
              response.data.message.token != undefined)
          ) {
            let djangoToken = response.data.message.token;
            let redirect = response.data.message.redirect;
            console.log(redirect); 
            let Isadmin = response.data.message.is_admin;
            console.log(djangoToken);
            //authentication is set to true, allowing for access to protected pages
            Auth.authenticate();
            const cookies = new Cookies();
            if (document.getElementById("checkedBox").checked == true) {
              cookies.set("loggedin", Auth.getAuth(), { path: "/" });
              cookies.set('token', response.data.message.token, {
                path: '/',
                domain: 'squire-olas.com', // Replace with your desired domain
              });
              cookies.set("loginfrommail", "true", { path: "/" });
              cookies.set("redirect", redirect, { path: "/" });
              cookies.set("Isadmin", Isadmin, { path: "/" });
            } else {
              // localStorage.setItem("loginfrommail", "true");
              // localStorage.set("loginfrommail", "true",);
              cookies.set("loginfrommail", "true", { path: "/" });
              cookies.set("Isadmin", Isadmin, { path: "/" });
              cookies.set("redirect", redirect, { path: "/" });
              cookies.set("loggedin", Auth.getAuth(), { path: "/" });
              cookies.set('token', response.data.message.token, {
                path: '/',
                domain: 'squire-olas.com', // Replace with your desired domain
              });
              //cookies.set("token", response.data.message.token, { path: "/", domain: 'squire-olas.com' } );
            }
            axios({
              method: "POST",
              url: process.env.REACT_APP_BACKEND_URL + "/login/log_in",
              data: {
                username: this.state.Name,
                password: this.state.Pass, 
              },
            })
              //get the token from the backend and set the authentication to true
              //store the token in session storage
              .then((response) => {
                let jwtToken = response.data.refresh;
                sendTokenToChromeExtension({
                  djangoToken,
                  jwtToken,
                });
                console.log(djangoToken, jwtToken);
                this.setState({ Loader: false });
                //window.location.href = "/home" //loads in a different page
                this.setState({ Loader: false });
                if (redirect == 1) {
                  window.location.href = "/profile";
                }
                if (redirect == 2) {
                  window.location.href = "/how-to-use";
                }
                if (redirect == 3) {
                  window.location.href = "/planselection";
                }
                if (redirect == 4) {
                  window.location.href = "/home";
                }
              })
              .catch((err) => {
                this.setState({ ismodalopen: true });
                this.setState({
                  err: "Login Failed to extension",
                });
                if (redirect == 1) {
                  window.location.href = "/profile";
                }
                if (redirect == 2) {
                  window.location.href = "/how-to-use";
                }
                if (redirect == 3) {
                  window.location.href = "/planselection";
                }
                if (redirect == 4) {
                  window.location.href = "/home";
                }
                this.setState({ Loader: false });

                //console.log(err);
              });
            // console.log(cookies.get('loggedin')); // Pacman
            // .setItem("loggedin", Auth.getAuth());// using session storage to get the user who is logged in
            // localStorage.setItem("token", response.data.token);// set the token in session storage
          } else if (
            response.data.message.is_verified == true ||
            response.data.message.is_verified == null ||
            response.data.message.token == null ||
            response.data.message.token == undefined
          ) {
            this.setState({ isemailverified: true });
            console.log("else");
            console.log(response.data.message.is_verified);
          }
        })
        .catch((err) => {
          this.setState({ ismodalopen: true });
          let errormsg = JSON.parse(err.response.request.response);
          // console.log(errormsg.message.message);
          this.setState({ err: errormsg.message.message });
          this.setState({ Loader: false });
          // setTimeout(() => {
          //   this.setState({ errorMessage: null });
          // }, 3000);

          //console.log(err);
        });
    }
  }

  // start loader
  LoaderActive() {
    this.setState({ Loader: true });
  }

  //close modal popup
  handleClose() {
    this.setState({
      ismodalopen: false,
    });
  }
  render() {
    const authHandler = (err, data) => {
      console.log(err, data);
    };
    const {
      isemailverified,
      errorMessage,
    } = this.state;

    if (isemailverified == true) {
      console.log("Email unverified");
      return <VerifyEmail Email={this.state.Name} seconds="0" />;
    }
    if (this.state.TokenPresent) {
      if (this.state.redirect == 1) {
        return <Redirect to="/profile" />;
      }
      if (this.state.redirect == 2) {
        return <Redirect to="/how-to-use" />;
      }
      if (this.state.redirect == 3) {
        return <Redirect to="/planselection" />;
      }
      if (this.state.redirect == 4) {
        return <Redirect to="/home" />;
      }
    }
    return (
      //forms that have been made with react features
      //add user id, name and password
      <div className={`${styles.loginApp}`}>
        {this.state.Loader == true ? <Loader /> : null}
        <Authenticationleft />
        {/* {this.state.ismodalopen &&  */}
        <ErrorModal
          errorMessage={this.state.err}
          open={this.state.ismodalopen}
          handleClose={this.handleClose}
        />
        {/* } */}
        {/* Left Screen */}
        <div className={`${styles.split} ${styles.right} `}>
          {/* Mobile View Header */}
          <div
            className={`${styles.headerResMode} ${styles.flexJccAic}`}
            style={{ backgroundImage: `url(${backgroundimg})` }}
          >
            <img className={styles.olasheaderres} src={olaslogoimg} />
          </div>
          {/* right Side */}
          <div
            className={`${styles.h100v} ${styles.flexJccAic} ${styles.mobileResHeigh}`}
          >
            <div className={`${styles.loginWrapper}`}>
              <h2 className={`${styles.loginHeader} HeadingsButtonFontOlas`}>
                Welcome to <span className={styles.sqireRes}>NoteTaker</span>
              </h2>
              <br></br>
              

              <div className={styles.loginContainer}>
                <span className={styles.bysigningupyouc1}>
                  Don’t have a NoteTaker account?{" "}
                  <a href="https://squireapp.io/contact/" className={styles.links}>
                    {" "}
                    Request a Demo
                  </a>
                </span>

                <div className={styles.instantLoginContainer}>
                  {/* <img className={styles.separator14} src={separator} /> */}
                  <span id="instant_log">Instant Login</span>
                  {/* <img className={styles.separator4} src={separator} /> */}
                </div>

                {/* {this.state.errorMessage && (

                  <Alert className={`${styles.alert} alert-danger`}>
                    <Alert.Heading className={styles.alertMessage}> {this.state.errorMessage} </Alert.Heading>
                  </Alert>
                )} */}
                <TextField
                  label="Email"
                  id="UserNameInput"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.state.Name}
                  onChange={this.onChangeName}
                  className={styles.usernameField}
                />
                <TextField
                  label="Password"
                  variant="outlined"
                  type="password"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.state.Pass}
                  onChange={this.onChangePass}
                  className={styles.passField}
                />
                {errorMessage && (
                  <p className={styles.ErrorMessageParaSignUp}>
                    {errorMessage}
                  </p>
                )}
                <div className={styles.flexcontainerLogin}>
                  <div className={styles.flexJccAic}>
                    <input
                      id="checkedBox"
                      type="checkbox"
                      className={styles.checkBoxRememberMe}
                    ></input>
                    <span className={styles.rememberMe}>Remember Me</span>
                  </div>
                  <Link to="reset-password" className={styles.forgotPassword}>
                    Reset your password.
                  </Link>
                </div>

                <button
                  className={`HeadingsButtonFontOlas ${styles.loginButton}`}
                  onClick={this.onSubmit}
                >
                  Login
                </button>

                

                <span className={styles.termsService}>
                  By signing into NoteTaker, you agree to our{" "}
                  <Link
                    to="/terms-of-service"
                    target="blank"
                    className="forgot-password"
                  >
                    Terms of Service&nbsp;
                  </Link>
                  and&nbsp;
                  <Link
                    to="/privacy-policy"
                    target="blank"
                    className="forgot-password"
                  >
                    Privacy Policy
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
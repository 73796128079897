import { Box, Button, Modal } from '@mui/material';
import { Typography } from 'antd';
import React, { Component } from 'react'
import add from '../../Images/add.svg';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    maxWidth: 400,
    height: 200,
    bgcolor: 'background.paper',
    border: 'transparent',
    boxShadow: 24,
    p: 9,
    borderRadius: '15px',
    textAlign: 'center',
    padding: 5
};
export default class PlanPurchase extends Component {
    constructor() {
        super();
        this.refreshPage = this.refreshPage.bind(this);
    }
    refreshPage() {
        window.location.reload();
    }
    render() {
        const { open, handleClose, Message } = this.props
        return (
            <Modal open={open} aria-labelledby="modal-modal-title">
                <Box sx={style}>
                    <img onClick={handleClose} style={{ position: 'absolute', cursor: "pointer", right: '0', top: '0' }} src={add} />                    {/* <img src={errorIcon} /> */}
                    <Typography style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "16px", textTransform: "capitalize", lineHeight: "24px", textAlign: 'center', color: "#1D103B", margin: " 10px 0 20px 0" }}>
                        {Message != null ? Message : "If You have Purchased Plan Please refresh this page to check"}
                    </Typography>
                    <Button onClick={this.refreshPage} sx={{ backgroundColor: "#d8045c", textTransform: "capitalize", padding: "10px 25px", color: "#fff", height: 45, '&:hover': { backgroundColor: "#8c5df5" } }}>Refresh</Button>
                </Box>
            </Modal>
        )
    }
}

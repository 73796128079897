/* global chrome */
exports.sendTokenToChromeExtension = (jwt) => {
  try {
    console.log(chrome);
    chrome.runtime.sendMessage(
      "habiadmejhcipkmljfidkeagpekcmdlp",
      { jwt },
      (response) => {
        //   if (chrome.runtime.lastError) console.log(chrome.runtime.lastError);
        console.log("we got here")
        if (!response.success) {
          console.log("error sending message", response);
          return false;
        }
        console.log("Sucesss ::: ", response.message);
        return true
      }
    );
  } catch (error) {
    console.log(error);
    return false

  }
};
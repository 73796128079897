import React, { Component } from "react";
import { Box, Grid, Tab, } from "@mui/material";
import { NavbarLeft } from "../Components/Nav-Left/NavLeft";
import { NavbarTop } from "../Components/Navbar";
import UniversalCss from "../Universal.module.css";
import styles from "./user.module.css";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import InviteUserForm from "./InviteUserForm";
import UserDetails from "./UserDetails";
import Billing from "./Billing";
import ErrorModal from "../Components/Error_Modle/ErrorModal";
import SuccessModal from "../Components/Success_Modle/SuccessModal";
import axios from 'axios';
import Cookies from 'universal-cookie';
import ManageUserCharge from "./ManageUserCharge";





export default class UserManagement extends Component {
  constructor() {
    super();
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormFirstName = this.handleFormFirstName.bind(this);
    // this.InviteUserBtnFn = this.InviteUserBtnFn.bind(this);
    this.BillingPageByUsers = this.BillingPageByUsers.bind(this);
    this.getData = this.getData.bind(this);

    this.state = {
      Tabvalue: '1',
      anchorEl: null,
      err: null,
      ismodalopen: false,
      isSuccessmodalopen: false,
      data: [],
      companyusersinfo: [],
      pageSize: 10,
      totalPages: 0,
      // UserForm: [<InviteUserForm key={0} />]
    }
  }
  componentDidMount() {
    this.getData();
  }

  async getData() {
    const cookies = new Cookies();
    var config = cookies.get("token")

    if (!cookies.get("token")) {
      config = localStorage.getItem("token")
    }

    // console.log(config);
    var headerconfig = {
      method: 'get',
      url: process.env.REACT_APP_BACKEND_URL + '/company/users',
      headers: {
        'Authorization': 'Token ' + config,
      },
    };

    try {
      const response = await axios(headerconfig);
      const data = response.data.message;
      console.log(data);
      this.setState({
        data,
        totalPages: Math.ceil(data.length / this.state.pageSize), // assuming 10 items per page
      });
    } catch (error) {
      console.error(error);
    }

    var headerconfig1 = {
      method: 'get',
      url: process.env.REACT_APP_BACKEND_URL + '/company/get-company-plan-dtl',
      headers: {
        'Authorization': 'Token ' + config,
      },
    };

    axios(headerconfig1)
      .then(response => {
        console.log(response.data.message);
        this.setState({ companyusersinfo: response.data.message })
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleChange(e, Value) {
    this.setState({
      Tabvalue: Value
    })
  }

  handleFormFirstName(e) {
    console.log(e.target.value);
  }


  handleMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };
  // InviteUserBtnFn(e, UserForm) {
  //     e.preventDefault()
  //     this.setState({
  //         UserForm: [...UserForm, < InviteUserForm key={UserForm.length} />]
  //     })

  // }
  addSession() {
    const id = this.state.sessions.length;
    this.setState(state => ({
      // treat your state as immutable
      sessions: state.sessions.concat(id),
      sessionSelected: id
    }));
  }
  handleClose() {
    this.setState({
      ismodalopen: false,
      isSuccessmodalopen: false,
    });
  }
  BillingPageByUsers(val) {
    this.setState({ Tabvalue: val })
  }

  render() {
    return (
      <>
        <ErrorModal errorMessage={this.state.err} open={this.state.ismodalopen} handleClose={this.handleClose} />
        <SuccessModal Message={this.state.Message} open={this.state.isSuccessmodalopen} handleClose={this.handleClose} />
        <Box sx={{ flexGrow: 1 }}>
          <Grid container className={UniversalCss.GridContainerWrapperHegih}>
            <Grid item md={1.7} xs={2} className={UniversalCss.LeftNavbarShadow}>
              <NavbarLeft />
            </Grid>
            <Grid item md={10.3} xs={10} className={UniversalCss.navbarAndRouteWrapper} >
              <NavbarTop PageData={"Users"} />
              <div className={styles.UserWrapper}>
                <TabContext value={this.state.Tabvalue}>
                  <div className={styles.NavbarWrapperDiv}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList textColor="primary"
                        indicatorColor="primary" onChange={this.handleChange} aria-label="lab API tabs example">
                        <Tab className={styles.tableHeadercss} label="User Management" value="1" />
                        <Tab label="Billing" value="2" />
                      </TabList>
                    </Box>

                  </div>
                  <TabPanel className={styles.Tabpannel} value="1"><UserDetails BillingPageByUsers={this.BillingPageByUsers} data={this.state.data} open={this.state.ismodalopen} getData={this.getData} companyusersinfo={this.state.companyusersinfo} pageSize={this.state.pageSize} totalPages={this.state.totalPages} /></TabPanel>
                  <TabPanel className={styles.Tabpannel} value="2"><ManageUserCharge /></TabPanel>
                  {/* <TabPanel className={styles.Tabpannel} value="2"><Billing /></TabPanel> */}
                </TabContext>
              </div>
            </Grid>
          </Grid>
        </Box >
      </>
    );
  }
}

import React, { Component } from 'react'
import backgroundimg from "./../Images/backgrounds/login_background.svg";
import locklogo from './../Images/logo/lock.svg';
import olaslogoimg from "./../Images/logo/olaslogo.svg";
import styles from "./Login.module.css";

import { TextField } from "@mui/material";
import ErrorModal from '../Components/Error_Modle/ErrorModal';
import Authenticationleft from './Authenticationleft';
import axios from 'axios';
import SuccessModal from '../Components/Success_Modle/SuccessModal';


export default class CreateNewPass extends Component {
    constructor(props) {
        super(props)
        this.onChangePass = this.onChangePass.bind(this);
        this.onChangeConfirmPass = this.onChangeConfirmPass.bind(this);
        this.handlepassUpdate = this.handlepassUpdate.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            LoaderActive: true,
            err: null,
            Password: null,
            ConfirmPass: null,
            ismodalopen: false,
            Message: null,
            // isSuccessmodalopen: false

        }
    }
    handleClose() {
        this.setState({
            isSuccessmodalopen: false,
            ismodalopen: false,
        });
    }
    onChangePass(e) {
        this.setState({ Password: e.target.value })
    }
    onChangeConfirmPass(e) {
        this.setState({ ConfirmPass: e.target.value })
    }
    handlepassUpdate(e) {
        e.preventDefault();
        if (this.state.Password == this.state.ConfirmPass) {

            function getParameterByName(name, url = window.location.href) {
                name = name.replace(/[\[\]]/g, '\\$&');
                var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                    results = regex.exec(url);
                if (!results) return null;
                if (!results[2]) return '';
                return decodeURIComponent(results[2]);
            }
            const query = new URLSearchParams(this.props.location.search);
            var data = {
                email: getParameterByName('user'),
                // code: getParameterByName('code'),
                new: this.state.Password,
                confirm: this.state.ConfirmPass,
            };
            console.log(data);
            var config = {
                method: 'post',
                url: process.env.REACT_APP_BACKEND_URL + '/company/update-reset-password',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then(response => {
                    console.log(response);
                    this.setState({ isSuccessmodalopen: true, Message: "Password changed successfully! Please Log in" });
                    setTimeout(() => {
                        window.location.href = "/"
                    }, 2000);
                }

                ).catch(error => {
                    console.log(error);
                    this.setState({ isModalOpen: true });
                })
        } else {
            this.setState({ ismodalopen: true })
            this.setState({ err: "The passwords entered do not match the confirmed password." })

        }
    }
    render() {
        return (
            <div className={`${styles.loginApp}`}>
                < Authenticationleft />
                <ErrorModal errorMessage={this.state.err} open={this.state.ismodalopen} handleClose={this.handleClose} />
                <SuccessModal Message={this.state.Message} open={this.state.isSuccessmodalopen} handleClose={this.handleClose} />
                <div className={`${styles.split} ${styles.right} `}>
                    {/* Mobile View Header */}
                    <div
                        className={`${styles.headerResMode} ${styles.flexJccAic}`}
                        style={{ backgroundImage: `url(${backgroundimg})` }}
                    >
                        <img className={styles.olasheaderres} src={olaslogoimg} />
                    </div>
                    {/* right Side */}
                    <div className={` ${styles.mobileResHeigh}`}>
                        <div
                            className={`${styles.flexJccAic} ${styles.verifymailWrapper}`}
                        >
                            <div>
                                {/* Mail Icon Div*/}
                                <div className={styles.mailIcomContainer}>
                                    <div className={styles.mailIcomContainerDiv}>
                                        <img src={locklogo} className={styles.w100} />
                                    </div>
                                </div>
                                {/*  */}
                                <h2
                                    className={`${styles.loginHeader} ${styles.profileHeader} ${styles.textCenter}`}
                                >
                                    Create a new password
                                </h2>
                                <form onSubmit={this.handlepassUpdate} className={styles.NewPassForm}>
                                    <TextField label="Password" variant="outlined" type="password" className={`${styles.passField} ${styles.mb20p}`} required onChange={this.onChangePass} />
                                    <TextField label="Re enter Password" variant="outlined" type="password" className={`${styles.passField} ${styles.mb20p}`} onChange={this.onChangeConfirmPass} required />
                                    <div className={`${styles.mt10p} ${styles.dflexjcc}`}>
                                        <button
                                            type='submit'
                                            className={styles.RsendMailBtn}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

/* global chrome */
import React from "react";
import Auth from "../Auth";
import axios from "axios";
import styles from "./Login.module.css";
import backgroundimg from "./../Images/backgrounds/login_background.svg";
import Cookies from "universal-cookie";
import googleicon from "./../Images/logo/Google.svg";
import linkdinicon from "./../Images/logo/linkedin-alt.svg";
import olaslogoimg from "./../Images/logo/olaslogo.svg";
import Alert from "react-bootstrap/Alert";
import { sendTokenToChromeExtension } from "./sendTokenToExt";
import { TextField } from "@mui/material";
import Authenticationleft from "./Authenticationleft";
// import { Profile } from './Profile';
import { Link, Redirect } from "react-router-dom";
import Loader from "./Loader/Loader";
import { LoginSocialGoogle, LoginSocialLinkedin } from "reactjs-social-login";
import ErrorModal from "../Components/Error_Modle/ErrorModal";
import VerifyEmail from "./VerifyEmail";

//Create class
export class SignUp extends React.Component {
  //post the login credentials to the backend
  //retrieve the response from the backend - token 
  //if success - set auth to true, so rest of site can be accessed
  //else stay false - routes remain protected

  constructor() {
    super();
    //binding the data so it can be called
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePass = this.onChangePass.bind(this);
    this.onChangeRePass = this.onChangeRePass.bind(this);
    this.closeTranscribeAlert = this.closeTranscribeAlert.bind(this);
    this.openTranscribeAlert = this.openTranscribeAlert.bind(this);
    this.HandleAuthLog = this.HandleAuthLog.bind(this);
    this.handleLinkdinAuth = this.handleLinkdinAuth.bind(this);
    this.handleClose = this.handleClose.bind(this);

    //setting to empty strings
    this.state = {
      Email: "",
      Pass: "",
      RePass: "",
      transcribeAlert: false,
      errorMessage: "",
      rememberMe: false,
      verifyEmailPage: false,
      Loader: false,
      err: null,
      Passerr: null,
      LoginGoogleToken: null,
      AuthProvider: null,
      LoginAuthdata: null,
      TokenPresent: null,
      ismodalopen: false,
    };
  }
  componentDidMount() {
    const cookies = new Cookies();
    var config = cookies.get("token");
    this.setState({ TokenPresent: cookies.get("token") });
    if (!cookies.get("token")) {
      config = localStorage.getItem("token");
      this.setState({ TokenPresent: config });
      console.log("token: ", config);
    }
  }
  handleClose() {
    this.setState({
      ismodalopen: false,
    });
  }
  closeTranscribeAlert() {
    this.setState({ transcribeAlert: false });
  }
  openTranscribeAlert() {
    this.setState({ transcribeAlert: true });
    //console.log("button worjing")
  }
  // set the state of the userEmail
  onChangeEmail(e) {
    this.setState({ Email: e.target.value });
  }
  //setting the state of the password
  onChangePass(e) {
    this.setState({ Pass: e.target.value });
  }
  onChangeRePass(e) {
    this.setState({ RePass: e.target.value });
  }

  HandleAuthLog() {
    this.setState({ Loader: true });
    console.log(this.state.LoginAuthdata);
    console.log(this.state.AuthProvider);
    var data = JSON.stringify({
      source: this.state.AuthProvider,
      email: this.state.LoginAuthdata.email,
      first_name: this.state.LoginAuthdata.given_name,
      last_name: this.state.LoginAuthdata.family_name,
      oauthtoken: this.state.LoginAuthdata.access_token,
      // oauthtoken: this.state.LoginAuthdata.access_token
    });
    axios({
      method: "post",
      url: process.env.REACT_APP_BACKEND_URL + `/login/signup`,
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status == 200) {
          let djangoToken = response.data.message.token;
          let redirect = response.data.message.redirect;
          let Isadmin = response.data.message.is_admin;
          console.log(djangoToken);
          //authentication is set to true, allowing for access to protected pages
          Auth.authenticate();
          const cookies = new Cookies();

          cookies.set("loggedin", Auth.getAuth(), { path: "/" });
          cookies.set('token', response.data.message.token, {
            path: '/',
            domain: 'squire-olas.com', // Replace with your desired domain
          });
          cookies.set("loginfrommail", "false", { path: "/" });
          cookies.set("redirect", redirect, { path: "/" });
          cookies.set("Isadmin", Isadmin, { path: "/" });
          this.setState({ Loader: false });
          
          axios({
            method: "POST",
            url: process.env.REACT_APP_BACKEND_URL + "/login/log_in",
            data: {
              username: this.state.LoginAuthdata.email,
            },
          })
            //get the token from the backend and set the authentication to true
            //store the token in session storage
            .then((response) => {
              let jwtToken = response.data.refresh;
              sendTokenToChromeExtension({
                djangoToken,
                jwtToken,
              });
              console.log(djangoToken, jwtToken);
              //window.location.href = "/home" //loads in a different page
              // window.setTimeout(function () {
              this.setState({ Loader: false });
              if (redirect == 1) {
                window.location.href = "/profile";
              }
              if (redirect == 2) {
                window.location.href = "/how-to-use";
              }
              if (redirect == 3) {
                window.location.href = "/planselection";
              }
              if (redirect == 4) {
                window.location.href = "/home";
              }
              // }, 500);
            })
            .catch((err) => {
              this.setState({ ismodalopen: true });
              this.setState({
                err: "Login Failed to extension",
              });
              if (redirect == 1) {
                window.location.href = "/profile";
              }
              if (redirect == 2) {
                window.location.href = "/how-to-use";
              }
              if (redirect == 3) {
                window.location.href = "/planselection";
              }
              if (redirect == 4) {
                window.location.href = "/home";
              }
            });
        }
      })
      .catch((error) => {
        this.setState({ ismodalopen: true });
        this.setState({ err: error.message });
        console.log(error.message);
        this.setState({ Loader: false });
        // setTimeout(() => {
        //   this.setState({ err: null });
        // }, 3000)
      });
  }
  handleLinkdinAuth() {
    this.setState({ Loader: true });
    console.log(this.state.LoginAuthdata);
    console.log(this.state.AuthProvider);
    var data = JSON.stringify({
      source: this.state.AuthProvider,
      first_name: this.state.LoginAuthdata.localizedFirstName,
      last_name: this.state.LoginAuthdata.localizedLastName,
      linkedinaccesstoken: this.state.LoginAuthdata.access_token,
    });
    axios({
      method: "post",
      url: process.env.REACT_APP_BACKEND_URL + `/login/signup`,
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status == 200) {
          let djangoToken = response.data.message.token;
          let email = response.data.message.email;
          let redirect = response.data.message.redirect;
          let Isadmin = response.data.message.is_admin;
          console.log(djangoToken);
          //authentication is set to true, allowing for access to protected pages
          Auth.authenticate();
          const cookies = new Cookies();

          cookies.set("loggedin", Auth.getAuth(), { path: "/" });
          cookies.set('token', response.data.message.token, {
            path: '/',
            domain: 'squire-olas.com', // Replace with your desired domain
          });
          cookies.set("loginfrommail", "false", { path: "/" });
          cookies.set("redirect", redirect, { path: "/" });
          cookies.set("Isadmin", Isadmin, { path: "/" });
          axios({
            method: "POST",
            url: process.env.REACT_APP_BACKEND_URL + "/login/log_in",
            data: {
              username: email,
            },
          })
            //get the token from the backend and set the authentication to true
            //store the token in session storage
            .then((response) => {
              let jwtToken = response.data.refresh;
              sendTokenToChromeExtension({
                djangoToken,
                jwtToken,
              });
              console.log(djangoToken, jwtToken);
              //window.location.href = "/home" //loads in a different page
              // window.setTimeout(function () {
              this.setState({ Loader: false });
              if (redirect == 1) {
                window.location.href = "/profile";
              }
              if (redirect == 2) {
                window.location.href = "/how-to-use";
              }
              if (redirect == 3) {
                window.location.href = "/planselection";
              }
              if (redirect == 4) {
                window.location.href = "/home";
              }
            })
            .catch((err) => {
              this.setState({ ismodalopen: true });
              this.setState({
                err: "Login Failed to extension",
              });
              if (redirect == 1) {
                window.location.href = "/profile";
              }
              if (redirect == 2) {
                window.location.href = "/how-to-use";
              }
              if (redirect == 3) {
                window.location.href = "/planselection";
              }
              if (redirect == 4) {
                window.location.href = "/home";
              }
            });
        }
      })
      .catch((error) => {
        this.setState({ ismodalopen: true });
        this.setState({ Loader: false });
        this.setState({ err: error.message });
        console.log(error.message);
        // setTimeout(() => {
        //   this.setState({ err: null });
        // }, 3000)
      });
  }

  // on click of submit button
  onSubmit(e) {
    e.preventDefault();
    this.setState({ Loader: true });

    var data = JSON.stringify({
      email: this.state.Email,
      password: this.state.Pass,
      source: "email",
    });

    const { Email } = this.state;
    if (Email.includes("+")) {
      console.log("+++++++++");
      this.setState({ Loader: false });
      this.setState({ ismodalopen: true });
      this.setState({ err: "Invalid email format" });
    } else {
      if (this.state.Pass == this.state.RePass) {
        axios({
          method: "post",
          url: process.env.REACT_APP_BACKEND_URL + `/login/signup`,
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            console.log(response);
            this.setState({ Loader: false });
            // window.location.href = "/verifyemail";
            this.setState({ verifyEmailPage: true });
          })
          .catch((error) => {
            this.setState({ ismodalopen: true });
            let res = JSON.parse(error.request.response);
            console.log(res.message);
            if (
              res.message.trim() ===
              "{'username': [ErrorDetail(string='auth user with this username already exists.', code='unique')]}"
            ) {
              // console.log("true");
              this.setState({ Loader: false });
              this.setState({
                err: "This Account Already Exists, Please Sign In",
              });
            } else {
              this.setState({ Loader: false });
              this.setState({ err: error.message });
            }
            this.setState({ Loader: false });
          });
      } else {
        this.setState({ ismodalopen: true });
        this.setState({ Loader: false });
        this.setState({
          err: "The passwords entered do not match the confirmed password.",
        });
      }
    }
  }
  authHandler(err, data) {
    console.log(err, data);
  }
  responseGoogle(response) {
    console.log(response);
  }
  render() {
    const { tokenResponse } = this.state;
    const authHandler = (err, data) => {
      console.log(err, data);
    };
    const { transcribeAlert, closeTranscribeAlert, errorMessage } = this.state;

    if (this.state.TokenPresent) {
      return <Redirect to="/" />;
    }
    return (
      <>
        {/* //forms that have been made with react features
        //add user id, name and password */}

        {this.state.verifyEmailPage == false ? (
          <div className={`${styles.loginApp}`}>
            <Authenticationleft />
            <ErrorModal
              errorMessage={this.state.err}
              open={this.state.ismodalopen}
              handleClose={this.handleClose}
            />

            {this.state.Loader == true ? <Loader /> : null}
            <div className={`${styles.split} ${styles.right} `}>
              {/* Mobile View Header */}
              <div
                className={`${styles.headerResMode} ${styles.flexJccAic}`}
                style={{ backgroundImage: `url(${backgroundimg})` }}
              >
                <img className={styles.olasheaderres} src={olaslogoimg} />
              </div>
              {/* right Side */}
              <div
                className={`${styles.h100v} ${styles.flexJccAic} ${styles.mobileResHeigh}`}
              >
                <div className={`${styles.loginWrapper}`}>
                  <h2
                    className={`HeadingsButtonFontOlas ${styles.loginHeader}`}
                  >
                    Welcome to <span className={styles.sqireRes}>NoteTaker</span>
                  </h2>
                  <Alert
                    className={styles.alert}
                    show={transcribeAlert}
                    onClose={this.closeTranscribeAlert}
                    variant="danger"
                    dismissible
                  >
                    <Alert.Heading>Something went wrong!</Alert.Heading>
                    <Alert.Heading>Failed to Update</Alert.Heading>
                  </Alert>
                  <br></br>

                  <div className={styles.platformWrapper}>
                    <LoginSocialGoogle
                      client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                      scope="openid profile email"
                      // typeResponse="idToken"
                      // prompt="consent"
                      discoveryDocs="claims_supported"
                      onResolve={({ provider, data }) => {
                        console.log(provider, data);
                        this.setState({ LoginAuthdata: data });
                        this.setState({ AuthProvider: provider });
                        setTimeout(() => {
                          this.HandleAuthLog();
                        }, 100);
                      }}
                      onReject={(err) => {
                        console.log(err);
                      }}
                    >
                      <div
                        id="google_btn"
                        className={styles.login_with_platformwrapper}
                      >
                        <div className={styles.login_platform_icon_wrapper}>
                          <img className="w-100" src={googleicon} />
                        </div>
                        <p
                          className={`HeadingsButtonFontOlas ${styles.login_with_platformwrapper_innter_text}`}
                        >
                          Continue with Google
                        </p>
                      </div>
                    </LoginSocialGoogle>
                    <LoginSocialLinkedin
                      scope="r_emailaddress r_liteprofile"
                      client_id={process.env.REACT_APP_LINKDIN_CLIENT_ID}
                      client_secret={
                        process.env.REACT_APP_LINKDIN_CLIENT_SECRET
                      }
                      redirect_uri={process.env.REACT_APP_REDIRECT_URL}
                      // onLoginStart={this.handleLinkdinAuth}
                      onResolve={({ provider, data }) => {
                        console.log(
                          "Response Data From Linkdin",
                          provider,
                          data
                        );
                        this.setState({ AuthProvider: provider });
                        this.setState({ LoginAuthdata: data });
                        setTimeout(() => {
                          this.handleLinkdinAuth();
                        }, 100);
                      }}
                      onReject={(err) => {
                        console.log(err);
                      }}
                    >
                      <div
                        id="Linkdin_btn"
                        className={styles.login_with_platformwrapper}
                      >
                        <div className={styles.login_platform_icon_wrapper}>
                          <img className="w-100" src={linkdinicon} />
                        </div>
                        <p
                          className={`HeadingsButtonFontOlas ${styles.login_with_platformwrapper_innter_text}`}
                        >
                          Continue with Linkedin
                        </p>
                      </div>
                    </LoginSocialLinkedin>
                  </div>

                  <div className={styles.loginContainer}>
                    <span className={styles.bysigningupyouc1}>
                      Don’t have a NoteTaker account?{" "}
                      <a
                        href="https://squireapp.io/contact/"
                        className={styles.links}
                      >
                        {" "}
                        Request a Demo
                      </a>
                    </span>

                    <div className={styles.instantLoginContainer}>
                      {/* <img className={styles.separator14} src={separator} /> */}
                      <span>Instant Sign Up</span>
                      {/* <img className={styles.separator4} src={separator} /> */}
                    </div>

                    {this.state.errorMessage && (
                      <Alert className={`${styles.alert} alert-danger`}>
                        <Alert.Heading className={styles.alertMessage}>
                          {" "}
                          {this.state.errorMessage}{" "}
                        </Alert.Heading>
                      </Alert>
                    )}
                    <form onSubmit={this.onSubmit}>
                      <TextField
                        label="Email"
                        variant="outlined"
                        value={this.state.Email}
                        onChange={this.onChangeEmail}
                        className={styles.usernameField}
                        required
                      />
                      {/* {this.state.err && <p className={styles.ErrorMessageParaSignUp}>{this.state.err}</p>} */}
                      <TextField
                        label="Password"
                        variant="outlined"
                        type="password"
                        value={this.state.Pass}
                        inputProps={{
                          minLength: 8,
                        }}
                        onChange={this.onChangePass}
                        className={styles.passField}
                        required
                      />
                      <TextField
                        label="Re enter Password"
                        variant="outlined"
                        type="password"
                        value={this.state.RePass}
                        inputProps={{
                          minLength: 8,
                        }}
                        onChange={this.onChangeRePass}
                        className={styles.passField}
                        required
                      />
                      {/* {this.state.Passerr && <p className={styles.ErrorMessageParaSignUp}>{this.state.Passerr}</p>} */}
                      <span className={`${styles.mb10p}`}>
                        * The password must be at least 8 characters long.{" "}
                      </span>
                      <div className={styles.flexcontainerLogin}>
                        <div className={styles.flexJccAic}>
                          <input
                            id="checkedBox"
                            type="checkbox"
                            className={styles.checkBoxRememberMe}
                          ></input>
                          <span className={styles.rememberMe}>Remember Me</span>
                        </div>
                        <Link
                          to="/reset-password"
                          className={styles.forgotPassword}
                        >
                          Reset your password.
                        </Link>
                      </div>

                      <button
                        className={`HeadingsButtonFontOlas ${styles.loginButton}`}
                        type="submit"
                      >
                        Sign up
                      </button>
                    </form>
                    <span
                      className={`${styles.mt10p} ${styles.bysigningupyouc1}`}
                    >
                      Already have account?{" "}
                      <Link className={styles.links} to={"/"}>
                        Log In
                      </Link>
                    </span>

                    <span className={styles.termsService}>
                      By signing into NoteTaker, you agree to our
                      <Link
                        to="/terms-of-service"
                        target="blank"
                        className="forgot-password"
                      >
                        Terms of Service&nbsp;
                      </Link>
                      and&nbsp;
                      <Link
                        to="/privacy-policy"
                        target="blank"
                        className="forgot-password"
                      >
                        Privacy Policy
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <VerifyEmail Email={this.state.Email} seconds="60" />
        )}
      </>
    );
  }
}

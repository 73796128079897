import React from 'react';
import styles from './Settings.module.css'
import UniversalCss from '../Universal.module.css'
import knight from './../Images/squireInapp.png';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { NavbarTop } from '../Components/Navbar';
import { NavbarLeft } from '../Components/Nav-Left/NavLeft';
import { FormGroup, TextField } from '@mui/material';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert'
import uploadimage from './../Images/icons/imageUpload.svg';
import Cookies from 'universal-cookie';
import NotSuccess from './not-success';
import Success from './success'
import ErrorModal from '../Components/Error_Modle/ErrorModal';
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';


export class Settings extends React.Component {
    constructor(props) {
        super(props);
        // this.onSubmit = this.onSubmit.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeOld = this.onChangeOld.bind(this);
        this.onChangePass = this.onChangePass.bind(this);
        this.onChangeConfirm = this.onChangeConfirm.bind(this);
        this.onChangeFirstName = this.onChangeFirstName.bind(this)
        this.onChangeLastName = this.onChangeLastName.bind(this)
        this.onChangeEmail = this.onChangeEmail.bind(this)
        this.onChangePhoneNumber = this.onChangePhoneNumber.bind(this)
        this.onChangeCompanyName = this.onChangeCompanyName.bind(this)
        this.onChangeTitle = this.onChangeTitle.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleSubmitimage = this.handleSubmitimage.bind(this);

        this.closeTranscribeAlert = this.closeTranscribeAlert.bind(this)
        this.openTranscribeAlert = this.openTranscribeAlert.bind(this)
        this.closeTranscribeFaultAlert = this.closeTranscribeFaultAlert.bind(this)
        this.openTranscribeFaultAlert = this.openTranscribeFaultAlert.bind(this)
        this.handleCurrentPass = this.handleCurrentPass.bind(this)
        this.handleChangePass = this.handleChangePass.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.onChangeRole = this.onChangeRole.bind(this)

        // this.handleCloseError = this.handleCloseError.bind(this)
        //setting to empty strings
        this.state = {
            Name: '',
            Pass: '',
            Old: '',
            Confirm: '',
            transcribeAlert: false,
            transcribeFaultAlert: false,
            firstName: '',
            lastName: '',
            Email: '',
            phoneNumber: '',
            companyName: '',
            Title: '',
            img: null,
            imageurl: uploadimage,
            CurrPass: "",
            updateModalOpen: false,
            passwordModalOpen: false,
            isModalOpen: false,
            isApiCallSuccess: false,
            Passerr: null,
            loginfrommailacc: null,
            ApiError: null,
            fileSizeErr: null,
            err: null,
            isDiv1Visible: true,
            isDiv2Visible: false,
            roles: [],
            DefaultRole: '',
            alerting: false,
            faultAlert: false,
            defaultProcess: '',
            alertZoom: false,
            zoomFaultAlert: false,
            isChecked: false,
            atsFaultAlert: false,
            alertATS: false,
            isChecked1: false,
            timeframe: null,
            timeframeAlert: false,
            timeframeAlertFault: false,

        }
    }
    componentDidMount() {

        // console.log(this.state.LogdinFromMail);
        const cookies = new Cookies();
        var config = cookies.get("token")
        var Loginfrommail = cookies.get("loginfrommail")
        this.setState({ loginfrommailacc: cookies.get("loginfrommail") })
        // console.log("Loginfrommail: ", this.state.loginfrommailacc)
        if (!cookies.get("token")) {
            config = localStorage.getItem("token")
            console.log("token: ", config)
        }

        axios.get(process.env.REACT_APP_BACKEND_URL + '/login/get', {
            headers: {
                'Authorization': 'Token ' + config
            }
        }).then(
            response => {
                console.log(response)
                this.setState({
                    Email: response.data.message.email,
                    firstName: response.data.message.firstName,
                    lastName: response.data.message.lastName,
                    phoneNumber: response.data.message.mobile_number,
                    companyName: response.data.message.company_name,
                    Title: response.data.message.title,
                    imageurl: response.data.message.user_image_url,
                })
                //this.setState({ rolesDisplay:  })
            }

        ).catch((error) => {
            console.log(error);
            if (error.response && error.response.status === 401) {
                // Clear cookies or perform any other action
                // Replace 'clearCookies()' with the actual function to clear cookies
                const cookies = new Cookies();
                cookies.remove('token', {
                    path: '/',
                    domain: 'squire-olas.com', // Replace with your desired domain
                }); //clear storage so no user is stored in the session
                cookies.remove("loggedin"); //clear storage so no user is stored in the session
                cookies.remove("loginfrommail"); //clear storage so no user is stored in the session
                cookies.remove("redirect"); //clear storage so no user is stored in the session
                cookies.remove("Isadmin"); //clear storage so no user is stored in the session
                window.location.href = '/'
            }
        });
        axios
            .get(process.env.REACT_APP_LOGIN_URL + "/workorder/role", {
                headers: {
                    Authorization: "Token " + config,
                },
            })
            .then((response) => {
                console.log(response.data.message)
                this.setState({
                    roles: response.data.message
                });
            })
            .catch((error) => console.log(error));

        axios
            .get(process.env.REACT_APP_LOGIN_URL + "/workorder/getdefaultrole", {
                headers: {
                    Authorization: "Token " + config,
                },
                params: {
                    // Pass your parameters here as key-value pairs
                    voip_integration_label: "ZOOMCALL_DEFAULT_ROLE",
                },
            })
            .then((response) => {
                console.log(response.data.message)
                this.setState({
                    DefaultRole: response.data.message
                });
            })
            .catch((error) => console.log(error));
            axios
            .get(process.env.REACT_APP_LOGIN_URL + "/workorder/get_default_timeframe", {
                headers: {
                    Authorization: "Token " + config,
                },
                params: {
                    // Pass your parameters here as key-value pairs
                    voip_integration_label: "CREATE_TIMEFRAME",
                },
            })
            .then((response) => {
                console.log(response.data.message, "create time")
                this.setState({
                    timeframe: response.data.message
                });
            })
            .catch((error) => console.log(error));
        axios
            .get(process.env.REACT_APP_LOGIN_URL + "/workorder/get_zoom_automatic", {
                headers: {
                    Authorization: "Token " + config,
                },
                params: {
                    // Pass your parameters here as key-value pairs
                    voip_integration_auto_rec: "ZOOMCALL_AUTOREC",
                },
            })
            .then((response) => {
                console.log(response.data.message, "default")
                if (response.data.message == "1") {
                    this.setState({
                        isChecked: true
                    });
                }
                else {
                    this.setState({
                        isChecked: false
                    });
                }

            })
            .catch((error) => console.log(error));

        axios
            .get(process.env.REACT_APP_LOGIN_URL + "/workorder/get_auto_push", {
                headers: {
                    Authorization: "Token " + config,
                },
                params: {
                    // Pass your parameters here as key-value pairs
                    voip_integration_auto_push: "AUTO_PUSH",
                },
            })
            .then((response) => {
                console.log(response.data.message, "default")
                if (response.data.message == "1") {
                    this.setState({
                        isChecked1: true
                    });
                }
                else {
                    this.setState({
                        isChecked1: false
                    });
                }

            })
            .catch((error) => console.log(error));

    }

    handleClose() {
        console.log("clicked");
        this.setState({
            ismodalopen: false,
            updateModalOpen: false,
            passwordModalOpen: false,
            isApiCallSuccess: false
        });
        window.location.reload();
    }
    closeTranscribeAlert() {
        this.setState({ transcribeAlert: false })
    }
    closeTranscribeFaultAlert() {
        this.setState({ transcribeFaultAlert: false })
    }
    openTranscribeAlert() {
        this.setState({ transcribeAlert: true })
        //console.log("button worjing")
    }
    openTranscribeFaultAlert() {
        this.setState({ transcribeFaultAlert: true })
    }


    handleSubmitimage(e) {
        // this.setState({ img: e.target.files[0] });
        // this.setState({ imageurl: URL.createObjectURL(e.target.files[0]) });

        const MAX_FILE_SIZE = 2048 // 1MB

        let filesize = e.target.files[0].size / 1024
        console.log(filesize);

        if (filesize > MAX_FILE_SIZE) {
            this.setState({ fileSizeErr: "File size is greater than 2MB" })
            setTimeout(() => {
                this.setState({ fileSizeErr: null })
            }, 4000);
        } else {
            this.setState({ img: e.target.files[0] });
            this.setState({ imageurl: URL.createObjectURL(e.target.files[0]) });
        }
        // let data = new FormData()
        // data.append("profilephoto", e.target.files[0])
        // // let data = e.target.files[0]

        // const cookies = new Cookies();
        // var token = cookies.get("token")

        // if (!cookies.get("token")) {
        //     token = localStorage.getItem("token")
        //     console.log("token: ", token)
        // }
        // var config = {
        //     method: 'post',
        //     url: process.env.REACT_APP_BACKEND_URL + '/login/user-image',
        //     headers: {
        //         'Authorization': 'Token ' + token
        //     },
        //     data: data
        // };

        // axios(config)
        //     .then(function (response) {
        //         console.log(JSON.stringify(response.data));
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });
    }
    handleUploadpicBtn() {
        document.querySelector('#ImageUploadbtnSetting').click();
    }
    // set the state of the username
    onChangeName(e) {
        this.setState({ Name: e.target.value });
    }
    //setting the state of the password
    onChangePass(e) {
        this.setState({ Pass: e.target.value });
    }

    onChangeOld(e) {
        this.setState({ Old: e.target.value });
    }
    //setting the state of the password
    onChangeConfirm(e) {
        this.setState({ Confirm: e.target.value });

    }

    onChangeFirstName(e) {
        this.setState({ firstName: e.target.value });
    }
    onChangeLastName(e) {
        this.setState({ lastName: e.target.value });
    }
    onChangeEmail(e) {
        this.setState({ Email: e.target.value });
    }
    onChangePhoneNumber(e) {
        this.setState({ phoneNumber: e.target.value });
    }
    onChangeCompanyName(e) {
        this.setState({ companyName: e.target.value });
    }
    onChangeTitle(e) {
        this.setState({ Title: e.target.value });
    }
    handleImageChange = (e) => {
        this.setState({
            image: e.target.files[0]
        })
    };
    handleCurrentPass = (e) => {
        this.setState({ CurrPass: e.target.value })
    };
    handleChangePass(e) {
        e.preventDefault()
        this.setState({ Loader: true });
        const cookies = new Cookies();
        var config = cookies.get("token")

        if (!cookies.get("token")) {
            config = localStorage.getItem("token")
        }
        if (this.state.Pass == this.state.Confirm) {
            axios({
                method: "POST",
                url: process.env.REACT_APP_BACKEND_URL + '/login/change',
                headers: {
                    'Authorization': 'Token ' + config
                },
                data: {
                    old: this.state.CurrPass,
                    new: this.state.Pass,
                    confirm: this.state.Confirm,
                }
            }).then(
                response => {
                    console.log(response.message)
                    this.setState({
                        passwordModalOpen: true,
                        isApiCallSuccess: true
                    });
                }

            ).catch(error => {

                console.log(error.response.data.message);
                this.setState({ isModalOpen: true });
                this.setState({
                    err: error.response.data.message,
                    ApiError: error.response.data.message,
                    passwordModalOpen: true,
                    isApiCallSuccess: false
                });

            })

        } else {
            this.setState({ isModalOpen: true });
            this.setState({ Loader: false });
            this.setState({ err: "New Password and Confirm Password don't match" })
            // setTimeout(() => {
            //     this.setState({ Passerr: null })
            // }, 3000);
        }

    }
    handleButtonClick = (divToShow) => {
        if (divToShow === 1) {
            this.setState({
                isDiv1Visible: true,
                isDiv2Visible: false,
            });
        } else if (divToShow === 2) {
            this.setState({
                isDiv1Visible: false,
                isDiv2Visible: true,
            });
        }
    };

    handleSubmit(e) {
        e.preventDefault()
        const cookies = new Cookies();
        var config = cookies.get("token")

        if (!cookies.get("token")) {
            config = localStorage.getItem("token")
            //console.log("token: ", config)
        }
        console.log(this.state.img);

        let ImageData = new FormData()
        this.state.img && ImageData.append("profilephoto", this.state.img)
        ImageData.append("first_name", this.state.firstName,)
        ImageData.append("last_name", this.state.lastName,)
        ImageData.append("email", this.state.Email,)
        ImageData.append("title", this.state.Title,)
        ImageData.append("mobile_number", this.state.phoneNumber,)
        ImageData.append("company_name", this.state.companyName,)

        axios({
            method: "PUT",
            url: process.env.REACT_APP_BACKEND_URL + '/login/update',
            headers: {
                'Authorization': 'Token ' + config
            },
            // data: {
            //     first_name: this.state.firstName,
            //     last_name: this.state.lastName,
            //     email: this.state.Email,
            //     title: this.state.Title,
            //     mobile_number: this.state.phoneNumber,
            //     company_name: this.state.companyName,
            //     profilephoto: this.state.img
            // }
            data: ImageData
        }).then(


            response => {
                console.log(response.data)
                this.setState({
                    updateModalOpen: true,
                    isApiCallSuccess: true // set API call success to true
                });
                //this.openAlert()
                //this.openTranscribeAlert()
            }

        ).catch(error => {
            console.log(error);

            this.openTranscribeFaultAlert()
            this.setState({
                isModalOpen: true,
                passwordModalOpen: true,
                isApiCallSuccess: false // set API call success to false
            });
        })
        //console.log(this.state.firstName, this.state.image, this.state.lastName,this.state.phoneNumber,this.state.Email,this.state.companyName, this.state.Title,)


    }

    onChangeRole(e) {
        this.setState({ DefaultRole: e.target.value });
        console.log(e.target.value)
        e.preventDefault()
        const cookies = new Cookies();
        var config = cookies.get("token")

        if (!cookies.get("token")) {
            config = localStorage.getItem("token")
            //console.log("token: ", config)
        }

        axios({
            method: "POST",
            url: process.env.REACT_APP_LOGIN_URL + "/workorder/updatedefaultrole",
            headers: {
                Authorization: "Token " + config,
            },
            data: {
                roleid: e.target.value,
                voip_integration_label: "ZOOMCALL_DEFAULT_ROLE"
            },
        })
            //get the token from the backend and set the authentication to true
            //store the token in session storage
            .then((response) => {
                console.log(response.data);
                this.setState({ alerting: true });
                this.timer = setTimeout(() => {
                    this.setState({ alerting: false });
                }, 10000);

                //authentication is set to true, allowing for access to protected pages
            })
            .catch((err) => {
                console.log(err);
                this.setState({ zoomFaultAlert: true });
                this.timer = setTimeout(() => {
                    this.setState({ zoomFaultAlert: false });
                }, 10000);
            });

    }
    handleChangeSwitch = () => {
        var newChecked = ''
        this.setState((prevState) => {
            console.log('Previous State:', prevState.isChecked);
            const newChecked = !prevState.isChecked;
            console.log('New Value of isChecked:', newChecked);
            return {
                isChecked: newChecked,
            };
        }, () => {
            console.log("New Value of isChecked on last line:", this.state.isChecked);
            var tarValue = ''
            if (this.state.isChecked == true) {
                tarValue = 1
            }
            else {
                tarValue = 0
            }
            //e.preventDefault()
            const cookies = new Cookies();
            var config = cookies.get("token")

            if (!cookies.get("token")) {
                config = localStorage.getItem("token")
                //console.log("token: ", config)
            }

            axios({
                method: "POST",
                url: process.env.REACT_APP_LOGIN_URL + "/workorder/create_zoom_automatic",
                headers: {
                    Authorization: "Token " + config,
                },
                data: {
                    automatic_processing: tarValue,
                    voip_integration_auto_rec: "ZOOMCALL_AUTOREC"
                },
            })
                //get the token from the backend and set the authentication to true
                //store the token in session storage
                .then((response) => {
                    console.log(response.data);
                    this.setState({ alertZoom: true });
                    this.timer = setTimeout(() => {
                        setTimeout(() => {
                            // Add your second API request here
                            axios({
                                method: "GET",
                                url: process.env.REACT_APP_ZOOM_URL + "/zoom/token", // Replace with the actual endpoint
                                headers: {
                                    Authorization: "Token " + config,
                                }
                            })
                                .then((secondResponse) => {
                                    console.log("Second API Response:", secondResponse.data);
                                    // Handle the response of the second API request
                                })
                                .catch((secondErr) => {
                                    console.log("Second API Error:", secondErr);
                                    // Handle errors from the second API request
                                });
                        }, 2000); // 2 seconds delay (2000 milliseconds)
                    }, 500);
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({ faultAlert: true });
                    this.timer = setTimeout(() => {
                        this.setState({ faultAlert: false });
                    }, 10000);
                });

        });
    }

    handleChangeSwitch1 = () => {
        var newChecked = ''
        this.setState((prevState) => {
            console.log('Previous State:', prevState.isChecked1);
            const newChecked = !prevState.isChecked1;
            console.log('New Value of isChecked:', newChecked);
            return {
                isChecked1: newChecked,
            };
        }, () => {
            console.log("New Value of isChecked on last line:", this.state.isChecked1);
            var tarValue = ''
            if (this.state.isChecked1 == true) {
                tarValue = 1
            }
            else {
                tarValue = 0
            }
            //e.preventDefault()
            const cookies = new Cookies();
            var config = cookies.get("token")

            if (!cookies.get("token")) {
                config = localStorage.getItem("token")
                //console.log("token: ", config)
            }

            axios({
                method: "POST",
                url: process.env.REACT_APP_LOGIN_URL + "/workorder/create_auto_push",
                headers: {
                    Authorization: "Token " + config,
                },
                data: {
                    automatic_pushing: tarValue,
                    voip_integration_auto_push: "AUTO_PUSH"
                },
            })
                //get the token from the backend and set the authentication to true
                //store the token in session storage
                .then((response) => {
                    console.log(response.data);
                    this.setState({ alertATS: true });
                    this.timer = setTimeout(() => {
                        setTimeout(() => {
                            // Add your second API request here

                        }, 2000); // 2 seconds delay (2000 milliseconds)
                    }, 500);
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({ atsFaultAlert: true });
                    this.timer = setTimeout(() => {
                        this.setState({ atsFaultAlert: false });
                    }, 10000);
                });

        });

    };

    handleCreateTimeFrame = (e) => {
        this.setState({ defaultTime: e.target.value });
        console.log(e.target.value)
        e.preventDefault()
        const cookies = new Cookies();
        var config = cookies.get("token")

        if (!cookies.get("token")) {
            config = localStorage.getItem("token")
            //console.log("token: ", config)
        }

        axios({
            method: "POST",
            url: process.env.REACT_APP_LOGIN_URL + "/workorder/create_timeframe_view",
            headers: {
                Authorization: "Token " + config,
            },
            data: {
                timeframe: e.target.value,
                voip_integration_label: "CREATE_TIMEFRAME"
            },
        })
            //get the token from the backend and set the authentication to true
            //store the token in session storage
            .then((response) => {
                console.log(response.data);
                this.setState({ timeframeAlert: true, timeframe: e.target.value });
                this.timer = setTimeout(() => {
                    this.setState({ timeframeAlert: false });
                }, 10000);

                //authentication is set to true, allowing for access to protected pages
            })
            .catch((err) => {
                console.log(err);
                this.setState({ zoomFaultAlert: true });
                this.timer = setTimeout(() => {
                    this.setState({ timeframeAlertFault: false });
                }, 10000);
            });

    };


    render() {
        const { isChecked, isChecked1, timeframeAlert, timeframeAlertFault, timeframe, alertATS, atsFaultAlert, transcribeAlert, transcribeFaultAlert, roles, alerting, alertZoom, faultAlert, zoomFaultAlert, DefaultRole, defaultProcess } = this.state
        return (
            <Box sx={{ flexGrow: 1 }}>
                <Grid container >
                    <Grid item md={1.7} xs={2} className={UniversalCss.LeftNavbarShadow}>
                        <NavbarLeft />
                        <ErrorModal errorMessage={this.state.err} open={this.state.isModalOpen} handleClose={this.handleClose} />
                    </Grid>
                    <Grid item md={10.3} xs={10} className={styles.navbarAndRouteWrapper}>
                        <div>
                            <NavbarTop PageData={"Profile"} />
                        </div>
                        <div className={styles.flexcontainer12}>
                            <div className={styles.ProfileSettingWrapperDiv}>
                                <div className={styles.rectangle807} >
                                    <button onClick={() => this.handleButtonClick(1)} className={`HeadingsButtonFontOlas ${styles.contactus}`}>Profile Settings</button>
                                    <button onClick={() => this.handleButtonClick(2)} className={`HeadingsButtonFontOlas ${styles.contactus}`}>NoteTaker Configuration</button>
                                </div>
                                {this.state.isDiv2Visible &&
                                    <div className={styles.DefRoleWrapper}>
                                        <span className={styles.DefRole}> Default Role For Phone Calls Processed Through NoteTaker</span>
                                        <br></br>
                                        <FormControl className={styles.roleSelect} sx={{ marginLeft: '2%' }}>

                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                label="Label"
                                                value={DefaultRole}
                                                onChange={this.onChangeRole}
                                                required
                                            >
                                                {Array.isArray(roles)
                                                    ? roles.map((element) => {
                                                        return (
                                                            <MenuItem value={element.id}>{element.title}</MenuItem>
                                                        );
                                                    })
                                                    : null}
                                            </Select>
                                        </FormControl>
                                        <br></br>
                                        <Alert
                                            className={styles.Alerts}
                                            show={alerting}
                                            variant="success"
                                        >
                                            <Alert.Heading>Default Role Successfully Updated</Alert.Heading>
                                        </Alert>
                                        <Alert
                                            className={styles.Alerts}
                                            show={faultAlert}
                                            variant="danger"
                                        >
                                            <Alert.Heading>Default Role Was Not Updated</Alert.Heading>
                                        </Alert>
                                        <br></br>
                                        <br></br>
                                        <span className={styles.DefRole}> Zoom Auto Recording</span>
                                        <br></br>

                                        <FormGroup sx={{ marginLeft: '2%' }}>
                                            <Box display="flex" alignItems="center">
                                                <Typography sx={{ marginRight: '1%' }}>Off</Typography>
                                                <Switch
                                                    className={styles.switchClass1}
                                                    checked={isChecked}
                                                    onChange={this.handleChangeSwitch}
                                                    sx={{
                                                        transform: 'scale(1.5)',
                                                        '& .MuiSwitch-thumb': {
                                                            width: '22px',
                                                            height: '22px',
                                                            color: '#d8045c',
                                                            backgroundColor: '#d8045c',
                                                        },
                                                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                            backgroundColor: '#d8045c',
                                                            color: '#d8045c',
                                                        },
                                                        '&:hover .MuiSwitch-thumb': {
                                                            color: '#d8045c',
                                                            backgroundColor: '#d8045c',
                                                            width: '22px',
                                                            height: '22px',
                                                            transform: 'none',
                                                        },
                                                    }}
                                                />
                                                <Typography sx={{ marginLeft: '1%' }}>On</Typography>
                                            </Box>
                                        </FormGroup>


                                        {/*<Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          label="Label"
                          value={defaultProcess}
                          onChange={this.onChangeZoomProcess}
                          required
                        >
                         } <MenuItem value={1}>True</MenuItem>
                          <MenuItem value={0}>False</MenuItem>
                            
                        </Select>*/}

                                        <br></br>
                                        <Alert
                                            className={styles.Alerts}
                                            show={alertZoom}
                                            variant="success"
                                        >
                                            <Alert.Heading>Zoom Automatic Recording Settings Were Updated</Alert.Heading>
                                        </Alert>
                                        <Alert
                                            className={styles.Alerts}
                                            show={zoomFaultAlert}
                                            variant="danger"
                                        >
                                            <Alert.Heading>Zoom Automatic Recording Settings Were Not Updated</Alert.Heading>
                                        </Alert>




                                        <span className={styles.DefRole}> Default Timeframe for Displaying Calls</span>
                                        <br></br>
                                        <FormControl className={styles.roleSelect} sx={{ marginLeft: '2%' }}>

                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                label="Label"
                                                value={timeframe}
                                                onChange={this.handleCreateTimeFrame}
                                                required
                                            >
                                                <MenuItem value={30}>30 Days</MenuItem>
                                                <MenuItem value={60}>60 Days</MenuItem>
                                               <MenuItem value={90}>90 Days</MenuItem>
                                               <MenuItem value={120}>120 Days</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <br></br>
                                        <Alert
                                            className={styles.Alerts}
                                            show={timeframeAlert}
                                            variant="success"
                                        >
                                            <Alert.Heading>Timeframe Updated</Alert.Heading>
                                        </Alert>
                                        <Alert
                                            className={styles.Alerts}
                                            show={timeframeAlertFault}
                                            variant="danger"
                                        >
                                            <Alert.Heading>Timeframe Was Not Updated</Alert.Heading>
                                        </Alert>
                                        <br></br>
                                        <br></br>

                                        <br></br>
                                        <Alert
                                            className={styles.Alerts}
                                            show={alertATS}
                                            variant="success"
                                        >
                                            <Alert.Heading>Auto Process and Push to ATS Settings Were Updated</Alert.Heading>
                                        </Alert>
                                        <Alert
                                            className={styles.Alerts}
                                            show={atsFaultAlert}
                                            variant="danger"
                                        >
                                            <Alert.Heading>Auto Process and Push to ATS Settings Were Not Updated</Alert.Heading>
                                        </Alert>
                                    </div>

                                    



                                }
                                {/* Image */}
                                {this.state.isDiv1Visible &&
                                    <div
                                        className={`${UniversalCss.resJcc} ${UniversalCss.dflex} ${UniversalCss.alignFlexEnd} ${styles.UploadImageConteiner}`}
                                    >
                                        <div className={styles.uploadimageWrapper}>
                                            <img
                                                src={this.state.imageurl != null ? this.state.imageurl : uploadimage}
                                                className={`${UniversalCss.br10p} ${UniversalCss.w100} ${UniversalCss.h100}`}
                                                loading="lazy"
                                            />
                                        </div>
                                        <div>
                                            {this.state.fileSizeErr && <p className={styles.ErrorMessageParaSignUp}>{this.state.fileSizeErr}</p>}
                                            <p className={`${UniversalCss.FontPop} ${UniversalCss.Font400} ${UniversalCss.fontSiz14} 
                                        ${UniversalCss.lineH150} ${UniversalCss.TextCenter} ${UniversalCss.color1} ${styles.MessageParaImg}`}>
                                                Allowed JPG, GIF or PNG. Max size of 800kB
                                            </p>
                                            <div className={UniversalCss.positionRelative}>
                                                <input
                                                    id='ImageUploadbtnSetting'
                                                    className={`${styles.uploadImageBtn}`}
                                                    type='file'
                                                    accept='image/gif, image/jpeg, image/png'
                                                    onChange={this.handleSubmitimage}
                                                />
                                                <button
                                                    onClick={this.handleUploadpicBtn}
                                                    className={`${UniversalCss.positionAbsolute} ${UniversalCss.FontPop} ${UniversalCss.Font400} ${UniversalCss.fontSiz14} ${UniversalCss.TextCenter} ${styles.uploadImageBtn}`}
                                                >
                                                    Upload Photo
                                                </button>
                                            </div>
                                        </div>
                                    </div>}
                                {/* Image */}
                                {this.state.isDiv1Visible &&
                                    <form className={`${UniversalCss.mr25p} ${UniversalCss.ml25p}`} ref={this.form} onSubmit={this.handleSubmit}>
                                        <div className={styles.FormItemsWrapperDiv} >
                                            {/* flexcontainer14 */}
                                            <div className={styles.SettingFormInputs}>
                                                <label className={styles.FormLabelSetting}>
                                                    First Name *
                                                </label>
                                                <TextField value={this.state.firstName} onChange={this.onChangeFirstName} className={UniversalCss.w100} label="First Name" variant="outlined" required />
                                            </div>
                                            <div className={styles.SettingFormInputs}>
                                                <label className={styles.FormLabelSetting}>
                                                    Last Name *
                                                </label>
                                                <TextField value={this.state.lastName} onChange={this.onChangeLastName} className={UniversalCss.w100} label="Last Name" variant="outlined" required />
                                            </div>
                                            <div className={styles.SettingFormInputs}>
                                                <label className={styles.FormLabelSetting}>
                                                    Email *
                                                </label>
                                                <TextField value={this.state.Email} onChange={this.onChangeEmail} className={UniversalCss.w100} label="Email" disabled variant="outlined" required type="email" />
                                            </div>
                                            <div className={styles.SettingFormInputs}>
                                                <label className={styles.FormLabelSetting}>
                                                    Phone Number *
                                                </label>
                                                <TextField value={this.state.phoneNumber} onChange={this.onChangePhoneNumber} className={UniversalCss.w100} type='number' label=" Phone Number" inputProps={{
                                                    maxLength: 12,
                                                }} variant="outlined" required />
                                            </div>
                                            <div className={styles.SettingFormInputs}>
                                                <label className={styles.FormLabelSetting}>
                                                    Company *
                                                </label>
                                                <TextField value={this.state.companyName} onChange={this.onChangeCompanyName} className={UniversalCss.w100} label="Company" variant="outlined" required />
                                            </div>
                                            <div className={styles.SettingFormInputs}>
                                                <label className={styles.FormLabelSetting}>
                                                    Title *
                                                </label>
                                                <TextField value={this.state.Title} onChange={this.onChangeTitle} className={UniversalCss.w100} label="Title" variant="outlined" required />
                                            </div>
                                        </div>
                                        <button className={styles.UpdateBtn} type="submit" >Update Profile</button>
                                    </form>}
                                {this.state.isApiCallSuccess && <Success open={this.state.updateModalOpen} handleClose={this.handleClose} />}
                                {!this.state.isApiCallSuccess && <NotSuccess open={this.state.updateModalOpen} handleClose={this.handleClose} />}
                            </div>
                        </div>
                        {this.state.isDiv1Visible &&
                            this.state.loginfrommailacc == "true" ?
                            <div className={styles.flexcontainer12}>
                                <form ref={this.form} className={styles.rectangle8241} onSubmit={this.handleChangePass}>
                                    <div className={styles.rectangle807} >
                                        <span className={`HeadingsButtonFontOlas ${styles.contactus}`}>Password Settings</span>
                                    </div>
                                    <div className={`${UniversalCss.mr25p} ${UniversalCss.ml25p} ${styles.FormItemsWrapperDiv}`} >
                                        <div className={styles.SettingFormInputs}>
                                            <label className={styles.FormLabelSetting}>
                                                Current Password
                                            </label>
                                            <TextField id="outlined-basic" value={this.state.CurrPass} onChange={this.handleCurrentPass} className={UniversalCss.w100} label="Current Password" type='password'
                                                variant="outlined" required />
                                        </div>
                                        <div className={styles.SettingFormInputs}>
                                            <label className={styles.FormLabelSetting}>
                                                New Password
                                            </label>
                                            <TextField id="outlined-basic" value={this.state.Pass} onChange={this.onChangePass} className={UniversalCss.w100} label="New Password" type='password'
                                                variant="outlined" inputProps={{
                                                    minLength: 8,
                                                }} required />
                                        </div>

                                        <div className={styles.SettingFormInputs}>
                                            <label className={styles.FormLabelSetting}>
                                                Confirm Password
                                            </label>
                                            <TextField id="outlined-basic" value={this.state.Confirm}
                                                onChange={this.onChangeConfirm} className={UniversalCss.w100} label="Confirm Password"
                                                variant="outlined" type='password' InputProps={{ inputProps: { min: "8", max: "12" } }} required />
                                        </div>
                                    </div>
                                    <span className={`${UniversalCss.ml25p}`}>* The password must be at least 8 characters long. </span>
                                    {this.state.Passerr && <p className={styles.ErrorMessageParaSignUp}>{this.state.Passerr}</p>}
                                    {/* <Alert className={styles.alert} show={transcribeFaultAlert}
                                    onClose={this.closeTranscribeFaultAlert} variant="danger" dismissible>
                                    <Alert.Heading>Something went wrong!</Alert.Heading>
                                    <p>Failed to Update</p>
                                </Alert> */}
                                    {/* <Alert className={styles.alert} show={transcribeAlert} onClose={this.closeTranscribeAlert} variant="success" dismissible>
                                    <Alert.Heading>Password Updated!</Alert.Heading>
                                    <Alert.Heading>
                                        Return to the <a href='/'>Home Screen!</a>
                                    </Alert.Heading>
                                </Alert> */}
                                    <div className={`${UniversalCss.pr25p} ${UniversalCss.w100}`}>
                                        <button className={styles.UpdateBtn} type="submit" >Change Password</button>
                                    </div>
                                </form>
                            </div>
                            : null
                        }
                        {this.state.isApiCallSuccess && <Success open={this.state.passwordModalOpen} handleClose={this.handleClose} />}
                        {/* {!this.state.isApiCallSuccess && <NotSuccess errormsg={this.state.ApiError} open={this.state.passwordModalOpen} handleClose={this.handleClose} />} */}

                    </Grid>

                </Grid>
            </Box>

        )
    }
}
import React from 'react';
import styles from './Integrations.module.css'
import knight from './../Images/squireInapp.png';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { NavbarTop } from '../Components/Navbar';
import { NavbarLeft } from '../Components/Nav-Left/NavLeft';

import { TextField } from '@mui/material';
import Alert from 'react-bootstrap/Alert'
import axios from 'axios';
import Cookies from 'universal-cookie';

export class Bullhorn extends React.Component {
    constructor() {
        super();
        //binding the data so it can be called
        this.closeTranscribeAlert = this.closeTranscribeAlert.bind(this)
        this.openTranscribeAlert = this.openTranscribeAlert.bind(this)
        this.onChangePass = this.onChangePass.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.closeTranscribeFaultAlert = this.closeTranscribeFaultAlert.bind(this)
        this.openTranscribeFaultAlert = this.openTranscribeFaultAlert.bind(this)

        this.submitBullhorn = this.submitBullhorn.bind(this)

        //setting to empty strings
        this.state = {
            username: "",
            password: "",
            transcribeAlert: false,
            transcribeFaultAlert: false,
        }
    }
    closeTranscribeAlert() {
        this.setState({ transcribeAlert: false })
    }
    openTranscribeAlert() {
        this.setState({ transcribeAlert: true })
        //console.log("button worjing")
    }
    openTranscribeFaultAlert() {
        this.setState({ transcribeFaultAlert: true })
    }
    closeTranscribeFaultAlert() {
        this.setState({ transcribeFaultAlert: false })
    }
    onChangeName(e) {
        console.log(e.target.value)
        this.setState({ username: e.target.value });
    }
    onChangePass(e) {
        this.setState({ password: e.target.value });
    }



    submitBullhorn(e) {
        e.preventDefault();
        //console.log(this.state.username, this.state.password)
        const cookies = new Cookies();
        var config = cookies.get("token")

        if (!cookies.get("token")) {
            config = localStorage.getItem("token")
        }

        axios({
            method: "POST",
            url: 'http://127.0.0.1:8000/integration_ats/login',
            headers: {
                'Authorization': 'Token ' + config
            },
            data: {
                username: this.state.username,
                password: this.state.password,

            }
        })

            .then(
                response => {
                    console.log(response.data);
                   

                }
            )
            .catch((err) => {
                console.log(err)
                this.openTranscribeFaultAlert()
            });


    }

    render() {
        const { transcribeAlert, transcribeFaultAlert } = this.state
        return (
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        {/* <img className={styles.olaschibivectorss} src={knight} /> */}
                        <NavbarLeft />
                    </Grid>
                    <Grid item xs={10}>
                        <NavbarTop />
                        <div className={styles.flexcontainer12}>

                            <form className={styles.rectangle824} >
                                <div className={styles.rectangle807} >
                                    <span className={styles.contactus}>Bullhorn Integration</span>
                                </div>


                                <div className={styles.flexcontainer14} >
                                    <TextField value={this.state.username} onChange={this.onChangeName} className={styles.rectangle814} label="Username"
                                        variant="outlined" required />
                                    <br></br>
                                    <TextField value={this.state.password} type="password" onChange={this.onChangePass}
                                        className={styles.rectangle1120} label="Password"
                                        variant="outlined" required />
                                    <br></br>

                                </div>




                                <button className={styles.sendEmail} type="button" onClick={this.submitBullhorn}> Submit Integration</button>
                                <Alert className={styles.modalPopup} show={transcribeAlert} variant="success"><Alert.Heading>Success!</Alert.Heading><p>
                                    You are now connected to Bullhorn!</p></Alert>
                                <Alert className={styles.modalPopup} show={transcribeFaultAlert} variant="danger"><Alert.Heading>Error!</Alert.Heading><p>
                                    Something Went Wrong!</p></Alert>

                            </form>
                        </div>


                    </Grid>

                </Grid>
            </Box>

        )
    }
}
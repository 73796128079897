import React, { Component } from "react";
import styles from './Question.module.css'
import vuesaxlineartrash from "./../Images/vuesaxlineartrash.svg";
import vuesaxlinearsend from "./../Images/vuesaxlinearsend.svg";
import vuesaxlinearedit2 from "./../Images/vuesaxlinearedit2.svg";
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Card, Col, Container, Row, Button, Table } from "react-bootstrap";
import { TextField, Typography, Box } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import errorIcon from '../Images/icons/Errorindicator.svg';
import add from '../Images/add.svg';
const Deletestyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 643,
    maxWidth: 643,
    minHeight: 477,
    bgcolor: 'background.paper',
    border: 'transparent',
    boxShadow: 24,
    p: 6,
    borderRadius: '15px',
    textAlign: 'center',
};
class RoleModalClass extends React.Component {
    constructor(props) {
        super(props)

        this.getModal = this.getModal.bind(this)
        this.hideModal = this.hideModal.bind(this)
        this.getModalDelete = this.getModalDelete.bind(this)
        this.hideModalDelete = this.hideModalDelete.bind(this)

        this.getModalMenu = this.getModalMenu.bind(this)
        this.hideModalMenu = this.hideModalMenu.bind(this)
        this.delete = this.delete.bind(this)

        this.onSubmitRole = this.onSubmitRole.bind(this);
        this.onChangeRoleLabel = this.onChangeRoleLabel.bind(this);
        //this.onChangeQuestionKindCode = this.onChangeQuestionKindCode.bind(this);
        this.onChangeRoleTitle = this.onChangeRoleTitle.bind(this);
        this.onChangeRoleDescription = this.onChangeRoleDescription.bind(this);
        this.state = {
            show: false,
            roleDescription: this.props.roleDescription,
            rolesDisplay: this.props.rolesDisplay,
            id: this.props.passid,
            roleLabel: this.props.roleLabel,
            roleTitle: this.props.roleTitle,
            showDelete: false,
            showMenu: true
        }


    }
    onChangeRoleLabel(e) {
        this.setState({ roleLabel: e });
    }
    onChangeRoleTitle(e) {
        this.setState({ roleTitle: e });
    }
    onChangeRoleDescription(e) {
        this.setState({ roleDescription: e });
    }

    getModal() {
        this.setState({ show: true });
    };

    hideModal() {
        window.location.href = '/questionbank'
        //this.setState({ show: false });
    };

    getModalDelete() {
        this.setState({ showDelete: true });
    };

    hideModalDelete() {
        window.location.href = '/questionbank'
        //this.setState({ showDelete: false });
    };

    getModalMenu() {
        this.setState({ showMenu: true });
    };

    hideModalMenu() {
        window.location.href = '/questionbank'
        //this.setState({ showMenu: false });
    };
    onSubmitRole(e) {
        e.preventDefault();
        const cookies = new Cookies();
        var config = cookies.get("token")

        if (!cookies.get("token")) {
            config = localStorage.getItem("token")
            //console.log("token: ", config)
        }
        axios({
            method: "POST",
            url: process.env.REACT_APP_LOGIN_URL + '/workorder/updaterole',
            headers: {
                'Authorization': 'Token ' + config
            },
            data: {
                label: this.state.roleLabel,
                title: this.state.roleTitle,
                description: this.state.roleDescription,
                id: this.state.id,
                archive: 0,

            }
        })
            //get the token from the backend and set the authentication to true
            //store the token in session storage
            .then(
                response => {
                    //authentication is set to true, allowing for access to protected pages
                    window.location.href = '/questionbank'

                }
            )
            .catch((err) => {
                console.log(err)
            });
    }
    delete() {
        const cookies = new Cookies();
        var config = cookies.get("token")

        if (!cookies.get("token")) {
            config = localStorage.getItem("token")
            //console.log("token: ", config)
        }
        axios({
            method: "POST",
            url: process.env.REACT_APP_LOGIN_URL + '/workorder/deleterole',
            headers: {
                'Authorization': 'Token ' + config
            },
            data: {
                id: this.props.passid,

                archive: 1,
            }
        })
            //get the token from the backend and set the authentication to true
            //store the token in session storage
            .then(
                response => {
                    window.location.href = '/questionbank'
                    //authentication is set to true, allowing for access to protected pages

                }
            )
            .catch((err) => {
                console.log(err)
            });
    }
    render() {
        const { show, showDelete, showMenu } = this.state

        return (
            <React.Fragment>
                {this.props.show && (
                    <div className={styles.modalStyle}>

                        <Modal
                            size="md"
                            className={styles.modalPopup}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered show={showMenu} onHide={this.hideModalMenu}>
                            <Modal.Header closeButton>
                                {this.props.roleTitle}
                            </Modal.Header>
                            <Modal.Body>
                                <div className="rectangle-812">
                                    <div className="rectangle-813">
                                        <div className="flex-container-18">
                                            <img
                                                className="vuesaxlinearedit-2"
                                                src={vuesaxlinearedit2}
                                            />
                                            <button className={styles.primarybutton4} onClick={this.getModal}>Edit Role</button>
                                        </div>
                                        <hr className="line-15" />
                                    </div>
                                    <div className="flex-container-19">
                                        <img className="vuesaxlineartrash" src={vuesaxlineartrash} />
                                        <button className={styles.primarybutton4} onClick={this.getModalDelete}>Delete</button>
                                    </div>


                                </div></Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={this.hideModalMenu}>
                                    Close
                                </Button>

                            </Modal.Footer>
                        </Modal>
                        <Modal
                            size="lg"
                            className={styles.modalPopupLabel}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered show={show} onHide={this.hideModal}>
                            <Modal.Header closeButton>
                                <span className={styles.createlabel} >Edit Role</span>
                            </Modal.Header>

                            <Modal.Body>
                                <div className={styles.groupLabel} >
                                    <div className={styles.rectanglePopupLabel} >

                                        <InputLabel id="demo-simple-select-filled-label">Label</InputLabel>
                                        <TextField

                                            variant="outlined" value={this.state.roleLabel}
                                            contentEditable={true}
                                            onChange={(e) => this.onChangeRoleLabel(e.target.value)} />
                                        <br></br>
                                        <InputLabel id="demo-simple-select-filled-label">Title</InputLabel>
                                        <TextField

                                            variant="outlined" value={this.state.roleTitle}
                                            contentEditable={true}
                                            onChange={(e) => this.onChangeRoleTitle(e.target.value)} />
                                        <br></br>
                                        <InputLabel id="demo-simple-select-filled-label">Description</InputLabel>
                                        <TextField

                                            variant="outlined" value={this.state.roleDescription}
                                            contentEditable={true}
                                            onChange={(e) => this.onChangeRoleDescription(e.target.value)} />
                                        <br></br>

                                        <div className={styles.flexcontainerLabel1} >
                                            <button className={styles.shapeLabel} onClick={this.hideModal} >Cancel</button>
                                            <button className={styles.shapeLabel1} onClick={this.onSubmitRole} >Edit Role</button>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>

                        </Modal>

                        {/* <Modal
                            size="lg"
                            className={styles.modalPopupLabel}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered show={showDelete} onHide={this.hideModalDelete}>
                            <Modal.Header closeButton>
                                <span className={styles.createlabel} >Edit Role</span>
                            </Modal.Header>

                            <Modal.Body>
                                <div className={styles.groupLabelDelete} >

                                    <div className="flex-container-19">
                                        <label>
                                            <br></br>
                                            {this.props.roleTitle}
                                        </label>
                                        <br></br>


                                        <button className={styles.primarybutton4} onClick={this.delete}>Yes</button>
                                        <button className={styles.primarybutton4} onClick={this.hideModalDelete}>No</button>
                                    </div>
                                </div>
                            </Modal.Body>

                        </Modal> */}
                        <Modal                            
                            className={styles.modalPopupLabel}
                            aria-labelledby="modal-modal-title"
                            centered show={showDelete} onHide={this.hideModalDelete}>
                            <Box sx={Deletestyle}>
                            <img onClick={this.hideModalDelete} style={{ position: 'relative', cursor: "pointer", left: '280px', top: '-35px' }} src={add} />
                            <div className={styles.ErrorMessageImageWrapper}>
                                <img src={errorIcon} />
                            </div>
                            <Modal.Body>
                            <Typography style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "16px", lineHeight: "24px", textAlign: 'center', color: "#1D103B", margin: " 20px auto 20px auto", maxWidth: "374px" }}>
                            Are you sure you want to delete this role?<br></br>
                                            {this.props.roleTitle}
                            </Typography>
                            <div style={{ marginTop: "50px" }} className={styles.EditUSerBtns}>
                                <div onClick={this.hideModalDelete} style={{ marginRight: "10px" }} className={`${styles.EditUserCancelBtn}`}>No</div>
                                <div onClick={this.delete} style={{ marginLeft: "10px" }} className={`${styles.EditUserUpdateBtn}`}>Yes</div>
                            </div>
                            </Modal.Body>
                            </Box>
                        </Modal>
                        {/*} <Modal
                            size="lg"
                            className={styles.modalPopupLabel}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered show={show} onHide={this.hideModal}>
                            <Modal.Header closeButton>
                                <span className={styles.createlabel} >Edit Question</span>
                            </Modal.Header>

                            <Modal.Body>
                                <div className={styles.groupLabel} >
                                    <div className={styles.rectanglePopupLabel} >
                                        <div className={styles.flexcontainerLabel}>


                                        </div>
                                        <div className={styles.flexcontainerLabelLabel}>
                                            <span className={styles.labellabel} >Question</span>


                                        </div>
                                        <input
                                            className={styles.rectangleLabel}
                                            
                                            value={this.state.questionText}
                                            contentEditable={true}
                                            onChange={(e) => this.onChangeQuestion(e.target.value)}
                                            type="text"
                                        />

                                        <div className={styles.flexcontainerLabelLabel}>
                                            <span className={styles.labellabel} >Question Description</span>


                                        </div>
                                        <input
                                            className={styles.rectangleLabel}

                                            value={this.state.questionDescript}
                                            contentEditable={true}
                                            onChange={(e) => this.onChangeQuestionDescription(e.target.value)}
                                            type="text"
                                        />

                                        <div className={styles.flexcontainerLabelLabel}>
                                            <span className={styles.labellabel} >Question Label</span>


                                        </div>
                                        <select className={styles.rectangleLabel} onChange={this.onChangeQuestionLabel}>
                                            {Array.isArray(this.props.selectLabel)
                                                ? this.props.selectLabel.map(element => {
                                                    return <option value={element} >{element}</option>

                                                })
                                                : null}
                                        </select>
                                        <div className={styles.flexcontainerLabelLabel}>
                                            <span className={styles.labellabel} >Question Type</span>


                                        </div>

                                        <select className={styles.rectangleLabel} onChange={this.onChangeQuestionNer}>
                                            {Array.isArray(this.props.selectLabel)
                                                ? this.props.selectType.map(element => {
                                                    return <option value={element.nertype}>{element.nertype}</option>

                                                })
                                                : null}
                                        </select>
                                        
                                        



                                        <div className={styles.flexcontainerLabel1} >
                                            <button className={styles.shapeLabel} >Cancel</button>
                                            <button className={styles.shapeLabel1} onClick={this.onQuestionSubmit} >Create Question</button>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>

                                            </Modal>*/}

                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default RoleModalClass;

import { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select, TextField, } from "@mui/material";
import styles from "./user.module.css";
import InviteUserForm from "./InviteUserForm";

function UserpageForm(props) {
    const [ids, setIds] = useState([]);
    const addHandeler = () => {
        setIds((ids) => [...ids, <InviteUserForm />]);
    };

    const deleteHandeler = (removeId) => {
        setIds((ids) => ids.filter((id) => id !== removeId));
        props.removeFormValueById(removeId);
    }
    return (
        <div>
            <div className={styles.userFormDiv}>
                <div className={styles.FormItemWrapper}>
                    <label className={styles.FormLabel}>First name</label>
                    <TextField onChange={e => props.FirstNamefn(e, props.id)}
                        label="Enter First Name" variant="outlined" required />
                </div>
                <div className={styles.FormItemWrapper}>
                    <label className={styles.FormLabel}>Last name</label>
                    <TextField onChange={e => props.LastNamefn(e, props.id)} label="Enter Last Name" variant="outlined" required />
                </div>
                <div className={styles.FormItemWrapper}>
                    <label className={styles.FormLabel}>Email</label>
                    <TextField onChange={e => props.Emailfn(e, props.id)} label="Email" variant="outlined" required type={"email"} />
                </div>
                <div className={styles.FormItemWrapperRole}>
                    <label className={styles.FormLabel}>Role</label>
                    {/* <FormControl fullWidth>
                        <InputLabel className={styles.FormLabel} id="demo-simple-select-label">Role</InputLabel>
                        <Select
                            className={styles.SelectRecuter}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            onChange={e => props.Recruiterfn(e, props.id)}
                            label="Role"
                            required
                        >
                            <MenuItem value="Recruiter">Recruiter</MenuItem>
                            <MenuItem value="Admin">Admin</MenuItem>
                        </Select>
                    </FormControl> */}
                    <FormControl fullWidth>
                        <InputLabel >Role</InputLabel>
                        <Select
                            label="Role"
                            onChange={e => props.Recruiterfn(e, props.id)}
                        >
                            <MenuItem value="Recruiter">Recruiter</MenuItem>
                            <MenuItem value="Admin">Admin</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className={styles.FormItemWrapperPlusBtn}>
                    <div className={styles.AddNewUserRowWrapper}>
                        <button onClick={addHandeler} type="submit" className={styles.AddNewUserBtn}>+</button>
                    </div>
                </div>
            </div>
            {
                ids.map((id) => (
                    <InviteUserForm FirstNamefn={props.FirstNamefn} LastNamefn={props.LastNamefn} Emailfn={props.Emailfn} Recruiterfn={props.Recruiterfn} key={id} id={id} deleteHandeler={() => deleteHandeler(id)} />
                ))
            }
        </div >
    );
}

export default UserpageForm
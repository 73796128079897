/* global chrome */
import React from 'react';


import Cookies from 'universal-cookie';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { NavbarTop } from '../Components/Navbar';
import { NavbarLeft } from '../Components/Nav-Left/NavLeft';
var images = ''
var pageSize = 6
export class Zoom extends React.Component {
    constructor() {
        super();
        //binding the data so it can be called
        //setting to empty strings
       
        this.state = {
           

        }
    }

    
    // get request on load of page, to get all the folders in the s3
    async componentDidMount() {
        const cookies = new Cookies();
        var config = cookies.get("token")

        if (!cookies.get("token")) {
            config = localStorage.getItem("token")
            //console.log("token: ", config)
        }
        //const url = `https://zoom.squire-olas.com/auth_code?token=${encodeURIComponent(config)}`;
        //window.location.href = "https://zoom.us/oauth/authorize?response_type=code&client_id=w0EyyiELTJW_HimOTxqybA&redirect_uri=https://zoom.squire-olas.com/auth";
    }

    render() {
        //format the file so that it only displays the group name/ file name
       

        return (

            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        {/* <img className={styles.olaschibivectorss} src={knight} /> */}
                        <NavbarLeft />

                    </Grid>
                    <Grid item xs={10} >

                        <NavbarTop />
                        
                        <a href="https://zoom.us/oauth/authorize?response_type=code&client_id=IJ0f8X3VQlutmmzsYLw6sQ&redirect_uri=https://zoom.squire-olas.com/auth" target="_blank" rel="noopener noreferrer"><img src="https://marketplacecontent.zoom.us/zoom_marketplace/img/add_to_zoom.png" height="32" alt="Add to ZOOM" /></a>
                    </Grid>

                </Grid>
            </Box>

        )
    }

}
import React from "react";
import axios from "axios";
import knight from "./../Images/Olas-Chibi.jpg";
import styles from "./Calls.module.css";
import { Link } from "react-router-dom";
import { Pagination } from "antd";
import "antd/dist/antd.css";
import Cookies from "universal-cookie";
import eye from "./../Images/vuesaxlineareye.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { NavbarTop } from "../Components/Navbar";
import { NavbarLeft } from "../Components/Nav-Left/NavLeft";
import { TextField } from "@mui/material";
import UniversalCss from "../Universal.module.css";

var images = "";
var pageSize = 10;

export class Calls extends React.Component {
  constructor() {
    super();
    //binding the data so it can be called
    //setting to empty strings
    this.onChangeName = this.onChangeName.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.DatePicker = this.DatePicker.bind(this);
    this.onChangeQuestionLabel = this.onChangeQuestionLabel.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);

    this.state = {
      files: [],
      Name: "",
      Type: "",
      FileName: "",
      title: "",
      callName: "",
      image: null,
      fileUrl: [],
      User: "",
      visibility: false,
      recruiter: [],
      duration: "",
      person: "",
      show: false,
      alerting: false,
      transcribeAlert: false,
      transcribeFaultAlert: false,
      summarizeAlert: false,
      summarizeFaultAlert: false,
      extractAlert: false,
      extractFaultAlert: false,
      imageName: "",
      totalPage: 0,
      current: 1,
      minIndex: 0,
      maxIndex: 0,
      companyID: "",
      userLogged: "",
      hashFile: "",
      candidate_name: "",
      candidate_email: "",
      candidate_phone: "",
      search: "",
      searchDate: "",
      value: "",
      rolesDisplay: "",
      role: "",
      DatePickerDate: new Date(),
      spinnerLoading: false,
    };
  }
  handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase(); // Convert the search term to lower case
    this.setState({
      search: searchTerm,
      current: 1, // Reset pagination to the first page
      minIndex: 0,
      maxIndex: pageSize, // Assuming pageSize is the number of items per page
    });
  };
  DatePicker(e) {
    console.log(e, "datepicker")
    var dateSort = String(e);
    var year = dateSort.split(" ")[3];
    

    var date = dateSort.split(" ")[2];
    var month = dateSort.split(" ")[1];
    console.log("datepicker", year, month, date)
    var monthFormated = "";
    if (month == "Jan") {
      monthFormated = "01";
    } else if (month == "Feb") {
      monthFormated = "02";
    } else if (month == "Mar") {
      monthFormated = "03";
    } else if (month == "Apr") {
      monthFormated = "04";
    } else if (month == "May") {
      monthFormated = "05";
    } else if (month == "Jun") {
      monthFormated = "06";
    } else if (month == "Jul") {
      monthFormated = "07";
    } else if (month == "Aug") {
      monthFormated = "08";
    } else if (month == "Sep") {
      monthFormated = "09";
    } else if (month == "Oct") {
      monthFormated = "10";
    } else if (month == "Nov") {
      monthFormated = "11";
    } else if (month == "Dec") {
      monthFormated = "12";
    }

    var dateSorted = year + "-" + monthFormated + "-" + date;
    console.log("datepicker, ", dateSorted)
    this.setState({ searchDate: dateSorted });
    this.setState({ DatePickerDate: e });
  }
  async componentDidMount() {
    const cookies = new Cookies();
    var config = cookies.get("token");

    if (!cookies.get("token")) {
      config = localStorage.getItem("token");
    }
    this.setState({ spinnerLoading: true });

    axios
      .get(process.env.REACT_APP_LOGIN_URL + "/login/users", {
        headers: {
          Authorization: "Token " + config,
        },
      })
      .then((response) => {
        this.setState({ userLogged: response.data[0].username });
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.status === 401) {
          // Clear cookies or perform any other action
          // Replace 'clearCookies()' with the actual function to clear cookies
          const cookies = new Cookies();
          cookies.remove('token', {
            path: '/',
            domain: 'squire-olas.com', // Replace with your desired domain
          }); //clear storage so no user is stored in the session
          cookies.remove("loggedin"); //clear storage so no user is stored in the session
          cookies.remove("loginfrommail"); //clear storage so no user is stored in the session
          cookies.remove("redirect"); //clear storage so no user is stored in the session
          cookies.remove("Isadmin"); //clear storage so no user is stored in the session
          window.location.href = '/'
        }
      });

    axios
      .get(process.env.REACT_APP_LOGIN_URL + "/login/identify", {
        headers: {
          Authorization: "Token " + config,
        },
      })
      .then((response) => {
        this.setState({ companyID: response.data[0].companyid });
      })
      .catch((error) => console.log(error));

    axios
      .get(process.env.REACT_APP_FILEUPLOAD_URL + "/file/listings", {
        headers: {
          Authorization: "Token " + config,
        },
      })
      .then((response) => {
        var final_arry = [];
        var time = []

        for (let j = 0; j < response.data.message.length; j++) {
          if (j != 0) {
            var array = response.data.message[j];
            // console.log(array[4], "here now")
            var splitting = array[4].split("T")
            //console.log("here now", splitting)
            //var splitit = splitting[0].split("T")
            //array.splice(array.indexOf(4), 1, array[4].split("T")[0]);


            // /console.log(formattedTime, "here now");
            console.log("here now", array)
            final_arry.push(array);


          }
        }

        this.setState({
          files: final_arry,
          totalPage: response.data.message.length / pageSize,
          minIndex: 0,
          maxIndex: pageSize,
        });
      })
      .catch((error) => {
        console.log(error);
        // Handle the error case
      })
      .finally(() => {
        // Code here will run regardless of whether the request succeeded or failed
        // For example, you could stop a loading spinner here
        console.log("WHat")
        this.setState({ spinnerLoading: false });
      });
    axios
      .get(process.env.REACT_APP_LOGIN_URL + "/workorder/role", {
        headers: {
          Authorization: "Token " + config,
        },
      })
      .then((response) => {
        this.setState({ rolesDisplay: response.data.message });
      })
      .catch((error) => console.log(error));
  }
  handleClicks = (page) => {
    this.setState({
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize,
    });
  };
  // set the state of the name
  onChangeName(e) {
    this.setState({ Name: e.target.value });
  }
  // post to create new group
  onSubmit(e) {
    e.preventDefault();
    //post request to post the data
    axios({
      method: "POST",
      url: process.env.REACT_APP_TESTING_URL + "/api/create",
      data: {
        file_name: this.state.Name,
      },
    });
  }
  // handle the change of values
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  // handle the file
  handleImageChange = (e) => {
    this.setState({
      image: e.target.files[0],
    });
  };
  handleChangeCandidateName = (e) => {
    this.setState({
      candidate_name: e.target.value,
    });
  };
  handleChangeCandidateEmail = (e) => {
    this.setState({
      candidate_email: e.target.value,
    });
  };

  handleChangeCandidatePhone = (e) => {
    this.setState({
      candidate_phone: e.target.value,
    });
  };
  onChangeQuestionLabel(e) {
    this.setState({ role: e.target.value });
  }

  render() {
    const { files, search, searchDate, spinnerLoading } = this.state;
    const { current, minIndex, maxIndex, DatePickerDate } = this.state;



    var testArr = files.map(function (nested) {
      return nested.map(function (element) {
        return element;
      });
    });
    const filteredQuestions = testArr.filter((testArr) => {
      return searchDate === ""
        ? testArr
        : testArr[4].includes(searchDate);
    });
console.log("datepick", testArr)
    const filteredItemsAndCount = (() => {
      const filteredItems = testArr.filter((testItem) => {
        if (search !== "") {
          const candPhone = testItem[3]?.cand_phone?.toLowerCase();
          const candName = testItem[3]?.cand_name?.toLowerCase();
          const recruiter = testItem[3]?.user?.toLowerCase();
          return (
            (candPhone && candPhone.includes(search.toLowerCase())) ||
            (candName && candName.includes(search.toLowerCase())) ||
            (recruiter && recruiter.includes(search.toLowerCase()))
          );
        } else if (searchDate !== "") {
          const dateString = testItem[4].split('T')[0]; // Splits the string at 'T' and takes the first part
          return dateString === searchDate;
        }
        return true; // Include all elements if no search criteria provided
      });

      const numberOfSearchesReturned = filteredItems.length;

      return { filteredItems, numberOfSearchesReturned };
    })();
    return (
      <Box sx={{ flexGrow: 1, backgroundColor: "#89879d25" }}>
        <Grid container className={UniversalCss.GridContainerWrapperHegih}>
          <Grid item md={1.7} xs={2} className={UniversalCss.LeftNavbarShadow}>
            <NavbarLeft />
          </Grid>
          <Grid
            item
            md={10.3}
            xs={10}
            className={UniversalCss.navbarAndRouteWrapper}
          >
            <NavbarTop PageData={"Calls"} />

            <div className={styles.rectangle802}>
              <div className={styles.flexcontainer19}>
                <div className={styles.DashbordCallsSearchbar}>
                  <TextField
                    color="secondary"
                    className={styles.DashbordCallsSearchbarInput}
                    onChange={this.handleSearchChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    id="outlined-basic"
                    label="Search Call..."
                    variant="outlined"
                  />
                </div>
                {/* <TextField
                  disableUnderline={true}
                  id="outlined-basic"
                  label="Search Call..."
                  onChange={(e) => this.setState({ search: e.target.value })}
                  className={styles.rectangle637}
                /> */}

                <DatePicker
                  className={styles.DatePickCSS}
                  onChange={this.DatePicker}
                  format="y-MM-dd"
                  value={DatePickerDate}
                  selected={DatePickerDate}
                  popperPlacement="top-end"
                  popperModifiers={[
                    {
                      name: "offset",
                      options: {
                        offset: [5, 10],
                      },
                    },
                    {
                      name: "preventOverflow",
                      options: {
                        rootBoundary: "viewport",
                        tether: false,
                        altAxis: true,
                      },
                    },
                  ]}
                />
              </div>
              <Grid container className={styles.DashbordCallListHeader}>
                <Grid
                  item
                  xs={2}
                  className={styles.DashbordCallListHeaderItems}
                >
                  <span>Phone</span>
                </Grid>
                <Grid
                  item
                  xs={2}
                  className={styles.DashbordCallListHeaderItems}
                >
                  <span>Candidate</span>
                </Grid>
                <Grid
                  item
                  xs={1.33}
                  className={styles.DashbordCallListHeaderItems}
                >
                  <span>Duration</span>
                </Grid>
                <Grid
                  item
                  xs={1.33}
                  className={styles.DashbordCallListHeaderItems}
                >
                  <span>Date</span>
                </Grid>
                <Grid
                  item
                  xs={1.33}
                  className={styles.DashbordCallListHeaderItems}
                >
                  <span>Time</span>
                </Grid>
                <Grid
                  item
                  xs={2}
                  className={styles.DashbordCallListHeaderItems}
                >
                  <span>Recruiters</span>
                </Grid>
                <Grid
                  item
                  xs={2}
                  className={styles.DashbordCallListHeaderItems}
                >
                  <span>Actions</span>
                </Grid>
              </Grid>

              {spinnerLoading ? (
                <div  className={styles.SpinContainer}>
                <div className={styles.Spin}></div>
                </div>


              ) : (
                filteredItemsAndCount.filteredItems
                  .map((testArr, index) =>
                    index >= minIndex &&
                    index < maxIndex && (
                      <>
                        <Grid
                          container
                          key={testArr[0]}
                          className={styles.DashbordCallListItems}
                        >
                          <Grid
                            item
                            xs={2}
                            className={styles.DashbordCallListItemsData}
                          >
                            <span
                              className={styles.DashbordCallListItemsDataName}
                            >
                              {testArr[3].cand_phone}
                            </span>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            className={styles.DashbordCallListItemsData}
                          >
                            <span
                              className={
                                styles.DashbordCallListItemsDataDuration
                              }
                            >
                              {testArr[3].cand_name}
                            </span>
                          </Grid>
                          <Grid
                            item
                            xs={1.33}
                            className={styles.DashbordCallListItemsData}
                          >
                            <span className={styles.DashbordCallListItemsDataDate}>
                              {(() => {
                                const totalSeconds = Number(testArr[5]);
                                if (isNaN(totalSeconds) || totalSeconds === 0) {
                                  return null; // or return ''; if you prefer an empty string
                                }
                                const minutes = Math.floor(totalSeconds / 60);
                                const seconds = totalSeconds % 60;
                                return `${minutes}m ${seconds}s`;
                              })()}
                            </span>
                          </Grid>
                          <Grid
                            item
                            xs={1.33}
                            className={styles.DashbordCallListItemsData}
                          >
                            <span
                              className={styles.DashbordCallListItemsDataDate}
                            >
                              {testArr[4].split("T")[0]}
                            </span>
                          </Grid>
                          <Grid item xs={1.33} className={styles.DashbordCallListItemsData}>
                            <span className={styles.DashbordCallListItemsDataDate}>

                              {testArr[4].split("T")[1].split(' ')[0].slice(0, 5)}
                            </span>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            className={styles.DashbordCallListItemsData}
                          >
                            <span style={{ marginLeft: "8px" }}>
                              {testArr[3].user}
                            </span>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            className={styles.DashbordCallListItemsData}
                          >
                            <span
                              style={{
                                maxWidth: "60px",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Link to={`/${testArr[0]}`}>
                                <img
                                  // style={{ marginRight: "33px" }}
                                  src={eye}
                                />
                              </Link>
                            </span>
                          </Grid>
                        </Grid>

                       
                      </>
                    )
                  ))}
              <div
                style={{
                  // background: "red",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >

              </div>

            </div>
            <Pagination
              showSizeChanger={false}
              pageSize={pageSize}
              className="CallPagination"
              current={current}
              total={filteredItemsAndCount.numberOfSearchesReturned}
              onChange={this.handleClicks}
              style={{
                position: 'absolute',
                marginBottom: '10%',

                right: '1%', /* Adjust as needed */

              }}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }
}

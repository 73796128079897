import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Grid,
  Button,
} from "@mui/material";
import styles from "./user.module.css";
import React, { Component } from "react";
import DownloadImage from "../Images/icons/downloadUser.svg";

let UserData = [
  {
    date: "15-06-2022",
    Subscription_Plan: "Basic Plan",
    Duration: "6 month",
    status: "Running",
    Amount: "$ 500.00",
  },
  {
    date: "1-06-2022",
    Subscription_Plan: "Basic Plan",
    Duration: "6 month",
    status: "Completed",
    Amount: "$ 500.00",
  },
  {
    date: "1-06-2022",
    Subscription_Plan: "Basic Plan",
    Duration: "6 month",
    status: "Completed",
    Amount: "$ 500.00",
  },
  {
    date: "1-06-2022",
    Subscription_Plan: "Basic Plan",
    Duration: "6 month",
    status: "Completed",
    Amount: "$ 500.00",
  },
  {
    date: "1-06-2022",
    Subscription_Plan: "Basic Plan",
    Duration: "6 month",
    status: "Completed",
    Amount: "$ 500.00",
  },
];

let CardData = [
  {
    PlanType: "Personal",
    PlanExpire: "Trial ends : 4 Jun, 2023",
    PlanPrice: "$500.00",
    PlanPriceMonth: "/Month",
    PlanPara:
      "Lorem ipsum dolor sit amet, consectetur elit ligul elit. In ante ligula consequat vivamus elit sed molestie. Etiam at.",
    PlanBtn: "Upgrade",
  },
  {
    PlanType: "Professional",
    PlanPrice: "$800.00",
    PlanPriceMonth: "/6 Month",
    PlanPara:
      "Lorem ipsum dolor sit amet, consectetur elit ligul elit. In ante ligula consequat vivamus elit sed molestie. Etiam at.",
    PlanBtn: "Purchase",
  },
  {
    PlanType: "Team",
    PlanPrice: "$800.00",
    PlanPriceMonth: "/Year",
    PlanPara:
      "Lorem ipsum dolor sit amet, consectetur elit ligul elit. In ante ligula consequat vivamus elit sed molestie. Etiam at.",
    PlanBtn: "Purchase",
  },
];

export default class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCard: null,
    };
  }

  handleCardClick = (card) => {
    this.setState({ selectedCard: card });
  };
  render() {
    const { selectedCard } = this.state;
    return (
      <div>
        <div className={styles.billingPlansContainer}>
          <h6 className={styles.pricingCardContainerTitle}>Plans</h6>

          <Grid container spacing={1}>
            {CardData.map((item) => (
              <Grid item lg={4} md={6}>
                <Card
                  className={`${
                    item.PlanBtn === "Upgrade" && styles.PlansCardCurrentPlan
                  } ${styles.PlansCardWrapper}`}
                >
                  <div className={styles.PlansCradWrapperHeader}>
                    <div
                      className={
                        item.PlanType === "Personal"
                          ? styles.PlansCardUserInfoUpgrade
                          : item.PlanType === "Professional"
                          ? styles.PlansCardUserInfoProfessional
                          : styles.PlansCardUserInfoTeam
                      }
                    >
                      {item.PlanType}
                    </div>
                    <div className={styles.PlansCardEndTimeData}>
                      {item.PlanExpire}
                    </div>
                  </div>
                  <div className={styles.PlansCardPriceContainer}>
                    {item.PlanPrice}
                    <span>{item.PlanPriceMonth}</span>
                  </div>
                  <div className={styles.PlansCardPara}>{item.PlanPara}</div>
                  <button
                    className={
                      item.PlanBtn === "Upgrade"
                        ? styles.PlanCardUpgradeBtn
                        : styles.PlanCardPurchaseBtn
                    }
                  >
                    {item.PlanBtn}
                  </button>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>

        <div className={styles.billingHistoryContainer}>
          <h6 className={styles.billingHistoryTitle}>Billing History</h6>
          <div className={styles.UserTableContainer}>
            <Grid container className={styles.TableHeaderUsers}>
              <Grid item xs={2} className={styles.headerTableUsersPage}>
                <span>Date</span>
              </Grid>
              <Grid item xs={3} className={styles.headerTableUsersPage}>
                <span>Subscription Plan</span>
              </Grid>
              <Grid item xs={2} className={styles.headerTableUsersPage}>
                <span>Duration</span>
              </Grid>
              <Grid item xs={2} className={styles.headerTableUsersPage}>
                <span>Status</span>
              </Grid>
              <Grid item xs={2} className={styles.headerTableUsersPage}>
                <span>Amount</span>
              </Grid>
              <Grid item xs={1} className={styles.headerTableUsersPage}>
                <span>Invoice</span>
              </Grid>
            </Grid>
            {UserData.map((item) => (
              <Grid
                key={item.date}
                container
                className={styles.UserTableContainerRows}
              >
                <Grid item xs={2} className={styles.BillingTableDataPage}>
                  <span>{item.date}</span>
                </Grid>
                <Grid item xs={3} className={styles.BillingTableDataPage}>
                  <span>{item.Subscription_Plan}</span>
                </Grid>
                <Grid item xs={2} className={styles.BillingTableDataPage}>
                  <span>{item.Duration}</span>
                </Grid>
                <Grid item xs={2} className={styles.BillingTableDataPage}>
                  <span
                    className={
                      item.status == "Completed" && styles.durationCompleted
                    }
                  >
                    {item.status}
                  </span>
                </Grid>
                <Grid item xs={2} className={styles.BillingTableDataPage}>
                  <span>{item.Amount}</span>
                </Grid>
                <Grid
                  item
                  xs={1}
                  className={`${styles.BillingPageCenterDownload} ${styles.BillingTableDataPage}`}
                >
                  <div className={styles.DownloadImageBillingPage}>
                    <img src={DownloadImage} />
                  </div>{" "}
                </Grid>
              </Grid>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

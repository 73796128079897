/* global chrome */
import React from "react";
import axios from "axios";
import knight from "./../Images/Olas-Chibi.jpg";
import styles from "./CallsList.module.css";
import { Link } from "react-router-dom";
import style from "./Dashbord.module.css";
import {
  Card,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Pagination,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import "antd/dist/antd.css";
import Cookies from "universal-cookie";
import eye from "./../Images/vuesaxlineareye.svg";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Box from "@mui/material/Box";
import { NavbarTop } from "../Components/Navbar";
import { NavbarLeft } from "../Components/Nav-Left/NavLeft";
import { sendTokenToChromeExtension } from "../Login/sendTokenToExt";
import UniversalCss from "../Universal.module.css";

export class Dashboard extends React.Component {
  constructor() {
    super();
    //binding the data so it can be called
    //setting to empty strings
    this.onChangeQuestionLabel = this.onChangeQuestionLabel.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.DatePicker = this.DatePicker.bind(this);
    this.handleClearSearch = this.handleClearSearch.bind(this);
    // this.handleClicks = this.handleClicks.bind(this);

    this.state = {
      files: [],
      Name: "",
      Type: "",
      FileName: "",
      title: "",
      callName: "",
      image: null,
      fileUrl: [],
      User: "",
      visibility: false,
      recruiter: [],
      duration: "",
      person: "",
      show: false,
      alerting: false,
      transcribeAlert: false,
      transcribeFaultAlert: false,
      summarizeAlert: false,
      summarizeFaultAlert: false,
      extractAlert: false,
      extractFaultAlert: false,
      imageName: "",
      totalPage: 0,
      current: 1,
      minIndex: 0,
      maxIndex: 0,
      companyID: "",
      userLogged: "",
      hashFile: "",
      candidate_name: "",
      candidate_email: "",
      candidate_phone: "",
      search: "",
      searchDate: "",
      //   value: "",
      value: null,
      rolesDisplay: "",
      role: "",
      search: "",
      DatePickerDate: new Date(),
      spinnerLoading: false,
    };
  }

  DatePicker(e) {
    var dateSort = String(e);
    var year = dateSort.split(" ")[3];
    //console.log(year)

    var date = dateSort.split(" ")[2];
    var month = dateSort.split(" ")[1];
    var monthFormated = "";
    if (month == "Jan") {
      monthFormated = "01";
    } else if (month == "Feb") {
      monthFormated = "02";
    } else if (month == "Mar") {
      monthFormated = "03";
    } else if (month == "Apr") {
      monthFormated = "04";
    } else if (month == "May") {
      monthFormated = "05";
    } else if (month == "Jun") {
      monthFormated = "06";
    } else if (month == "Jul") {
      monthFormated = "07";
    } else if (month == "Aug") {
      monthFormated = "08";
    } else if (month == "Sep") {
      monthFormated = "09";
    } else if (month == "Oct") {
      monthFormated = "10";
    } else if (month == "Nov") {
      monthFormated = "11";
    } else if (month == "Dec") {
      monthFormated = "12";
    }

    var dateSorted = year + "-" + monthFormated + "-" + date;
    this.setState({ searchDate: dateSorted });
    this.setState({ DatePickerDate: e });
  }
  // get request on load of page, to get all the folders in the s3
  async componentDidMount() {
    const cookies = new Cookies();
    var config = cookies.get("token");

    if (!cookies.get("token")) {
      config = localStorage.getItem("token");
      //console.log("token: ", config) 
    }
    this.setState({ spinnerLoading: true });

    axios
      .get(process.env.REACT_APP_BACKEND_URL + "/login/access-token-jwt", {
        headers: {
          Authorization: "Token " + config,
        },
      })
      .then((response) => {
        console.log(response);
        let djangoToken = config;
        let jwtToken = response.data.refresh;
        sendTokenToChromeExtension({
          djangoToken,
          jwtToken,
        });
        console.log(djangoToken, jwtToken);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(process.env.REACT_APP_LOGIN_URL + "/workorder/role", {
        headers: {
          Authorization: "Token " + config,
        },
      })
      .then((response) => {
        //this.setState({ userLogged: response.data[0].username })
        this.setState({ rolesDisplay: response.data.message });
      })
      .catch((error) => console.log(error));

    axios
      .get(process.env.REACT_APP_LOGIN_URL + "/login/users", {
        headers: {
          Authorization: "Token " + config,
        },
      })
      .then((response) => {
        // console.log(response.data[0].username)
        this.setState({ userLogged: response.data[0].username });
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.status === 401) {
          // Clear cookies or perform any other action
          // Replace 'clearCookies()' with the actual function to clear cookies
          const cookies = new Cookies();
          cookies.remove('token', {
            path: '/',
            domain: 'squire-olas.com', // Replace with your desired domain
          }); //clear storage so no user is stored in the session
          cookies.remove("loggedin"); //clear storage so no user is stored in the session
          cookies.remove("loginfrommail"); //clear storage so no user is stored in the session
          cookies.remove("redirect"); //clear storage so no user is stored in the session
          cookies.remove("Isadmin"); //clear storage so no user is stored in the session
          window.location.href = '/'
        }
      });

    //console.log("token: ", config)
    axios
      .get(process.env.REACT_APP_LOGIN_URL + "/login/identify", {
        headers: {
          Authorization: "Token " + config,
        },
      })
      .then((response) => {
        this.setState({ companyID: response.data[0].companyid });
      })
      .catch((error) => console.log(error));

    axios
      .get(process.env.REACT_APP_BACKEND_URL + "/file/listings", {
        headers: {
          Authorization: "Token " + config,
        },
        params: {
          recent_count: 10
        }
      })
      .then((response) => {
        console.log("here", response.data.message)
        var final_arry = [];
        var time = []

        for (let j = 0; j < response.data.message.length; j++) {
          if (j != 0) {
            var array = response.data.message[j];
            // console.log(array[4], "here now")
            var splitting = array[4].split("T")
            //console.log("here now", splitting)
            //var splitit = splitting[0].split("T")
            //array.splice(array.indexOf(4), 1, array[4].split("T")[0]);


            // /console.log(formattedTime, "here now");
            console.log("here now", array)
            final_arry.push(array);


          }
        }
        this.setState({
          files: final_arry,
          // totalPage: response.data.message.length / pageSize,
          // minIndex: 0,
          // maxIndex: pageSize,
        });
      })
      .catch((error) => {
        console.log(error);
        // Handle the error case
      })
      .finally(() => {
        // Code here will run regardless of whether the request succeeded or failed
        // For example, you could stop a loading spinner here
        console.log("WHat")
        this.setState({ spinnerLoading: false });
      });
  }
  onChangeQuestionLabel(e) {
    this.setState({ role: e.target.value });
  }
  // handleClicks = (page) => {
  //   this.setState({
  //     current: page,
  //     minIndex: (page - 1) * pageSize,
  //     maxIndex: page * pageSize,
  //   });
  //   console.log(this.state.totalPage);
  // };
  // set the state of the name
  onChangeName(e) {
    this.setState({ Name: e.target.value });
  }
  // post to create new group
  onSubmit(e) {
    e.preventDefault();
    //post request to post the data
    axios({
      method: "POST",
      url: process.env.REACT_APP_TESTING_URL + "/api/create",
      data: {
        file_name: this.state.Name,
      },
    });
  }
  // handle the change of values
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  // handle the file
  handleImageChange = (e) => {
    this.setState({
      image: e.target.files[0],
    });
  };

  handleChangeCandidateName = (e) => {
    this.setState({
      candidate_name: e.target.value,
    });
  };
  handleChangeCandidateEmail = (e) => {
    this.setState({
      candidate_email: e.target.value,
    });
  };

  handleChangeCandidatePhone = (e) => {
    this.setState({
      candidate_phone: e.target.value,
    });
  };

  SettingsPage() {
    const id = document.getElementById("lang").value;
    if (id == "settings") {
      window.location.href = "/settings";
    }
    if (id == "logout") {
      alert("Logged out");
      const cookies = new Cookies();
      cookies.remove("token"); //clear storage so no user is stored in the session
      cookies.remove("loggedin"); //clear storage so no user is stored in the session
      //redirect user to the home page, which is protected, so will redirect to login page
      localStorage.clear();
      window.location.href = "/";
    }
  }
  handleClearSearch = () => {
    this.setState({ search: '' });
  }
  render() {
    //format the file so that it only displays the group name/ file name
    const { files, search, searchDate, spinnerLoading } = this.state;

    console.log("searchinhg,", files)
    const { DatePickerDate, value, current, minIndex, maxIndex } = this.state;

    var empty = [];
    var testArr = files.map(function (nested) {
      return nested.map(function (element) {
        return element;
      });
    });

    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container className={UniversalCss.GridContainerWrapperHegih}>
          <Grid item md={1.7} xs={2} className={UniversalCss.LeftNavbarShadow}>
            <NavbarLeft />
          </Grid>
          <Grid
            item
            md={10.3}
            xs={10}
            className={UniversalCss.navbarAndRouteWrapper}
          >
            <NavbarTop PageData={"Dashboard"} />
            {/*} <div className={styles.flexcontainer11}>
                        <div className={styles.group48096215}>

                            <div className={styles.rectangle796}>
                                <div className={styles.flexcontainer12}>
                                    <span className={styles.num48}>48</span>
                                    <span className={styles.totalcalls}>Total calls</span>
                                </div>

                            </div>
                        </div>
                        <div className={styles.rectangle803}>
                            <div className={styles.flexcontainer13}>
                                <span className={styles.num15}>15</span>
                                <span className={styles.newcandidates}>new candidates</span>
                            </div>

                        </div>
                        <div className={styles.rectangle805}>
                            <div className={styles.flexcontainer14}>
                                <span className={styles.num3500}>35:00</span>
                                <div className={styles.flexcontainer15}>
                                    <span className={styles.avgtalktime}>Avg talk time</span>

                                </div>
                            </div>
                            <div className={styles.flexcontainer16}>

                            </div>
                        </div>
                        <div className={styles.rectangle804}>
                            <div className={styles.flexcontainer17}>
                                <span className={styles.num20}>20%</span>
                                <span className={styles.avgprofilecapture}>avg profile capture</span>
                            </div>

                        </div>
                                            </div>*/}
            <div className={styles.rectangle802}>
              <div className={styles.flexcontainer18}>
                <span className={styles.log}>Recent Calls</span>
                {/* <Link className={styles.links} to={"/"}> */}
                <button onClick={(e) => this.setState({ search: "" })} className={styles.primarybutton3}>View all</button>
                {/* </Link> */}
              </div>
              <div className={styles.flexcontainer19}>
                <div className={style.DashbordCallsSearchbar}>
                  <TextField
                    color="secondary"
                    className={style.DashbordCallsSearchbarInput}
                    onChange={(e) => this.setState({ search: e.target.value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    id="outlined-basic"
                    label="Search Call..."
                    variant="outlined"
                    value={this.state.search}
                  />
                </div>
                {/* <TextField
                  id="outlined-basic"
                  label="Search Call..."
                  variant="outlined"
                  onChange={(e) => this.setState({ search: e.target.value })}
                  className={styles.rectangle637}
                /> */}
                {/*} <select className={styles.rectangle638}>
                                <option>Filter</option>
        </select>*/}

                {/*}  <Calendar className={styles.rectangle637}  onChange={(e) => this.setState({value: value},{searchDate: e.target.value})} value={value} />
        
    */}
                <DatePicker
                  className={styles.DatePickCSS}
                  onChange={this.DatePicker}
                  format="y-MM-dd"
                  value={DatePickerDate}
                  selected={DatePickerDate}
                  popperPlacement="top-end"
                  popperModifiers={[
                    {
                      name: "offset",
                      options: {
                        offset: [5, 10],
                      },
                    },
                    {
                      name: "preventOverflow",
                      options: {
                        rootBoundary: "viewport",
                        tether: false,
                        altAxis: true,
                      },
                    },
                  ]}
                />
              </div>

              {/* <div className={styles.grid}>
                <span className={styles.s1}>Phone</span>
                <span className={styles.s2}>Candidate</span>
                <span className={styles.s3}>Date</span>
                <span className={styles.s4}>Recruiters</span>
                <span className={styles.s5}>Actions</span>
              </div> */}
              <Grid container className={style.DashbordCallListHeader}>
                <Grid item xs={2} className={style.DashbordCallListHeaderItems}>
                  <span>Phone</span>
                </Grid>
                <Grid item xs={2} className={style.DashbordCallListHeaderItems}>
                  <span>Candidate</span>
                </Grid>
                <Grid item xs={1.33} className={style.DashbordCallListHeaderItems}>
                  <span>Duration</span>
                </Grid>
                <Grid item xs={1.33} className={style.DashbordCallListHeaderItems}>
                  <span>Date</span>
                </Grid>
                <Grid item xs={1.33} className={style.DashbordCallListHeaderItems}>
                  <span>Time</span>
                </Grid>
                <Grid item xs={2} className={style.DashbordCallListHeaderItems}>
                  <span>Recruiters</span>
                </Grid>
                <Grid item xs={2} className={style.DashbordCallListHeaderItems}>
                  <span>Actions</span>
                </Grid>
              </Grid>
              {spinnerLoading ? (
                <div  className={styles.SpinContainer}>
                <div className={styles.Spin}></div>
                </div>


              ) : (
              testArr.filter((files) => {
                if (search !== "") {
                  const candPhone = files[3]?.cand_phone?.toLowerCase();
                  const candName = files[3]?.cand_name?.toLowerCase();
                  const recruiter = files[3]?.user?.toLowerCase();

                  return (
                    (candPhone && candPhone.includes(search.toLowerCase())) ||
                    (candName && candName.includes(search.toLowerCase())) ||
                    (recruiter && recruiter.includes(search.toLowerCase()))
                  );
                } else if (searchDate !== "") {
                  const dateString = files[4].split('T')[0]; // Splits the string at 'T' and takes the first part
          return dateString === searchDate;
                }

                return true; // Include all elements if no search criteria provided
              })
                .map((files, index) => (
                  // index >= minIndex &&
                  // index < maxIndex && (
                  <>
                    <Grid
                      container
                      key={files[0]}
                      className={style.DashbordCallListItems}
                    >
                      <Grid
                        item
                        xs={2}
                        className={style.DashbordCallListItemsData}
                      >
                        <span className={style.DashbordCallListItemsDataName}>
                          {files[3].cand_phone}
                        </span>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        className={style.DashbordCallListItemsData}
                      >
                        <span
                          className={style.DashbordCallListItemsDataDuration}
                        >
                          {files[3].cand_name}
                        </span>
                      </Grid>
                      <Grid
                        item
                        xs={1.33}
                        className={style.DashbordCallListItemsData}
                      >
                        <span className={style.DashbordCallListItemsDataDate}>
                          {(() => {
                            const totalSeconds = Number(files[5]);
                            if (isNaN(totalSeconds) || totalSeconds === 0) {
                              return null; // or return ''; if you prefer an empty string
                            }
                            const minutes = Math.floor(totalSeconds / 60);
                            const seconds = totalSeconds % 60;
                            return `${minutes}m ${seconds}s`;
                          })()}
                        </span>
                      </Grid>
                      <Grid
                        item
                        xs={1.33}
                        className={style.DashbordCallListItemsData}
                      >
                        <span className={style.DashbordCallListItemsDataDate}>
                          {files[4].split("T")[0]}
                        </span>
                      </Grid>
                      <Grid
                        item
                        xs={1.33}
                        className={style.DashbordCallListItemsData}
                      >
                        <span className={style.DashbordCallListItemsDataDate}>
                          {files[4].split("T")[1].split(' ')[0].slice(0, 5)}
                        </span>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        className={style.DashbordCallListItemsData}
                      >
                        <span style={{ marginLeft: "8px" }}>
                          {files[3].user}
                        </span>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        className={style.DashbordCallListItemsData}
                      >
                        <span
                          style={{
                            maxWidth: "60px",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Link to={`/${files[0]}`}>
                            <img src={eye} />
                          </Link>
                        </span>
                      </Grid>
                    </Grid>
                    {/* <div key={files[0]} className={styles.grid1}>
                      <span className={styles.s11}>{files[3].cand_phone}</span>
                      <span className={styles.s21}>{files[3].cand_name}</span>
                      <span className={styles.s31}>{files[4]}</span>
                      <span className={styles.s41}>{files[3].user}</span>
                      <div className={styles.s51}>
                        <Link to={`/${files[0]}`}>
                          <img src={eye}></img>
                        </Link>
                      </div>
                    </div> */}
                  </>
                )))}
            </div>
            {/* <Pagination
              showSizeChanger={false}
              pageSize={pageSize}
              current={current}
              total={testArr.length}
              onChange={this.handleClicks}
            /> */}
          </Grid>
        </Grid>
      </Box>
    );
  }
}

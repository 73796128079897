//this is to find whether the person is logged in or not
// set as a boolean value, either true or false
const Auth = {
    // at the beginning set to false
    isAuthenticated: false,
    //authenticate function to find the boolean value
    authenticate(){
        this.isAuthenticated = true;
    },
    //log out of the application
    signOut(){
        this.isAuthenticated = false;
    },
    //get the status of the authentication
    getAuth(){
        return this.isAuthenticated;
    }
}

export default Auth;
import React, { Component } from 'react'
import axios from 'axios';
import Loader from './Loader/Loader';
import Auth from "../Auth";
import Cookies from "universal-cookie";
import styles from "./Login.module.css";
import ErrorModal from '../Components/Error_Modle/ErrorModal';




export default class Verifytoken extends Component {
    constructor(props) {
        super(props)
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            LoaderActive: true,
            err: null,
            ismodalopen: false
        }
    }
    componentDidMount() {
        function getParameterByName(name, url = window.location.href) {
            name = name.replace(/[\[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2]);
        }
        const query = new URLSearchParams(this.props.location.search);

        var data = {
            email: getParameterByName('user'),
            code: getParameterByName('code'),
        };


        var config = {
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/login/accountactivation',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(response.data.message.redirect);
                let djangoToken = response.data.message.token;
                let Isadmin = response.data.message.is_admin;
                console.log(djangoToken);
                //authentication is set to true, allowing for access to protected pages
                Auth.authenticate();
                const cookies = new Cookies();

                cookies.set("loggedin", Auth.getAuth(), { path: "/" });
                cookies.set('token', response.data.message.token, {
                    path: '/',
                    domain: 'squire-olas.com', // Replace with your desired domain
                  });
                cookies.set("loginfrommail", true, { path: "/" });
                cookies.set("Isadmin", Isadmin, { path: "/" });
                cookies.set("redirect", response.data.message.redirect, { path: "/" });

                // localStorage.setItem("loginfrommail", true);
                window.setTimeout(function () {
                    window.location.href = "/profile";
                }, 100);
                // window.location.href = "/"
            })
            .catch(error => {
                this.setState({ ismodalopen: true })
                console.log(error);
                this.setState({ err: error.message })
            });


    }
    handleClose() {
        this.setState({
            ismodalopen: false,
        });
    }
    render() {
        return (
            <>
                < Loader />
                <ErrorModal errorMessage={this.state.err} open={this.state.ismodalopen} handleClose={this.handleClose} />
               

            </>
        )
    }
}


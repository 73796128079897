import React from 'react';
import Auth from './Auth';
import axios from 'axios';

import knight from './Images/squire.png';

import reviewer from './Images/v394_27835.png';
import stars from './Images/istockphoto-1264098294-612x612-removebg-preview.png';
import Cookies from 'universal-cookie';
import group48096972 from './Images/group48096972.svg'


import olaslogo from './Images/path291.png';


//Create class
export class EmailReset extends React.Component {
    //post the login credentials to the backend
    //retrieve the response from the backend - token
    //if success - set auth to true, so rest of site can be accessed
    //else stay false - routes remain protected

    constructor() {
        super();
        //binding the data so it can be called
        this.onSubmit = this.onSubmit.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangePass = this.onChangePass.bind(this);
        //setting to empty strings
        this.state = {
            Name: '',
            Pass: ''
        }
    }
    // set the state of the username
    onChangeName(e) {
        this.setState({ Name: e.target.value });
    }
    //setting the state of the password
    onChangePass(e) {
        this.setState({ Pass: e.target.value });
    }

    // on click of submit button
    onSubmit(e) {
        e.preventDefault();
        //post request to post the data
        axios({
            method: "POST",
            url: process.env.REACT_APP_LOGIN_URL + '/api/login',
            data: {
                username: this.state.Name,
                password: this.state.Pass
            }
        })
            //get the token from the backend and set the authentication to true
            //store the token in session storage
            .then(
                response => {
                    console.log(response.data.token);
                    //authentication is set to true, allowing for access to protected pages
                    Auth.authenticate();
                    const cookies = new Cookies();
                    cookies.set('loggedin', Auth.getAuth(), { path: '/' });
                    cookies.set('token', response.data.token, { path: '/' });


                    //console.log(cookies.get('loggedin')); // Pacman
                    //.setItem("loggedin", Auth.getAuth());// using session storage to get the user who is logged in
                    //localStorage.setItem("token", response.data.token);// set the token in session storage
                    window.location.href = "/" //loads in a different page
                }
            )
            .catch((err) => {

                alert("login failed, try again")

                window.location.href = '/';

                //console.log(err);
            });
    }
    authHandler(err, data) {
        console.log(err, data);

    }
    responseGoogle(response) {
        console.log(response);
    }
    render() {

        return (
            //forms that have been made with react features
            //add user id, name and password
            <div className='App'>

                <div className="split left">
                    <img className="olas-logo" src={olaslogo}></img>


                    <img className='images' src={knight}></img>
                    <div className="group-37300">

                        <div className="rectangle-2986">
                            <span className="lorem-ipsum-very-ner">
                                &quot;lorem ipsum Very nervous going in but the atmosphere and staff
                                were warm and welcoming. They were on time, friendly, and made
                                everything easy to understand. The doctor was kind and explained my
                                options after examination. Would recommend to people nervous about
                                spinal issues.&quot;
                            </span>
                            <div className="flex-container">
                                <img className="ellipse-6056" src={reviewer} />
                                <div className="flex-container-1">
                                    <span className="erica-richardson">Erica Richardson</span>
                                    <span className="google-review">Google Review</span>
                                </div>
                                <img className="group-37272" src={stars} />
                            </div>
                        </div>
                    </div>
                    <img className="olas-logo2" src={olaslogo}></img>
                </div>

                <div className="split right">
                    <div className="group-48097034">
                        <img className="group-480969722" src={group48096972} />
                        <span className="reset-your-password">Reset Your Password</span>

                        <span className="check-your-email-for">

                            Check your email for intstrucitons on how to reset your password.

                        </span>

                        <div className="shape1">
                            <a href='/new-pass' className="primary-button">Go To E-mail</a>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
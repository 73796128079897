import React, { Component } from 'react'
import { Avatar, Pagination, Grid } from "@mui/material";
import styles from "./user.module.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import UserpageForm from './UserpageForm';
import editimg from '../Images/icons/edit.png';
import deactivate from '../Images/icons/deactivate.png';
import reactivate from '../Images/icons/reactivate.png';
import axios from 'axios';
import Cookies from 'universal-cookie';
import ErrorModal from '../Components/Error_Modle/ErrorModal';
import SuccessModal from '../Components/Success_Modle/SuccessModal';
import { Menu, MenuItem } from '@szhsin/react-menu';
import "@szhsin/react-menu/dist/index.css";
import IncreaseUserModal from './Components/IncreaseUserModal';
import DeleteUserModal from './Components/DeleteUserModal';
import UpdateUserModal from './Components/UpdateUserModal';



export default class UserDetails extends Component {
    constructor(props) {

        super(props);
        this.FirstNamefn = this.FirstNamefn.bind(this);
        this.LastNamefn = this.LastNamefn.bind(this);
        this.Emailfn = this.Emailfn.bind(this);
        this.Recruiterfn = this.Recruiterfn.bind(this);
        this.inviteUser = this.inviteUser.bind(this);
        this.removeFormValueById = this.removeFormValueById.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        // this.getData = this.getData.bind(this);
        this.handleRole = this.handleRole.bind(this);
        this.handleEditUserModalClose = this.handleEditUserModalClose.bind(this);
        this.handleEditLastName = this.handleEditLastName.bind(this);
        this.handleEditFirstName = this.handleEditFirstName.bind(this);
        this.UpdateEditUser = this.UpdateEditUser.bind(this);
        this.handleEditUser = this.handleEditUser.bind(this);
        this.DeleteUser = this.DeleteUser.bind(this);
        this.increaselicense = this.increaselicense.bind(this);
        this.handleReactivate = this.handleReactivate.bind(this);

        this.state = {
            value: '1',
            UserApiData: null,
            FirstName: null,
            LastName: null,
            Email: null,
            Recruiter: null,
            FormValue: [],
            err: null,
            Message: null,
            ismodalopen: false,
            isSuccessmodalopen: false,
            // data: [],
            // pageSize: 10,
            currentPage: 1,
            // totalPages: 0,
            // companyusersinfo: [],
            EdituserOpen: false,
            EdituserUpdate: null,
            EditUserFirstName: null,
            EditUserLastName: null,
            EditUserRole: null,
            EditUserEmail: null,
            DeleteuserOpen: false,
            DeleteUserEmail: null,
            IncreaseUsers: false
        }
    }
    

    handleMenuOpen = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = (e) => {
        this.setState({ anchorEl: null });
    };
    handleEditUser(e, email, firstName, lastName, recruiter) {
        // console.log(email, firstName, lastName, recruiter);
        this.setState({ EditUserEmail: email })
        this.setState({ EditUserFirstName: firstName })
        this.setState({ EditUserLastName: lastName })
        this.setState({ EditUserRole: recruiter })
        this.setState({ EdituserOpen: true })

    }
    handlePageChange = (event, value) => {
        this.setState({ currentPage: value });
    };
    changeWidth(e) {
        this.setState({ pageSize: parseInt(e.target.value, 10) });
    };

    FirstNamefn(e, id) {
        const first_name = e.target.value;
        const newData = { first_name: first_name, id: id, };
        const index = this.state.FormValue.findIndex(item => item.id === id);
        if (index === -1) {
            const updatedData = [...this.state.FormValue, newData].sort((a, b) => a.id - b.id);
            this.setState({ FormValue: updatedData });
        } else {
            const updatedData = [...this.state.FormValue];
            updatedData[index] = { ...updatedData[index], first_name };
            this.setState({ FormValue: updatedData });
        }

    }
    LastNamefn(e, id) {
        // this.setState({ LastName: e.target.value })
        const last_name = e.target.value;
        const newData = { last_name: last_name, id: id, };
        const index = this.state.FormValue.findIndex(item => item.id === id);
        if (index === -1) {
            const updatedData = [...this.state.FormValue, newData].sort((a, b) => a.id - b.id);
            this.setState({ FormValue: updatedData });
        } else {
            const updatedData = [...this.state.FormValue];
            updatedData[index] = { ...updatedData[index], last_name };
            this.setState({ FormValue: updatedData });
        }
    }
    Emailfn(e, id) {
        // this.setState({ Email: e.target.value })
        const email = e.target.value;
        const newData = { email: email, id: id, };
        const index = this.state.FormValue.findIndex(item => item.id === id);
        if (index === -1) {
            const updatedData = [...this.state.FormValue, newData].sort((a, b) => a.id - b.id);
            this.setState({ FormValue: updatedData });
        } else {
            const updatedData = [...this.state.FormValue];
            updatedData[index] = { ...updatedData[index], email };
            this.setState({ FormValue: updatedData });
        }
    }
    Recruiterfn(e, id) {
        // console.log(this.state.data);
        // this.setState({ Recruiter: e.target.value })
        const Recruiter = e.target.value;
        const newData = { Recruiter: Recruiter, id: id, };
        const index = this.state.FormValue.findIndex(item => item.id === id);
        if (index === -1) {
            const updatedData = [...this.state.FormValue, newData].sort((a, b) => a.id - b.id);
            this.setState({ FormValue: updatedData });
        } else {
            const updatedData = [...this.state.FormValue];
            updatedData[index] = { ...updatedData[index], Recruiter };
            this.setState({ FormValue: updatedData });
        }
    }
    inviteUser(e) {
        e.preventDefault()
        // console.log(this.state.FormValue);
        // console.log("clicked");
        const cookies = new Cookies();
        var config = cookies.get("token")

        if (!cookies.get("token")) {
            config = localStorage.getItem("token")
        }

        var data = this.state.FormValue

        console.log(data);
        var invalidEmail = []
        for (let i = 0; i < data.length; i++) {
            const el = data[i].email;
            console.log(el);
            if (el.includes('+')) {
                invalidEmail.push(el)
            }

        }
        console.log(invalidEmail);
        if (invalidEmail.length != 0) {
            console.log("invalid email");
            this.setState({ ismodalopen: true })
            let emails = invalidEmail
            emails = emails.join(", ")
            this.setState({ err: "Invalid email format: " + emails })
        } else {
            var config = {
                method: 'post',
                url: process.env.REACT_APP_BACKEND_URL + '/company/save-users',
                headers: {
                    'Authorization': 'Token ' + config,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            if (this.props.companyusersinfo.active_users >= this.props.companyusersinfo.purchased_users) {
                console.log("users >=", "not active licence, active_users", this.props.companyusersinfo.active_users, "purchased_users", this.props.companyusersinfo.purchased_users);
                this.setState({ IncreaseUsers: true })
            }
            if (this.props.companyusersinfo.active_users < this.props.companyusersinfo.purchased_users) {
                console.log("active licence, active_users", this.props.companyusersinfo.active_users, "users <", "purchased_users", this.props.companyusersinfo.purchased_users);
                axios(config)
                    .then(response => {
                        this.setState({ isSuccessmodalopen: true })
                        this.setState({ Message: response.data.message.message })
                        // console.log(response);
                        // this.getData()
                        this.props.getData()
                    })
                    .catch(error => {
                        this.setState({ ismodalopen: true })
                        console.log(error.response.data.message);
                        if (error.response.data.message.duplicate_email_list) {
                            let emails = error.response.data.message.duplicate_email_list
                            emails = emails.join(", ")
                            this.setState({ err: "The following email(s) already have NoteTaker accounts: " + emails })
                        }
                    });
            }
        }


    }
    removeFormValueById = (id) => {
        // console.log("Id in parent Component", id);
        this.setState(prevState => ({
            FormValue: prevState.FormValue.filter(item => item.id !== id)
        }));

    }
    handleClose() {
        this.setState({
            ismodalopen: false,
            isSuccessmodalopen: false,
        });
    }
    handleRole(e) {
        // console.log(e.target.value);
        this.setState({ EditUserRole: e.target.value })
    }
    handleEditFirstName(e) {
        this.setState({ EditUserFirstName: e.target.value })
    }
    handleEditLastName(e) {
        this.setState({ EditUserLastName: e.target.value })
    }
    handleEditUserModalClose() {
        this.setState({ EdituserOpen: false })
        this.setState({ DeleteuserOpen: false })
        this.setState({ IncreaseUsers: false })
    }
    UpdateEditUser(e) {
        e.preventDefault()
        this.handleMenuClose()

        const cookies = new Cookies();
        var config = cookies.get("token")

        if (!cookies.get("token")) {
            config = localStorage.getItem("token")
        }

        var data = [
            {
                first_name: this.state.EditUserFirstName,
                last_name: this.state.EditUserLastName,
                email: this.state.EditUserEmail,
                Recruiter: this.state.EditUserRole
            }
        ]
        // console.log(data);

        var config = {
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/company/update-users',
            headers: {
                'Authorization': 'Token ' + config,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(response => {
                this.setState({ isSuccessmodalopen: true })
                // console.log(response);
                this.setState({ Message: response.data.message.message })
                // this.getData()
                this.props.getData()
                this.setState({ EdituserOpen: false })
            })
            .catch(error => {
                this.setState({ ismodalopen: true })
                console.log(error.response.data.message);
                this.setState({ err: JSON.stringify(error.response.data.message) })
            });

    }
    handleDeleteUser(e, email) {
        this.setState({ DeleteUserEmail: email })
        this.setState({ DeleteuserOpen: true })
    }
    handleReactivate(e, email) {
        e.preventDefault()
        console.log(email);

        const cookies = new Cookies();
        var config = cookies.get("token")

        if (!cookies.get("token")) {
            config = localStorage.getItem("token")
        }

        var data = JSON.stringify({
            email: email
        });

        var config = {
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/company/reactivate-user',
            headers: {
                'Authorization': 'Token  ' + config,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then((response) => {
                this.props.getData()
                this.setState({ isSuccessmodalopen: true })
                console.log(response);
                this.setState({ Message: response.data.message.message })
            })
            .catch(function (error) {
                console.log(error);
                this.setState({ ismodalopen: true })
                this.setState({ err: (error.response.data.message) })
            });

    }
    DeleteUser(e) {
        e.preventDefault()
        this.handleMenuClose()

        const cookies = new Cookies();
        var config = cookies.get("token")

        if (!cookies.get("token")) {
            config = localStorage.getItem("token")
        }

        var data = {
            email: this.state.DeleteUserEmail,
        }

        // console.log(data);

        var config = {
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/company/delete-user',
            headers: {
                'Authorization': 'Token ' + config,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(response => {
                this.setState({ isSuccessmodalopen: true })
                // console.log(response);
                this.setState({ Message: response.data.message.message })
                // this.getData()
                this.props.getData()
                this.setState({ DeleteuserOpen: false })
            })
            .catch(error => {
                this.setState({ ismodalopen: true })
                console.log(error.response.data.message);
                this.setState({ DeleteuserOpen: false })
                this.setState({ err: JSON.stringify(error.response.data.message) })
            });

    }
    increaselicense(e) {
        console.log(this.props);
        this.setState({ IncreaseUsers: false })
        this.props.BillingPageByUsers("2")
    }
    render() {

        const { data, totalPages, pageSize, companyusersinfo } = this.props
        const { currentPage } = this.state;
        const { EdituserOpen, EdituserUpdate, DeleteuserOpen, IncreaseUsers } = this.state;
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const pageData = data.slice(startIndex, endIndex);
        return (
            <div>
                {/* Update User */}
                <UpdateUserModal EditUserEmail={this.state.EditUserEmail} EditUserLastName={this.state.EditUserLastName} handleEditLastName={this.handleEditLastName} handleEditFirstName={this.handleEditFirstName} EditUserFirstName={this.state.EditUserFirstName} EdituserOpen={EdituserOpen} UpdateEditUser={this.UpdateEditUser} handleEditUserModalClose={this.handleEditUserModalClose} handleRole={this.handleRole} EditUserRole={this.state.EditUserRole} />
                {/* Delete Modal */}
                <DeleteUserModal DeleteuserOpen={DeleteuserOpen} DeleteUser={this.DeleteUser} handleEditUserModalClose={this.handleEditUserModalClose} />
                {/* Increase Users Modal */}
                <IncreaseUserModal IncreaseUsers={IncreaseUsers} increaselicense={this.increaselicense} handleEditUserModalClose={this.handleEditUserModalClose} />


                <ErrorModal errorMessage={this.state.err} open={this.state.ismodalopen} handleClose={this.handleClose} />
                <SuccessModal Message={this.state.Message} open={this.state.isSuccessmodalopen} handleClose={this.handleClose} />
                <div className={styles.UserInformationWrapper}>
                    <div className={styles.InfodataWrapper}>
                        <h2 className={styles.infoData}><span>{companyusersinfo.active_users}</span>/<span className={styles.totalUserCount}>{companyusersinfo.purchased_users}</span></h2>
                        <h3 className={styles.infoDataLabel}>Total User</h3>
                    </div>
                    <span className={styles.breakerSpan}></span>
                    <div className={styles.InfodataWrapper} style={{ textAlign: "center" }}>
                        <h2 className={styles.infoData}><span>{companyusersinfo.admin_count}</span></h2>
                        <h3 className={styles.infoDataLabel}>Admin</h3>
                    </div>
                    <span className={styles.breakerSpan}></span>
                    <div className={styles.InfodataWrapper} style={{ textAlign: "center" }}>
                        <h2 className={styles.infoData}><span>{companyusersinfo.recruiter_count}</span></h2>
                        <h3 className={styles.infoDataLabel}>Recruiter</h3>
                    </div>
                    <span className={styles.breakerSpan}></span>

                </div>
                <hr style={{ color: "gray" }}></hr>
                {/*  */}
                <div className={styles.AddUserFormWrapper}>
                    <h3 className={styles.Invtehead}>Invite User</h3>
                    <div className={styles.InviteUserWrapper} id="InviteUserWrapper">
                        <form onSubmit={this.inviteUser}>

                            <UserpageForm FormValue={this.state.FormValue} removeFormValueById={this.removeFormValueById} id={"initialForm1Olas"} FirstNamefn={this.FirstNamefn} LastNamefn={this.LastNamefn} Emailfn={this.Emailfn} Recruiterfn={this.Recruiterfn} />
                            <div className={`${styles.BtnInviteUser} ${styles.FormItemWrapperPlusBtn}`}>
                                <div className={styles.InviteUserBtnwrapper}>
                                    <button onClick={this.InviteUserBtnFn} type='submit' className={styles.InviteUserBtn}>Invite User</button>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>



                <div className={styles.UserTableContainer}>
                    <Grid container className={`HeadingsButtonFontOlas ${styles.TableHeaderUsers}`} >
                        <Grid item xs={4} className={styles.headerTableUsersPage}>
                            <span>User</span>
                        </Grid>
                        <Grid item xs={4} className={styles.headerTableUsersPage}>
                            <span>Email Id</span>
                        </Grid>
                        <Grid item xs={2} className={styles.headerTableUsersPage}>
                            <span>Role</span>
                        </Grid>
                        <Grid item xs={1} className={styles.headerTableUsersPage}>
                            <span>Status</span>
                        </Grid>
                        <Grid item xs={1} className={styles.headerTableUsersPage}>
                        </Grid>
                    </Grid>
                    {pageData.map((item) => (
                        <Grid key={item.email} container className={styles.UserTableContainerRows}>
                            <Grid item xs={4} className={styles.UserTableDataPage}>
                                <div className={styles.UserTableAvatarContainer}>
                                    <Avatar
                                        alt=""
                                        src={item.url}
                                        sx={{ width: 30, height: 30 }}
                                    />
                                    <span style={{ marginLeft: "5px" }}>{item.first_name} {item.last_name}</span>
                                </div>
                            </Grid>
                            <Grid item xs={4} className={styles.UserTableDataPage}>
                                <span>{item.email}</span>
                            </Grid>
                            <Grid item xs={2} className={styles.UserTableDataPage}>
                                <span>{item.Recruiter ? item.Recruiter : "undefined"}</span>
                            </Grid>
                            <Grid item xs={1} className={styles.UserTableDataPage}>
                                <span
                                    className={`${item.status == "Pending" && styles.pendingStatement} ${item.status == "Deactivated" && styles.deactivateStatement} ${item.status == "Active" && styles.AcceptStatement}`}
                                >{item.status ? item.status : "undefined"}</span>
                            </Grid>
                            <Grid item xs={1} className={styles.UserTableDataPage}>

                                <Menu menuButton={<MoreVertIcon
                                    onClick={this.handleMenuOpen}
                                    className={styles.userTableDataMoreVertIcon}
                                />}>
                                    <MenuItem className={styles.menueItem} onClick={e => this.handleEditUser(e, item.email, item.first_name, item.last_name, item.Recruiter)}>
                                        <div className={styles.menueItem}><Avatar sx={{ padding: "2px", width: 24, height: 24, marginRight: "5px" }} src={editimg} />Edit</div>
                                    </MenuItem>

                                    <MenuItem className={item.is_current_user ? styles.AvatarIcon : styles.menueItem} >

                                        {item.status == "Deactivated" ?
                                            <div className={item.is_current_user ? styles.AvatarIcon : styles.menueItem}
                                                onClick={!item.is_current_user ? e => this.handleReactivate(e, item.email) : null}>
                                                <Avatar sx={{ padding: "2px", width: 24, height: 24, marginRight: "5px" }} src={reactivate} />
                                                Reactivate
                                            </div>
                                            :
                                            <div className={item.is_current_user ? styles.AvatarIcon : styles.menueItem}
                                                onClick={!item.is_current_user ? e => this.handleDeleteUser(e, item.email) : null}>
                                                <Avatar sx={{ padding: "2px", width: 24, height: 24, marginRight: "5px" }} src={deactivate} />
                                                Deactivate
                                            </div>}

                                    </MenuItem>
                                </Menu>

                            </Grid>
                        </Grid>
                    ))}

                </div>
                <div className={styles.PaginationContainer}>
                    <Pagination
                        count={totalPages}
                        className={styles.PaginationWrapper}
                        page={currentPage}
                        onChange={this.handlePageChange}
                    />
                </div></div>
        )
    }
}

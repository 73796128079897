import React, { Component } from "react";
import { Box, Grid, Tab, } from "@mui/material";
import { NavbarLeft } from "../Components/Nav-Left/NavLeft";
import { NavbarTop } from "../Components/Navbar";
import UniversalCss from "../Universal.module.css";
import styles from "./Initigration.module.css";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ErrorModal from "../Components/Error_Modle/ErrorModal";
import SuccessModal from "../Components/Success_Modle/SuccessModal";
import VoipAts from "./VoipAts";
import Extensionintigration from "./Extensionintigration";





export default class Integrations extends Component {
    constructor() {
        super();
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            value: '1',
            err: null,
            ismodalopen: false,
            isSuccessmodalopen: false,
        }
    }
    handleChange(e, Value) {
        this.setState({
            value: Value
        })
    }

    handleClose() {
        this.setState({
            ismodalopen: false,
            isSuccessmodalopen: false,
        });
    }

    render() {
        return (
            <>
                <ErrorModal errorMessage={this.state.err} open={this.state.ismodalopen} handleClose={this.handleClose} />
                <SuccessModal Message={this.state.Message} open={this.state.isSuccessmodalopen} handleClose={this.handleClose} />
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container className={UniversalCss.GridContainerWrapperHegih}>
                        <Grid item md={1.7} xs={2} className={UniversalCss.LeftNavbarShadow}>
                            <NavbarLeft />
                        </Grid>
                        <Grid item md={10.3} xs={10} className={UniversalCss.navbarAndRouteWrapper} >
                            <NavbarTop PageData={"Integration"} />
                            <div className={UniversalCss.UserWrapper}>
                                <TabContext value={this.state.value}>
                                    <div className={UniversalCss.NavbarWrapperDiv}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList textColor="primary"
                                                indicatorColor="primary" onChange={this.handleChange} aria-label="lab API tabs example">
                                                <Tab className={styles.tableHeadercss} label="Voip & ATS" value="1" />
                                                {/* <Tab className={styles.tableHeadercss} label="Chrome Extention" value="2" /> */}
                                            </TabList>
                                        </Box>
                                    </div>
                                    <TabPanel value="1"><VoipAts /></TabPanel>
                                    {/* <TabPanel value="2"><Extensionintigration /></TabPanel> */}
                                </TabContext>
                            </div>
                        </Grid>
                    </Grid>
                </Box >
            </>
        );
    }
}

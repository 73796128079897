import React, { Component } from 'react'
import add from '../../Images/add.svg';
import errorIcon from '../../Images/icons/Errorindicator.svg';
import styles from "../Initigration.module.css";
import { Box, Modal } from '@mui/material';


const Deletestyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 643,
    maxWidth: 643,
    minHeight: 477,
    bgcolor: 'background.paper',
    border: 'transparent',
    boxShadow: 24,
    p: 6,
    borderRadius: '15px',
    textAlign: 'center',
};
export default class NotAbleToConnect extends Component {
    render() {
        const { notAbletoConnect, handleClose, currentConnect, wanttoConnect } = this.props
        return (
            <Modal open={notAbletoConnect} aria-labelledby="modal-modal-title">
                <Box sx={Deletestyle}>
                    <img onClick={handleClose} style={{ position: 'relative', cursor: "pointer", left: '280px', top: '-35px' }} src={add} />
                    <div className={styles.ErrorMessageImageWrapper}>
                        <img src={errorIcon} style={{ width: "100%" }} />
                    </div>
                    <div>
                        <div className={styles.ModalHeadingError}>Not able to connect</div>
                        <div className={styles.ModalParaError}>if you want to connect <span>{wanttoConnect}</span>, you must <br /> first disconnect <span>{currentConnect}</span></div>
                    </div>
                    <div style={{ marginTop: "50px" }} className={styles.EditUSerBtns}>
                        <div onClick={handleClose} className={`${styles.NotabletoconnectBtn}`}>Ok</div>
                    </div>
                </Box>
            </Modal>
        )
    }
}

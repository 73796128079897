import React from "react";
import Auth from "../Auth";
import axios from "axios";
import knight from "./../Images/Olas-Chibi.jpg";
import { Card, Col, Container, Row, Button, Table } from "react-bootstrap";
import styles from "./Roles.module.css";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "antd/dist/antd.css";
import Cookies from "universal-cookie";
import vuesaxlinearmore from "./../Images/vuesaxlinearmore.svg";
import PopupModalClass from "./PopupModal";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { NavbarTop } from "../Components/Navbar";
import { NavbarLeft } from "../Components/Nav-Left/NavLeft";
import { Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import UniversalCss from "../Universal.module.css";
import { CheckBox } from "@mui/icons-material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export class Roles extends React.Component {
  constructor() {
    super();
    this.openHandler = this.openHandler.bind(this);
    this.onChangeQuestionNew = this.onChangeQuestionNew.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.popupCloseHandler = this.popupCloseHandler.bind(this);

    this.state = {
      roleQuestionsText: [],
      userLogged: "",
      show: false,
      alerting: false,
      rolesDesc: [],
      rolesID: [],
      url: "",
      show: false,
      allRoles: "",
      allRolesText: "",
      questions: [],
      questionkinds: [],
      addQuestion: [],
      allRolesDesc: "",
      allRolesLabel: "",
      showModal: 0,
    };
  }
  handleCheckboxChange = (optionId) => {
    const { addQuestion } = this.state;
    if (addQuestion.includes(optionId)) {
      this.setState({
        addQuestion: addQuestion.filter((id) => id !== optionId),
      });
    } else {
      this.setState({
        addQuestion: [...addQuestion, optionId],
      });
    }
  };
  onChangeQuestionNew(e) {
    this.setState({ addQuestion: e.target.value });
  }

  openHandler() {
    this.setState({ show: true });
    //console.log("button worjing")
  }
  popupCloseHandler() {
    this.setState({ show: false });
    //window.location.reload();
  }
  onSubmit(e) {
    e.preventDefault();
    console.log(this.state.addQuestion)
    var addListQuestions = this.state.addQuestion

    const replacedArray = this.state.addQuestion.map(() => 0);
    console.log(replacedArray)

    const cookies = new Cookies();
    var config = cookies.get("token");

    if (!cookies.get("token")) {
      //console.log("here wer are anfsfsdgd")
      config = localStorage.getItem("token");
      //console.log("token: ", config)
    }
    //console.log("testing:", this.state.allRoles)

    if (
      this.state.addQuestion == null ||
      this.state.addQuestion == [] ||
      this.state.addQuestion == undefined
    ) {
      this.state.addQuestion = 1;
      //console.log("FJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJ",this.state.questionNewLabel,  this.state.questionText, this.state.questionner, this.state.questionDescript )
    } else {
      axios({
        method: "POST",
        url:
          process.env.REACT_APP_LOGIN_URL + `/workorder/createrolequestionmap`,
        headers: {
          Authorization: "Token " + config,
        },
        data: {
          roleid: this.state.allRoles,
          priorityList: replacedArray,
          QuestionIDList: addListQuestions,
        },
      })
        //get the token from the backend and set the authentication to true
        //store the token in session storage
        .then((response) => {
          window.location.href = window.location.pathname;
          //authentication is set to true, allowing for access to protected pages
        })
        .catch((err) => {
          console.log(err);
          alert("something went wrong");
        });
    }
  }
  async componentDidMount() {
    const cookies = new Cookies();
    var config = cookies.get("token");

    if (!cookies.get("token")) {
      //console.log("here wer are anfsfsdgd")
      config = localStorage.getItem("token");
      //console.log("token: ", config)
    }
    const newUrl = window.location.pathname.split("/"[0]);
    //console.log("***********************", newUrl[2])
    this.setState({ url: newUrl[2] });
    //console.log("token: ", config)

    axios
      .get(
        process.env.REACT_APP_LOGIN_URL +
        `/workorder/rolequestions/${newUrl[2]}`,
        {
          headers: {
            Authorization: "Token " + config,
          },
        }
      )
      .then((response) => {
        console.log("roleq", response.data.message)
        var arrayquestionstext = [];
        var arrayid = [];
        var arraydesc = [];
        var arraycompany = [];
        for (let i = 0; i < response.data.message.length; i++) {
          arrayquestionstext.push(response.data.message);
          arrayid.push(response.data.message[i].id);
          arraydesc.push(response.data.message[i].questiondescription);
          arraycompany.push(response.data.message[i].questiondescription);
        }
        this.setState({ roleQuestionsText: response.data.message });
        //this.setState({ rolesDesc: arraydesc })
        //this.setState({ rolesID: arrayid })
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.status === 401) {
          // Clear cookies or perform any other action
          // Replace 'clearCookies()' with the actual function to clear cookies
          const cookies = new Cookies();
          cookies.remove('token', {
            path: '/',
            domain: 'squire-olas.com', // Replace with your desired domain
          }); //clear storage so no user is stored in the session
          cookies.remove("loggedin"); //clear storage so no user is stored in the session
          cookies.remove("loginfrommail"); //clear storage so no user is stored in the session
          cookies.remove("redirect"); //clear storage so no user is stored in the session
          cookies.remove("Isadmin"); //clear storage so no user is stored in the session
          window.location.href = '/'
        }
      });
    axios
      .get(process.env.REACT_APP_LOGIN_URL + "/login/users", {
        headers: {
          Authorization: "Token " + config,
        },
      })
      .then((response) => {
        // console.log(response.data[0].username)
        this.setState({ userLogged: response.data[0].username });
      })
      .catch((error) => console.log(error));
    axios
      .get(process.env.REACT_APP_LOGIN_URL + "/workorder/role", {
        headers: {
          Authorization: "Token " + config,
        },
      })
      .then((response) => {
        //this.setState({ userLogged: response.data[0].username })
        //console.log("here is the questions", response.data.message)
        var change = newUrl[2];
        //console.log("(((((((((((((((((((((hhhere)))))))))))))))))))))", response.data, change)
        for (let i = 0; i < response.data.message.length; i++) {
          //console.log(newUrl, response.data.message[0].id )
          if (response.data.message[i].id == change) {
            //console.log("its working", response.data.message[i])
            this.setState({ allRoles: response.data.message[i].id });
            this.setState({ allRolesText: response.data.message[i].title });
            this.setState({ allRolesLabel: response.data.message[i].label });
            this.setState({
              allRolesDesc: response.data.message[i].description,
            });
          }
        }
        //if (response.data.message)
      })
      .catch((error) => console.log(error));
    axios
      .get(process.env.REACT_APP_LOGIN_URL + "/questionbank/question", {
        headers: {
          Authorization: "Token " + config,
        },
      })
      .then((response) => {
        //this.setState({ userLogged: response.data[0].username })
        console.log("here is the questions", response.data.message)
        this.setState({ questions: response.data.message });
      })
      .catch((error) => console.log(error));

    axios
      .get(process.env.REACT_APP_LOGIN_URL + "/questionbank/questionkinddtls", {
        headers: {
          Authorization: "Token " + config,
        },
      })
      .then((response) => {
        //this.setState({ userLogged: response.data[0].username })
        //console.log("here is the questions", response.data.message)
        this.setState({ questionkinds: response.data.message });
      })
      .catch((error) => console.log(error));
  }
  getModal = (value) => {
    this.setState({ showModal: value });
  };
  hideModal = (value) => {
    this.setState({ showModal: 0 });
  };
  SettingsPage() {
    const id = document.getElementById("lang").value;
    if (id == "settings") {
      window.location.href = "/settings";
    }
    if (id == "logout") {
      alert("Logged out");
      const cookies = new Cookies();
      cookies.remove("token"); //clear storage so no user is stored in the session
      cookies.remove("loggedin"); //clear storage so no user is stored in the session
      localStorage.clear();
      //redirect user to the home page, which is protected, so will redirect to login page
      window.location.href = "/";
    }
  }
  render() {
    const {
      roleQuestionsText,
      userLogged,
      url,
      show,
      allRoles,
      allRolesText,
      questions,
      allRolesDesc,
      allRolesLabel,
      addQuestion,
      questionkinds,
    } = this.state;
    //console.log("here we are", questions)
    const numbers = [1, 2, 3, 4, 5];
    var roleid = 0

    for (let i = 0; i < roleQuestionsText.length; i++) {
      console.log(roleQuestionsText[i].roleid__companyid)
      roleid = roleQuestionsText[i].roleid__companyid
    }
    console.log(roleid)
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container className={UniversalCss.GridContainerWrapperHegih}>
          <Grid item md={1.7} xs={2} className={UniversalCss.LeftNavbarShadow}>
            <NavbarLeft />
          </Grid>
          <Grid
            item
            md={10.3}
            xs={10}
            className={UniversalCss.navbarAndRouteWrapper}
          >
            <NavbarTop />
            <Modal
              size="lg"
              className={styles.modalPopupLabel}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={show}
              onHide={this.popupCloseHandler}
            >
              <Modal.Header closeButton>
                <span className={styles.createlabel}>Add Question To Role</span>
              </Modal.Header>

              <Modal.Body>
                <div className={styles.groupLabel}>
                  <div className={styles.rectanglePopupLabel}>
                    {/*<InputLabel className={styles.RoleLabel}id="demo-simple-select-filled-label">
                      Role
    </InputLabel>*/}
                    <TextField
                      variant="outlined"
                      onChange={this.onChangeQuestion}
                      value={this.state.allRolesText}
                      disabled={true}
                    />
                    <br></br>

                    <FormControl sx={{ minWidth: "100%" }}>
                      {/*<InputLabel id="demo-simple-select-filled-label">
                        Questions
    </InputLabel>*/}
                      {questionkinds.map((element) => (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >

                            <Typography>{element[1]}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box className={styles.CheckBox} maxHeight={200} overflow="auto">
                              <FormControl component="fieldset">
                                <FormGroup>
                                  {questions.filter((question) => question.questionkindcode === element[0])
                                    .map((question) => (
                                      <FormControlLabel
                                        key={question.id}
                                        control={<Checkbox checked={addQuestion.includes(question.id)}
                                          onChange={() => this.handleCheckboxChange(question.id)} />}
                                        label={question.questiontext}
                                        value={question.id}
                                      />
                                    ))}
                                </FormGroup>
                              </FormControl>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      ))}



                      {/*Array.isArray(questions)
                          ? questions.map((element) => {
                              return (
                                <FormGroup  className={styles.FormGroup}  >
  <FormControlLabel   control={<Checkbox  value={element.id} />} label= {element.questiontext} />
 
</FormGroup>
                               
                              );
                            })
                          : null}*/}

                    </FormControl>

                    <div className={styles.flexcontainerLabel1}>
                      <button
                        className={styles.shapeLabel}
                        onClick={this.popupCloseHandler}
                      >
                        Cancel
                      </button>
                      <button
                        className={styles.shapeLabel1}
                        onClick={this.onSubmit}
                      >
                        Add Question To Role
                      </button>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            <div className={styles.rectangle8245}>
              <span className={styles.shape2Roles}>{allRolesText}</span>
              {roleid !== 0 && (
                <button
                  className={styles.shape1Roles}
                  onClick={() => this.openHandler(url.id)}
                  disabled={roleid === 0}
                >
                  Add Question To Role
                </button>
              )}




              {Array.isArray(roleQuestionsText)
                ? roleQuestionsText.map((number) => (
                  <div className={styles.group48096853}>
                    <div className={styles.flexcontainerNew}>
                      <div className={styles.flexcontainerNew1}>
                        <span className={styles.numNew01}>
                          #{number.questionbankid__id}
                        </span>
                        <hr className={styles.lineNew111} />
                      </div>

                      <button
                        className={styles.nutton}
                        onClick={() =>
                          this.getModal(number.questionbankid__id)
                        }
                        disabled={number.roleid__companyid == 0}
                      >
                        <img
                          className={styles.vuesaxlinearmoreNew}
                          src={vuesaxlinearmore}
                        />
                      </button>
                    </div>
                    <span className={styles.describeyourdesignNew}>
                      {number.questionbankid__questiontext}
                    </span>
                    <span className={styles.ipsumestodioatdiNew}>
                      {number.questionbankid__questiondescription}
                    </span>

                    <hr className={styles.line11New} />

                    {number.questionbankid__questionkindcode !== "ATS_TALISMAN" && (
                      <PopupModalClass
                        className={styles}
                        show={this.state.showModal === number.questionbankid__id}
                        onHide={() => this.hideModal(number.questionbankid__id)}
                        passid={number.questionbankid__id}
                        pass_name={number.questionbankid__questiontext}
                      />
                    )}
                  </div>
                ))
                : null}
            </div>
            {/*<div className={styles.flexcontainer44}>

                        <div className={styles.rectangle8245} >
                            <button className={styles.shape1Roles} >Add Question To Role</button>
                            {roleQuestionsText.map((number) =>
                            <div className={styles.flexcontainer6Roles} >
                                
                                <span className={styles.ipsumestodioatdi}>
                                {number.questiondescription}
                                </span>
                                <span className={styles.num01Roles}>#{number.id}</span>
                                <span className={styles.describeDesign}>
                                {number.questiontext}
                                </span>
                                
                                <img className={styles.vuesaxlinearmoreRoles}  src={vuesaxlinearmore} />
                            </div>
                             )}
                            
                        </div>
                            </div>*/}
          </Grid>
        </Grid>
      </Box>
    );
  }
}

import React, { Component } from 'react'
import styles from "./Initigration.module.css";
import bulhorn from "../Images/intigrationpage/bulhorn.svg";
import itris from "../Images/intigrationpage/itris.png";
import ZoomApp from "../Images/intigrationpage/zoom.png";
import Teamslogo from "../Images/intigrationpage/teams.png";
import knight from '../Images/intigrationpage/chromeExt.png';
import rc from '../Images/rc.png'
import NotAbleToConnect from './Component/NotAbleToConnect';
import ConFormationDisconnect from './Component/ConFormationDisconnect';
import IntigrateApps from './Component/IntigrateApps';
import Cookies from 'universal-cookie';
import axios from 'axios';
import Loader from '../Login/Loader/Loader';
import ErrorModal from '../Components/Error_Modle/ErrorModal';
import TalismanImage from '../Images/Talisman.png'

const IntigratioAts = [
    {
        img: bulhorn,
        name: "Bullhorn",
        added: false,
        connected: false,
        checked: true,
        Endmessage: "That's it! You have now initiated the integration process between Squire and Bullhorn. Squire's team will handle the setup, and you can look forward to the seamless connection between the two platforms.",
        InstallSteps: [
            {
                count: 1,
                text: "Create a Support Ticket in the Bullhorn Resource Center.",
                stepsHead: "To initiate the integration process between Squire and Bullhorn, you'll need to create a support ticket in the Bullhorn Resource Center. Follow the steps below:",
                step: [
                    <li>Access the Bullhorn Resource Center.</li>,
                    <li>Create a new support ticket titled: "Request for Bullhorn / Squire Integration - REST API Key".</li>,
                    <li>In the body of the ticket, clearly mention that you are trying to connect your Bullhorn account to Squire.</li>
                ],
            },
            {
                count: 2,
                text: " Provide Required Information to Bullhorn Support",
                stepsHead: "Bullhorn will require specific information from you to proceed with the integration. Follow the instructions below:",
                step: [
                    <li>In the support ticket, provide the following details to Bullhorn Support:
                        <ul className={styles.subUlIntigrationpage}>
                            <li><span>Redirect URLs: </span> <a href='https://www.squire-olas.com/home' rel="noreferrer" target='_blank'>https://www.squire-olas.com/home</a></li>
                            <li><span>Terms of Service: </span>  <a href='https://squireapp.io/terms/' rel="noreferrer" target='_blank'>https://squireapp.io/terms/</a></li>
                        </ul>
                    </li>,
                    <li>Submit the support ticket and wait for Bullhorn Support to respond.</li>,
                ],
            },
            {
                count: 3,
                text: "Receive Integration Details from Bullhorn Support",
                stepsHead: "Within 2-3 business days, Bullhorn Support will share the necessary details with you to proceed with the integration. They will provide you with the required API key and any additional information needed.",
            },
            {
                count: 4,
                text: "Receive Integration Details from Bullhorn Support",
                stepsHead: "Once you receive the integration details from Bullhorn Support, follow the steps below to input them in Squire:",
                step: [
                    <li>Input the provided API key and any other relevant details in the designated fields provided by Squire.</li>,
                    <li>Make sure to enter the information accurately and as instructed by Bullhorn Support.</li>,
                ],
            },
            {
                count: 5,
                text: "Integration Setup by Squire",
                stepsHead: "After inputting the integration details in Squire, Squire's team will take care of setting up, maintaining, and supporting the connection between Squire and Bullhorn on your behalf. They will ensure that the integration is properly configured. ",
            },

        ]

    },
    {
        img: itris,
        name: "itris",
        added: false,
        connected: false,
        checked: true,
        Endmessage: "That's it! You have now successfully integrated Squire with itris. Squire will seamlessly update your itris records based on the conversations you have with candidates and clients.",
        InstallSteps: [
            {
                count: 1,
                text: "Enter Your itris Credentials",
                stepsHead: "In order to integrate Squire with itris, you'll need to provide your itris credentials. Follow the steps below:",
                step: [
                    <li>Enter your itris credentials in the designated fields below. You will need to contact your itris representative to get the URL and Port details.
                        <ul className={styles.subUlIntigrationpage}>
                            <li>URL</li>
                            <li>Port</li>
                            <li>Username</li>
                            <li>Password</li>
                        </ul>
                    </li>,
                    <li>Make sure you provide accurate and valid credentials for your itris account.</li>,
                ],
            },
            {
                count: 2,
                text: " Connect Squire with itris",
                stepsHead: `After entering your itris credentials, click on the "Connect" button. Squire will then attempt to establish a connection with your itris account. Follow the instructions below:`,
                step: [
                    <li>Click the "Connect" button to initiate the connection process.</li>,
                    <li>Wait for Squire to establish a connection with your itris account.</li>,
                ],
            },
            {
                count: 3,
                text: " Verify the Connection",
                stepsHead: "If the connection is successful, you will see a message indicating the successful integration between Squire and itris.",
                step: [
                    <span className={styles.ulspanForSucces}>If the connection is unsuccessful:</span>,
                    <li>Double-check the credentials you entered to ensure they are correct.</li>,
                    <li>Retry the connection process by clicking the "Connect" button again.</li>,
                    <li>If the problem persists, please raise a support ticket in the Squire support center for further assistance.</li>,
                ],
            },


        ]
    },

    {
        img: TalismanImage,
        name: "Talisman",
        added: false,
        connected: false,
        checked: true,
        Endmessage: "That's it! You have now initiated the integration process between Talisman and Squire. ",
        InstallSteps: [
            {
                count: 1,
                text: "Enter the Username and Password that have been provided from Squire",
                stepsHead: "",

            },
            {
                count: 2,
                text: "Enter your consultant code",
                stepsHead: "",
            },
        ]
    },
]

const Intigratiovoid = [
    {
        img: ZoomApp,
        name: "Zoom",
        added: false,
        connected: false,
        checked: true,
        Endmessage: "That's it! You have now successfully connected Squire to Zoom. Whether you chose the Squire Chrome Extension or the Zoom App, you can now capture and process your Zoom calls effortlessly.",
        InstallSteps: [
            {
                count: 1,
                text: "Choose Your Method",
                stepsHead: "Squire offers two methods to connect with Zoom. You can either use the Squire Chrome Extension or install the Squire Zoom App. Choose the option that suits you best.",
            },
            {
                count: 2,
                text: "Squire Chrome Extension",
                stepsHead: `If you prefer to use the Squire Chrome Extension, it allows you to capture Zoom calls that occur in your browser. Exit out of this pop-up and navigate to the Chrome Extension card instead.`,
            },
            {
                count: 3,
                text: " Installing the Squire Zoom App",
                step: [
                    <li>Click the Zoom button below.</li>,
                    <li>Enter your login credentials</li>,
                    <li>Wait for the installation process to complete.</li>
                ],
            },
            {
                count: 4,
                text: " Verify Connection",
                stepsHead: `Once the installation is successful, Squire will display a message confirming that you have successfully connected your Zoom and Squire applications.`,
                step: [
                    <span className={styles.ulspanForSucces}>If you encounter any issues during the installation:</span>,
                    <li>Try the installation process again.</li>,
                    <li>If the problem persists after multiple attempts, please open a ticket in the Squire support center for further assistance.</li>
                ],
            },
        ]
    }
    ,
    /*{
        img: Teamslogo,
        name: "Teams",
        added: false,
        connected: false,
        checked: true,
        Endmessage: "That's it! You have now successfully connected Squire to MS Teams. Whether you chose the Squire chrome extension or the Teams app, you can now capture and process calls with ease.",
        InstallSteps: [
            {
                count: 1,
                text: "Choose Your Method",
                stepsHead: "You have two options for connecting Squire to MS Teams. You can either use the Squire chrome extension or install the Squire Teams app.",
            },
            {
                count: 2,
                text: "Squire Chrome Extension",
                stepsHead: `If you prefer to use the Squire chrome extension, it allows you to capture Teams calls that happen in your browser. Exit out of this pop-up and navigate to the Chrome Extension card instead.`,
            },
            {
                count: 3,
                text: "Squire Teams App",
                stepsHead: "To seamlessly connect Squire and Teams, automatically capture calls, and send recordings to Squire for processing, you'll need to install the Squire Teams app. Follow the steps below:",
            }, {
                count: 4,
                text: "Installing the Squire Teams App",
                step: [
                    <li>Click the Teams button below.</li>,
                    <li>Follow the instructions on the screen to install the app.</li>,
                    <li>Wait for the installation process to complete.</li>,
                ],
            },
            {
                count: 5,
                text: " Verify Connection",
                stepsHead: `Once the installation is successful, Squire will display a message confirming that you have successfully connected your Teams and Squire applications.`,
                step: [
                    <span className={styles.ulspanForSucces}>If you encounter any issues during the installation:</span>,
                    <li>Try the installation process again.</li>,
                    <li>If the problem persists after multiple attempts, please open a ticket in the Squire support center for further assistance.</li>
                ],
            },


        ]
    },*/
    {
        img: knight,
        name: "Chrome Extension",
        added: false,
        connected: false,
        checked: true,
        Endmessage: "That's it! You have now successfully installed and pinned the Squire Chrome Extension. You can access the extension by clicking on its icon in the browser's navigation bar whenever you need to use it. Enjoy using Squire seamlessly within your Chrome browser!",
        InstallSteps: [
            {
                count: 1,
                text: "Access the Chrome Web Store",
                stepsHead: "Open your Google Chrome browser and navigate to the Chrome Web Store.",
            },
            {
                count: 2,
                text: "Find the Squire Extension",
                stepsHead: `In the search bar of the Chrome Web Store, type "Squire" and press Enter. Look for the official Squire Chrome Extension.`,
            },
            {
                count: 3,
                text: "Install the Squire Extension",
                stepsHead: `Click on the Squire Extension listing to access its details page. Click the "Add to Chrome" button to initiate the installation.`,
            }, {
                count: 4,
                text: "Allow Required Permissions",
                stepsHead: `A pop-up window will appear, requesting your permission to add the Squire Extension to your Chrome browser. Click the "Add extension" button to grant the necessary permissions.`,
            },
            {
                count: 5,
                text: "Pin the Squire Extension to the Browser Nav Bar",
                stepsHead: `Once the installation is complete, you can choose to pin the Squire Extension to your browser's navigation bar for easy access. Follow the steps below:`,
                step: [
                    <li>Click on the puzzle icon (Extensions menu) located at the top right corner of your Chrome browser.</li>,
                    <li>Locate the Squire Extension in the list of installed extensions.</li>,
                    <li>Right-click on the Squire Extension icon and select the "Pin" option.</li>
                ],
            },


        ]
    },
    {
        img: rc,
        name: "RingCentral",
        added: false,
        connected: false,
        checked: true,
        Endmessage: "That's it! You have now successfully integrated Squire with Ring Central. You can operate as usual within RingCentral and watch Squire capture and summarise those important conversations",
        InstallSteps: [
            {
                count: 1,
                text: "Click the Connect button below",
                stepsHead: "Start the integration process by clicking the connect button below",
            },
            {
                count: 2,
                text: "Enter your RingCentral credentials",
                stepsHead: `In the new page that opens, enter your RingCentral credentials`,
            },
            {
                count: 3,
                text: "Provide Squire with access to your RingCentral account",
                stepsHead: `Squire needs access to your RingCentral account to work properly. Click Authorize to proceed.`,
            }, {
                count: 4,
                text: "Success, proceed as usual",
                stepsHead: `You've now successfully integrated Squire with your RingCentral account. Continue to operate as usual and watch Squire work`,
            },



        ]
    }


]

export default class VoipAts extends Component {
    constructor() {
        super();
        this.handleClose = this.handleClose.bind(this);
        this.DisconnectApp = this.DisconnectApp.bind(this);
        this.intigration = this.intigration.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handleIds = this.handleIds.bind(this)
        this.handlePassword = this.handlePassword.bind(this);
        this.ConnectVoip = this.ConnectVoip.bind(this);
        this.submitBullhorn = this.submitBullhorn.bind(this);
        this.Startintigration = this.Startintigration.bind(this);
        this.handleItrisUrl = this.handleItrisUrl.bind(this);
        this.handleItrisPort = this.handleItrisPort.bind(this);
        this.handleBullhornApiKey = this.handleBullhornApiKey.bind(this);
        this.voipInitgration = this.voipInitgration.bind(this);
        this.intigrationappInfo = this.intigrationappInfo.bind(this);

        this.state = {
            notAbletoConnect: false,
            confirmDisconnectConnect: false,
            IntigrateAppsToOlas: false,
            email: null,
            ids: null,
            password: null,
            AppImage: null,
            AppName: null,
            AppInstallSteps: [],
            ATSData: IntigratioAts,
            ItrisUrl: null,
            ItrisPort: null,
            BullhornApiKey: null,
            AppEndmessage: null,
            // ats Connection
            AtsConnected: null,
            IsBulhornConnected: null,
            IsItrisConnected: null,
            IsZoomConnected: null,
            IsTeamsConnected: null,
            IsExtensionConnected: null,
            Loader: false,
            ismodalopen: false,
            err: null,
            errorMessage: null,
            AtsConnected2: null,
        }
    }
    componentDidMount() {
        this.intigrationappInfo()
    }
    intigrationappInfo() {
        const cookies = new Cookies();
        var config = cookies.get("token")

        if (!cookies.get("token")) {
            config = localStorage.getItem("token")
        }

        var data = {
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL + '/integration_ats/get_status',
            headers: {
                'Authorization': 'Token ' + config
            }
        };

        axios(data)
            .then((response) => {
                console.log(response.data.message);
                if (response.data.message === "Itris") {
                    this.setState({ AtsConnected: "itris" })
                }
                if (response.data.message === "Talisman") {
                    this.setState({ AtsConnected: "Talisman" })
                }
                if (response.data.message === "Bullhorn") {
                    this.setState({ AtsConnected: "Bullhorn" })
                }
            })
            .catch((error) => {
                console.log(error);
                if (error.response && error.response.status === 401) {
                  // Clear cookies or perform any other action
                  // Replace 'clearCookies()' with the actual function to clear cookies
                  const cookies = new Cookies();
                  cookies.remove('token', {
                            path: '/',
                            domain: 'squire-olas.com', // Replace with your desired domain
                          }); //clear storage so no user is stored in the session
                  cookies.remove("loggedin"); //clear storage so no user is stored in the session
                  cookies.remove("loginfrommail"); //clear storage so no user is stored in the session
                  cookies.remove("redirect"); //clear storage so no user is stored in the session
                  cookies.remove("Isadmin"); //clear storage so no user is stored in the session
                  window.location.href='/'
                }
              });

            var data = {
                method: 'get',
                url: process.env.REACT_APP_BACKEND_URL + '/integration_ats/get_status_voip',
                headers: {
                    'Authorization': 'Token ' + config
                }
            };
    
            axios(data)
                .then((response) => {
                    console.log(response.data.message);
                    if (response.data.message === "Ring Central") {
                        this.setState({ AtsConnected2: "RingCentral" })
                    }
                    if (response.data.message === "Zoom") {
                        this.setState({ AtsConnected2: "Zoom" })
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

    }
    handleClose() {
        this.setState({ notAbletoConnect: false })
        this.setState({ confirmDisconnectConnect: false })
        this.setState({ IntigrateAppsToOlas: false })
        this.setState({
            ismodalopen: false,
        });
    }
    DisconnectApp(e) {
        e.preventDefault()

        console.log(this.state.AppName);

        const cookies = new Cookies();
        var config = cookies.get("token")

        if (!cookies.get("token")) {
            config = localStorage.getItem("token")
        }
        if (this.state.AppName === "itris") {

            var data = {
                method: 'post',
                url: process.env.REACT_APP_BACKEND_URL + '/integration_ats/delete_credentials',
                headers: {
                    'Authorization': 'Token ' + config
                }
            };

            axios(data)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    this.setState({ AtsConnected: null })
                    this.setState({ confirmDisconnectConnect: false })
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        if (this.state.AppName === "Talisman") {
            console.log("here")

            var data = {
                method: 'post',
                url: process.env.REACT_APP_BACKEND_URL + '/integration_ats/delete_talisman',
                headers: {
                    'Authorization': 'Token ' + config
                }
            };

            axios(data)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    this.setState({ AtsConnected: null })
                    this.setState({ confirmDisconnectConnect: false })
                })
                .catch((error) => {
                    console.log(error);
                });

                
        }
        if (this.state.AppName === "Bullhorn") {
            console.log("here")

            var data = {
                method: 'post',
                url: process.env.REACT_APP_BACKEND_URL + '/integration_ats/delete_bullhorn',
                //url: 'http://localhost:8000/integration_ats/delete_bullhorn',
                headers: {
                    'Authorization': 'Token ' + config
                }
            };

            axios(data)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    this.setState({ AtsConnected: null })
                    this.setState({ confirmDisconnectConnect: false })
                })
                .catch((error) => {
                    console.log(error);
                });

                
        }

    }
    intigration() {
        this.setState({ IntigrateAppsToOlas: false })
    }
    handleEmail(e) {
        this.setState({ email: e.target.value })
    }
    handleIds(e) {
        this.setState({ ids: e.target.value })
    }
    handlePassword(e) {
        this.setState({ password: e.target.value })
    }
    handleItrisUrl(e) {
        this.setState({ ItrisUrl: e.target.value })
    }
    handleItrisPort(e) {
        this.setState({ ItrisPort: e.target.value })
    }
    handleBullhornApiKey(e) {
        this.setState({ BullhornApiKey: e.target.value })
    }

    ConnectVoip(e) {
        e.preventDefault()
        console.log(this.state.AppName);
        console.log(this.state.email);
        console.log(this.state.password);
        console.log(this.state.ItrisPort);
        console.log(this.state.ItrisUrl);
        console.log(this.state.BullhornApiKey);
        if (this.state.AppName === "Bullhorn") {
            this.submitBullhorn(e)

        }
        if (this.state.AppName === "itris") {
            this.submitItris(e)
            console.log("itris");
        }
        if (this.state.AppName === "Talisman") {
            this.submitTalisman(e)
            console.log("talisman");
        }
    }

    submitItris(e) {
        e.preventDefault()
        this.setState({ Loader: true })
        console.log("hello");
        const cookies = new Cookies();
        var token = cookies.get("token")

        if (!cookies.get("token")) {
            token = localStorage.getItem("token")
        }

        var data = JSON.stringify({
            url: this.state.ItrisUrl,
            port: this.state.ItrisPort,
            username: this.state.email,
            password: this.state.password,
        });

        var config = {
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/integration_ats/post_credentials',
            headers: {
                'Authorization': 'Token ' + token,
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(config)
            .then(response => {
                console.log(JSON.stringify(response.data));
                this.setState({ Loader: false })
                this.setState({ IntigrateAppsToOlas: false })
                this.setState({ AtsConnected: this.state.AppName })
            })
            .catch(error => {
                console.log(error);
                this.setState({ ismodalopen: true });
                let errormsg = JSON.parse(error.response.request.response);
                console.log(error)
                this.setState({ err: errormsg.message.message });
                this.setState({ Loader: false })
            });

    }
    submitTalisman(e) {
        e.preventDefault()
        this.setState({ Loader: true })
        console.log("hello");
        const cookies = new Cookies();
        var token = cookies.get("token")

        if (!cookies.get("token")) {
            token = localStorage.getItem("token")
        }

        var data = JSON.stringify({
            username: this.state.email,
            password: this.state.password,
            ids: this.state.ids
        });

        var config = {
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/integration_ats/post_talisman_creds',
            headers: {
                'Authorization': 'Token ' + token,
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(config)
            .then(response => {
                console.log(JSON.stringify(response.data));
                this.setState({ Loader: false })
                this.setState({ IntigrateAppsToOlas: false })
                this.setState({ AtsConnected: this.state.AppName })
            })
            .catch(error => {
                console.log(error);
                this.setState({ ismodalopen: true });
                let errormsg = JSON.parse(error.response.request.response);
                console.log(error)
                this.setState({ err: errormsg.message.message });
                this.setState({ Loader: false })
            });

    }
    Startintigration(e, name, img, InstallSteps, Endmessage) {
        this.setState({ AppImage: img })
        this.setState({ AppName: name })
        this.setState({ AppEndmessage: Endmessage })
        this.setState({ AppInstallSteps: InstallSteps })
        if (this.state.AtsConnected === name) {
            this.setState({ confirmDisconnectConnect: true })
        } else if (this.state.AtsConnected !== name) {
            if (this.state.AtsConnected === null) {
                this.setState({ IntigrateAppsToOlas: true })
            } else if (this.state.AtsConnected !== name) {
                this.setState({ notAbletoConnect: true })
            }
        }
    }
    voipInitgration(e, name, img, InstallSteps, Endmessage) {
        this.setState({ AppImage: img })
        this.setState({ AppName: name })
        this.setState({ AppEndmessage: Endmessage })
        this.setState({ AppInstallSteps: InstallSteps })
        this.setState({ IntigrateAppsToOlas: true })
    }
    submitBullhorn(e) {
        e.preventDefault()
        
        const cookies = new Cookies();
        var config = cookies.get("token")
        console.log(this.state.BullhornApiKey, "username")
        if (!cookies.get("token")) {
            config = localStorage.getItem("token")
        }
        axios({
            method: "POST",
            url: process.env.REACT_APP_BACKEND_URL + '/integration_ats/login',
            //url: 'http://localhost:8000/integration_ats/login',
            headers: {
                'Authorization': 'Token ' + config
            },
            data: {
                username: this.state.email,
                password: this.state.password,
            }
        })
            .then(
                response => {
                    console.log(response.data);
                    this.setState({ Loader: false })
        this.setState({ AtsConnected: this.state.AppName })
        this.setState({ IntigrateAppsToOlas: false })
                    
                    sessionStorage.setItem('BhRestToken', response.data.message)
                    sessionStorage.setItem('restUrl', response.data.restUrl)

                    //window.location.reload()
                }
            )
            .catch((err) => {
                console.log(err)
                this.setState({ Loader: false })
                // this.openTranscribeFaultAlert()
            });
    }
    render() {
        return (
            <>
                {this.state.Loader === true ? <Loader /> : null}
                {/* IntigrateApps  */}
                <IntigrateApps AppEndmessage={this.state.AppEndmessage} AppInstallSteps={this.state.AppInstallSteps} AppName={this.state.AppName} handleIds={this.handleIds} handleEmail={this.handleEmail} ConnectVoip={this.ConnectVoip} handlePassword={this.handlePassword} AppImage={this.state.AppImage} IntigrateAppsToOlas={this.state.IntigrateAppsToOlas} handleClose={this.handleClose} handleItrisUrl={this.handleItrisUrl} handleItrisPort={this.handleItrisPort} handleBullhornApiKey={this.handleBullhornApiKey} />
                {/* Not Able to Connect PopUp */}
                <NotAbleToConnect currentConnect={this.state.AtsConnected} wanttoConnect={this.state.AppName} notAbletoConnect={this.state.notAbletoConnect} handleClose={this.handleClose} />
                {/* ConFormationDisconnect */}
                <ConFormationDisconnect DisconnectAppName={this.state.AppName} DisconnectApp={this.DisconnectApp} handleClose={this.handleClose} confirmDisconnectConnect={this.state.confirmDisconnectConnect} />
                <ErrorModal
                    errorMessage={this.state.err}
                    open={this.state.ismodalopen}
                    handleClose={this.handleClose}
                />
                <div className={styles.VoipAtsWrapper}>
                    {this.state.ATSData.map((item) => (
                        <div key={item.name} className={`${styles.IntigrationItem} ${item.name === this.state.AtsConnected ? styles.IntigrationItemActive : null}`}>
                            {item.name === this.state.AtsConnected ?
                                <span className={styles.IntigrationItemCheckbox}>
                                    {/* <Checkbox color="success" /> */}
                                    <input onClick={e => this.Startintigration(e, item.name, item.img, item.InstallSteps, item.Endmessage)} type="checkbox" value={item.IntigrationItemText} checked />
                                </span>
                                : null}

                            <div onClick={e => this.Startintigration(e, item.name, item.img, item.InstallSteps, item.Endmessage)} className={styles.IntigrationItemIcon}>
                                <img src={item.img} alt={item.name} className={styles.IntigrationItemIconImage} />
                            </div>
                            <div onClick={e => this.Startintigration(e, item.name, item.img, item.InstallSteps, item.Endmessage)} className={`HeadingsButtonFontOlas ${styles.IntigrationItemText}`}>
                                <span>{item.name}</span>
                                {item.name === this.state.AtsConnected ?
                                    <span className={styles.IntigrationItemConnected}>You’re Connected</span>
                                    : null}
                            </div>
                        </div>
                    ))}
                </div>
                <div>
                    <div className={`HeadingsButtonFontOlas ${styles.IntigrationItemHeading}`}>VOIP Integrations</div>
                    <div className={styles.VoipAtsWrapper}>
                        {Intigratiovoid.map((item) => (
                            <div key={item.name} className={`${styles.IntigrationItem} ${item.name === this.state.AtsConnected2 ? styles.IntigrationItemActive : null}`}>
                                {item.name === this.state.AtsConnected2 ?
                                <span className={styles.IntigrationItemCheckbox}>
                                        {/* <Checkbox color="success" /> */}
                                        <input type="checkbox" value={item.IntigrationItemText} checked  />
                                    </span>
                                    : null}

                                <div onClick={e => this.voipInitgration(e, item.name, item.img, item.InstallSteps, item.Endmessage)} className={styles.IntigrationItemIcon}>
                                    <img src={item.img} alt={item.name} className={styles.IntigrationItemIconImage} />
                                </div>
                                <div onClick={e => this.voipInitgration(e, item.name, item.img, item.InstallSteps, item.Endmessage)} className={`HeadingsButtonFontOlas ${styles.IntigrationItemText}`}>
                                    <span>{item.name}</span>
                                    {item.connected === true ?
                                        <span className={styles.IntigrationItemConnected}>You’re Connected</span>
                                        : null}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </>
        )
    }
}

import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Auth from '../Auth';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
// protected route function
const ProtectedRoute = ({ component: Component, ...rest }) => (
    //create a new route
    <Route
        {...rest}
        // find if the user is authenticated or not
        //if so allow the user to access the protected pages
        //otherwise redirect to the login page
        render={props =>
            (cookies.get("loggedin") == "true" || localStorage.getItem("loggedin") == "true") && cookies.get("redirect") >= 4 ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: "/"
                    }}
                />
            )
        }
    />
);
export default ProtectedRoute
import axios from 'axios';
import React, { Component } from 'react'
import Loader from '../Loader/Loader';
import { TextField } from "@mui/material";
import backgroundimg from "../../Images/backgrounds/login_background.svg";
import locklogo from '../..//Images/logo/lock.svg';
import olaslogoimg from "../../Images/logo/olaslogo.svg";
import styles from "../Login.module.css";
import ErrorModal from '../../Components/Error_Modle/ErrorModal';
import Authenticationleft from '../Authenticationleft';
import SuccessModal from '../../Components/Success_Modle/SuccessModal';
import Cookies from 'universal-cookie';
import { Redirect } from 'react-router';


export default class VerifyInviteUser extends Component {
    constructor(props) {
        super(props)
        this.onChangePass = this.onChangePass.bind(this);
        this.onChangeConfirmPass = this.onChangeConfirmPass.bind(this);
        this.handlepassUpdate = this.handlepassUpdate.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.state = {
            err: null,
            Message: null,
            ismodalopen: false,
            isSuccessmodalopen: false,
            loaderactive: false,
            Password: null,
            ConfirmPass: null,
            setpassWindow: false,
            passseted: false
        }
    }
    componentDidMount() {
        this.setState({ loaderactive: true })
        function getParameterByName(name, url = window.location.href) {
            name = name.replace(/[\[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2]);
        }
        const query = new URLSearchParams(this.props.location.search);

        var data = {
            email: getParameterByName('user'),
            code: getParameterByName('code'),
        };
        var config = {
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/company/verify-company-users',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then((response) => {
                console.log(response);
                this.setState({ loaderactive: false })
                this.setState({ setpassWindow: true })
            })
            .catch((err) => {
                console.log(err);
                this.setState({ loaderactive: false })
            });

    }
    onChangePass(e) {
        this.setState({ Password: e.target.value })
    }
    onChangeConfirmPass(e) {
        this.setState({ ConfirmPass: e.target.value })
    }
    handlepassUpdate(e) {
        e.preventDefault();
        this.setState({ loaderactive: true })
        if (this.state.Password.length >= 8) {

            if (this.state.Password == this.state.ConfirmPass) {
                this.setState({ loaderactive: true })
                function getParameterByName(name, url = window.location.href) {
                    name = name.replace(/[\[\]]/g, '\\$&');
                    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                        results = regex.exec(url);
                    if (!results) return null;
                    if (!results[2]) return '';
                    return decodeURIComponent(results[2]);
                }
                const query = new URLSearchParams(this.props.location.search);

                var data = {
                    email: getParameterByName('user'),
                    new: this.state.Password,
                    confirm: this.state.ConfirmPass
                };
                var config = {
                    method: 'post',
                    url: process.env.REACT_APP_BACKEND_URL + '/company/set-company-user-password',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                axios(config)
                    .then((response) => {
                        console.log(response);
                        this.setState({ loaderactive: false })
                        this.setState({ isSuccessmodalopen: true })
                        this.setState({ Message: response.data.message })
                        const cookies = new Cookies();
                        cookies.remove('token')//clear storage so no user is stored in the session
                        cookies.remove('loggedin')//clear storage so no user is stored in the session
                        cookies.remove('loginfrommail')//clear storage so no user is stored in the session
                        cookies.remove('redirect')//clear storage so no user is stored in the session
                        cookies.remove('Isadmin')//clear storage so no user is stored in the session
                        this.setState({ passseted: true })
                    })
                    .catch((err) => {
                        console.log(err);
                        this.setState({ loaderactive: false })
                    });

            } else {
                this.setState({ ismodalopen: true })
                this.setState({ err: "The passwords entered do not match." })
                this.setState({ loaderactive: false })
            }
        } else {
            this.setState({ ismodalopen: true })
            this.setState({ err: "Passwords needs to be at least 8 characters long" })
            this.setState({ loaderactive: false })
        }
    }
    handleClose() {
        this.setState({
            isSuccessmodalopen: false,
            ismodalopen: false,
        });
    }
    render() {
        const { loaderactive, setpassWindow, passseted } = this.state
        if (passseted) {
            return <Redirect to='/' />;
        }
        return (
            <>
                {loaderactive && <Loader />}
                {setpassWindow && <>
                    <div className={`${styles.loginApp}`}>
                        < Authenticationleft />
                        <ErrorModal errorMessage={this.state.err} open={this.state.ismodalopen} handleClose={this.handleClose} />
                        <SuccessModal Message={this.state.Message} open={this.state.isSuccessmodalopen} handleClose={this.handleClose} />
                        <div className={`${styles.split} ${styles.right} `}>
                            {/* Mobile View Header */}
                            <div
                                className={`${styles.headerResMode} ${styles.flexJccAic}`}
                                style={{ backgroundImage: `url(${backgroundimg})` }}
                            >
                                <img className={styles.olasheaderres} src={olaslogoimg} />
                            </div>
                            {/* right Side */}
                            <div className={` ${styles.mobileResHeigh}`}>
                                <div
                                    className={`${styles.flexJccAic} ${styles.verifymailWrapper}`}
                                >
                                    <div>
                                        {/* Mail Icon Div*/}
                                        <div className={styles.mailIcomContainer}>
                                            <div className={styles.mailIcomContainerDiv}>
                                                <img src={locklogo} className={styles.w100} />
                                            </div>
                                        </div>
                                        {/*  */}
                                        <h2
                                            className={`${styles.loginHeader} ${styles.profileHeader} ${styles.textCenter}`}
                                        >
                                            Create password
                                        </h2>
                                        <form onSubmit={this.handlepassUpdate} className={styles.NewPassForm}>
                                            <TextField label="Password" variant="outlined" type="password" className={`${styles.passField} ${styles.mb20p}`} required onChange={this.onChangePass} />
                                            <TextField label="Re-enter Password" variant="outlined" type="password" className={`${styles.passField} ${styles.mb20p}`} onChange={this.onChangeConfirmPass} required />
                                            <div className={`${styles.mt10p} ${styles.dflexjcc}`}>
                                                <button
                                                    type='submit'
                                                    className={styles.RsendMailBtn}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
            </>
        )
    }
}

import React, { Component } from "react";
import styles from "./Login.module.css";
import knight from "./../Images/KyloeNotetaker.png";
import backgroundimg from "./../Images/backgrounds/login_background.svg";
import AuthLeftImg from "./../Images/AuthLeftImg.png";
import AuthRightImg from "./../Images/AuthRightImg.png";


export default class Authenticationleft extends Component {
  render() {
    return (
      <div
        className={`${styles.split} ${styles.left} ${styles.positionRelative}`}
        style={{ backgroundImage: `url(${backgroundimg})` }}
      >
        <img className={styles.olasLogo} src={AuthLeftImg}></img>
        {/* <img className={styles.olasLogo} src={olaslogo}></img> */}
        <img className={styles.olasLogo2} src={AuthRightImg}></img>
        {/* <img className={styles.olasLogo2} src={olaslogo}></img> */}
        <div
          className={`${styles.positionRelative} ${styles.loginKnightLogoWrapper}`}
        >
          <div className={styles.SquireLogImage}>
            <img className={styles.SquireLogo} src={knight}></img>
          </div>
          <div
            className={`${styles.carousalWrapperDiv} ${styles.positionAbsolute}`}
          >
           
          </div>
        </div>
      </div>
    );
  }
}

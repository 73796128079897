import styles from './Login.module.css';
import React, { Component } from 'react'
import Authenticationleft from './Authenticationleft'
import backgroundimg from './../Images/backgrounds/login_background.svg';
import locklogo from './../Images/logo/lock.svg';
import { TextField } from '@mui/material';
import olaslogoimg from './../Images/logo/olaslogo.svg';
import axios from "axios";
import ErrorModal from '../Components/Error_Modle/ErrorModal';



export default class ResetPass extends Component {
    constructor() {
        super();

        this.FormSubbmit = this.FormSubbmit.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handleClose = this.handleClose.bind(this);


        this.state = {
            err: null,
            email: null,
            verifyEmailPage: false,
            ismodalopen: false
        }

    }
    FormSubbmit(e) {
        this.setState({ err: null })

        e.preventDefault()
        console.log("form subbmited");

        var data = JSON.stringify({
            email: this.state.email,
        });

        axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + `/company/send-reset-password`,
            data: data,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                console.log(response)
                this.setState({ verifyEmailPage: true })
            })
            .catch(error => {
                this.setState({ ismodalopen: true })
                console.log(error.response.data.message)
                if (error.response.data.message.message) {
                    this.setState({ err: error.response.data.message.message })
                }
                else {
                    this.setState({ err: error.response.data.message })
                }
            })
    }
    handleEmail(e) {
        this.setState({ email: e.target.value })
        console.log(e.target.value);
    }
    handleClose() {
        this.setState({
            ismodalopen: false,
        });
    }
    render() {
        const { ismodalopen, err } = this.state
        return (
            <div className={`${styles.loginApp}`}>
                < Authenticationleft />
                <ErrorModal errorMessage={err} open={this.state.ismodalopen} handleClose={this.handleClose} />
                <div className={`${styles.split} ${styles.right} `}>
                    {/* Mobile View Header */}
                    <div
                        className={`${styles.headerResMode} ${styles.flexJccAic}`}
                        style={{ backgroundImage: `url(${backgroundimg})` }}
                    >
                        <img className={styles.olasheaderres} src={olaslogoimg} />
                    </div>
                    {/* right Side */}
                    <div className={` ${styles.mobileResHeigh}`}>
                        <div
                            className={`${styles.flexJccAic} ${styles.verifymailWrapper}`}
                        >
                            {this.state.verifyEmailPage == false ?
                                <div>
                                    {/* Mail Icon Div*/}
                                    <div className={styles.mailIcomContainer}>
                                        <div className={styles.mailIcomContainerDiv}>
                                            <img src={locklogo} className={styles.w100} />
                                        </div>
                                    </div>
                                    {/*  */}
                                    <h2
                                        className={`HeadingsButtonFontOlas ${styles.loginHeader} ${styles.profileHeader} ${styles.textCenter}`}
                                    >
                                        Reset Your Password
                                    </h2>
                                    <p className={`${styles.ResetPasswordPara}`}>
                                        Having issues signing in? Enter your email to reset your password.
                                    </p>
                                    <form onSubmit={this.FormSubbmit}>

                                        <TextField
                                            className={styles.ResetPassInput}
                                            label='Email'
                                            variant='outlined'
                                            required
                                            type='email'
                                            onChange={this.handleEmail}
                                        />
                                        {/* {this.state.err && <p style={{ textAlign: "center", marginTop: "-25px", marginBottom: "5px" }} className={styles.ErrorMessageParaSignUp}>{this.state.err}</p>} */}
                                        <div className={styles.dflexjcc}>
                                            <button
                                                type='submit'
                                                className={`HeadingsButtonFontOlas ${styles.RsendMailBtn}`}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                :
                                <div>
                                    {/* Mail Icon Div*/}
                                    <div className={styles.mailIcomContainer}>
                                        <div className={styles.mailIcomContainerDiv}>
                                            <img src={locklogo} className={styles.w100} />
                                        </div>
                                    </div>
                                    {/*  */}
                                    <h2
                                        className={`HeadingsButtonFontOlas ${styles.loginHeader} ${styles.profileHeader} ${styles.textCenter}`}
                                    >
                                        Reset Your Password
                                    </h2>
                                    <p className={`${styles.ResetPasswordPara}`}>
                                        Check your email for instructions on how to reset your password.
                                    </p>

                                    <div className={styles.dflexjcc}>
                                        <a
                                            href='https://mail.google.com/mail/u/0/#inbox'
                                            className={`HeadingsButtonFontOlas ${styles.RsendMailBtn}`}
                                            target='_blank'
                                        >
                                            Check Your Inbox
                                        </a>
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

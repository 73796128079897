import { Box, Modal, TextField } from "@mui/material";
import React, { Component } from "react";
import add from "../../Images/add.svg";
import styles from "../Initigration.module.css";

const Deletestyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 643,
  maxWidth: 643,
  bgcolor: "background.paper",
  border: "transparent",
  boxShadow: 24,
  // p: 2,
  padding: "15px 15px 20px 33px",
  borderRadius: "15px",
};

export default class IntigrateApps extends Component {
  render() {
    const {
      IntigrateAppsToOlas,
      AppImage,
      handleClose,
      handleEmail,
      handleIds,
      handlePassword,
      ConnectVoip,
      AppName,
      AppInstallSteps,
      submitBullhorn,
      handleBullhornApiKey,
      handleItrisPort,
      handleItrisUrl,
      AppEndmessage,
    } = this.props;
    return (
      <Modal open={IntigrateAppsToOlas}>
        <Box className={styles.BoxWrapper} sx={Deletestyle}>
          <div className={styles.IntigrateModalNav}>
            <div className={styles.IntigrateModalCompanyInfo}>
              <div className={styles.IntigrateModalImgWrapper}>
                <img className={styles.IntigrateModalImg} src={AppImage} />
              </div>
              <span className={styles.IntigrateModalCompanyName}>
                {AppName}
              </span>
            </div>
            <div className={styles.IntigrateAppsToOlasCloseArrow}>
              <img
                onClick={handleClose}
                className={styles.IntigrateModalCrossIcon}
                src={add}
              />
            </div>
          </div>
          <div className={styles.IntigrateModalConnectInfo}>
            <div className={styles.IntigrateModalIntigrationInfoHeading}>
              How to integrate NoteTaker with {AppName}?
            </div>
            {/* <div className={styles.IntigrateModalIntigrationInfoSubHeading}>Follow the below step and connect ATS/CRM</div> */}
            <div className={styles.IntigrateModalCompanyInfoBr}></div>
            <div className={styles.IntigrateModalCompanyInstructionWrapper}>
              {/* mapThis */}
              {AppInstallSteps.map((item) => (
                <div
                  key={item.count}
                  className={styles.IntigrateModalCompanyinitgrationsteps}
                >
                  <div
                    key={item.count}
                    className={styles.IntigrateModalCompanyInstructionsDiv}
                  >
                    <div
                      className={styles.IntigrateModalCompanyInstructionsCount}
                    >
                      {item.count}
                    </div>
                    <div
                      className={styles.IntigrateModalCompanyInstructionsPara}
                    >
                      {item.text}
                    </div>
                  </div>
                  <div
                    className={
                      styles.IntigrateModalCompanyInstructionStepHeader
                    }
                  >
                    {item.stepsHead}
                  </div>
                  <ul
                    className={
                      styles.IntigrateModalCompanyInstructionStepsWrapperCount
                    }
                  >
                    {item.step}
                  </ul>
                </div>
              ))}
              <div className={styles.IntigrateModalCompanyInstructionLastMsg}>
                {AppEndmessage}
              </div>
              {/* mapThis */}
            </div>
          </div>
          <form onSubmit={ConnectVoip}>
  <div className={styles.IntigrateModalUserInformationWrapper}>
    {AppName === "itris" && (
      <>
        <div className={styles.IntigrateModalUserInformationInputs}>
          <TextField
            onChange={handleItrisUrl}
            label="URL"
            variant="outlined"
            type="text"
            required
            fullWidth
          />
        </div>
        <div className={styles.IntigrateModalUserInformationInputs}>
          <TextField
            onChange={handleItrisPort}
            label="Port"
            variant="outlined"
            type="text"
            required
            fullWidth
          />
        </div>
      </>
    )}

    {/* Add other conditions for different Apps */}
    {['Zoom'].includes(AppName) && (
      <div style={{ textAlign: "center" }}>
        {AppName === "Zoom" || AppName === "Teams" ? (
          <a href="https://zoom.squire-olas.com" target="_blank" rel="noopener noreferrer">
            <img src="https://marketplacecontent.zoom.us/zoom_marketplace/img/add_to_zoom.png" height="32" alt="Add to ZOOM" />
          </a>
        ) : (
          <a href="YOUR_RINGCENTRAL_LINK" target="_blank" rel="noopener noreferrer">
            {/* Insert RingCentral image */}
          </a>
        )}
      </div>
    )}

    {/* Common input fields */}
    {['Talisman'].includes(AppName) && (
      <div className={styles.IntigrateModalUserInformationInputs}>
        <TextField
          onChange={handleIds}
          label="ID"
          variant="outlined"
          type="text"
          required
          fullWidth
        />
       
       
      </div>
    )}
     {['Talisman', 'Bullhorn', 'itris'].includes(AppName) && (
      <div className={styles.IntigrateModalUserInformationInputs}>
       
        <TextField
          onChange={handleEmail}
          label="Username"
          variant="outlined"
          type="text"
          required
          fullWidth
        />
      </div>
    )}

    {['Talisman', 'Bullhorn', 'itris'].includes(AppName) && (
      <div className={styles.IntigrateModalUserInformationInputs}>
        <TextField
          onChange={handlePassword}
          label="Password"
          type="password"
          variant="outlined"
          required
          fullWidth
        />
      </div>
    )}
  </div>
  {/* Common bottom buttons */}
  <div className={styles.IntigrateModalBottomBtns}>
    <button onClick={handleClose} className={styles.IntigrateModalBottomBtnsCancel}>
      Cancel
    </button>
    {['RingCentral'].includes(AppName) && (
      <a
      href="https://ringcentraldev.squire-olas.com/auth/integrate"
      target="_blank"
      className={styles.IntigrateModalBottomBtnsConnect}
    >
      Connect
    </a>
    )}
    {['Chrome Extension'].includes(AppName) && (
      <a
        href="https://chrome.google.com/webstore/category/extensions?hl=en"
        target="_blank"
        className={styles.IntigrateModalBottomBtnsConnect}
      >
        Connect
      </a>
    )}
    {['Talisman', 'Bullhorn', 'Zoom', 'Teams', 'itris'].includes(AppName) && (
      <button type="submit" className={styles.IntigrateModalBottomBtnsConnect}>
        Connect
      </button>
    )}
  </div>
</form>
        </Box>
      </Modal>
    );
  }
}

import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/poppins";
import "@fontsource/open-sans";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Login } from "./Login/Login";
import { NewPass } from "./Documentation";
import { Dashboard } from "./Dashboard/Dashbord";
// import { Reset } from './reset';
import { Calls } from "./Calls/Calls";
import { EmailReset } from "./email-reset";
import { Question } from "./Question/Question";
import { Roles } from "./Roles/Roles";
import { ContactUs } from "./Support/Support";
import { Single } from "./SingleFile/Single_file";
import { Bullhorn } from "./Integrations/Bullhorn";
import { Itris } from "./Integrations/Itris";
import { Settings } from "./Settings/Settings";
import { Profile } from "./Login/Profile";
import { UseSquire } from "./Login/UseSquire";
import { SignUp } from "./Login/SignUp";
import ResetPass from "./Login/ResetPass";
import CreateNewPass from "./Login/CreateNewPass";
import Verifytoken from "./Login/verifytoken";
import UserManagement from "./UserFlow/UserManagement";
import { PlanSelection } from "./Login/PlanSelection";
import ProfileRoutes from "./Routes/ProfileRoutes";
import ProtectedRoute from "./Routes/ProtectedRoute";
import AdminRoutes from "./Routes/AdminRoutes";
import Integrations from "./Integrations/Integrations";
import VerifyInviteUser from "./Login/VerifyInviteUser.js/VerifyInviteUser";
import { Zoom } from "./Integrations/Zoom";
import PlanRoute from "./Routes/PlanRoute";
import Terms from "./TermsAndConditons/Terms";
import Privacy from "./TermsAndConditons/Privacy";
import UserReporting from "./UserFlow/UserReporting";

class App extends Component {
  render() {
    return (
      // router and switch for navigating the website
      <Router>
        <Switch>
          <Route path="/" component={Login} exact />
          <Route path="/verifyaccount" component={Verifytoken} />
          <Route path="/reset-password" component={ResetPass} exact />
          <Route path="/resetpassword" component={CreateNewPass} exact />
          <Route path="/userinvite" component={VerifyInviteUser} exact />
          <Route path="/terms-of-service" component={Terms} exact />
          <Route path="/privacy-policy" component={Privacy} exact />

          <PlanRoute path="/planselection" component={PlanSelection} exact />
          <AdminRoutes path="/user" component={UserManagement} exact />
          <AdminRoutes path="/reporting" component={UserReporting} exact />
          <Route path="/integrations" component={Integrations} exact />

          <ProfileRoutes path="/profile" component={Profile} exact />
          <ProfileRoutes path="/how-to-use" component={UseSquire} exact />

          <ProtectedRoute path="/home" component={Dashboard} exact />
          <ProtectedRoute path="/calls" component={Calls} exact />
          <ProtectedRoute path="/questionbank" component={Question} exact />
          <ProtectedRoute path="/settings" component={Settings} exact />

          <Route path="/documentation" component={NewPass} exact />
          <ProtectedRoute path="/email-reset" component={EmailReset} exact />

          {/* <ProtectedRoute path='/reset' component={Reset} exact /> */}
          <ProtectedRoute path="/support" component={ContactUs} exact />
          <ProtectedRoute path="/bullhorn" component={Bullhorn} exact />
          <ProtectedRoute path="/Zoom" component={Zoom} exact />
          <ProtectedRoute path="/itris" component={Itris} exact />

          <ProtectedRoute path="/questionbank/:id" component={Roles} />
          <ProtectedRoute path="/:id" component={Single} />
        </Switch>
      </Router>
    );
  }
}
export default App;

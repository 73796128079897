import React, { Component } from 'react'
import styles from "./user.module.css";
import { Card, Grid } from '@mui/material';

export default class ManageUserCharge extends Component {
    constructor() {
        super();
        this.OnManageAccount = this.OnManageAccount.bind(this);
    }
    OnManageAccount() {
        document.querySelector("#ManageAccount-ChargeBee_olas").click()
    }
    render() {
        return (
            <div>
                <div className={styles.billingPlansContainer}>
                    <Grid container spacing={1}>
                        <Grid item >
                            <Card className={styles.ManageSubscriptionCard}>
                                <div className={`HeadingsButtonFontOlas ${styles.ManageSubscriptionCardHeading}`}>Subscription Management </div>
                                <div className={styles.ManageSubscriptionCardPara}>Click the button below to open Subscription Management where you can change the number of licences on your plan, change the billing frequency or cancel your subscription. </div>
                                <button onClick={this.OnManageAccount} className={styles.ManageSubscriptionCardbutton}>Manage Subscription</button>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }
}

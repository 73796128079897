import React, { Component } from 'react'
import styles from "./Login.module.css";
import emaillogo from './../Images/logo/email.svg';
import backgroundimg from "./../Images/backgrounds/login_background.svg";
import olaslogoimg from "./../Images/logo/olaslogo.svg";
import Authenticationleft from "./Authenticationleft";
import ErrorModal from '../Components/Error_Modle/ErrorModal';
import axios from 'axios';
import SuccessModal from '../Components/Success_Modle/SuccessModal';



export default class VerifyEmail extends Component {
    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
        this.onResendActivation = this.onResendActivation.bind(this);
        this.state = {
            err: null,
            ismodalopen: false,
            isSuccessmodalopen: false,
            Message: null,
            minutes: 0,
            seconds: this.props.seconds,
        }

    }
    componentDidMount() {
        this.interval = setInterval(() => {
            const { seconds, minutes } = this.state;
            if (seconds > 0) {
                this.setState({ seconds: seconds - 1 });
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(this.interval);
                } else {
                    this.setState({
                        seconds: 59,
                        minutes: minutes - 1
                    });
                }
            }
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleClose() {
        this.setState({
            ismodalopen: false,
            isSuccessmodalopen: false,
            minutes: 2,
            seconds: 0,
        });
        this.componentDidMount()
    }
    handleCloseError() {
        this.setState({
            ismodalopen: false,
            isSuccessmodalopen: false,
            minutes: 0,
            seconds: 0,
        });
        this.componentDidMount()
    }


    onResendActivation = () => {
        // e.preventDefault();
        console.log(this.props.Email);
        var data = JSON.stringify({
            email: this.props.Email,
        });
        axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + `/login/resend-activation-link`,
            data: data,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                this.setState({ isSuccessmodalopen: true })
                console.log(response);
            })
            .catch(error => {
                this.setState({ ismodalopen: true })
                console.log(error);
            });

    }
    render() {
        const { minutes, seconds } = this.state;
        return (
            <>
                <div className={`${styles.loginApp}`}>
                    <Authenticationleft />
                    <ErrorModal errorMessage={this.state.err} open={this.state.ismodalopen} handleClose={this.handleCloseError} />
                    <SuccessModal Message={this.state.Message} open={this.state.isSuccessmodalopen} handleClose={this.handleClose} />

                    <div className={`${styles.split} ${styles.right} `}>
                        {/* Mobile View Header */}
                        <div className={`${styles.headerResMode} ${styles.flexJccAic}`} style={{ backgroundImage: `url(${backgroundimg})` }}>
                            <img className={styles.olasheaderres} src={olaslogoimg} />
                        </div>
                        {/* right Side */}
                        <div className={`${styles.h100v} ${styles.flexJccAic} ${styles.mobileResHeigh}`}>
                            <div
                                className={`${styles.flexJccAic} ${styles.verifymailWrapper}`}
                            >
                                <div>
                                    {/* Mail Icon Div*/}
                                    <div className={styles.mailIcomContainer}>
                                        <div className={styles.mailIcomContainerDiv}>
                                            <img src={emaillogo} className={styles.w100} />
                                        </div>
                                    </div>
                                    {/*  */}
                                    <h2
                                        className={`${styles.loginHeader} ${styles.profileHeader} ${styles.textCenter}`}
                                    >
                                        Verify Your Email
                                    </h2>
                                    <p className={`${styles.verifyemialpara}`}>
                                        To ensure your NoteTaker account is properly connected and
                                        secure, please verify your account by clicking the link we
                                        sent to your email.
                                        <br />
                                        <br />
                                        If you did not receive the email, please check your inbox
                                        and spam filters or click the following button to get a new
                                        email.
                                    </p>
                                    <div className={styles.dflexjcc}>

                                        <button
                                            disabled={seconds > 0 || minutes > 0}
                                            style={{
                                                background: seconds > 0 || minutes > 0 ? "d8045c50" : "#d8045c"
                                            }}
                                            className={styles.RsendMailBtn}
                                            onClick={this.onResendActivation}
                                        >
                                            Click here to re-send verification email
                                        </button>

                                    </div>
                                    {seconds > 0 || minutes > 0 ? (
                                        <p style={{ textAlign: "center", marginTop: "10px" }}>
                                            Re-send verification email in{" "}
                                            {minutes < 10 ? `0${minutes}` : minutes}:
                                            {seconds < 10 ? `0${seconds}` : seconds}
                                        </p>
                                    ) : (
                                        null
                                    )}
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            </>
        )
    }
}

/* global chrome */
import React from 'react';
import Auth from '../Auth';
import styles from './Login.module.css';
import backgroundimg from './../Images/backgrounds/login_background.svg';
import reviewer from './../Images/v394_27835.png';
import Cookies from 'universal-cookie';
import playcircle from './../Images/icons/play-circle.svg';
import playcircleWhite from './../Images/icons/play-circleWhite.svg';
import olaslogoimg from './../Images/logo/olaslogo.svg';
import Modal from '@mui/material/Modal';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Authenticationleft from './Authenticationleft';
import { sendTokenToChromeExtension } from "./sendTokenToExt";
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 256,
        borderradius: 5,
        color: "#33475B",
        backgroundColor: "#D9D9D9",
        fontsize: 10,
        fontfamily: 'Poppins',
        fontweight: 500,
    },
});

const tooltipText = `Install the NoteTaker Chrome Extension to proceed`


//Create class
export class UseSquire extends React.Component {
    constructor() {
        super();
        //binding the data so it can be called
        this.onChangeExtensionInstalled = this.onChangeExtensionInstalled.bind(this);
        this.onChangeExtensionVideo = this.onChangeExtensionVideo.bind(this);
        this.onChangeIntigrateETS = this.onChangeIntigrateETS.bind(this);
        this.onChangeQuestionAndRole = this.onChangeQuestionAndRole.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.NextBtn = this.NextBtn.bind(this);

        //setting to empty strings
        this.state = {
            ExtensionInstalled: false,
            ExtensionVideo: false,
            ExtensionVideoModel: false,
            IntigrateETS: false,
            IntigrateETSModel: false,
            QuestionAndRole: false,
            QuestionAndRoleModel: false,
            nextPage: false

        };
        let exist = sendTokenToChromeExtension({
            message: "doesExist"
        });
    }

    onChangeExtensionInstalled(e) {
        this.setState({ ExtensionInstalled: true });
    }
    onChangeExtensionVideo(e) {
        this.setState({ ExtensionVideo: true });
        this.setState({ ExtensionVideoModel: true });
    }
    onChangeIntigrateETS(e) {
        this.setState({ IntigrateETS: true });
        this.setState({ IntigrateETSModel: true });
    }
    onChangeQuestionAndRole(e) {
        this.setState({ QuestionAndRole: true });
        this.setState({ QuestionAndRoleModel: true });
    }
    handleClose(e) {
        this.setState({ ExtensionVideoModel: false });
        this.setState({ IntigrateETSModel: false });
        this.setState({ QuestionAndRoleModel: false });
    }
    NextBtn() {
        this.setState({ nextPage: true });
        const cookies = new Cookies();

        var config = cookies.get("token")

        if (!cookies.get("token")) {
      config = localStorage.getItem("token")
      //console.log("token: ", config)
    }
    axios({
      method: "POST",
      url: process.env.REACT_APP_LOGIN_URL + "/workorder/create_zoom_automatic",
      headers: {
          Authorization: "Token " + config,
      },
      data: {
          automatic_processing: 0,
          voip_integration_auto_rec: "ZOOMCALL_AUTOREC"
      },
  })
      //get the token from the backend and set the authentication to true
      //store the token in session storage
      .then((response) => {
          console.log(response.data);
          
         

          //authentication is set to true, allowing for access to protected pages
      })
      .catch((err) => {
          console.log(err);
          
      });
      axios({
        method: "POST",
        url: process.env.REACT_APP_LOGIN_URL + "/workorder/updatedefaultrole",
        headers: {
            Authorization: "Token " + config,
        },
        data: {
            roleid: 2,
            voip_integration_label: "ZOOMCALL_DEFAULT_ROLE"
        },
    })
        //get the token from the backend and set the authentication to true
        //store the token in session storage
        .then((response) => {
            console.log(response.data);
           

            //authentication is set to true, allowing for access to protected pages
        })
        .catch((err) => {
            console.log(err);
            
        });
      if (cookies.get("redirect") >= 2 && cookies.get("Isadmin") === "true") {
        cookies.set("redirect", 3, { path: "/" });
    }
        
    }


    render() {
        if (this.state.nextPage == true) {
            return <Redirect to='/planselection' />;
        }
        return (
            //forms that have been made with react features
            //add user id, name and password
            <div className={`${styles.loginApp}`}>
                <Authenticationleft />
                <div className={`${styles.split} ${styles.right} ${styles.flexjccaicRES}`}>
                    {/* Mobile View Header */}
                    <div
                        className={`${styles.headerResMode} ${styles.flexJccAic}`}
                        style={{ backgroundImage: `url(${backgroundimg})` }}
                    >
                        <img className={styles.olasheaderres} src={olaslogoimg} />
                    </div>
                    {/* right Side */}
                    <div className={` ${styles.mobileResHeigh}`}>
                        <div className={`${styles.ProfileWrapper} ${styles.resDflexJccAic}`}                         >
                            <div>
                                <h2 className={`HeadingsButtonFontOlas ${styles.loginHeader} ${styles.profileHeader} ${styles.textCenter}`} >
                                    How to use the NoteTaker product
                                </h2>
                                {/* Div for Progressbar */}
                                <div className={styles.ProgressBarDiv}>
                                    <div className={styles.flexJccAic}>
                                        <div
                                            className={`${styles.ProgressBarComp} ${styles.ProgressBarCompActive}`}></div>
                                        <div className={`${styles.ProgressBarComp} ${styles.ProgressBarCompActive}`}></div>
                                        <div className={`${styles.ProgressBarComp}`}></div>
                                        <div className={`${styles.ProgressBarComp}`}></div>
                                    </div>
                                </div>
                                <div className={`${styles.progressstepperContainer} ${styles.flexJccAic}`}>
                                    <div className={styles.ProgressStepperDiv}>
                                        <div className={`${styles.flexJccAic} ${styles.progressbarIconDiv}`}>1</div>
                                        <div className={styles.ProgressDashes}></div>
                                        <div className={`${styles.flexJccAic} ${styles.progressbarIconDiv}`}>2</div>
                                        <div className={styles.ProgressDashes}></div>
                                        <div className={`${styles.flexJccAic} ${styles.progressbarIconDiv}`}>3</div>
                                    </div>
                                    <div className={styles.ProgressItemsWrappersteeper}>
                                        <div className={styles.PogressDivWrapper}>
                                            <h2 className={`HeadingsButtonFontOlas ${styles.PogressDivHeadingstepper}`}>Install The NoteTaker Chrome Extension</h2>
                                            <div className={`${styles.dflex} ${styles.ProgressdivBtnWrappwe}`}>

                                                <a href='https://chrome.google.com/webstore/detail/squire/habiadmejhcipkmljfidkeagpekcmdlp?hl=en' target="_blank" className={`HeadingsButtonFontOlas ${this.state.ExtensionInstalled == true ? styles.StepperBtnsActive : null} ${styles.StepperBtns}`} onClick={this.onChangeExtensionInstalled}>Install</a>

                                                <button onClick={this.onChangeExtensionVideo} style={{ margin: "0" }} className={`HeadingsButtonFontOlas ${styles.StepperBtns}  ${this.state.ExtensionVideo == true ? styles.StepperBtnsActive : null}`}><img src={this.state.ExtensionVideo == true ? playcircleWhite : playcircle} /> How To Video</button>

                                            </div>
                                        </div>
                                        <div className={styles.PogressDivWrapper}>
                                            <h2 className={`HeadingsButtonFontOlas ${styles.PogressDivHeadingstepper}`}>Create Questions & Roles for Interviews</h2>
                                            <div className={`${styles.dflex} ${styles.ProgressdivBtnWrappwe}`}>
                                                <button onClick={this.onChangeQuestionAndRole} className={`HeadingsButtonFontOlas ${this.state.QuestionAndRole == true ? styles.StepperBtnsActive : null} ${styles.StepperBtns}`}><img src={this.state.StepperBtnsActive == true ? playcircleWhite : playcircle} /> How To Video</button>
                                            </div>
                                        </div>
                                        <div className={styles.PogressDivWrapper}>
                                            <h2 className={`HeadingsButtonFontOlas ${styles.PogressDivHeadingstepper}`}>Integrate with your ATS (optional)</h2>
                                            <div className={`${styles.dflex} ${styles.ProgressdivBtnWrappwe}`}>
                                                <button onClick={this.onChangeIntigrateETS} className={`HeadingsButtonFontOlas ${this.state.IntigrateETS == true ? styles.StepperBtnsActive : null} ${styles.StepperBtns}`}><img src={this.state.IntigrateETS == true ? playcircleWhite : playcircle} /> ATS integration video</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className={`${styles.justify_content_around} ${styles.flexJccAic}`}>
                                    <Link to="/profile" className={`HeadingsButtonFontOlas ${styles.StepperBtns1}`}>Back</Link>
                                    {/* Next Btn */}
                                    {this.state.ExtensionInstalled == true ? <button style={{ background: "#d8045c", color: "#fff" }} id='NextBtnHowUse' onClick={this.NextBtn} className={`HeadingsButtonFontOlas ${styles.StepperBtns1}`} >Next
                                    </button> : <CustomWidthTooltip title={tooltipText} arrow placement="top">
                                        <button id='NextBtnHowUse' className={`HeadingsButtonFontOlas ${styles.NextBtnCss}`} >Next
                                        </button>
                                    </CustomWidthTooltip>}
                                    {/*  */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    open={this.state.ExtensionVideoModel}
                    onClose={this.handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className={styles.PopupVedioWrapper}>
                        <iframe
                            className={styles.PopupVedioIframe}
                            
                            src="https://www.youtube.com/embed/bYj-bhH0Usc"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                        />
                    </div>
                </Modal>
                <Modal
                    open={this.state.IntigrateETSModel}
                    onClose={this.handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className={styles.PopupVedioWrapper}>
                        <iframe
                            className={styles.PopupVedioIframe}
                            src="https://www.youtube.com/embed/YsFs6G7_Uo4"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                        />
                    </div>
                </Modal>
                <Modal
                    open={this.state.QuestionAndRoleModel}
                    onClose={this.handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className={styles.PopupVedioWrapper}>
                        <iframe
                            className={styles.PopupVedioIframe}
                            src="https://www.youtube.com/embed/6RpOL9FiP9M"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                        />
                    </div>
                </Modal>

            </div>
        );
    }
}


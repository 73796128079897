/* global chrome */
import React, { useEffect } from "react";
import styles from "./Login.module.css";
import backgroundimg from "./../Images/backgrounds/login_background.svg";
import olaslogoimg from "./../Images/logo/olaslogo.svg";
import Loader from "./Loader/Loader";
import Authenticationleft from "./Authenticationleft";
import ErrorModal from "../Components/Error_Modle/ErrorModal";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import { Radio } from "@mui/material";
import { Link, Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
import NotificationModal from "../Components/NotificationModal/NotificationModal";
import axios from "axios";
import WaitLoader from "./WaitLoader/WaitLoader";
import PlanPurchase from "../Components/NotificationModal/PlanPurchase";
import { sendTokenToChromeExtension } from "../Login/sendTokenToExt";

// import { pink } from '@mui/material/colors';

//Create class
export class PlanSelection extends React.Component {
  constructor() {
    super();
    //binding the data so it can be called
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleNextBtn = this.handleNextBtn.bind(this);
    this.planVerify = this.planVerify.bind(this);
    this.chargbeeEmail = this.chargbeeEmail.bind(this);
    this.redirecttohome = this.redirecttohome.bind(this);
    this.RedirectfromChargeBee = this.RedirectfromChargeBee.bind(this);
    this.logout = this.logout.bind(this);
    // this.findComponent = this.findComponent.bind(this);
    //setting to empty strings
    this.state = {
      ismodalopen: false,
      activeButton: "EUR",
      selectedValue: null,
      message: "please refresh this page to continue",
      notification: false,
      redirectresponse: null,
      gotohome: false,
      waitLoader: false,
      PurchasePalnModal: false,
      PlanMessage: null,
    };
  }
  componentDidMount() {
    this.chargbeeEmail();
    this.RedirectfromChargeBee();
  }
  RedirectfromChargeBee() {
    const urlParams = new URLSearchParams(window.location.search);
    const src = urlParams.get("src");

    // Now you can use the retrieved parameters as needed
    console.log("src:", src);
    console.log(src === "chargebee");
    if (src === "chargebee") {
      this.setState({ waitLoader: true });
      setTimeout(() => {
        this.planVerify();
      }, 15000);
    }
  }
  chargbeeEmail() {
    const script = document.createElement("script");
    let body = document.querySelector("Body");

    const cookies = new Cookies();
    var config = cookies.get("token");

    if (!cookies.get("token")) {
      config = localStorage.getItem("token");
    }

    console.log(config);
    var headerconfig = {
      method: "get",
      url: process.env.REACT_APP_BACKEND_URL + "/login/get",
      headers: {
        Authorization: "Token " + config,
      },
    };
    axios(headerconfig)
      .then((response) => {
        console.log(response.data);
        let email = response.data.message.email;
        let firstname = response.data.message.firstName;
        let lastName = response.data.message.lastName;

        console.log(email, firstname, lastName);
        script.innerText = `const buttons = document.querySelectorAll(".RadioBtnPlanselectionPage"); buttons.forEach(button => { button.addEventListener("click", function () { const cbInstance = Chargebee.getInstance(); const cart = cbInstance.getCart(); const customer = { email: "${email}", first_name: "${firstname}", last_name: "${lastName}" }; cart.setCustomer(customer); console.log("Chargebee function runs");  }); });`;
        body.append(script);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  planVerify() {
    const cookies = new Cookies();
    var config = cookies.get("token");

    if (!cookies.get("token")) {
      config = localStorage.getItem("token");
    }

    console.log(config);
    var headerconfig = {
      method: "get",
      url: process.env.REACT_APP_BACKEND_URL + "/company/get-company-plan-dtl",
      headers: {
        Authorization: "Token " + config,
      },
    };

    axios(headerconfig)
      .then((response) => {
        console.log(response.data.message.redirect);
        let redirect = response.data.message.redirect;
        // console.log(response.data.message.is_plan_purchased);
        cookies.set("redirect", response.data.message.redirect, { path: "/" });
        this.setState({ redirectresponse: response.data.message.redirect });
        if (redirect === 4) {
          this.setState({ waitLoader: false });
          this.setState({ notification: true });
          this.setState({
            message:
              "Click here to return home as your plan is currently active.",
          });
        }
        if (redirect === 3) {
          this.setState({ waitLoader: false });
          this.setState({ PurchasePalnModal: true });
          this.setState({
            PlanMessage:
              "You have no active Plan. If you have then Refresh the Page.",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ waitLoader: false });
      });
  }

  handleButtonClick(button) {
    this.setState((prevState) => ({
      activeButton: prevState.activeButton === button ? "EUR" : button,
    }));
  }
  handleClose() {
    this.setState({ ismodalopen: false });
    this.setState({ notification: false });
    this.setState({ PurchasePalnModal: false });
  }
  handleChange(e) {
    this.setState({ selectedValue: e.target.value });
    console.log(e.target.value);
  }
  handleNextBtn(e) {
    const Inputdata = this.state.selectedValue;
    if (Inputdata === "a") {
      let Onemonth = document.querySelector("#USD-Monthly");
      Onemonth.click();
     
    }
    if (Inputdata === "b") {
      let Oneyear = document.querySelector("#USD-Yearly");
      Oneyear.click();
      
    }
    if (Inputdata === "c") {
      let Onemonth = document.querySelector("#EUR-Monthly");
      Onemonth.click();
      
    }
    if (Inputdata === "d") {
      let Oneyear = document.querySelector("#EUR-Yearly");
      Oneyear.click();
     
    }
    if (Inputdata === "e") {
      let Onemonth = document.querySelector("#GBP-Monthly");
      Onemonth.click();
     
    }
    if (Inputdata === "f") {
      let Oneyear = document.querySelector("#GBP-Yearly");
      
    }
  }
  logout() {
    console.log("logout");
    const cookies = new Cookies();
    cookies.remove("token"); //clear storage so no user is stored in the session
    cookies.remove("loggedin"); //clear storage so no user is stored in the session
    cookies.remove("loginfrommail"); //clear storage so no user is stored in the session
    cookies.remove("redirect"); //clear storage so no user is stored in the session
    cookies.remove("Isadmin"); //clear storage so no user is stored in the session
    //redirect user to the home page, which is protected, so will redirect to login page
    localStorage.clear();
    sendTokenToChromeExtension({
      message: "logoutApp",
    });
    window.location.href = "/";
  }
  redirecttohome() {
    this.setState({ gotohome: true });
  }

  render() {
    const { gotohome, selectedValue, activeButton, redirectresponse } =
      this.state;
    if (gotohome === true) {
      return <Redirect to="/home" />;
    }

    return (
      <div className={`${styles.loginApp}`}>
        <Authenticationleft />
        <ErrorModal
          errorMessage={this.state.err}
          open={this.state.ismodalopen}
          handleClose={this.handleClose}
        />
        <NotificationModal
          redirecttohome={this.redirecttohome}
          Message={this.state.message}
          open={this.state.notification}
          handleClose={this.handleClose}
        />
        <PlanPurchase
          Message={this.state.PlanMessage}
          open={this.state.PurchasePalnModal}
          handleClose={this.handleClose}
        />
        {this.state.Loader == true ? <Loader /> : null}
        {this.state.waitLoader === true ? <WaitLoader /> : null}
        <button className={styles.LogoutBtnPlanSelection} onClick={this.logout}>
          Log Out
        </button>
        <div
          style={{ minHeight: "auto" }}
          className={`${styles.split} ${styles.right} ${styles.flexJccAic}`}
        >
          <div>
            {/* Mobile View Header */}
            <div
              className={`${styles.headerResMode} ${styles.flexJccAic}`}
              style={{ backgroundImage: `url(${backgroundimg})` }}
            >
              <img className={styles.olasheaderres} src={olaslogoimg} />
            </div>
            {/* right Side */}
            <div className={` ${styles.mobileResHeigh}`}>
              <div
                className={`${styles.ProfileWrapper} ${styles.resDflexJccAic}`}
              >
                <div>
                  <h2
                    className={`HeadingsButtonFontOlas ${styles.loginHeader} ${styles.profileHeader} ${styles.textCenter}`}
                  >
                    Plan Selection
                  </h2>
                  <div className={styles.ProgressBarDiv}>
                    {/* Div for Progressbar */}
                    <div className={styles.flexJccAic}>
                      <div
                        className={`${styles.ProgressBarComp} ${styles.ProgressBarCompActive}`}
                      ></div>
                      <div
                        className={`${styles.ProgressBarComp} ${styles.ProgressBarCompActive}`}
                      ></div>
                      <div
                        className={`${styles.ProgressBarComp} ${styles.ProgressBarCompActive}`}
                      ></div>
                      <div
                        className={`${styles.ProgressBarComp} ${styles.ProgressBarCompActive}`}
                      ></div>
                    </div>
                  </div>
                  <div className={styles.CurrencyBtnPlanPage}>
                    <button
                      className={`${styles.CurrencyButton} ${
                        activeButton === "USD"
                          ? styles.CurrencyButtonActive
                          : ""
                      }`}
                      onClick={() => this.handleButtonClick("USD")}
                    >
                      USD
                    </button>
                    <button
                      className={`${styles.CurrencyButton} ${
                        activeButton === "EUR"
                          ? styles.CurrencyButtonActive
                          : ""
                      }`}
                      onClick={() => this.handleButtonClick("EUR")}
                    >
                      EUR
                    </button>
                    <button
                      className={`${styles.CurrencyButton} ${
                        activeButton === "GBP"
                          ? styles.CurrencyButtonActive
                          : ""
                      }`}
                      onClick={() => this.handleButtonClick("GBP")}
                    >
                      GBP
                    </button>
                  </div>

                  <div className={styles.PalnSelectionSelectWrapper}>
                    <div className={styles.PlanSelectionFreeTrial}>
                      <div className={styles.FreeTrialWrapperPlanSelection}>
                        <StarOutlinedIcon
                          className={styles.StartIconPlanSelection}
                        />
                        <span className={styles.FreetrialPlanSelection}>
                          Free Trial For 7 Days!
                        </span>
                        <StarOutlinedIcon
                          className={styles.StartIconPlanSelection}
                        />
                      </div>
                    </div>

                    <>
                      <div className={styles.PlanInfoWrapper}>
                        {activeButton === "USD" ? (
                          <Radio
                            className="RadioBtnPlanselectionPage"
                            sx={{
                              color: "#d8045c",
                              "&.Mui-checked": {
                                color: "#d8045c",
                              },
                            }}
                            checked={selectedValue === "a"}
                            onChange={this.handleChange}
                            value="a"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "A" }}
                          />
                        ) : activeButton === "GBP" ? (
                          <Radio
                            className="RadioBtnPlanselectionPage"
                            sx={{
                              color: "#d8045c",
                              "&.Mui-checked": {
                                color: "#d8045c",
                              },
                            }}
                            checked={selectedValue === "e"}
                            onChange={this.handleChange}
                            value="e"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "E" }}
                          />
                        ) : (
                          <Radio
                            className="RadioBtnPlanselectionPage"
                            sx={{
                              color: "#d8045c",
                              "&.Mui-checked": {
                                color: "#d8045c",
                              },
                            }}
                            checked={selectedValue === "c"}
                            onChange={this.handleChange}
                            value="c"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "C" }}
                          />
                        )}
                        <div className={styles.PlanSelectionPalnsText}>
                          <div className={styles.TimeInfoPlansSelection}>
                            <span className={styles.PlanSelectionHaeding}>
                              Monthly Plan
                            </span>
                            <span className={styles.PlanSelectionSubHaeding}>
                              Completely free for the first 7 days, try it out!
                            </span>
                          </div>
                          <div className={styles.PriceWrapperSelectPlans}>
                            <span className={styles.BarierPlanSelection}></span>
                            <span className={styles.PrciePlanSelection}>
                              {activeButton === "USD"
                                ? "$ 40"
                                : activeButton === "GBP"
                                ? "£ 25"
                                : "€ 20"}
                            </span>
                          </div>
                        </div>
                      </div>
                      <hr className={styles.planselextionHr} />
                      <div className={styles.PlanInfoWrapper}>
                        {activeButton === "USD" ? (
                          <Radio
                            className="RadioBtnPlanselectionPage"
                            sx={{
                              color: "#d8045c",
                              "&.Mui-checked": {
                                color: "#d8045c",
                              },
                            }}
                            checked={selectedValue === "b"}
                            onChange={this.handleChange}
                            value="b"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "b" }}
                          />
                        ) : activeButton === "GBP" ? (
                          <Radio
                            className="RadioBtnPlanselectionPage"
                            sx={{
                              color: "#d8045c",
                              "&.Mui-checked": {
                                color: "#d8045c",
                              },
                            }}
                            checked={selectedValue === "f"}
                            onChange={this.handleChange}
                            value="f"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "F" }}
                          />
                        ) : (
                          <Radio
                            className="RadioBtnPlanselectionPage"
                            sx={{
                              color: "#d8045c",
                              "&.Mui-checked": {
                                color: "#d8045c",
                              },
                            }}
                            checked={selectedValue === "d"}
                            onChange={this.handleChange}
                            value="d"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "D" }}
                          />
                        )}
                        <div className={styles.PlanSelectionPalnsText}>
                          <div className={styles.TimeInfoPlansSelection}>
                            <span className={styles.PlanSelectionHaeding}>
                              Annual Plan
                            </span>
                            <span className={styles.PlanSelectionSubHaeding}>
                              Completely free for the first 7 days, try it out!
                            </span>
                          </div>
                          <div className={styles.PriceWrapperSelectPlans}>
                            <span className={styles.BarierPlanSelection}></span>
                            <span className={styles.PrciePlanSelection}>
                              {activeButton === "USD"
                                ? "$ 400"
                                : activeButton === "GBP"
                                ? "£ 250"
                                : "€ 200"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                  <div
                    className={`${styles.PlanSelectionBtnsBottom} ${styles.JustifyContentBetween}  ${styles.flexJccAic}`}
                  >
                    <Link
                      to="/how-to-use"
                      className={`HeadingsButtonFontOlas ${styles.StepperBtns1}`}
                    >
                      Back
                    </Link>
                    <button
                      onClick={this.handleNextBtn}
                      className={`HeadingsButtonFontOlas ${styles.FreetrialBtnPlanSelection}`}
                    >
                      Start Free Trial
                    </button>
                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React from 'react';
import Auth from './Auth';
import axios from 'axios';


//Create class
export class NewPass extends React.Component {
    //post the login credentials to the backend
    //retrieve the response from the backend - token
    //if success - set auth to true, so rest of site can be accessed
    //else stay false - routes remain protected

    constructor() {
        super();
        //binding the data so it can be called
    }

    render() {

        return (
            //forms that have been made with react features
            //add user id, name and password
            <div className='App'>

                <h1>
                    Zoom documentation
                </h1>
                <h4>
                    How to connect to Zoom
                </h4>
                <p>
                    Once you have successfully signed up to AI NoteTaker, you will then be able to access the zoom app. To install zoom:
                </p>


                <li>
                    Ensure you are Signed in with your zoom account
                </li>

                <li>
                    navigate to the integrations page
                </li>

                <li>
                    Click on Zoom
                </li>
                <li>
                    Click on the button Add to Zoom
                </li>

                <li>
                    Click on the allow button to allow the app to be installed.
                </li>
                <li>
                    Go to Zoom
                </li>

                <li>
                    Go to the Apps tab
                </li>

                <li>
                    Click on NoteTaker and Login with your account credentials
                </li>

                <br></br>
                <p>
                    If this process is followed you should be able to successfully access the app!
                </p>

                <p>
                    If you have any issues don’t hesitate to use our support page and one of our team will help sort any issues for you!
                </p>




            </div>

        );
    }
}
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import correct from './../Images/Group 48096900.svg';
import add from './../Images/add.svg';
import styles from './Settings.module.css'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 643,
  height: 477,
  bgcolor: 'background.paper',
  border: 'transparent',
  boxShadow: 24,
  p: 9,
  borderRadius: '15px',
  textAlign: 'center',
};

function BasicModal(props) {
  const { open, handleClose } = props;


  return (
    <div>
      <Modal open={open} aria-labelledby="modal-modal-title">
        <Box sx={style}>
          <img onClick={handleClose} style={{ position: 'relative', left: '280px', bottom: '55px' }} src={add} /><br />
          <img src={correct} />
          <Typography sx={{ fontSize: "16px", lineHeight: "24px", p: 3, px: 15 }}>
            Your Profile setting has been Successfully Updated
          </Typography>
          <Button onClick={handleClose} sx={{ backgroundColor: "d8045c", color: "#fff", width: 205, height: 45, '&:hover': { backgroundColor: "#8c5df5" } }}>Ok</Button>
        </Box>
      </Modal>
    </div>
  );
}

export default BasicModal;

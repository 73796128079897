/* global chrome */
import React from "react";
import styles from "./NavLeft.module.css";
import { Link, NavLink } from "react-router-dom";
import "antd/dist/antd.css";
import maskGroup from "./../../Images/maskGroup.svg";
import group48096677 from "./../../Images/Squire-Support-Symbol.png";
import UniversalCss from "../../Universal.module.css";
import knight from "../../Images/KyloeNotetaker.png";
// import knight from "../../Images/squireInapp.jpeg";
import Cookies from "universal-cookie";

const NavitemList = [
  {
    name: "Dashboard",
    path: "/home",
    id: 1,
    bgclass: "bgimgDashbord",
  },
  {
    name: "Calls",
    path: "/calls",
    id: 2,
    bgclass: "bgimgCalls",
  },
  // {
  //     name: "Groups",
  //     path: "/groups",
  //     id: 3,
  //     bgclass: "bgimgprofile"

  // },
  {
    name: "Question bank",
    path: "/questionbank",
    id: 4,
    bgclass: "bgimgmessage",
  },
  // {
  //     name: "Tasks",
  //     path: "/task",
  //     id: 5,
  //     bgclass: "bgimgtask"

  // },
  // {
  //     name: "Analytics",
  //     path: "/analytics",
  //     id: 6,
  //     bgclass: "bgimgactivity"

  // },
  {
    name: "User",
    path: "/user",
    id: 7,
    bgclass: "bgimguser",
  },
  {
    name: "Integration",
    path: "/integrations",
    id: 8,
    bgclass: "bgimgsetting",
  },
];
const AdminNavList = [
  {
    name: "Dashboard",
    path: "/home",
    id: 1,
    bgclass: "bgimgDashbord",
  },
  {
    name: "Calls",
    path: "/calls",
    id: 2,
    bgclass: "bgimgCalls",
  },
  // {
  //     name: "Groups",
  //     path: "/groups",
  //     id: 3,
  //     bgclass: "bgimgprofile"

  // },
  {
    name: "Question bank",
    path: "/questionbank",
    id: 4,
    bgclass: "bgimgmessage",
  },
  // {
  //     name: "Tasks",
  //     path: "/task",
  //     id: 5,
  //     bgclass: "bgimgtask"

  // },
  // {
  //     name: "Analytics",
  //     path: "/analytics",
  //     id: 6,
  //     bgclass: "bgimgactivity"

  // }
  {
    name: "Integration",
    path: "/integrations",
    id: 8,
    bgclass: "bgimgsetting",
  },
];
export class NavbarLeft extends React.Component {
  constructor(props) {
    super(props);
    this.handleActiveCalss = this.handleActiveCalss.bind(this);
    this.state = {
      Isadmin: null,
    };
  }
  handleActiveCalss(e, item) {
    // console.log(e.target.childNodes[0].childNodes[0]);
    // let ImageSrc = e.target.childNodes[0].childNodes[0].setAttribute("src", item.activeIcon)
    // console.log(ImageSrc);
  }
  componentDidMount() {
    const cookies = new Cookies();
    this.setState({ Isadmin: cookies.get("Isadmin") });
  }

  render() {
    return (
      <div className={styles.NavbarWrapperSidebar}>
        <div className={styles.KnightLogWrapper}>
          <img src={knight} className={UniversalCss.w100} />
        </div>
        <div className={styles.sideNavItemsWrapper}>
          {(this.state.Isadmin == "false" ? AdminNavList : NavitemList).map(
            (item) => (
              <div key={item.name}>
                <NavLink
                  exact
                  activeClassName="navbarlinkactive"
                  onClick={(e) => this.handleActiveCalss(e, item)}
                  id={item.id}
                  className={`${styles.NavItemLink} ${styles.NavItemDiv} ${UniversalCss.w100} navitems`}
                  to={item.path}
                >
                  <div
                    id="NavIcon"
                    className={`${item.bgclass} ${styles.NavItemIcons}`}
                  >
                    <img className={UniversalCss.w100} src={item.Icon} />
                  </div>
                  <span className={styles.NavitemLeft}>{item.name}</span>
                </NavLink>
              </div>
            )
          )}
        </div>

        <div className={styles.Support_Container}>
          
          <div className={styles.rectangle2999}>
            <div className={styles.catabsolutecontainer}>
              <span className={styles.support}>Support</span>
              <span className={styles.havingtroublewith}>
                Having Trouble With NoteTaker? Contact Support.
              </span>
              <div className={styles.flexcontainer8}>
                <Link className={styles.links} to={"/support"}>
                  <button className={styles.shape}>
                    <span className={styles.primarybutton}>Go to Support</span>
                  </button>
                </Link>
              </div>
            </div>
            <img className={styles.maskgroup} src={maskGroup} />
          </div>
        </div>
      </div>
    );
  }
}

/* global chrome */
import React, { useEffect } from 'react';
import styles from './Login.module.css';
import backgroundimg from './../Images/backgrounds/login_background.svg';
import Cookies from 'universal-cookie';
import uploadimage from './../Images/icons/imageUpload.svg';
import olaslogoimg from './../Images/logo/olaslogo.svg';
import { TextField } from '@mui/material';
import axios from "axios";
import Loader from './Loader/Loader'
import Authenticationleft from './Authenticationleft';
import { Redirect } from "react-router-dom";
import ErrorModal from '../Components/Error_Modle/ErrorModal';
import { sendTokenToChromeExtension } from './sendTokenToExt';



//Create class
export class Profile extends React.Component {
  constructor() {
    super();
    //binding the data so it can be called
    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangePhoneNumber = this.onChangePhoneNumber.bind(this);
    this.onChangeCompany = this.onChangeCompany.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.handleSubmitimage = this.handleSubmitimage.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.checkMail = this.checkMail.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.checkMail()

    //setting to empty strings
    this.state = {
      FirstName: '',
      LastName: '',
      Email: "",
      PhoneNumber: '',
      Company: '',
      Title: '',
      Password: "",
      img: null,
      imageurl: uploadimage,
      err: null,
      verifyemailPage: false,
      HowTouUsePage: false,
      Loader: false,
      fileSizeErr: null,
      ismodalopen: false,
    };
  }

  componentDidMount() {
    const cookies = new Cookies();
    var config = cookies.get("token")

    if (!cookies.get("token")) {
      config = localStorage.getItem("token")
      console.log("token: ", config)
    }
    axios.get(process.env.REACT_APP_BACKEND_URL + '/login/get', {
      headers: {
        'Authorization': 'Token ' + config
      }
    }).then(
      response => {
        console.log(response.data.message.email)
        this.setState({
          Email: response.data.message.email,
          FirstName: response.data.message.firstName,
          LastName: response.data.message.lastName,
          PhoneNumber: response.data.message.mobile_number,
          Company: response.data.message.company_name,
          Title: response.data.message.title,
          imageurl: response.data.message.user_image_url,
        })
        //this.setState({ rolesDisplay:  })
      }

    ).catch(error => {
      console.log(error)
    }
    )
    axios.get(process.env.REACT_APP_BACKEND_URL + '/login/access-token-jwt', {
      headers: {
        'Authorization': 'Token ' + config
      }
    }).then(
      response => {
        console.log(response)
        let djangoToken = config;
        let jwtToken = response.data.refresh;
        sendTokenToChromeExtension({
          djangoToken,
          jwtToken
        });
        console.log(djangoToken, jwtToken);
      }

    ).catch(error => {
      console.log(error)
    }
    )

  }
  handleClose() {
    this.setState({
      ismodalopen: false,
    });
  }
  checkMail() {
    this.props ? console.log("Props Present") : console.log("props not present");
    { this.props ? <Redirect to="/signup" /> : <Redirect to="/signup" /> }
  }

  ResendMail(e) {
    this.setState({ HowTouUsePage: true })
  }

  handleUploadpicBtn() {
    document.querySelector('#ImageUploadbtn').click();
  }

  handleSubmitimage(e) {

    const MAX_FILE_SIZE = 2048 // 2MB

    let filesize = e.target.files[0].size / 1024
    console.log(filesize);

    if (filesize > MAX_FILE_SIZE) {
      this.setState({ fileSizeErr: "File size is greater than 2MB" })
      setTimeout(() => {
        this.setState({ fileSizeErr: null })
      }, 4000);
    } else {
      this.setState({ img: e.target.files[0] });
      this.setState({ imageurl: URL.createObjectURL(e.target.files[0]) });
    }
  }
  onChangeFirstName(e) {
    this.setState({ FirstName: e.target.value });
  }
  onChangeLastName(e) {
    this.setState({ LastName: e.target.value });
  }

  onChangePhoneNumber(e) {
    this.setState({ PhoneNumber: e.target.value });
  }
  onChangeCompany(e) {
    this.setState({ Company: e.target.value });
  }
  onChangeTitle(e) {
    this.setState({ Title: e.target.value });
  }
  onSubmit(e) {
    e.preventDefault()
    const cookies = new Cookies();
    var config = cookies.get("token")

    if (!cookies.get("token")) {
      config = localStorage.getItem("token")
      //console.log("token: ", config)
    }
   
    let ImageData = new FormData()
    this.state.img && ImageData.append("profilephoto", this.state.img)
    ImageData.append("first_name", this.state.FirstName,)
    ImageData.append("last_name", this.state.LastName,)
    ImageData.append("email", this.state.Email,)
    ImageData.append("title", this.state.Title,)
    ImageData.append("mobile_number", this.state.PhoneNumber,)
    ImageData.append("company_name", this.state.Company,)
    axios({
      method: "PUT",
      url: process.env.REACT_APP_BACKEND_URL + '/login/update',
      headers: {
        'Authorization': 'Token ' + config
      },
      data: ImageData
    }).then(
      response => {
        console.log(response)
        window.location.href = "/how-to-use"
        const cookies = new Cookies();
        if (cookies.get("redirect") == 1) {
          cookies.set("redirect", 2, { path: "/" });
        }
      }

    ).catch(error => {
      this.setState({ ismodalopen: true })
      console.log(error);
    })

    
  }


  render() {
    return (
      //forms that have been made with react features
      //add user id, name and password
      <div className={`${styles.loginApp}`}>
        <Authenticationleft />
        <ErrorModal errorMessage={this.state.err} open={this.state.ismodalopen} handleClose={this.handleClose} />
        {this.state.Loader == true ? <Loader /> : null}
        {/* {this.props.location && console.log(this.props.location.Email)} */}
        <div className={`${styles.split} ${styles.right} ${styles.flexJccAic}`}>
          <div>
            {/* Mobile View Header */}
            <div
              className={`${styles.headerResMode} ${styles.flexJccAic}`}
              style={{ backgroundImage: `url(${backgroundimg})` }}
            >
              <img className={styles.olasheaderres} src={olaslogoimg} />
            </div>
            {/* right Side */}
            <div className={` ${styles.mobileResHeigh}`}>
              <div
                className={`${styles.ProfileWrapper} ${styles.resDflexJccAic}`}
              >
                <div>
                  <h2
                    className={`HeadingsButtonFontOlas ${styles.loginHeader} ${styles.profileHeader} ${styles.textCenter}`}
                  >
                    Profile
                  </h2>
                  <div className={styles.ProgressBarDiv}>
                    {/* Div for Progressbar */}
                    <div className={styles.flexJccAic}>
                      <div
                        className={`${styles.ProgressBarComp} ${styles.ProgressBarCompActive}`}
                      ></div>
                      <div className={`${styles.ProgressBarComp}`}></div>
                      <div className={`${styles.ProgressBarComp}`}></div>
                      <div className={`${styles.ProgressBarComp}`}></div>
                    </div>
                  </div>
                  {/* Image */}
                  <div
                    className={`${styles.resJcc} ${styles.dflex} ${styles.alignFlexEnd}`}
                  >
                    <div className={styles.uploadimageWrapper}>
                      <img
                        src={this.state.imageurl != null ? this.state.imageurl : uploadimage}
                        className={`${styles.br25p} ${styles.w100} ${styles.h100}`} loading="lazy"
                      />
                    </div>
                    <div>
                      {this.state.fileSizeErr && <p className={styles.ErrorMessageParaSignUp}>{this.state.fileSizeErr}</p>}
                      <p className={styles.profileImagePara}>
                        Allowed JPG, GIF or PNG. Max size of 800kB
                      </p>
                      <div className={styles.positionRelative}>
                        <input
                          id='ImageUploadbtn'
                          className={`${styles.uploadImageBtn}`}
                          type='file'
                          accept='image/gif, image/jpeg, image/png'
                          onChange={this.handleSubmitimage}
                        />
                        <button
                          onClick={this.handleUploadpicBtn}
                          className={`HeadingsButtonFontOlas ${styles.positionAbsolute} ${styles.uploadImageBtn}`}
                        >
                          Upload Photo
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Form Filed */}
                  <form onSubmit={this.onSubmit}>
                    <div className={`${styles.profileFormWrapper}`}>
                      {/* Name Row */}
                      <div
                        className={`${styles.dflex} ${styles.flexcolumn} ${styles.resDflexJccAic} ${styles.ProfileFormRow}`}
                      >
                        <div className={styles.ProfileLeftWrapper}>
                          <label className={styles.ProfileFormInputaLabel}>
                            First Name *
                          </label>
                          <TextField
                            id='outlined-basic'
                            className={styles.ProfileFormInput}
                            label='First Name'
                            value={this.state.FirstName}
                            onChange={this.onChangeFirstName}
                            required
                            type='text'
                          />
                        </div>
                        <div className={styles.ProfiRightftWrapper}>
                          <label className={styles.ProfileFormInputaLabel}>
                            Last Name *
                          </label>
                          <TextField
                            className={styles.ProfileFormInput}
                            label='Last Name'
                            value={this.state.LastName}
                            onChange={this.onChangeLastName}
                            variant='outlined'
                            required
                            type='text'
                          />
                        </div>
                      </div>
                      {/* Email Number */}
                      <div
                        className={`${styles.dflex} ${styles.flexcolumn} ${styles.resDflexJccAic} ${styles.ProfileFormRow}`}
                      >
                        <div className={styles.ProfileLeftWrapper}>
                          <label className={styles.ProfileFormInputaLabel}>
                            Email *
                          </label>
                          <TextField
                            disabled
                            className={styles.ProfileFormInput}
                            label='Email'
                            value={this.state.Email}
                            variant='outlined'
                            required
                            type='email'
                          />
                        </div>
                        <div className={styles.ProfiRightftWrapper}>
                          <label className={styles.ProfileFormInputaLabel}>
                            Phone Number
                          </label>
                          <TextField
                            className={styles.ProfileFormInput}
                            label='Phone Number'
                            value={this.state.PhoneNumber}
                            onChange={this.onChangePhoneNumber}
                            variant='outlined'
                            type='number'
                            pattern='[0-9]{3}-[0-9]{2}-[0-9]{3}'
                          />
                        </div>
                      </div>
                      {/* Company Title */}
                      <div
                        className={`${styles.dflex} ${styles.flexcolumn} ${styles.resDflexJccAic} ${styles.ProfileFormRow}`}
                      >
                        <div className={styles.ProfileLeftWrapper}>
                          <label className={styles.ProfileFormInputaLabel}>
                            Company *
                          </label>
                          <TextField
                            className={styles.ProfileFormInput}
                            label='Company '
                            value={this.state.Company}
                            onChange={this.onChangeCompany}
                            required
                            type='text'
                          />
                        </div>
                        <div className={styles.ProfiRightftWrapper}>
                          <label className={styles.ProfileFormInputaLabel}>
                            Title *
                          </label>
                          <TextField
                            id='outlined-basic'
                            className={styles.ProfileFormInput}
                            value={this.state.Title}
                            onChange={this.onChangeTitle}
                            label='Title '
                            variant='outlined'
                            required
                            type='text'
                          />
                        </div>
                      </div>
                      {this.state.err && <p className={`${styles.ErrorMessageParaSignUp} ${styles.m0}`}>{this.state.err}</p>}
                      {/* Btns */}
                      <div className={styles.ProfileFooterBtn}>
                        {/* <Link to="how-to-use" className={styles.skpibtn}>Skip</Link> */}
                        <button
                          type='submit'
                          className={`HeadingsButtonFontOlas ${styles.nextpageibtn}`}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import styles from './Question.module.css'
import vuesaxlineartrash from "./../Images/vuesaxlineartrash.svg";
import vuesaxlinearsend from "./../Images/vuesaxlinearsend.svg";
import vuesaxlinearedit2 from "./../Images/vuesaxlinearedit2.svg";
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Card, Col, Container, Row, Button, Table } from "react-bootstrap";
import { TextField, Typography, Box } from "@mui/material";
import errorIcon from '../Images/icons/Errorindicator.svg';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import add from '../Images/add.svg';
const Deletestyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 643,
    maxWidth: 643,
    minHeight: 477,
    bgcolor: 'background.paper',
    border: 'transparent',
    boxShadow: 24,
    p: 6,
    borderRadius: '15px',
    textAlign: 'center',
};
class ModalClass extends React.Component {
    constructor(props) {
        super(props)

        this.getModal = this.getModal.bind(this)
        this.hideModal = this.hideModal.bind(this)

        this.getModalDelete = this.getModalDelete.bind(this)
        this.hideModalDelete = this.hideModalDelete.bind(this)

        this.getModalMenu = this.getModalMenu.bind(this)
        this.hideModalMenu = this.hideModalMenu.bind(this)
        this.delete = this.delete.bind(this)


        this.onQuestionSubmit = this.onQuestionSubmit.bind(this);
        this.onChangeQuestion = this.onChangeQuestion.bind(this);
        this.onChangeQuestionDescription = this.onChangeQuestionDescription.bind(this);
        this.onChangeQuestionLabel = this.onChangeQuestionLabel.bind(this);
        this.onChangeQuestionNer = this.onChangeQuestionNer.bind(this);
        this.state = {
            show: false,
            questionText: this.props.name,
            questionDescript: this.props.description,
            questionNewLabel: '',
            questionNewLabelCode: this.props.selectLabel,
            questionner: '',
            questionNer: '',
            questionKindSelect: '',
            id: this.props.passid,
            showDelete: false,
            showMenu: true
        }


    }
    async componentDidMount() {
        const cookies = new Cookies();
        var config = cookies.get("token")

        if (!cookies.get("token")) {
            config = localStorage.getItem("token")
            //console.log("token: ", config)
        }
        axios.get(process.env.REACT_APP_LOGIN_URL + '/questionbank/questionkinddtls', {
            headers: {
                'Authorization': 'Token ' + config
            }
        }).then(
            response => {
                var setLabels = []

                for (let i = 0; i < response.data.message.length; i++) {
                    if (!setLabels.some(item => item.code === response.data.message[i].questionkindcode)) {
                        setLabels.push({ code: response.data.message[i].questionkindcode, desc: response.data.message[i].questionkindcode__kinddescription });
                    }
                }
                this.setState({ questionKindSelect: response.data.message })


            }

        ) .catch((error) => {
            console.log(error);
            if (error.response && error.response.status === 401) {
              // Clear cookies or perform any other action
              // Replace 'clearCookies()' with the actual function to clear cookies
              const cookies = new Cookies();
              cookies.remove('token', {
                        path: '/',
                        domain: 'squire-olas.com', // Replace with your desired domain
                      }); //clear storage so no user is stored in the session
              cookies.remove("loggedin"); //clear storage so no user is stored in the session
              cookies.remove("loginfrommail"); //clear storage so no user is stored in the session
              cookies.remove("redirect"); //clear storage so no user is stored in the session
              cookies.remove("Isadmin"); //clear storage so no user is stored in the session
              window.location.href='/'
            }
          });
    }
    onChangeQuestion(e) {

        this.setState({ questionText: e });
    }
    onChangeQuestionDescription(e) {

        this.setState({ questionDescript: e });
    }
    onChangeQuestionLabel(e) {
        this.setState({ questionNewLabel: e.target.value });
    }
    onChangeQuestionNer(e) {
        this.setState({ questionner: e.target.value });
    }
    getModal() {
        this.setState({ show: true });
    };

    hideModal() {
        // window.location.href = '/questionbank'
        this.setState({ show: false });
    };

    getModalDelete() {
        this.setState({ showDelete: true });
    };

    hideModalDelete() {
        // window.location.href = '/questionbank'
        this.setState({ showDelete: false });
    };

    getModalMenu() {
        this.setState({ showMenu: true });
    };

    hideModalMenu() {
        window.location.href = '/questionbank'
        this.setState({ showMenu: false });
    };



    delete() {
        const cookies = new Cookies();
        var config = cookies.get("token")

        if (!cookies.get("token")) {
            config = localStorage.getItem("token")
            //console.log("token: ", config)
        }
        axios({
            method: "POST",
            url: process.env.REACT_APP_LOGIN_URL + '/questionbank/questiondelete',
            headers: {
                'Authorization': 'Token ' + config
            },
            data: {
                id: this.props.passid,

                archive: 1,
            }
        })
            //get the token from the backend and set the authentication to true
            //store the token in session storage
            .then(
                response => {
                    window.location.href = '/questionbank'
                    //authentication is set to true, allowing for access to protected pages

                }
            )
            .catch((err) => {
                console.log(err)
            });
    }
    onQuestionSubmit(e) {
        e.preventDefault();
        const cookies = new Cookies();
        var config = cookies.get("token")
        console.log(this.state.questionNewLabel, this.state.questionText, this.state.questionDescript, this.state.questionner)

        if (!cookies.get("token")) {
            config = localStorage.getItem("token")
            //console.log("token: ", config)
        }


        if (this.state.questionNewLabel == null || this.state.questionNewLabel == "" || this.state.questionNewLabel == undefined
            || this.state.questionText == null || this.state.questionText == "" || this.state.questionText == undefined
            || this.state.questionDescript == null || this.state.questionDescript == "" | this.state.questionDescript == undefined) {
            alert("Fill in All fields")
        }
        else {
            axios({
                method: "POST",
                url: process.env.REACT_APP_LOGIN_URL + '/questionbank/questionupdate',
                headers: {
                    'Authorization': 'Token ' + config
                },
                data: {
                    id: this.props.passid,
                    questionkindcode: this.state.questionNewLabel,
                    questiontext: this.state.questionText,
                    questionlabel: this.state.questionner,
                    questiondescription: this.state.questionDescript,
                    nertype: this.state.questionner,
                    archive: 0,
                    questionkindcode__kinddescription: this.state.questionNewLabel


                }
            })
                //get the token from the backend and set the authentication to true
                //store the token in session storage
                .then(
                    response => {
                        window.location.href = '/questionbank'
                        //authentication is set to true, allowing for access to protected pages

                    }
                )
                .catch((err) => {
                    console.log(err)
                });
        }

    }
    render() {
        const { show, questionNer, questionKindSelect, showDelete, showMenu } = this.state
        //console.log("quuestion stuff", this.props.selectLabel);
        //const {questionText} = this.props.name

        return (
            <React.Fragment>
                {this.props.show && (
                    <div className={styles.modalStyle}>



                        <Modal
                            size="md"
                            className={styles.modalPopup}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered show={showMenu} onHide={this.hideModalMenu}>
                            <Modal.Header closeButton>
                                {this.props.name}
                            </Modal.Header>
                            <Modal.Body>
                                <div className="rectangle-812">
                                    <div className="rectangle-813">
                                        <div className="flex-container-18">
                                            <img
                                                className="vuesaxlinearedit-2"
                                                src={vuesaxlinearedit2}
                                            />
                                            <button className={styles.primarybutton4} onClick={this.getModal}>Edit Question</button>
                                        </div>
                                        <hr className="line-15" />
                                    </div>
                                    <div className="flex-container-19">
                                        <img className="vuesaxlineartrash" src={vuesaxlineartrash} />
                                        <button className={styles.primarybutton4} onClick={this.getModalDelete}>Delete</button>
                                    </div>


                                </div></Modal.Body>
                            <Modal.Footer>
                                <Button className={styles.closebutton} variant="secondary" onClick={this.hideModalMenu}>
                                    Close
                                </Button>

                            </Modal.Footer>
                        </Modal>

                        <Modal
                            size="lg"
                            className={styles.modalPopupLabel}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered show={show} onHide={this.hideModal}>
                            <Modal.Header closeButton>
                                <span className={styles.createlabel} >Edit Question</span>
                            </Modal.Header>

                            <Modal.Body>
                                <div className={styles.groupLabel} >
                                    <div className={styles.rectanglePopupLabel} >

                                        <TextField id="outlined-basic" label="Question" variant="outlined" value={this.state.questionText}
                                            contentEditable={true}
                                            onChange={(e) => this.onChangeQuestion(e.target.value)} />
                                        <br></br>

                                        <TextField id="outlined-basic" label="Description" variant="outlined" value={this.state.questionDescript}
                                            contentEditable={true}
                                            onChange={(e) => this.onChangeQuestionDescription(e.target.value)} />
                                        <br></br>
                                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                                            <InputLabel id="demo-simple-select-filled-label">Label</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                label="Label"
                                                onChange={this.onChangeQuestionLabel}
                                                required
                                            >
                                                {Array.isArray(questionKindSelect)
                                                    ? questionKindSelect.map(element => {
                                                        return <MenuItem value={element[0]} >{element[1]}</MenuItem>

                                                    })
                                                    : null}

                                            </Select>

                                        </FormControl>


                                        <br></br>
                                        {/*<FormControl sx={{ m: 1, minWidth: 120 }}>
                                            <InputLabel id="demo-simple-select-filled-label">Type</InputLabel>
                                            <Select

                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                label="Type"

                                                onChange={this.onChangeQuestionNer}
                                                required
                                            >
                                                {Array.isArray(this.props.selectLabel)
                                                    ? this.props.selectType.map(element => {
                                                        return <MenuItem value={element.nertype} >{element.nertype}</MenuItem>

                                                    })
                                                    : null}

                                            </Select>

                                                </FormControl>*/}
                                        <div className={styles.flexcontainerLabel1} >
                                            <button className={styles.shapeLabel} onClick={this.hideModal} >Cancel</button>
                                            <button className={styles.shapeLabel1} onClick={this.onQuestionSubmit} >Edit Question</button>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>

                        </Modal>


                        <Modal                            
                            className={styles.modalPopupLabel}
                            aria-labelledby="modal-modal-title"
                            centered show={showDelete} onHide={this.hideModalDelete}>
                            <Box sx={Deletestyle}>
                            <img onClick={this.hideModalDelete} style={{ position: 'relative', cursor: "pointer", left: '280px', top: '-35px' }} src={add} />
                            <div className={styles.ErrorMessageImageWrapper}>
                                <img src={errorIcon} />
                            </div>
                            <Modal.Body>
                            <Typography style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "16px", lineHeight: "24px", textAlign: 'center', color: "#1D103B", margin: " 20px auto 20px auto", maxWidth: "374px" }}>
                            Are you sure you want to delete this question?<br></br>
                                            {this.props.name}
                            </Typography>
                            <div style={{ marginTop: "50px" }} className={styles.EditUSerBtns}>
                                <div onClick={this.hideModalDelete} style={{ marginRight: "10px" }} className={`${styles.EditUserCancelBtn}`}>No</div>
                                <div onClick={this.delete} style={{ marginLeft: "10px" }} className={`${styles.EditUserUpdateBtn}`}>Yes</div>
                            </div>
                            </Modal.Body>
                            </Box>
                        </Modal>

                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default ModalClass;

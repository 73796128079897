import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import axios from "axios";
import knight from "./../Images/Olas-Chibi.jpg";
import styles from "./Support.module.css";
import Alert from "react-bootstrap/Alert";
import "antd/dist/antd.css";
import Cookies from "universal-cookie";

import UniversalCss from "../Universal.module.css";

import PDF from "./../Images/PDF.png";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { NavbarTop } from "../Components/Navbar";
import { NavbarLeft } from "../Components/Nav-Left/NavLeft";
import { TextField } from "@mui/material";

import Textarea from "@mui/joy/Textarea";

var images = "";
var pageSize = 6;
export class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.onChangeName = this.onChangeName.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.openHandler = this.openHandler.bind(this);

    this.popupCloseHandler = this.popupCloseHandler.bind(this);
    this.closeTranscribeAlert = this.closeTranscribeAlert.bind(this);
    this.openTranscribeAlert = this.openTranscribeAlert.bind(this);
    this.closeTranscribeFaultAlert = this.closeTranscribeFaultAlert.bind(this);
    this.openTranscribeFaultAlert = this.openTranscribeFaultAlert.bind(this);

    this.closeSummarizeAlert = this.closeSummarizeAlert.bind(this);
    this.openSummarizeAlert = this.openSummarizeAlert.bind(this);
    this.closeSummarizeFaultAlert = this.closeSummarizeFaultAlert.bind(this);
    this.openSummarizeFaultAlert = this.openSummarizeFaultAlert.bind(this);

    this.closeExtractAlert = this.closeExtractAlert.bind(this);
    this.openExtrctAlert = this.openExtrctAlert.bind(this);
    this.closeExtractFaultAlert = this.closeExtractFaultAlert.bind(this);
    this.openExtractFaultAlert = this.openExtractFaultAlert.bind(this);
    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeMessage = this.onChangeMessage.bind(this);
    this.state = {
      files: [],
      Name: "",
      Type: "",
      FileName: "",
      title: "",
      callName: "",
      image: null,
      fileUrl: [],
      User: "",
      visibility: false,
      recruiter: [],
      duration: "",
      person: "",
      show: false,
      alerting: false,
      transcribeAlert: false,
      transcribeFaultAlert: false,
      summarizeAlert: false,
      summarizeFaultAlert: false,
      extractAlert: false,
      extractFaultAlert: false,
      imageName: "",
      totalPage: 0,
      current: 1,
      minIndex: 0,
      maxIndex: 0,
      companyID: "",
      userLogged: "",
      hashFile: "",
      candidate_name: "",
      candidate_email: "",
      candidate_phone: "",
      firstName: "",
      lastName: "",
      email: "",
      message: "",
    };
  }

  onChangeName(e) {
    this.setState({ Name: e.target.value });
  }
  // post to create new group
  onSubmit(e) {
    e.preventDefault();
    //post request to post the data
    axios({
      method: "POST",
      url: process.env.REACT_APP_TESTING_URL + "/api/create",
      data: {
        file_name: this.state.Name,
      },
    });
  }
  async componentDidMount() {
    const cookies = new Cookies();
    var config = cookies.get("token");
    if (!cookies.get("token")) {
      config = localStorage.getItem("token");
      //console.log("token: ", config)
    }

    axios
      .get(process.env.REACT_APP_LOGIN_URL + "/login/users", {
        headers: {
          Authorization: "Token " + config,
        },
      })
      .then((response) => {
        this.setState({ userLogged: response.data[0].username });
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.status === 401) {
          // Clear cookies or perform any other action
          // Replace 'clearCookies()' with the actual function to clear cookies
          const cookies = new Cookies();
          cookies.remove('token', {
                    path: '/',
                    domain: 'squire-olas.com', // Replace with your desired domain
                  }); //clear storage so no user is stored in the session
          cookies.remove("loggedin"); //clear storage so no user is stored in the session
          cookies.remove("loginfrommail"); //clear storage so no user is stored in the session
          cookies.remove("redirect"); //clear storage so no user is stored in the session
          cookies.remove("Isadmin"); //clear storage so no user is stored in the session
          window.location.href='/'
        }
      });
  }
  SettingsPage() {
    const id = document.getElementById("lang").value;
    if (id == "settings") {
      window.location.href = "/settings";
    }
    if (id == "logout") {
      alert("Logged out");
      const cookies = new Cookies();
      cookies.remove("token"); //clear storage so no user is stored in the session
      cookies.remove("loggedin"); //clear storage so no user is stored in the session
      //redirect user to the home page, which is protected, so will redirect to login page
      window.location.href = "/";
    }
  }

  // handle the change of values
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  // handle the file
  handleImageChange = (e) => {
    this.setState({
      image: e.target.files[0],
    });
  };
  handleChangeCandidateName = (e) => {
    this.setState({
      candidate_name: e.target.value,
    });
  };
  handleChangeCandidateEmail = (e) => {
    this.setState({
      candidate_email: e.target.value,
    });
  };

  handleChangeCandidatePhone = (e) => {
    this.setState({
      candidate_phone: e.target.value,
    });
  };



  wrapperFunction() {
    this.transcribeFile();
    this.summarizeFile();
  }
  popupCloseHandler() {
    this.setState({ show: false });
    window.location.reload();
  }
  openAlert() {
    this.setState({ alerting: true });
    //console.log("button worjing")
  }
  closeTranscribeAlert() {
    this.setState({ transcribeAlert: false });
  }
  closeTranscribeFaultAlert() {
    this.setState({ transcribeFaultAlert: false });
  }
  openTranscribeAlert() {
    this.setState({ transcribeAlert: true });
    //console.log("button worjing")
  }
  openTranscribeFaultAlert() {
    this.setState({ transcribeFaultAlert: true });
  }

  closeSummarizeAlert() {
    this.setState({ summarizeAlert: false });
  }
  closeSummarizeFaultAlert() {
    this.setState({ summarizeFaultAlert: false });
  }
  openSummarizeAlert() {
    this.setState({ summarizeAlert: true });
    // console.log("button worjing")
  }
  openSummarizeFaultAlert() {
    this.setState({ summarizeFaultAlert: true });
  }

  closeExtractAlert() {
    this.setState({ extractAlert: false });
  }
  closeExtractFaultAlert() {
    this.setState({ extractFaultAlert: false });
  }
  openExtrctAlert() {
    this.setState({ extractAlert: true });
    // console.log("button worjing")
  }
  openExtractFaultAlert() {
    this.setState({ extractFaultAlert: true });
  }

  openHandler() {
    this.setState({ show: true });
    //console.log("button worjing")
  }

  onChangeFirstName(e) {
    this.setState({ firstName: e.target.value });
  }

  onChangeLastName(e) {
    this.setState({ lastName: e.target.value });
  }
  onChangeEmail(e) {
    this.setState({ email: e.target.value });
  }
  onChangeMessage(e) {
    this.setState({ message: e.target.value });
  }

  sendEmail = (e) => {
    e.preventDefault();
    const cookies = new Cookies();
    var config = cookies.get("token");
    if (!cookies.get("token")) {
      config = localStorage.getItem("token");
      //console.log("token: ", config)
    }

    console.log(this.state.firstName, this.state.lastName, this.state.email, this.state.message )

    axios({
      method: "POST",
      url: process.env.REACT_APP_BACKEND_URL +'/crm/sendsupeml',
      //url: 'http://127.0.0.1:8000/integration_ats/get_credentials',
      headers: {
          'Authorization': 'Token ' + config
      },
      data: {
        email_msg: this.state.firstName + " " + this.state.lastName + " "+this.state.email+ "\n=====================\nMessage : \n"+this.state.message,
        email: "rory@olas.io",
        email_sub: "Customer Support Query : For CustID : <custid> - "+this.state.firstName + " " +this.state.lastName

      }
  })

      .then(
          response => {
              console.log(response.data);
              this.openTranscribeAlert();
          }
      )
      .catch((err) => {
          console.log(err)
          alert("Hmm, something went wrong");
          //this.openTranscribeFaultAlert()
      });

    /*emailjs
      .sendForm(
        "service_x36arqq",
        "template_gtq9izg",
        this.form.current,
        "P9LbViaMIkKUwdn9z"
      )
      .then(
        (result) => {
          console.log(result);
          this.openTranscribeAlert();
        },
        (error) => {
          console.log(error);
          alert("Hmm, something went wrong");
        }
      );*/
  };
  render() {
    const { show, alerting, userLogged, transcribeAlert } = this.state;

    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container className={UniversalCss.GridContainerWrapperHegih}>
          <Grid item md={1.7} xs={2} className={UniversalCss.LeftNavbarShadow}>
            <NavbarLeft />
          </Grid>
          <Grid
            item
            md={10.3}
            xs={10}
            className={UniversalCss.navbarAndRouteWrapper}
          >
            <NavbarTop PageData={"Support"} />

            <div className={styles.flexcontainer12}>
              <form
                ref={this.form}
                className={styles.rectangle824}
                onSubmit={this.sendEmail}
                style={{ borderRadius: "15px", minHeight: " 90vh" }}
              >
                <div className={styles.rectangle807}>
                  <span
                    className={`HeadingsButtonFontOlas ${styles.contactus}`}
                  >
                    contact us
                  </span>
                </div>

                <div className={styles.flexcontainer14}>
                  <TextField
                    id="outlined-basic"
                    onChange={this.onChangeFirstName}
                    className={styles.rectangle814}
                    label="First Name"
                    variant="outlined"
                    required
                  />
                  <br></br>
                  <TextField
                    id="outlined-basic"
                    onChange={this.onChangeLastName}
                    className={styles.rectangle1120}
                    label="Last Name"
                    variant="outlined"
                    required
                  />
                  <br></br>
                </div>
                <span className={styles.email}>Email</span>

                <TextField
                  id="outlined-basic"
                  onChange={this.onChangeEmail}
                  className={styles.rectangle1121}
                  label="Email"
                  variant="outlined"
                  required
                  type="email"
                />
                <br></br>
                <span className={styles.message}>Message</span>
                <Textarea
                  className={styles.rectangle8141}
                  minRows={5}
                  placeholder="Message"
                  type="text"
                  onChange={this.onChangeMessage}
                  size="lg"
                  required
                />

                <input
                  className={`HeadingsButtonFontOlas ${styles.sendEmail}`}
                  type="submit"
                  value="Contact Us"
                />
                <Alert
                  className={styles.modalPopup}
                  show={transcribeAlert}
                  variant="success"
                >
                  <Alert.Heading>Success!</Alert.Heading>
                  <p>
                    Thank you for getting in touch. A member of our team will
                    review and respond within 24 hours.
                  </p>
                </Alert>
              </form>
              <div className={styles.flexcontainer15}>
                <div className={styles.rectangle808}>
                  <div className={styles.rectangle809}>
                    <span className={styles.quickoverview}>Quick Overview</span>
                  </div>
                  <span className={styles.shortvideo}>
                    Short overview video
                  </span>
                  
                  <iframe
                            className={styles.PopupVedioIframe}
                            
                            src="https://www.youtube.com/embed/jmPV11FL0a0"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                        />
                    
                </div>
                <div className={styles.rectangle810}>
                  <div className={styles.rectangle809}>
                    <span className={styles.quickoverview}>
                      Terms and policies
                    </span>
                  </div>
                  <div className={styles.gridcontainer}>
                    <div className={styles.griditem}>
                      {" "}
                      <a
                        className={styles.PDF}
                        target="_blank"
                        href={"https://squireapp.io/privacy-policy/"}
                      >
                        <img className={styles.Pics} src={PDF} />
                      </a>
                    </div>
                    <div className={styles.griditem}>
                      <a
                        className={styles.PDF}
                        target="_blank"
                        href={"https://squireapp.io/terms/"}
                      >
                        <img className={styles.Pics} src={PDF} />
                      </a>
                    </div>

                    <div className={styles.griditem}>
                      <a
                        className={styles.PDF}
                        target="_blank"
                        href={"https://squireapp.io/privacy-policy/"}
                      >
                        {" "}
                        <p className={styles.TextPDF}>Privacy Policy</p>{" "}
                      </a>
                    </div>
                    <div className={styles.griditem}>
                      <a
                        className={styles.PDF}
                        target="_blank"
                        href={"https://squireapp.io/terms/"}
                      >
                        {" "}
                        <p className={styles.TextPDF}>Master SaaS Terms</p>{" "}
                      </a>
                    </div>

                    <div className={styles.griditem}>
                      {" "}
                      <a
                        className={styles.PDF}
                        target="_blank"
                        href={"https://squireapp.io/acceptableusepolicy/"}
                      >
                        <img className={styles.Pics} src={PDF} />
                      </a>
                    </div>
                    <div className={styles.griditem}>
                      <a
                        className={styles.PDF}
                        target="_blank"
                        href={"https://squireapp.io/data-protection/"}
                      >
                        <img className={styles.Pics} src={PDF} />
                      </a>
                    </div>

                    <div className={styles.griditem}>
                      <a
                        className={styles.PDF}
                        target="_blank"
                        href={"https://squireapp.io/acceptableusepolicy/"}
                      >
                        {" "}
                        <p className={styles.TextPDF}>
                          Acceptable use policy
                        </p>{" "}
                      </a>
                    </div>
                    <div className={styles.griditem}>
                      <a
                        className={styles.PDF}
                        target="_blank"
                        href={"https://squireapp.io/data-protection/"}
                      >
                        {" "}
                        <p className={styles.TextPDF}>Data Protection</p>{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

import React, { Component } from 'react'
import { Box, Button, Modal } from '@mui/material';
import { Typography } from 'antd';
import add from '../../Images/add.svg';
import errorIcon from '../../Images/icons/Errorindicator.svg';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 643,
    maxWidth: 643,
    minHeight: 477,
    bgcolor: 'background.paper',
    border: 'transparent',
    boxShadow: 24,
    p: 9,
    borderRadius: '15px',
    textAlign: 'center',
};

export default class ErrorModal extends Component {
    constructor() {
        super();
    }

    render(props) {
        const { open, handleClose, errorMessage } = this.props
        return (
            <Modal open={open} aria-labelledby="modal-modal-title">
                <Box sx={style}>
                    <img onClick={this.props.handleClose} style={{ position: 'relative', cursor: "pointer", left: '280px', bottom: '55px' }} src={add} /><br />
                    <img src={errorIcon} />
                    <Typography style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "16px", lineHeight: "24px", textAlign: 'center', color: "#1D103B", margin: " 20px 0 20px 0" }}>
                        {errorMessage != null ? errorMessage : "Error "}
                    </Typography>
                    <Button onClick={handleClose} sx={{ backgroundColor: "#d8045c", color: "#fff", width: 205, height: 45, '&:hover': { backgroundColor: "#8c5df5" } }}>Ok</Button>
                </Box>
            </Modal>
        )
    }
}

import React from "react";

import vuesaxlinearmore4 from "./../Images/vuesaxlinearmore4.svg";

import vuesaxlinearelement from "./../Images/vuesaxlinearelement.svg";
import axios from "axios";
import Cookies from "universal-cookie";

import group48096677 from "./../Images/group48096677.svg";

import vuesaxlinearcall from "./../Images/vuesaxlinearcall.svg";
import vuesaxlinearmessage from "./../Images/vuesaxlinearmessage.svg";
import { Alert, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import maskGroup from "./../Images/maskGroup.svg";
import Modal from "react-bootstrap/Modal";
import divider from "./../Images/divider.svg";
import knight from "./../Images/Olas-Chibi.jpg";
// import { Card, Col, Container, Row, Button, Table } from "react-bootstrap";
import ModalClass from "./Modal";
import RoleModalClass from "./RoleModal";
import profile from "./../Images/Profile.jpg";

import "antd/dist/antd.css";
import styles from "./Question.module.css";
import { Avatar } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { NavbarTop } from "../Components/Navbar";
import { NavbarLeft } from "../Components/Nav-Left/NavLeft";
import { TextField } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import { Pagination } from "antd";
import UniversalCss from "../Universal.module.css";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

var pageSize = 6;
var pageSize1 = 6;
export class Question extends React.Component {
  constructor() {
    super();
    this.openHandler = this.openHandler.bind(this);
    this.openLableHandler = this.openLableHandler.bind(this);
    this.openQuestionHandler = this.openQuestionHandler.bind(this);
    this.openRoleHandler = this.openRoleHandler.bind(this);

    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeQuestionKindCode = this.onChangeQuestionKindCode.bind(this);
    //this.onChangeQuestionKindCode = this.onChangeQuestionKindCode.bind(this);
    this.onChangeQuestionKindDESC = this.onChangeQuestionKindDESC.bind(this);
    this.onChangeQuestionKindLABEL = this.onChangeQuestionKindLABEL.bind(this);

    this.onQuestionSubmit = this.onQuestionSubmit.bind(this);
    this.onChangeQuestion = this.onChangeQuestion.bind(this);
    this.onChangeQuestionDescription =
      this.onChangeQuestionDescription.bind(this);
      this.onChangeBullhornFieldID = this.onChangeBullhornFieldID.bind(this)
    this.onChangeQuestionLabel = this.onChangeQuestionLabel.bind(this);
    this.onChangeQuestionNer = this.onChangeQuestionNer.bind(this);

    this.onSubmitRole = this.onSubmitRole.bind(this);
    this.onChangeRoleLabel = this.onChangeRoleLabel.bind(this);
    //this.onChangeQuestionKindCode = this.onChangeQuestionKindCode.bind(this);
    this.onChangeRoleTitle = this.onChangeRoleTitle.bind(this);
    this.onChangeRoleDescription = this.onChangeRoleDescription.bind(this);

    this.popupCloseHandler = this.popupCloseHandler.bind(this);
    this.popupCloseLabelHandler = this.popupCloseLabelHandler.bind(this);
    this.popupCloseQuestionHandler = this.popupCloseQuestionHandler.bind(this);
    this.popupCloseRoleHandler = this.popupCloseRoleHandler.bind(this);
    this.GetData = this.GetData.bind(this);
    this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);

    this.state = {
      userLogged: "",
      show: false,
      questions: [],
      search: "",
      showLabel: "",
      questionkindcode: "",
      questionkindDesc: "",
      questionkindLabel: "",
      questionKindSelect: "",
      questionNer: "",
      showQuestion: false,
      questionText: "",
      questionDescript: "",
      questionNewLabel: "",
      questionner: "",
      showRole: false,
      roleLabel: "",
      roleTitle: "",
      roleDescription: "",
      allquestionsShow: true,
      rolequestionsShow: false,
      rolesDisplay: [],
      showfilterQuestions: true,
      showModal: 0,
      totalPage: 0,
      current: 1,
      minIndex: 0,
      maxIndex: 0,

      totalPage1: 0,
      current1: 1,
      minIndex1: 0,
      maxIndex1: 0,
      snackbaractive: false,
      SnackbarMessage: "",
      snackbaractiveError: false,
      activeDiv: 'div1',
      bullhornfieldID: ""
    };
  }
  handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase(); // Convert the search term to lower case
    this.setState({
      search: searchTerm,
      current: 1, // Reset pagination to the first page
      minIndex: 0,
      maxIndex: pageSize, // Assuming pageSize is the number of items per page
      current1: 1, // If you have a separate pagination for roles, reset that as well
      minIndex1: 0,
      maxIndex1: pageSize1
    });
  };
  /*getRole = value => {
        this.setState({ showModal: value });
    };*/
  handleDivClick = (divName) => {
    this.setState({ activeDiv: divName });
    // Store the active state in local storage
    localStorage.setItem('activeDiv', divName);
  };
  handleSnackbarClose = () => {
    this.setState({ snackbaractive: false, snackbaractiveError: false });
  };

  getModal = (value) => {
    this.setState({ showModal: value });
  };

  hideModal = (value) => {
    this.setState({ showModal: 0 });
  };
  showAllQuestions() {
    this.setState({ allquestionsShow: true });
    this.setState({ showfilterQuestions: true });
    this.setState({ rolequestionsShow: false });
    this.setState({ textFieldValue: "" });
  }
  showRoleQuestions() {
    this.setState({ allquestionsShow: false });
    this.setState({ showfilterQuestions: false });
    this.setState({ rolequestionsShow: true });
    this.setState({ textFieldValue: "" });
  }

  popupCloseHandler() {
    this.setState({ show: false });
  }
  popupCloseLabelHandler() {
    this.setState({ showLabel: false });
  }

  popupCloseRoleHandler() {
    this.setState({ showRole: false });
  }

  popupCloseQuestionHandler() {
    this.setState({ showQuestion: false });
  }
  SettingsPage() {
    const id = document.getElementById("lang").value;
    if (id == "settings") {
      window.location.href = "/settings";
    }
    if (id == "logout") {
      alert("Logged out");
      const cookies = new Cookies();
      cookies.remove("token"); //clear storage so no user is stored in the session
      cookies.remove("loggedin"); //clear storage so no user is stored in the session
      localStorage.clear();
      //redirect user to the home page, which is protected, so will redirect to login page
      window.location.href = "/";
    }
  }
  onChangeQuestionKindCode(e) {
    this.setState({ questionkindcode: e.target.value });
  }
  onChangeQuestionKindDESC(e) {
    this.setState({ questionkindDesc: e.target.value });
  }
  onChangeQuestionKindLABEL(e) {
    this.setState({ questionkindLabel: e.target.value });
  }

  onChangeQuestion(e) {
    this.setState({ questionText: e.target.value });
  }
  onChangeQuestionDescription(e) {
    this.setState({ questionDescript: e.target.value });
  }
  onChangeBullhornFieldID(e){
    this.setState({bullhornfieldID: e.target.value})
  }
  onChangeQuestionLabel(e) {
    this.setState({ questionNewLabel: e.target.value });
  }
  onChangeQuestionNer(e) {
    this.setState({ questionner: e.target.value });
  }

  onChangeRoleLabel(e) {
    this.setState({ roleLabel: e.target.value });
  }
  onChangeRoleTitle(e) {
    this.setState({ roleTitle: e.target.value });
  }
  onChangeRoleDescription(e) {
    this.setState({ roleDescription: e.target.value });
  }

  openLableHandler() {
    this.setState({ showLabel: true });
  }
  openQuestionHandler() {
    this.setState({ showQuestion: true });
  }
  openRoleHandler() {
    this.setState({ showRole: true });
  }

  openHandler() {
    this.setState({ show: !this.state.show });
    //console.log("button worjing")
  }
  async componentDidMount() {
    this.GetData();
  }

  GetData() {
    const cookies = new Cookies();
    var config = cookies.get("token");

    if (!cookies.get("token")) {
      //console.log("here wer are anfsfsdgd")
      config = localStorage.getItem("token");
      //console.log("token: ", config)
    }

    axios
      .get(process.env.REACT_APP_LOGIN_URL + "/login/users", {
        headers: {
          Authorization: "Token " + config,
        },
      })
      .then((response) => {
        this.setState({ userLogged: response.data[0].username });
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.status === 401) {
          // Clear cookies or perform any other action
          // Replace 'clearCookies()' with the actual function to clear cookies
          const cookies = new Cookies();
          cookies.remove('token', {
            path: '/',
            domain: 'squire-olas.com', // Replace with your desired domain
          }); //clear storage so no user is stored in the session
          cookies.remove("loggedin"); //clear storage so no user is stored in the session
          cookies.remove("loginfrommail"); //clear storage so no user is stored in the session
          cookies.remove("redirect"); //clear storage so no user is stored in the session
          cookies.remove("Isadmin"); //clear storage so no user is stored in the session
          window.location.href = '/'
        }
      });

    axios
      .get(process.env.REACT_APP_LOGIN_URL + "/questionbank/question", {
        headers: {
          Authorization: "Token " + config,
        },
      })
      .then((response) => {
        this.setState({
          questions: response.data.message,
          totalPage: response.data.length / pageSize,
          minIndex: 0,
          maxIndex: pageSize,
        });
      })
      .catch((error) => console.log(error));

    axios
      .get(process.env.REACT_APP_LOGIN_URL + "/questionbank/questionkinddtls", {
        headers: {
          Authorization: "Token " + config,
        },
      })
      .then((response) => {
        console.log(response.data.message, "here");
        //this.setState({ questionKindSelect: response.data.message })

        var setLabels = [];

        for (let i = 0; i < response.data.message.length; i++) {
          setLabels.push({
            code: response.data.message[i][0],
            desc: response.data.message[i][1],
          });
        }
        this.setState({ questionKindSelect: response.data.message });
      })
      .catch((error) => console.log(error));

    axios
      .get(process.env.REACT_APP_LOGIN_URL + "/questionbank/questionner", {
        headers: {
          Authorization: "Token " + config,
        },
      })
      .then((response) => {
        this.setState({ questionNer: response.data.message });
      })
      .catch((error) => console.log(error));

    axios
      .get(process.env.REACT_APP_LOGIN_URL + "/workorder/role", {
        headers: {
          Authorization: "Token " + config,
        },
      })
      .then((response) => {
        console.log(response.data.message)
        this.setState({
          rolesDisplay: response.data.message,
          totalPage1: response.data.length / pageSize1,
          minIndex1: 0,
          maxIndex1: pageSize,
        });
      })
      .catch((error) => console.log(error));

    const activeDiv = localStorage.getItem('activeDiv');
    if (activeDiv) {
      this.setState({ activeDiv });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const cookies = new Cookies();
    var config = cookies.get("token");

    if (!cookies.get("token")) {
      config = localStorage.getItem("token");
      //console.log("token: ", config)
    }
    axios({
      method: "POST",
      url: process.env.REACT_APP_LOGIN_URL + "/questionbank/label_add",
      headers: {
        Authorization: "Token " + config,
      },
      data: {
        questionkindcode: this.state.questionkindcode,
        kinddescription: this.state.questionkindDesc,
        kindlabel: this.state.questionkindLabel,
      },
    })
      //get the token from the backend and set the authentication to true
      //store the token in session storage
      .then((response) => {
        console.log(response.data);
        //authentication is set to true, allowing for access to protected pages
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onQuestionSubmit(e, page) {
    e.preventDefault();
    const totalPages = page; // Function to calculate the total number of pages
    const lastPage = totalPages; // Set the last page as the total number of pages
    const cookies = new Cookies();
    var config = cookies.get("token");
    console.log(this.state.bullhornfieldID)

    if (!cookies.get("token")) {
      config = localStorage.getItem("token");
      //console.log("token: ", config)
    }
    console.log(config);
    if (this.state.questionner == null || this.state.questionner == "") {
      this.state.questionner = "Person";
      // console.log(
      //   "FJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJ",
      //   this.state.questionNewLabel,
      //   this.state.questionText,
      //   this.state.questionner,
      //   this.state.questionDescript
      // );
    }
    if (
      this.state.questionNewLabel == null ||
      this.state.questionNewLabel == ""
    ) {
      this.state.questionNewLabel = "FND";
      //console.log("FJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJ",this.state.questionNewLabel,  this.state.questionText, this.state.questionner, this.state.questionDescript )
    }

    if (
      this.state.questionNewLabel == null ||
      this.state.questionNewLabel == "" ||
      this.state.questionText == null ||
      this.state.questionText == "" ||
      this.state.questionner == null ||
      this.state.questionner == "" ||
      this.state.questionDescript == null ||
      this.state.questionDescript == ""
    ) {
      alert("not posted");
    } else {
      axios({
        method: "POST",
        //url: 'http://localhost:8000/questionbank/questionadd',
        url: process.env.REACT_APP_LOGIN_URL + "/questionbank/questionadd",
        headers: {
          Authorization: "Token " + config,
        },
        data: {
          questionkindcode: this.state.questionNewLabel,
          questiontext: this.state.questionText,
          questionlabel: this.state.questionner,
          questiondescription: this.state.questionDescript,
          nertype: this.state.questionner,
          bullhornfieldID: this.state.bullhornfieldID

        },
      })
        //get the token from the backend and set the authentication to true
        //store the token in session storage
        .then((response) => {
          this.GetData();
          this.setState({
            showQuestion: false,
            SnackbarMessage: "Question Added Successfully!",
            snackbaractive: true,
            questionText: null,
            questionDescript: null
          });
          this.setState({
            allquestionsShow: true,
            showfilterQuestions: true,
            rolequestionsShow: false,

          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            showRole: false,
            snackbaractiveError: true,
            SnackbarMessage: "Error!",
          });
        });
    }
  }

  onSubmitRole(e) {
    e.preventDefault();
    const cookies = new Cookies();
    var config = cookies.get("token");

    if (!cookies.get("token")) {
      config = localStorage.getItem("token");
      //console.log("token: ", config)
    }
    if (
      this.state.roleDescription == undefined ||
      this.state.roleDescription == null ||
      this.state.roleDescription == "" ||
      this.state.roleTitle == undefined ||
      this.state.roleTitle == null ||
      this.state.roleTitle == "" ||
      this.state.roleLabel == undefined ||
      this.state.roleLabel == null ||
      this.state.roleLabel == ""
    ) {
      alert("Fill in all fields");
    } else {
      axios({
        method: "POST",
        url: process.env.REACT_APP_LOGIN_URL + "/workorder/createrole",
        headers: {
          Authorization: "Token " + config,
        },
        data: {
          label: this.state.roleLabel,
          title: this.state.roleTitle,
          description: this.state.roleDescription,
        },
      })
        //get the token from the backend and set the authentication to true
        //store the token in session storage
        .then((response) => {
          console.log("success");
          this.GetData();
          this.setState({
            showRole: false,
            SnackbarMessage: "Role Added Successfully!",
            snackbaractive: true,
            roleLabel: null,
            roleTitle: null,
            roleDescription: null

          });
          this.setState({
            allquestionsShow: false,
            showfilterQuestions: false,
            rolequestionsShow: true,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            showRole: false,
            snackbaractiveError: true,
            SnackbarMessage: "Error!",
          });
        });
    }
  }
  handleClicks = (page) => {
    this.setState({
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize,
    });
  };
  handleClicks1 = (page) => {
    this.setState({
      current1: page,
      minIndex1: (page - 1) * pageSize1,
      maxIndex1: page * pageSize1,
    });
  };

  handleRoleClick = (e) => {
    window.location.href = '/questionbank/' + e
  }

  render() {
    const {
      questions,
      search,
      showQuestion,
      showRole,
      rolesDisplay,
      current,
      minIndex,
      maxIndex,
      current1,
      minIndex1,
      maxIndex1,
      questionKindSelect,
      questionNer,
      allquestionsShow,
      rolequestionsShow,
      activeDiv

    } = this.state;
    var questionsDisplay = questions;
    const filteredQuestions = questionsDisplay.filter((question) => {
      const searchTerm = search.toLowerCase();

      return searchTerm === "" ||
        question.questiontext.toLowerCase().includes(searchTerm) ||
        question.questionlabel.toLowerCase().includes(searchTerm) ||
        question.questionkindcode__kinddescription.toLowerCase().includes(searchTerm) ||
        question.questiondescription.toLowerCase().includes(searchTerm)
    });

    const filteredQuestions1 = rolesDisplay.filter((question) => {
      const searchTerm = search.toLowerCase();

      return searchTerm === "" ||
        question.description.toLowerCase().includes(searchTerm) ||
        question.label.toLowerCase().includes(searchTerm) ||
        question.title.toLowerCase().includes(searchTerm)
    });
    const { activeTab } = this.state;
    console.log("roles", rolesDisplay)



    console.log("TwO", questions)
    const optionsChange = () => {
      const id = document.getElementById("Types").value;
      if (id == "FUNC") {
        questionsDisplay = [];
        for (let i = 0; i < questions.length; i++) {
          if (questions[i].questionkindcode == "FUNC") {
            questionsDisplay.push({ questions: questions[i] });
          }
        }
      }
      if (id == "JOB") {
        for (let i = 0; i < questions.length; i++) {
          if (questions[i].questionkindcode == "JOBSPCFC") {
          }
        }
      }

      if (id == "UNI") {
        for (let i = 0; i < questions.length; i++) {
          if (questions[i].questionkindcode == "UNQDIFF") {
          }
        }
      }

      if (id == "BD") {
        for (let i = 0; i < questions.length; i++) {
          if (questions[i].questionkindcode == "BD") {
          }
        }
      }
    };
    const itemList = ["Item1", "Item2", "Item3", "Item4", "Item5"];
    const highlightedClass = activeDiv === "div1" ? styles.highlighted : styles.allquestions;
    const highlightedClass1 = activeDiv === "div2" ? styles.highlighted : styles.allquestions;

    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container className={styles.GridContainerWrapperHeight}>
          <Grid item md={1.7} xs={2} className={UniversalCss.LeftNavbarShadow}>
            <NavbarLeft />
          </Grid>
          <Grid
            item
            md={10.3}
            xs={10}
            className={UniversalCss.navbarAndRouteWrapper}
          >
            <NavbarTop PageData={"Questions"} />
            <Snackbar
              open={this.state.snackbaractive}
              autoHideDuration={10000}
              onClose={this.handleSnackbarClose}
            >
              <Alert
                onClose={this.handleSnackbarClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                {this.state.SnackbarMessage}
              </Alert>
            </Snackbar>
            <Snackbar
              open={this.state.snackbaractiveError}
              autoHideDuration={10000}
              onClose={this.handleSnackbarClose}
            >
              <Alert
                onClose={this.handleSnackbarClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                {this.state.SnackbarMessage}
              </Alert>
            </Snackbar>
            <Grid container className={styles.rectangle824}>
              <Grid
                item
                xs={2}
                className={styles.DashbordCallListHeaderItems1}
              >
                <button
                  className={`${highlightedClass}`}
                  onClick={() => {
                    this.showAllQuestions(); this.handleDivClick('div1')
                  }}

                >
                  All questions
                </button>

              </Grid>
              <Grid
                item
                xs={1.5}
                className={styles.DashbordCallListHeaderItems2}
              >
                <button
                  className={`${highlightedClass1}`}
                  onClick={() => {
                    this.showRoleQuestions(); this.handleDivClick('div2')
                  }}


                >
                  Roles
                </button>
              </Grid>
              <Grid
                item
                xs={1.5}
                className={styles.DashbordCallListHeaderItems3}
              >
                <button
                  className={styles.shape3}
                  onClick={this.openRoleHandler}
                >
                  Create Role
                </button>
              </Grid>
              <Grid
                item
                xs={1.5}
                className={styles.DashbordCallListHeaderItems4}
              >
                <button
                  className={styles.shape3}
                  onClick={this.openQuestionHandler}
                >
                  Create question
                </button>
              </Grid>
              <Grid
                item
                xs={2}
                className={styles.DashbordCallListHeaderItems5}
              >
                <TextField
                  color="secondary"
                  className={styles.searchkey}
                  disableUnderline={true}
                  id="outlined-basic"
                  label="Search"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={this.handleSearchChange}
                />
              </Grid>
            </Grid>
            <Modal
              size="lg"
              className={styles.modalPopupLabel}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={showQuestion}
              onHide={this.popupCloseQuestionHandler}
            >
              <Modal.Header closeButton>
                <span className={styles.createlabel}>Create Question</span>
              </Modal.Header>

              <Modal.Body>
                <div className={styles.groupLabel}>
                  <div className={styles.rectanglePopupLabel}>
                    <TextField
                      label="Add Question Here..."
                      id="questionID"
                      variant="outlined"
                      onChange={this.onChangeQuestion}
                      value={this.state.questionText}
                      required
                    />
                    <br></br>

                    <TextField
                      label="Add Question Description Here..."
                      id="questionID"
                      variant="outlined"
                      onChange={this.onChangeQuestionDescription}
                      value={this.state.questionDescript}
                      required
                    />
                      <br></br>
                    <TextField
                      label="Add Bullhorn Field Here..."
                      id="bullhornFieldId"
                      variant="outlined"
                      onChange={this.onChangeBullhornFieldID}
                      value={this.state.bullhornfieldID}
                      required
                    />

                    <br></br>

                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-filled-label">
                        Label
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        label="Label"
                        onChange={this.onChangeQuestionLabel}
                        required
                      >
                        {Array.isArray(questionKindSelect)
                          ? questionKindSelect.map((element) => {
                            return (
                              <MenuItem value={element[0]}>{element[1]}</MenuItem>
                            );
                          })
                          : null}
                      </Select>
                    </FormControl>

                    <br></br>
                    

                    <div className={styles.flexcontainerLabel1}>
                      <button
                        className={styles.shapeLabel}
                        onClick={this.popupCloseQuestionHandler}
                      >
                        Cancel
                      </button>
                      <button
                        className={styles.shapeLabel1}
                        onClick={this.onQuestionSubmit}
                      >
                        Create Question
                      </button>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              size="lg"
              className={styles.modalPopupLabel}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={showRole}
              onHide={this.popupCloseRoleHandler}
            >
              <Modal.Header closeButton className={styles.QuestionClose}>
                <span className={styles.createlabel}>Create Role</span>
              </Modal.Header>

              <Modal.Body>
                <div className={styles.groupLabel}>
                  <div className={styles.rectanglePopupLabel}>
                    <InputLabel id="demo-simple-select-filled-label">
                      Label
                    </InputLabel>
                    <TextField
                      label="For internal reference - example Amazon aws 2023"
                      id="questionID"
                      variant="outlined"
                      onChange={this.onChangeRoleLabel}
                      value={this.state.roleLabel}
                      required
                    />
                    <br></br>
                    <InputLabel id="demo-simple-select-filled-label">
                      Title
                    </InputLabel>
                    <TextField
                      label="Title for the role, e.g 'Full Stack Software Engineer'"
                      id="questionID"
                      variant="outlined"
                      onChange={this.onChangeRoleTitle}
                      value={this.state.roleTitle}
                      required
                    />
                    <br></br>
                    <InputLabel id="demo-simple-select-filled-label">
                      Description
                    </InputLabel>
                    <TextField
                      label="Any description or relevant comments"
                      id="questionID"
                      variant="outlined"
                      onChange={this.onChangeRoleDescription}
                      value={this.state.roleDescription}
                      required
                    />
                    <br></br>

                    <div className={styles.flexcontainerLabel1}>
                      <button
                        className={styles.shapeLabel}
                        onClick={this.popupCloseRoleHandler}
                      >
                        Cancel
                      </button>
                      <button
                        className={styles.shapeLabel1}
                        onClick={this.onSubmitRole}
                      >
                        Create Role
                      </button>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            <Grid container className={styles.GridDeuce}>
              <Grid
                item
                xs={12}
                className={styles.SecondPart}
              >

                {activeDiv === "div1" && (
                  <>

                    <div className={styles.Container}>
                      {questionsDisplay
                        .filter((questionDisplay) => {
                          const searchTerm = search.toLowerCase();
                          return (
                            searchTerm === "" ||
                            questionDisplay.questiontext.toLowerCase().includes(searchTerm) ||
                            questionDisplay.questionlabel.toLowerCase().includes(searchTerm) ||
                            questionDisplay.questiondescription.toLowerCase().includes(searchTerm)
                          );
                        })
                        .map(
                          (questionsDisplay, index) =>
                            index >= minIndex &&
                            index < maxIndex && (
                              <div
                                key={questionsDisplay.id}
                                className={styles.rectangle809}
                              > 
                                <div className={styles.flexcontainer14}>
                                  <div className={styles.shape5}>
                                    <span
                                      questionkindcode__kinddescription={
                                        questionsDisplay.questionkindcode__kinddescription
                                      }
                                      className={styles.logintoyouraccou}
                                    >
                                      {
                                        questionsDisplay.questionkindcode__kinddescription
                                      }
                                    </span>
                                  </div>



                                  <button
                                    className={styles.dottedbuttons}
                                    onClick={() =>
                                      this.getModal(questionsDisplay.id)
                                    }
                                    disabled={questionsDisplay.companyid == 0}
                                  >
                                    <img
                                      className={styles.vuesaxlinearmore4}
                                      src={vuesaxlinearmore4}
                                    />
                                  </button>
                                </div>

                                <span className={styles.whatisthebasicfu}>
                                  {questionsDisplay.questiontext}
                                </span>

                                <span className={styles.loremipsumdolorsi}>
                                  {questionsDisplay.questiondescription}
                                </span>
                                <ModalClass
                                  className={styles}
                                  show={
                                    this.state.showModal === questionsDisplay.id
                                  }
                                  onHide={() =>
                                    this.hideModal(questionsDisplay.id)
                                  }
                                  name={questionsDisplay.questiontext}
                                  description={
                                    questionsDisplay.questiondescription
                                  }
                                  label={
                                    questionsDisplay.questionkindcode__kinddescription
                                  }
                                  type={questionsDisplay.questionlabel}
                                  selectLabel={questionKindSelect}
                                  selectType={questionNer}
                                  passid={questionsDisplay.id}
                                />
                              </div>
                            )
                        )}
                    </div>

                    <div
                      style={{
                        marginRight: "3.5%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >

                    </div>
                  </>
                )}
                {activeDiv === "div2" ? (
                <>
                 
                  <div className={styles.flexcontainer12}>
                    <div className={styles.Container}>
                      {/*rolesDisplay
                        .filter((rolesDisplay) =>
                          search.toLowerCase() === ""
                            ? true
                            : rolesDisplay.title.toLowerCase().includes(search)
              )*/}

                      {rolesDisplay
                        .filter((rolesDisplay) => {
                          const searchTerm = search.toLowerCase();
                          return (
                            searchTerm === "" ||
                            rolesDisplay.description.toLowerCase().includes(searchTerm) ||
                            rolesDisplay.label.toLowerCase().includes(searchTerm) ||
                            rolesDisplay.title.toLowerCase().includes(searchTerm)
                          );
                        })
                        .map((rolesDisplay, index1) => {
                          const isInRange = index1 >= minIndex1 && index1 < maxIndex1;
                          return isInRange && (
                            <div
                              key={rolesDisplay.id}
                              className={styles.rectangle8091}

                            >
                              <div
                                className={styles.flexcontainer14}


                              >
                                <div className={styles.shape5} onClick={() => this.handleRoleClick(rolesDisplay.id)}>
                                  {rolesDisplay.label}
                                </div>
                                <button
                                  className={styles.dottedbuttons}
                                  onClick={() => this.getModal(rolesDisplay.id)}
                                >
                                  <img
                                    className={styles.vuesaxlinearmore4}
                                    src={vuesaxlinearmore4}
                                  />
                                </button>
                              </div>

                              <div className={styles.Content} onClick={() => this.handleRoleClick(rolesDisplay.id)}>
                                <div>
                                  <span className={styles.whatisthebasicfu}
                                  >
                                    {rolesDisplay.title}
                                  </span>
                                </div>


                                <span className={styles.loremipsumdolorsi}
                                >
                                  {rolesDisplay.description}
                                </span>
                              </div>

                              {rolesDisplay.companyid !== 0 && (
                                <RoleModalClass
                                  show={this.state.showModal === rolesDisplay.id}
                                  onHide={() => this.hideModal(rolesDisplay.id)}
                                  passid={rolesDisplay.id}
                                  roleDescription={rolesDisplay.description}
                                  roleLabel={rolesDisplay.label}
                                  roleTitle={rolesDisplay.title}
                                />
                              )}
                            </div>
                          );
                        })}
                    </div>


                  </div>
                  <div
                    style={{
                      marginRight: '3.5%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >


                  </div>
                </>

              ) : null}
              </Grid>

            </Grid>

          </Grid>

        </Grid>
        {activeDiv === "div2" ? (
              <Pagination
                style={{
                  position: 'absolute',
                  marginBottom: '10%',

                  right: '3.5%', /* Adjust as needed */


                }}
                className="CallPagination"
                showSizeChanger={false}
                pageSize={pageSize1}
                current={current1}
                total={filteredQuestions1.length}
                onChange={this.handleClicks1}

              />
            ) : null}
            
        {activeDiv === "div1" ? (
          <Pagination
            style={{
              position: 'absolute',
              marginBottom: '10%',

              right: '3.5%', /* Adjust as needed */

            }}
            showSizeChanger={false}
            className="CallPagination"
            pageSize={pageSize}
            current={current}
            total={filteredQuestions.length}
            onChange={this.handleClicks}
          />
        ) : null}
      </Box>
    );
  }
}

import { Box, Modal, Typography } from '@mui/material'
import React, { Component } from 'react'
import add from '../../Images/add.svg';
import styles from "../user.module.css";
import errorIcon from '../../Images/icons/Errorindicator.svg';

const Deletestyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 643,
    maxWidth: 643,
    minHeight: 477,
    bgcolor: 'background.paper',
    border: 'transparent',
    boxShadow: 24,
    p: 6,
    borderRadius: '15px',
    textAlign: 'center',
};

export default class DeleteUserModal extends Component {
    render() {
        const { DeleteuserOpen, handleEditUserModalClose, DeleteUser } = this.props
        return (
            <Modal open={DeleteuserOpen} aria-labelledby="modal-modal-title">
                <Box sx={Deletestyle}>
                    <img onClick={handleEditUserModalClose} style={{ position: 'relative', cursor: "pointer", left: '280px', top: '-35px' }} src={add} />
                    <div className={styles.ErrorMessageImageWrapper}>
                        <img src={errorIcon} />
                    </div>
                    <Typography style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "16px", lineHeight: "24px", textAlign: 'center', color: "#1D103B", margin: " 20px auto 20px auto", maxWidth: "270px" }}>
                        Are You Sure You Want To Deactivate This User From Your Account?
                    </Typography>
                    <div style={{ marginTop: "50px" }} className={styles.EditUSerBtns}>
                        <div onClick={handleEditUserModalClose} style={{ marginRight: "10px" }} className={`${styles.EditUserCancelBtn}`}>Cancel</div>
                        <div onClick={DeleteUser} style={{ marginLeft: "10px" }} className={`${styles.EditUserUpdateBtn}`}>Deactivate User</div>
                    </div>
                </Box>
            </Modal>
        )
    }
}
